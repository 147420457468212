import React, {useState, useEffect} from 'react';
import {Button, Form, FormControl, Icon, Schema, SelectPicker, Table} from 'rsuite';
import CheckPicker from 'rsuite/es/CheckPicker';
import Badge from 'rsuite/es/Badge';
import * as S from './styled';
import Modal from '../../components/Modal';
import {fieldSorter} from '../../utils'
import BaseTable from "../../components/base/BaseTable";

const {Column, HeaderCell, Cell} = Table;
const {NumberType, StringType} = Schema.Types;

const formModel = Schema.Model({
    wz_key: NumberType(),
    de_key: NumberType(),
    sde_key: NumberType(),
    prefix: StringType(),
    account_id: StringType()
});

const actionSelectData = [
    {option: false, label: 'Allowed'},
    {option: true, label: 'Blocked'},
];

export default ({data = [], filter = {}, accountList = [], onRemoveItem, onEditItem, onEditItemList, onAddItem, loading, isLoading, update, service, ...props}) => {
    const [tableData, setTableData] = useState([]);
    const [currentEditedFieldKeyList, setCurrentEditedFieldKeyList] = React.useState(null);
    const [editedFieldKeyList, setEditedFieldKeyList] = useState([]);
    const [deleteKeyList, onDeleteKeys] = useState([]);
    const [localLoading, setLocalLoading] = useState(isLoading);
    const [checkedKeys, onChangeCheckedKeys] = useState([]);
    const [changedData, onChangedData] = useState(false);

    const nullServicePlan = {
        "sp_key": null,
        "sp_name": null,
        "name": "Any"
    };
    const servicePlanList = [...props.servicePlanList.slice(), nullServicePlan].sort().reverse();

    const [formServicePlan, onChangeFormServicePlan] = useState({});

    let formGroupsRefs = new Map();

    const searchedData = ["sde_wz_name", "sde_de_name", "sde_name", "prefix", "sp_name", "acc_name", "action"];

    useEffect(() => {
        const tableDataList = data.filter((item) => {
            // return item if filter is empty
            if (!filter || filter && !filter.str)
                return true;

            const match = filter.str.toLowerCase();

            const filterMatching = searchedData.map((keyName) => {
                if (item[keyName]) {
                    const matchedString = item[keyName].toString().toLowerCase();

                    if (matchedString.includes(match)) {
                        return true
                    }
                }
            });

            return filterMatching.some(dataMatch => !!dataMatch);
        });
        tableDataList.sort(fieldSorter(['wz_key', 'de_key', 'sde_key', 'prefix', 'acc_name', 'sp_key']))
        const groupedData = Object.values(tableDataList.reduce((a, curr) => {
            const {acc_id, sde_key, prefix, de_key, wz_key} = curr;
            const key = `${acc_id}_${sde_key}_${prefix}_${de_key}_${wz_key}`;
            (a[key] = a[key] || []).push(curr);
            return a;
        }, {}));

        const combinedNumber = groupedData.map((value) => {
            const duplicateList = value.map(duplicate => {
                return {sp_key: duplicate.sp_key, sp_name: duplicate.sp_name, ab_key: duplicate.ab_key}
            });

            return {...value[0], sp_duplicates: duplicateList}
        });
        setTableData(combinedNumber);

        if (combinedNumber.length) {
            const curFormServicePlan = combinedNumber.reduce((acc, value) => {
                acc[value["ab_key"]] = {sp_key: value["sp_duplicates"].map(sp => sp.sp_key)};
                return acc;
            }, {});
            onChangeFormServicePlan({...formServicePlan, ...curFormServicePlan})
        }

        onChangeCheckedKeys(combinedNumber.filter(item => item.action).map(item => item.ab_key));
        if (currentEditedFieldKeyList) {
            let editedFilters = new Set([...editedFieldKeyList, currentEditedFieldKeyList]);
            editedFilters.delete(currentEditedFieldKeyList);
            setEditedFieldKeyList(Array.from(editedFilters));
            setCurrentEditedFieldKeyList(null);
        }
    }, [data, filter]);

    const createFormGroupRefs = (key, ref) => {
        if (!formGroupsRefs.has(key)) {
            formGroupsRefs.set(key, new Set([ref]))
        } else {
            formGroupsRefs.get(key).add(ref);
        }
    };

    const clearEditField = (key) => {
        let editedFilters = new Set([...editedFieldKeyList, key]);
        editedFilters.delete(key);
        setEditedFieldKeyList(Array.from(editedFilters));
        setCurrentEditedFieldKeyList(null);
    };

    const handleCheck = (value, checked) => {
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);
        onChangeCheckedKeys(nextCheckedKeys)
    };

    const handleSubmit = async (key) => {
        const formFields = formGroupsRefs.get(key);
        let dataObject = {action: checkedKeys.some(item => item === key)};
        formFields.forEach((field) => {
            if (!field) {
                return;
            }
            const formData = field.getFormValue();

            Object.keys(formData).forEach(key => {
                if (key !== 'action') dataObject[key] = formData[key];
            });
        });
        const tableRow = tableData.find(value => value.ab_key === key);
        const duplicateKeys = tableRow.sp_duplicates.map(duplicate => duplicate.ab_key);
        const filteredData = data.filter(item => duplicateKeys.includes(item.ab_key));

        const absentRows = filteredData.filter(item => !dataObject.sp_key.includes(item.sp_key));
        const existRows = filteredData.filter(item => dataObject.sp_key.includes(item.sp_key));
        const existRowKeys = existRows.map(item => item.sp_key);

        if (!dataObject.sp_key.length) {
            const allRowsToDelete = filteredData.map(item => item.ab_key);

            allRowsToDelete.forEach(key => {
                onRemoveItem(key, service).then(() => {
                    onChangedData(true);
                    update()
                })
            })
        } else {
            // compare two objects
            const existRowsComparable = existRows.map(row => {
                return {
                    account_id: row.acc_id,
                    action: row.action,
                    wz_key: row.wz_key,
                    de_key: row.de_key,
                    sde_key: row.sde_key,
                    prefix: row.prefix,
                    sp_key: null
                }
            });
            const dataObjectComparable = Object.assign({}, dataObject, {sp_key: null});
            let comparedRows;

            existRowsComparable.forEach(row => {
                comparedRows = !Object.is(row, dataObjectComparable)
            });

            return await onEditItemList(duplicateKeys, dataObjectComparable, service).then(async () => {
                const spKeyList = dataObject.sp_key;
                await addItemAsync(spKeyList, existRows, dataObject, filteredData);
                await removeItemAsync(absentRows);
                await update();
            })
        }

        return await key
    };
    const addItemAsync = async (spKeyList, existRows, dataObject, filteredData) => {
        const diffSpKeyList = spKeyList.filter(sp_key => !existRows.map(row => row.sp_key).includes(sp_key))

        for (const sp_key of diffSpKeyList) {
            let dataToEdit = {};
            if (filteredData.length) {
                dataToEdit = dataObject
            }

            onChangedData(true);
            await onAddItem({...dataToEdit, sp_key}, service);
        }
    };

    const removeItemAsync = async (absentRows) => {
        if (absentRows.length) {
            for (const item of absentRows) {
                onChangedData(true);
                await onRemoveItem(item.ab_key, service);
            }
        }
    };

    return (
        <>
            <BaseTable
                virtualized
                className="tableFilters"
                data={tableData}
                loading={loading || localLoading}
                height={tableData.length < 12 ? (tableData.length + 1) * 47 : 600}
                headerHeight={47}
                rowHeight={46}
            >
                <Column flexGrow minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Zone</span>
                    </HeaderCell>
                    <S.CustomCell dataKey={"sde_wz_name"}>
                        {(rowData) => {
                            if (editedFieldKeyList.includes(rowData["ab_key"])) {
                                return (
                                    <Form
                                        model={formModel}
                                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                                        formDefaultValue={{wz_key: rowData["wz_key"]}}
                                    >
                                        <FormControl
                                            name="wz_key"
                                            className="tableFilters_field"
                                            errorPlacement="topEnd"
                                            accepter={SelectPicker}
                                            data={props.worldzoneList}
                                            placeholder="Zone"
                                            valueKey="wz_key"
                                            labelKey="name"
                                            type="text"
                                        />
                                    </Form>
                                )
                            }
                            const servicePlanCount = rowData["sp_duplicates"].filter(sp => sp.sp_key);
                            return (
                                <>
                                    {servicePlanCount.length > 1 &&
                                    <Badge className="duplicates_badge" content={servicePlanCount.length}/>}
                                    <span className="tableFilters__previewText">{rowData["sde_wz_name"]}</span>
                                </>
                            )
                        }}
                    </S.CustomCell>
                </Column>
                <Column flexGrow minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Destination</span>
                    </HeaderCell>
                    <Cell dataKey={"sde_de_name"}>
                        {(rowData) => {
                            if (editedFieldKeyList.includes(rowData["ab_key"])) {
                                return (
                                    <Form
                                        model={formModel}
                                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                                        formDefaultValue={{de_key: rowData["de_key"]}}
                                    >
                                        <FormControl
                                            name="de_key"
                                            className="tableFilters_field"
                                            errorPlacement="topEnd"
                                            accepter={SelectPicker}
                                            data={props.destinationList}
                                            placeholder="Destination"
                                            valueKey="de_key"
                                            labelKey="name"
                                            type="text"
                                        />
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["sde_de_name"]}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={2} minWidth={130} className="access_list_exclusion__column" align="center">
                    <HeaderCell>
                        <span className="access_list_exclusion__header_text">Subdestination</span>
                    </HeaderCell>
                    <Cell dataKey={"sde_name"}>
                        {(rowData) => {
                            if (editedFieldKeyList.includes(rowData["ab_key"])) {
                                return (
                                    <Form
                                        model={formModel}
                                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                                        formDefaultValue={{sde_key: rowData["sde_key"]}}
                                    >
                                        <FormControl
                                            name="sde_key"
                                            className="tableFilters_field"
                                            errorPlacement="topEnd"
                                            accepter={SelectPicker}
                                            data={props.subdestinationList}
                                            placeholder="Subdestination"
                                            valueKey="sde_key"
                                            labelKey="name"
                                            type="text"
                                        />
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["sde_name"]}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={2} minWidth={130} className="access_list_exclusion__column" align="center">
                    <HeaderCell>
                        <span className="access_list_exclusion__header_text">Prefix</span>
                    </HeaderCell>
                    <Cell dataKey={"prefix"}>
                        {(rowData) => {
                            if (editedFieldKeyList.includes(rowData["ab_key"])) {
                                return (
                                    <Form
                                        model={formModel}
                                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                                        formDefaultValue={{prefix: rowData["prefix"]}}
                                    >
                                        <FormControl
                                            name="prefix"
                                            className="tableFilters_field"
                                            errorPlacement="topEnd"
                                            placeholder="Prefix"
                                            type="text"
                                        />
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["prefix"]}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={2} minWidth={150} className="access_list_exclusion__column" align="center">
                    <HeaderCell>
                        <span className="access_list_exclusion__header_text">Price</span>
                    </HeaderCell>
                    <Cell dataKey={"sp_name"}>
                        {(rowData) => {
                            const servicePlanName = rowData["sp_name"] ? rowData["sp_name"] : 'Any';

                            const servicePlanText = rowData["sp_duplicates"].length ?
                                rowData["sp_duplicates"].map(sp => {
                                    if (!sp.sp_name) {
                                        return 'Any';
                                    }
                                    return sp.sp_name;
                                }).join(', ') : servicePlanName;

                            if (editedFieldKeyList.includes(rowData["ab_key"])) {
                                return (
                                    <Form
                                        model={formModel}
                                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                                        formValue={formServicePlan[rowData["ab_key"]]}
                                        formDefaultValue={{sp_key: rowData["sp_duplicates"].map(sp => sp.sp_key)}}
                                        onChange={(formValue) => {
                                            let curFormValue = Object.assign({}, formValue);
                                            const lastFormValue = formServicePlan[rowData["ab_key"]];
                                            if (formValue["sp_key"].includes(null)) {
                                                curFormValue = {sp_key: [null]}
                                            }
                                            const diffFromValue = formValue["sp_key"].filter(value => !lastFormValue["sp_key"].includes(value));
                                            if (diffFromValue.length && diffFromValue.find(value => value)) {
                                                curFormValue = {sp_key: formValue["sp_key"].filter(value => value)}
                                            }
                                            onChangeFormServicePlan({...formServicePlan, [rowData["ab_key"]]: curFormValue})
                                        }}
                                    >
                                        <FormControl
                                            name="sp_key"
                                            className="tableFilters_field"
                                            errorPlacement="topEnd"
                                            accepter={CheckPicker}
                                            data={servicePlanList}
                                            placeholder="Price"
                                            cleanable={false}
                                            valueKey="sp_key"
                                            labelKey="name"
                                            type="text"
                                        />
                                    </Form>
                                )
                            }

                            return (
                                <span className="tableFilters__previewText">
                                    {servicePlanText}
                                </span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={2} minWidth={180} className="access_list_exclusion__column" align="center">
                    <HeaderCell>
                        <span className="access_list_exclusion__header_text">Dialer account</span>
                    </HeaderCell>
                    <Cell dataKey={"acc_name"}>
                        {(rowData) => {
                            const currentAccount = accountList.find(account => {
                                const equalId = rowData["acc_id_list"] ? rowData["acc_id_list"].includes(account.id) : true;

                                if (equalId) {
                                    const accountNameSplited = account.name.split("(");
                                    const accountName = accountNameSplited.length > 0 ? accountNameSplited[0].trim() : accountNameSplited;

                                    return rowData["acc_name"] ? accountName === rowData["acc_name"] : true;
                                }
                            });

                            if (editedFieldKeyList.includes(rowData["ab_key"])) {
                                return (
                                    <Form
                                        model={formModel}
                                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                                        formDefaultValue={{account_id: rowData?.acc_id}}
                                    >
                                        <FormControl
                                            name="account_id"
                                            className="tableFilters_field"
                                            errorPlacement="topEnd"
                                            accepter={SelectPicker}
                                            data={accountList}
                                            placeholder="Account"
                                            valueKey="id"
                                            labelKey="name"
                                            type="text"
                                        />
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["acc_name"] || 'Any'}</span>
                            );
                        }}
                    </Cell>
                </Column>
                <Column width={200} className="access_list_exclusion__column" align="center">
                    <HeaderCell>
                        <span className="access_list_exclusion__header_text">Block/Allow</span>
                    </HeaderCell>
                    <CheckCell
                        dataKey="ab_key"
                        checkedKeys={checkedKeys}
                        onChange={handleCheck}
                        {...{
                            editedFieldKeyList,
                            createFormGroupRefs
                        }}/>
                </Column>
                <Column width={174}>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="tableFilters_buttons">
                                {!editedFieldKeyList.includes(rowData["ab_key"])
                                    ? <Button size="sm" color="lightblue" onClick={() => {
                                        let editedFilters = new Set([...editedFieldKeyList, rowData["ab_key"]]);
                                        setEditedFieldKeyList(Array.from(editedFilters));
                                    }}>
                                        <Icon icon="edit2"/>
                                    </Button>
                                    : <>
                                        <Button size="sm" color="green" onClick={() => {
                                            handleSubmit(rowData["ab_key"]).then(async () => {
                                                clearEditField(rowData["ab_key"]);
                                            }) // --- SUBMIT
                                        }}>
                                            <Icon icon="check-circle"/>
                                        </Button>

                                        <Button size="sm" color="red" onClick={() => {
                                            let editedFilters = new Set([...editedFieldKeyList, rowData["ab_key"]]);
                                            editedFilters.delete(rowData["ab_key"]);
                                            setEditedFieldKeyList(Array.from(editedFilters));
                                            const spDuplicateKeys = rowData.hasOwnProperty("sp_duplicates") && rowData["sp_duplicates"].length ?
                                                rowData["sp_duplicates"].map(sp => sp.sp_key) : [rowData["sp_key"]];

                                            onChangeFormServicePlan({...formServicePlan, [rowData["ab_key"]]: {sp_key: spDuplicateKeys}});
                                        }}>
                                            <Icon icon="close-circle"/>
                                        </Button>
                                    </>
                                }
                                <Button
                                    size="sm"
                                    color="red"
                                    onClick={() => {
                                        onDeleteKeys(rowData.sp_duplicates.map(duplicate => duplicate.ab_key))
                                    }}
                                >
                                    <Icon icon="trash2"/>
                                </Button>
                            </div>
                        )}
                    </Cell>
                </Column>

            </BaseTable>
            <Modal
                show={deleteKeyList.length}
                title="Confirm deleting"
                onSuccess={() => {
                    deleteKeyList.forEach(key => {
                        onRemoveItem(key, service).then(() => {
                            update()
                        })
                    });
                    onDeleteKeys([])
                }}
                onClose={() => onDeleteKeys([])}
                successText={'Yes'}
                footer
            >
                <div style={{display: 'flex', justifyContent: "center"}}>Are you sure you want to delete this blocked destination?</div>
            </Modal>
        </>
    )
};

export const CheckCell = ({
    rowData,
    checkedKeys,
    dataKey,
    editedFieldKeyList,
    onChange,
    createFormGroupRefs,
    ...props
}) => {

    const currentLabel = rowData['action'];

    return (
        <Cell
            {...props}
            style={{padding: 0}}
        >
            <div>
                {
                    editedFieldKeyList.includes(rowData["ab_key"])
                    ? <Form
                        model={formModel}
                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                        formDefaultValue={{action: rowData["action"]}}
                    >
                        <FormControl
                            name="action"
                            accepter={SelectPicker}
                            data={actionSelectData}
                            onChange={(val) => onChange(rowData[dataKey], val)}
                            valueKey="option"
                            labelKey="label"
                            className="tableFilters_field"
                            errorPlacement="topEnd"
                            cleanable={false}
                            searchable={false}
                        />
                    </Form>
                    : <span className="tableFilters__previewText">{actionSelectData[+currentLabel].label}</span>
                }
            </div>
        </Cell>
    );
};