import React, {useRef} from "react";
import {Form, Input, Modal, Message, Alert, Schema} from "rsuite";
import {copyToClipboard, generatePassword} from "utils";
import CustomField from "pages/NewAccount/ContactCustomFieldRefactored";
import {BaseButton} from "components/base/BaseButton";
import styled from "styled-components";

import m from "definedMessages";

const {StringType} = Schema.Types;
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#?!_@$%^&*-]{8,}$/;

const PasswordModal = ({
    show,
    email,
    disabled,
    passwordState,
    errorsPasswordForm,
    
    formatMessage,

    update,
    onCloseModal,
    setPasswordState,
    setErrorsPasswordForm
}) => {
    const passwordRef = useRef(null);


    const passwordModel = Schema.Model({
        password: StringType()
            .isRequired(formatMessage(m.required))
            .maxLength(40, formatMessage(m.minLength, {count: 40}))
            .addRule(value => passwordRegExp.test(value), formatMessage(m.pleaseEnterLegalCharacters)),
        confirm_password: StringType()
            .isRequired(formatMessage(m.required))
            .addRule((value, data) => data.password === value, formatMessage(m.passwordDoesntMatch)),
    });

    const setPassword = () => {
        if (!errorsPasswordForm.password && !errorsPasswordForm.confirm_password) {
            const formValue = passwordRef.current.getFormValue();
            
            if (!formValue)
                return;

            const password = passwordRef.current.getFormValue().password;

            update(password);
            setPasswordState(null);
            onCloseModal();
        }
    };

    const onChangePasswordForm = (formValue) => {
        setPasswordState(formValue);
        setTimeout((passwordRef) => {
            const status = passwordModel.check(passwordRef.current.getFormValue());
            const password = status.password.hasError ? status.password.errorMessage : null;
            const confirm_password = status.confirm_password.hasError ? status.confirm_password.errorMessage : null;
            setErrorsPasswordForm({
                password,
                confirm_password
            });
        }, 100, passwordRef);
    };

    const generateNewPassword = () => {
        const password = generatePassword();
        setPasswordState({...passwordState, password, confirm_password: password});
        const textToCopy = `${formatMessage(m.email)}: ${email} ${formatMessage(m.password)}: ${password}`;
        copyToClipboard(textToCopy);
        Alert.success(`${formatMessage(m.newGeneratedPasswordHasBeenCopied)}!`, 10000)
    };

    return <Modal width={700} show={show} onClose={onCloseModal} onHide={onCloseModal}>
        <Message
            type="info"
            description={
                <p>{formatMessage(m.customerPasswordValidation)}</p>
            }
            style={{marginBottom: 10}}
        />

        <Form
            layout="inline"
            ref={passwordRef}
            // model={passwordModel}
            formValue={passwordState}
            onChange={(formValue) => onChangePasswordForm(formValue)}
        >
            <CustomField
                disabled={disabled}
                accepter={Input}
                name="password"
                type="password"
                placeholder={formatMessage(m.password)}
                errorMessage={errorsPasswordForm.password}
            />
            <CustomField
                disabled={disabled}
                accepter={Input}
                name="confirm_password"
                type="password"
                placeholder={formatMessage(m.confirmPassword)}
                errorMessage={errorsPasswordForm.confirm_password}
            />
            <FormButton onClick={generateNewPassword}>{formatMessage(m.newSubaccountGeneratePassword)}</FormButton>
            <FormButton onClick={setPassword}>{formatMessage(m.newSubaccountSetPassword)}</FormButton>
        </Form>
    </Modal>
};

export default PasswordModal;


const FormButton = styled(BaseButton).attrs(() => ({
    buttonStyle: "primary",
    size: "sm"
}))`
    &&& {
        height: 36px;
        margin-right: 5px;
    }
`;