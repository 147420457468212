import React, { useState, useEffect } from "react";
import Page from "components/base/Page";
import PageHeader from "components/base/PageHeader";
import styled, {createGlobalStyle} from "styled-components";
import AccessListFiltersSettings from "./AccessListFiltersSettings";
import AccessListFiltersFilter from "./AccessListFiltersFilter";
import AccessListFiltersTable from "./AccessListFiltersTable";
import { FilterSectionFlexboxGrid, StyledItemFitWidth, StyledItemAutoMargin } from "components/base/FitContainers";
import { ButtonPrimary } from "components/base/BaseButton";
import { Alert } from "rsuite";
import AccessListFiltersCreateModal from "./AccessListFiltersCreateModal";
import { withTranslation } from "hoc/WithTranslation";
import { pipe } from "utils/helpers";
import ModalConfirm from "components/Modal/ModalConfirm";


const AccessListFiltersPage = ({
    data: dataList,
    settings,
    settingsIsFetched,
    loading,

    service,
    account,
    accountId,
    dialerInfo,
    permissions,
    accountDialerState,
    accountJoinList,

    servicePlanList,
    currencyList,
    worldzoneList,
    destinationList,
    subdestinationList,
    numberTypeFamilyList,

    getNotification,
    modifyNotification,
    getAuthNumberType,
    getList,
    create,
    modify,
    remove,
    setLoading,

    showReasonHandler,

    formatMessage
}) => {

    const defaultFilterValue = {
        str: "",
        antf_key: null
    };

    const defaultSettingsValue = {
        daily: false,
        price_list: [],
        datetime: new Date(),
        type: null,
        filtered_destinations: false
    };

    const filtersModel = {
        antf_key: (value, filterValue) => value.antf_key === filterValue || !filterValue,
        str: (value, filterValue) => (value.a_prefix && value.a_prefix.includes(filterValue))
            || (value.a_worldzone_name && value.a_worldzone_name.includes(filterValue))
            || (value.b_worldzone_name && value.b_worldzone_name.includes(filterValue))
            || (value.a_destination_name && value.a_destination_name.includes(filterValue))
            || (value.b_destination_name && value.b_destination_name.includes(filterValue))
            || (value.a_subdestination_name && value.a_subdestination_name.includes(filterValue))
            || (value.b_subdestination_name && value.b_subdestination_name.includes(filterValue))
    };

    const [filter, setFilter] = useState(defaultFilterValue);
    const [filteredData, setFilteredData] = useState(dataList);
    const [settingsValue, setSettingsValue] = useState(defaultSettingsValue);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteKey, setDeleteKey] = useState(null);


    const [editData, setEditData] = useState(null);

    const defaultSort = {
        column: "number", type: "asc"
    };

    const [sort, setSort] = useState(defaultSort);

    useEffect(() => {
        if (accountId) {
            getNotification(accountId);
            getList(service, accountId);
            getAuthNumberType();
        }
    }, [accountId]);

    useEffect(() => {
        if (settings) {
            setSettingsValue(settings);
        }
    }, [settings]);

    useEffect(() => {
        setFilteredData((data) => {
            if (dataList.length) {
                return pipe(dataList, filter, filtersModel);
            }
            return [];
        });

        
    }, [dataList, filter]);

    const getItems = () => {
        getList(service, accountId);
    };

    const onEditAccessList = (data) => {
        setShowEditModal(true);
        setEditData(data);
    };

    const onDeleteAccessList = (alfKey) => {
        setShowDeleteModal(true);
        setDeleteKey(alfKey);
    };

    const onSubmitCreateNew = async (value) => {
        const requestData = {
            ...value,
            rate_min: value?.rate_min ? parseFloat(value.rate_min) : value.rate_min
        };

        setLoading(true);

        await create(accountId, service, requestData)
            .then((response) => {
                if (response?.access_list_filter && response.access_list_filter?.alf_key) {
                    Alert.success("Access list filter was created")

                    getItems();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onSubmitModify = async (alfKey, value) => {
        const requestData = {
            ...value,
            rate_min: value?.rate_min ? parseFloat(value.rate_min) : value.rate_min
        };

        setLoading(true);

        await modify(alfKey, accountId, service, requestData)
            .then((response) => {
                if (response?.access_list_filter && response.access_list_filter?.alf_key) {
                    Alert.success("Access list filter was modified")

                    getItems();
                }
            })
            .finally(() => {
                setLoading(false);
                setEditData(null);
            });
    };

    const onSubmitRemove = async () => {
        setLoading(true);

        await remove(deleteKey, accountId, service)
            .then((response) => {
                if (response?.access_list_filter && response.access_list_filter?.alf_key) {
                    Alert.success("Access list filter was deleted")

                    getItems();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createModalShowFlag = showCreateModal || showEditModal;
    const createModalSubmitFunc = editData ? onSubmitModify : onSubmitCreateNew;


    return (<Page>
        <GlobalStyle/>
        <PageHeader title={ "Access List Filters" }/>

        <AccessListFiltersSettings
            service={service}
            loading={loading}
            accountId={accountId}
            settingsValue={settingsValue}
            settingsIsFetched={settingsIsFetched}
            setSettingsValue={setSettingsValue}
            setLoading={setLoading}
            handleSubmit={modifyNotification}
    
            formatMessage={formatMessage}
        />

        <StyledFilterSectionFlexboxGrid>
            <StyledItemFitWidth>
                <AccessListFiltersFilter
                    service={service}
                    filter={filter}

                    numberTypeFamilyList={numberTypeFamilyList}

                    setFilter={setFilter}

                    formatMessage={formatMessage}
                />
            </StyledItemFitWidth>
            <StyledItemAutoMargin>
                <ButtonPrimary
                    onClick={() => {
                        setShowCreateModal(true);
                    }}
                >
                    Create new
                </ButtonPrimary>
            </StyledItemAutoMargin>
        </StyledFilterSectionFlexboxGrid>

        <AccessListFiltersTable
            data={filteredData}
            loading={loading}

            setSort={setSort}
            onEdit={onEditAccessList}
            onDelete={onDeleteAccessList}
            getItems={getItems}

            formatMessage={formatMessage}
        />

        <AccessListFiltersCreateModal
            show={createModalShowFlag}
            data={editData}

            servicePlanList={servicePlanList}
            currencyList={currencyList}
            worldzoneList={worldzoneList}
            destinationList={destinationList}
            subdestinationList={subdestinationList}
            numberTypeFamilyList={numberTypeFamilyList}

            onCloseModal={() => {
                setShowCreateModal(false);
                setShowEditModal(false);
                setEditData(null);
            }}
            onSubmit={createModalSubmitFunc}

            formatMessage={formatMessage}
        />
        {showDeleteModal && <ModalConfirm
            show={showDeleteModal}
            title={"Confirm please"}
            onSuccess={onSubmitRemove}
            onClose={() => setShowDeleteModal(false)}
        >
            <p>Are you sure you wanna delete this?</p>
        </ModalConfirm>}
    </Page>)
};

export default withTranslation(AccessListFiltersPage);


const GlobalStyle = createGlobalStyle`
    .rs-picker-check-menu-items {
        overflow-y: hidden !important;
        max-height: none !important;
    }
`;

const StyledFilterSectionFlexboxGrid = styled(FilterSectionFlexboxGrid)`
    && {
        margin-top: 0px;
        margin-bottom: 20px;
    }
`;