import React, {useMemo} from "react";
import styled from "styled-components";
import TableEditCellsNew from "components/Table/TableEditCells/TableEditCellsNew";
import {Input, Schema, SelectPicker} from "rsuite";


const {StringType, NumberType} = Schema.Types;



export default ({
    data,
    service,
    isSupplier,

    mccList,
    e212List,
    servicePlanList,
    suppliersGroupList,
    subdestinationList,
    destinationList,
    worldzoneList,

    trafficTypeState,

    setShowCreateModal,
    setSelectedDefaultData,

    ...props
}) => {

    const isMcc = trafficTypeState === "mcc";
    const isGanNumber = trafficTypeState === "gan";
    const isDefault = trafficTypeState === "mcc_default";

    const dayModel = Schema.Model({
        day: NumberType()
            .min(1, "Minimum 1")
            .max(10000000, "Maximum 10000000")
            .isRequired("This field is required"),
    });

    const hourModel = Schema.Model({
        hour: NumberType()
            .min(1, "Minimum 1")
            .max(10000000, "Maximum 10000000")
            .isRequired("This field is required"),
    });

    const maxDurationModel = Schema.Model({
        call_duration_max: NumberType().min(1, "Enter a positive number").max(10800, "3 hours is maximum").isRequired("Required")
    });
    
    const newTrafficLimitModel = Schema.Model({
        sp_key: NumberType().isRequired("This is required field")
    });
    
    const newSupplierTrafficLimitModel = Schema.Model({
        ...(["gan_group", "gan", "prefix", "mcc"].includes(trafficTypeState)
        ? {
            senderid: StringType()
                .maxLength(40, "Maximum 40")
                .isRequired("This is required field"),
                
        } : {})
    });
    
    const formModel = Schema.Model.combine(
        maxDurationModel,
        isSupplier ? newSupplierTrafficLimitModel : newTrafficLimitModel
    );

    const columns = useMemo(() => {
        
        // displayWhen: ["gan_group", "gan", "prefix", "mcc"],
        if (isSupplier || isDefault) {

            return [
                ...(isDefault ? [{
                    id: "default_type",
                    name: "Type",
                    placeholder: "Type",
                    dataKey: "defaultType",
                    flexGrow: 1,
                    displayWhen: ["mcc_default", "supplier"],
                }] : []),
                ...(isGanNumber ? [{
                    id: "gan_number",
                    name: "Number",
                    placeholder: "Number",
                    dataKey: "gan_number",
                    flexGrow: 2,
                    displayWhen: ["gan"],
                    // model: maxDurationModel,
                }] : []),
                ...(isSupplier ? [{
                    id: "group_name",
                    name: "Group name",
                    placeholder: "Group name",
                    dataKey: "group_name",
                    flexGrow: 3,
                    displayWhen: ["gan_group", "gan"],
                    defaultValue: (params) => params["gan_group_id"],
                    value: ({gan_group_name}) => `${gan_group_name}`
                }] : []),
                ...(isMcc ? [{
                    id: "mcc",
                    name: "Operator",
                    placeholder: "Operator",
                    dataKey: "mcc",
                    defaultValue: ({mccmnc_name}) => mccmnc_name,
                    value: ({mccmnc_name}) => `${mccmnc_name}`,
                    flexGrow: 3,
                    displayWhen: ["prefix", "mcc"],
                }] : []),
                {
                    id: "senderid",
                    name: "Sender ID",
                    placeholder: "Sender ID",
                    dataKey: "senderid",
                    value: ({senderid}) => senderid || "Any senderId",
                    flexGrow: 3,
                    displayWhen: ["gan_group", "gan", "prefix", "mcc", "supplier", "mcc_default"],
                },
                {
                    id: "day",
                    name: "Day",
                    placeholder: "Day",
                    dataKey: "day",
                    value: ({day, count_day}) => isDefault && count_day === undefined ? `${day}` : `${day}/${count_day}`,
                    displayWhen: ["gan_group", "gan", "prefix", "mcc", "supplier", "mcc_default"],
                    flexGrow: 1,
                    accepter: Input,
                    model: dayModel,
                },
                {
                    id: "hour",
                    name: "Hour",
                    placeholder: "Hour",
                    dataKey: "hour",
                    value: ({hour, count_hour}) => isDefault && count_hour === undefined ? `${hour}` : `${hour}/${count_hour}`,
                    displayWhen: ["gan_group", "gan", "prefix", "mcc", "supplier", "mcc_default"],
                    flexGrow: 1,
                    accepter: Input,
                    model: hourModel,
                },
            ];
        }

        return [
            {
                id: "sp_key",
                name: "Price",
                dataKey: "sp_key",
                valueKey: "sp_key",
                labelKey: "name",
                placeholder: "Price",

                minWidth: 300,
                flexGrow: 1,

                accepter: SelectPicker,
                cleanable: false,
                data: servicePlanList,

                model: newTrafficLimitModel,

                defaultValue: ({sp_key}) => sp_key,
                value: ({service_plan_name}) => service_plan_name,
            },
            {
                id: "wz_key",
                name: "Worldzone",
                dataKey: "wz_key",
                valueKey: "wz_key",
                labelKey: "name",
                placeholder: "Worldzone",

                minWidth: 300,
                flexGrow: 1,

                accepter: SelectPicker,
                data: worldzoneList,

                defaultValue: ({wz_key}) => wz_key,
                value: ({worldzone_name}) => worldzone_name,
            },
            {
                id: "de_key",
                name: "Destination",
                dataKey: "de_key",
                valueKey: "de_key",
                labelKey: "name",
                placeholder: "Destination",

                minWidth: 300,
                flexGrow: 1,

                accepter: SelectPicker,
                data: destinationList,

                defaultValue: ({de_key}) => de_key,
                value: ({destination_name}) => destination_name,
            },
            ...(service ? [{
                id: "sde_key",
                name: "Subdestination",
                dataKey: "sde_key",
                valueKey: "sde_key",
                labelKey: "name",
                placeholder: "Subdestination",

                minWidth: 300,
                flexGrow: 1,

                accepter: SelectPicker,
                data: subdestinationList,

                defaultValue: ({sde_key}) => sde_key,
                value: ({subdestination_name}) => subdestination_name,
            }] : [{
                id: "mcc",
                name: "Operator",
                dataKey: "operator",
                valueKey: "operator",
                labelKey: "name",
                placeholder: "Operator",

                // minWidth: 300,
                flexGrow: 1,

                accepter: SelectPicker,
                data: e212List,

                defaultValue: ({mcc, mnc}) => `${mcc}${mnc}`,
                value: ({country_name, operator, mcc, mnc}) => `${country_name}, ${operator} (${mcc}${mnc})`,
            }]),
            {
                id: "call_duration_max",
                name: "Max Duration",
                dataKey: "call_duration_max",
                placeholder: "Max Duration",

                // minWidth: 200,
                width: 130,
                // flexGrow: 1,

                accepter: Input,
                model: maxDurationModel,
            },
        ];
    }, [isSupplier, service, trafficTypeState, mccList, servicePlanList, worldzoneList, destinationList, subdestinationList]);
 
    const convertedData = useMemo(() => {
        return data.map((value, idx) => {
            if ("gan_key" in value) {
                value.senderid_gan_key = `${value?.senderid}_${value?.gan_key}`;
                value.key = value.senderid_gan_key;
            }

            if ("key" in value && value.key === null) {
                value.default = true;
                value.onRowSubmitDefault = () => {
                    const selectedData = {
                        mcc: value.mcc,
                        mnc: value.mnc,
                        hour: value.hour,
                        day: value.day,
                        senderid: value.senderid
                    };
                    setSelectedDefaultData(selectedData);
                    setShowCreateModal(true);
                };
                value.key = `${value.key}_${idx}`;
            }
            return value;
        });
    }, [data, trafficTypeState]);

    const filteredColumns = columns.filter(el => "displayWhen" in el ? el.displayWhen.indexOf(trafficTypeState) !== -1 : true);
    const rowIdKey = isSupplier ? "key" : "tl_key";

    return (
        <StyledTable
            rowIdKey={rowIdKey}
            defaultRowIdKey="senderid_gan_key"
            rowClassName={(rowData) => {
                if (rowData)
                    return rowData.undeletable ? "undeletable-row" : ""
            }}
            virtualized
            data={convertedData}
            height={500}
            columns={filteredColumns}
            headerHeight={40}
            autoHeight={false}
            shouldUpdateScroll={false}
            formModel={formModel}
            addButtonWidth={125}
            canCreate={!!service}
            canModify={!isGanNumber}
            canRemove={!isGanNumber}
            defaultDisableKey="trunk_lm_key"
            {...props}
        />
    );
};

const StyledTable = styled(TableEditCellsNew)`
    &&.rs-table.rs-table-hover {
        margin-top: 0;
        margin-bottom: 0;
    }

    .rs-table-row.undeletable-row .rs-table-cell-content {
        background: lightgray;
    }

    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons {
        float: right;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons > .rs-btn {
        width: 28px;
        height: 28px;
        padding: 4px 8px;
    }
    
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons > .rs-btn {
        margin-right: 5px;
    }
    .rs-table-body-row-wrapper .rs-table-cell-content .tableFilters_buttons .rs-btn-lightblue {
        color: var(--color-light);
        background: var(--color-info);
    }
    
    .rs-error-message.rs-error-message-show {
    
        position: absolute;
        color: red;
        top: -4px;
        font-size: 10px;
        border-radius: 10px;
        bottom: auto !important;
        padding: 0 4px;
        right: 6px !important;
    }
    
    .rs-error-message.rs-error-message-show .rs-error-message-arrow {
        display: none;
    }
    .rs-error-message.rs-error-message-show .rs-error-message-inner {
        white-space: nowrap;
    }
`;