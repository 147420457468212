import {connect} from 'react-redux';
import {
    getAccountAllocatedNumbers, getAccountTrunk, setCurrentTrunkId, setCurrentRangeNumber,
    setAllocationRestrictions
} from '../../../actions/accounts';
import {getAccountServicePlanList} from "../../../actions/self_allocation_restrictions";
import {getTrunkNumberCsvById} from '../../../actions/numbers';
import TabTrunksRefactored from './TabTrunksRefactored';
import {trunkDownloadNumbers, unsetTrunksInited} from '../../../actions';
import {getIvrList} from '../../../actions/ivr';



const mapState = ({ auth, ivr, suppliers, accounts, references, self_allocation_restrictions}) => ({
    ivr_list: ivr.list,
    authInfo: auth.authInfo,
    account: accounts.accountView,
    trunks: accounts.accountTrunk,
    trunksInited: accounts.trunksInited,
    trunksLoading: accounts.accountTrunkLoading,
    allocatedNumbers: accounts.accountAllocated,
    allocatedNumbersCount: accounts.accountAllocatedCount,
    allocatedNumbersTotalCount: accounts.accountAllocatedNumbersTotalCount,
    allocatedNumbersPage: accounts.allocatedNumbersPage,
    allocatedNumbersPerPage: accounts.allocatedNumbersPerPage,
    allocatedNumbersLoading: accounts.accountAllocatedLoading,
    worldzone_list: references.worldzone_list,
    subdestination_list: references.subdestination_list,
    destination_list: references.destination_list,
    currentTrunkId: accounts.currentTrunkId,
    currentRangeNumber: accounts.currentRangeNumber,
    accountServicePlanList: self_allocation_restrictions.list,
    raterc_list: references.raterc_list,
    accountJoinList: accounts.accountJoinList,
    service: auth.service,
    currentSupplier: suppliers.currentSupplier
});


export default connect(mapState, {
    setAllocationRestrictions,
    setCurrentTrunkId,
    unsetTrunksInited,
    setCurrentRangeNumber,
    getAccountTrunk,
    getAccountAllocatedNumbers,
    getTrunkNumberCsvById,
    getAccountServicePlanList,
    trunkDownloadNumbers,
    getIvrList
})(TabTrunksRefactored);
  
