import {connect} from "react-redux";
import IncorporatedNumbers from "./IncorporatedNumbers";
import {
    getSupplierTrunkDropdownList,
} from "../../actions/accounts";
import {
    getIncorporatedNumbersGroups,
    getIncorporatedNumbersList,
    getIncorporatedNumbersSuppliers,
    clearIncorporatedNumberList,
} from "../../actions/incorporated_numbers";
import {getGanSuppliersCommonList} from "../../actions/gan_suppliers";
import {getSuppliers, getSupplierGroups, getSupplierNumbers} from "../../actions/suppliers";
import {getAccountManagersInformators} from "../../actions/account_managers";
import {showReasonHandler} from "../../actions/reason_handler";


const mapStateToProps = ({incorporated_numbers, gan_suppliers, references, suppliers, accounts, auth}) => ({
    service: auth.service,
    trunks: accounts.accountSupplierGanTrunks,
    authInfo: auth.authInfo,
    suppliers: suppliers.items,
    suppliersLoading: suppliers.loading,
    groupList: incorporated_numbers.groupList,
    groupListLoading: incorporated_numbers.groupListLoading,
    suppliersGroupList: suppliers.suppliersGroupList,
    suppliersGroupLoading: suppliers.suppliersGroupLoading,
    supplierList: incorporated_numbers.supplierList,
    supplierListFiltered: gan_suppliers.commonList,
    numberList: incorporated_numbers.numberList,
    numberCount: incorporated_numbers.numberCount,
    supplierPrefixCount: suppliers.suppliersPrefixListCount,
    suppliersPrefixList: suppliers.suppliersPrefixList,
    suppliersPrefixLoading: suppliers.suppliersPrefixLoading,
    numberListLoading: incorporated_numbers.numberListLoading,
    subdestinationList: references.subdestination_list,
    destinationList: references.destination_list,
    paymentTermsList: references.payment_terms_list,
    currencyList:references.currency_list,
    permissions: auth.permissions,
});

export default connect(mapStateToProps, {
    getIncorporatedNumbersGroups,
    getIncorporatedNumbersList,
    getIncorporatedNumbersSuppliers,
    getAccountManagersInformators,
    getSupplierGroups,
    getSupplierNumbers,
    showReasonHandler,
    getSuppliers,
    getTrunks: getSupplierTrunkDropdownList,
    clearIncorporatedNumberList,
    getGanSuppliersCommonList,
})(IncorporatedNumbers);