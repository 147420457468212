import {TRAFFIC_LIMITS as PREFIX} from '../const';

import {
    TRAFFIC_LIMITS_CREATE_API,
    TRAFFIC_LIMITS_LIST_API,
    TRAFFIC_LIMITS_MODIFY_API,
    TRAFFIC_LIMITS_REMOVE_API,
    SMS_TRAFFIC_LIMITS_LIST_API,
    SMS_TRAFFIC_LIMITS_CREATE_API,
    SMS_TRAFFIC_LIMITS_MODIFY_API,
    SMS_TRAFFIC_LIMITS_REMOVE_API
} from '../const/apiMethods';

import {api} from '../api/loginRoutes';
import {createAction} from './defaults';

const setList = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);

const setAccumulateList = createAction('SET_ACC_ITEMS_' + PREFIX);
const setClearAccumulateList = createAction('SET_CLEAR_ACC_ITEMS_' + PREFIX);

export const getTrafficLimitsList = (account_id) => (dispatch) => {
    dispatch(setLoading(true));
    api(TRAFFIC_LIMITS_LIST_API, {target: {account_id}})
        .then(result => {
            if (!result || (!result.trafficlimit_list && !result.call_duration_max)) {
                return;
            }
            dispatch(setList({
                call_duration_max: result.call_duration_max,
                allocation_ban_expired_at: result.allocation_ban_expired_at,
                list: result.trafficlimit_list
            }));
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
};

export const createTrafficLimit = (account_id, data) => async () => {
    return await api(TRAFFIC_LIMITS_CREATE_API, {
        target: {account_id},
        ...data
    });
};

export const modifyTrafficLimit = (account_id, data, defaultData) => async () => {
    return await api(TRAFFIC_LIMITS_MODIFY_API, {
        target: {
            account_id,
            tl_key: defaultData.tl_key,
        },
        ...data,
        call_duration_max: parseInt(data.call_duration_max),
    });
};

export const removeTrafficLimit = (account_id, key) => async () => {
    return await api(TRAFFIC_LIMITS_REMOVE_API, {
        target: {
            account_id,
            tl_key: key
        },
    });
};

export const getSupplierTrafficLimitsList = (supplier_id, type, filter) => (dispatch) => {
    dispatch(setLoading(true));
    api(SMS_TRAFFIC_LIMITS_LIST_API, {
        target: {
            'sms.supplier_id': supplier_id
        },
        type,
        filter
    })
        .then(result => {
            if (!result || (!result['sms.traffic_limit_list'])) {
                return;
            }
            dispatch(setList({
                list: result['sms.traffic_limit_list']
            }));
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
};

export const getAccumulateDataFromRequestedList = (requestList = []) => (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setClearAccumulateList());

    requestList.forEach(async (request) => {

        await api(request.method, request.params)
            .then((result) => {
                const defaultType = request.params?.type === "mcc_default" 
                    ? "Default MCC" : request.params?.type === "supplier" 
                        ? "Whole account limit" : null;
                const currentList = result[request?.result_list_name || 'list'].map(item => {
                    return {...item, type: request.params?.type, defaultType};
                });

                dispatch(setAccumulateList({list: currentList}))
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    });
    
    if (!requestList.length) {
        dispatch(setLoading(false));
    }
};

export const clearAccumulateData = () => (dispatch) => {
    dispatch(setClearAccumulateList());
};

export const createSupplierTrafficLimit = (data, supplier_id, gan_group_id, trunk_id) => async () => {
    // return await api(SMS_TRAFFIC_LIMITS_CREATE_API, {
    //     target: {
    //         "sms.supplier_id": supplier_id,
    //         ...(gan_group_id ? {'sms.supplier_gan_group_id': gan_group_id} : {}),
    //         ...(trunk_id ? {'sms.supplier_trunk_id': trunk_id} : {}),
    //     },
    //     ...data
    // });
};

export const modifySupplierTrafficLimit = (targetParams, data, defaultParams) => async () => {
    const {hour: hour, day: day, ...params} = data;

    const keyParams = {
        'sms.traffic_limit_key_list': [defaultParams?.key],
        'sms.supplier_id': targetParams?.supplier_id
    }

    if ('gan_key' in defaultParams) {
        keyParams['sms.supplier_gan_key_list'] = [defaultParams?.gan_key];
        keyParams['senderid_list'] = [defaultParams?.senderid];

        delete keyParams['sms.traffic_limit_key_list'];
    }

    return await api(SMS_TRAFFIC_LIMITS_MODIFY_API, {
        target: {
            ...(targetParams?.gan_group_id ? {'sms.supplier_gan_group_id': targetParams?.gan_group_id} : {}),
            ...(targetParams?.trunk_id ? {'sms.supplier_trunk_id': targetParams?.trunk_id} : {}),
            ...keyParams
        },
        hour: parseInt(hour),
        day: parseInt(day),
        ...params
    });
};

export const removeSupplierTrafficLimit = (targetParams, data, defaultParams) => async () => {
    const keyParams = {
        'sms.traffic_limit_key_list': [defaultParams?.key],
        'sms.supplier_id': targetParams?.supplier_id
    }

    if ('gan_key' in defaultParams) {
        keyParams['sms.supplier_gan_key_list'] = [defaultParams?.gan_key];
        keyParams['senderid_list'] = [defaultParams?.senderid];

        delete keyParams['sms.traffic_limit_key_list'];
    }
    
    const result = await api(SMS_TRAFFIC_LIMITS_REMOVE_API, {
        target: {
            ...(targetParams?.gan_group_id ? {'sms.supplier_gan_group_id': targetParams?.gan_group_id} : {}),
            ...(targetParams?.trunk_id ? {'sms.supplier_trunk_id': targetParams?.trunk_id} : {}),
            ...keyParams
        },
        ...data
    });
    return result;
};