import React, {useRef, useState, useEffect} from "react";
import {Alert, Loader, Placeholder} from "rsuite";
import CreateForm from "./CreateForm";
import Modal from "../../components/base/BaseModal";
import PageHeader from "../../components/base/PageHeader";
import PanelLayout from "../../components/base/PanelLayout";
import {api} from "../../api/loginRoutes";
import {useLocation, withRouter} from "react-router-dom";
import {
    ACCOUNT_CREATE_METHOD, 
    SMS_ACCOUNT_CREATE_METHOD,
    ACCOUNT_CONTACT_CHECK_METHOD
} from "../../const/apiMethods";
import {checkPermissionsFor} from "../../store/storeHelpers";

import { transformed_service_reverse_data } from "../../const";
import {compareObjects, servicePick} from "../../utils";

const {Paragraph} = Placeholder;
const USER_NAME_ALREADY_USED_CODE = 4;

const NewAccount = ({
    service, 
    history,
    authInfo,
    howFindList,
    countryList,
    currency_list,
    admin_role_list,
    client_role_list,
    registeredAccount,
    loadingReferences,
    payment_terms_list,
    account_manager_list,

    setDefaultFormCreateAccount,
    getAccountManagersDropdownList
}) => {
    const location = useLocation();
    const currentPath = location.pathname; 
    const previousPath = useRef(location.pathname); 

    const [formDefaultValue, setFormDefaultValue] = useState({
        account_manager_id: null,
        cur_key: null,
        pt_key: null,
        trunk_notificaton: "all_numbers_trunk"
    });

    const [disabled, setDisabled] = useState(false);
    const [showDuplicatesSubmitModal, setShowDuplicatesSubmitModal] = useState(false);
    const [duplicatesSubmitLoading, setDuplicatesSubmitLoading] = useState(false);
    const [duplicatesSubmitData, setDuplicatesSubmitData] = useState({});

    useEffect(() => {
        const closePage = () => {
            setDefaultFormCreateAccount({});
        };

        const unlisten = () => {
            window.addEventListener("beforeunload", closePage);
            return () => window.removeEventListener("beforeunload", closePage);
        };

        return unlisten();
    }, [currentPath]);


    useEffect(() => {
        return () => {
            setDefaultFormCreateAccount({});
        };
    }, [currentPath]);

    useEffect(() => {
        previousPath.current = location.pathname;
    }, [currentPath]);

    useEffect(() => {
        const accountManagerList = account_manager_list.filter(value => value.active || value.has_accounts);

        setUpFormDefaultValues(service, currency_list, payment_terms_list, accountManagerList);
    }, [service, currency_list, payment_terms_list, account_manager_list]);

    const setUpFormDefaultValues = (service, paymentTermsList, acountManagerList, currencyList) => {
        const defaultValue = {};

        if (paymentTermsList.length) {
            defaultValue.pt_key = paymentTermsList[0].pt_key;
        }

        if (acountManagerList.length) {
            defaultValue.account_manager_id = acountManagerList[0].id;
        }

        if (Object.keys(registeredAccount)) {
            if (registeredAccount.cur_key) {
                defaultValue.cur_key = registeredAccount.cur_key;
            }

            defaultValue.hf_key = registeredAccount.hf_key;
            defaultValue.cntr_code = registeredAccount.cntr_code;

            if (registeredAccount.name && registeredAccount.email) {
                const person = registeredAccount.name;
                const email = registeredAccount.email;
                const messenger = registeredAccount.skype;

                defaultValue.name = person;
                defaultValue.user_name = person;
                defaultValue.user_email = email;
                defaultValue.user_messenger = messenger;
            }
        } else {
            if (currency_list.length) {
                defaultValue.cur_key = currency_list[0].cur_key;
            }
        }

        if (!compareObjects(formDefaultValue, defaultValue)) {
            setFormDefaultValue((value) => {
                return {
                    ...value,
                    ...defaultValue
                }
            })
        }
    };

    const onCancel = () => {
        history.goBack();
    };

    const onCheckAccountMessenger = (messenger, telegram_username) => {
        return api(ACCOUNT_CONTACT_CHECK_METHOD, {
            contact_list: [
                {messenger, telegram_username}
            ]
        });
    };

    const onCreateAccount = async ({params, users, contacts, ...data}) => {
        const method = servicePick(service, ACCOUNT_CREATE_METHOD, SMS_ACCOUNT_CREATE_METHOD);

        try {
            const result = await api(method, params);
            setDisabled(false);

            if (result && result.account_id) {

                if (!checkPermissionsFor("account_user:create") || !checkPermissionsFor("account_contact:create")) {
                    history.replace("/reload");
                    history.push({
                        pathname: `/accounts/view/${result.account_id}`,
                        search: "?tab=general"
                    });
                } else {
                    for (const user of users) {
                        await api("account_user:create", {
                            ...user,
                            ...(params?.reg_key ? {reg_key: params.reg_key} : {}),
                            target: {
                                account_id: result.account_id
                            }
                        });
                    }

                    for (const contact of contacts) {
                        await api("account_contact:create", {
                            ...contact,
                            target: {account_id: result.account_id}
                        }, true);
                    }

                    history.replace("/reload");
                    history.push({
                        pathname: `/accounts/view/${result.account_id}`,
                        search: "?tab=general"
                    });
                    setDuplicatesSubmitLoading(false);

                }
                //     await Promise.all([
                //         ...users
                //             .map(
                //                 (user) => api("account_user:create", {
                //                     ...user,
                //                     ...(params?.reg_key ? {reg_key: params.reg_key} : {}),
                //                     target: {
                //                         account_id: result.account_id
                //                     }
                //                 })
                //             ),
                //         ...contacts
                //             .map(
                //                 (contact) =>{
                //                     api("account_contact:create", {
                //                         ...contact,
                //                         target: {account_id: result.account_id}
                //                     }, true)
                //                 }
                //             )
                //         ])
                //         .finally(() => {
                //             history.replace("/reload");
                //             history.push({
                //                 pathname: `/accounts/view/${result.account_id}`,
                //                 search: "?tab=general"
                //             });
                //             setDuplicatesSubmitLoading(false);
                //         });
                // }
            } else {
                // if (result.error.code === USER_NAME_ALREADY_USED_CODE) {
                //     // Alert.error("Account name already exists, please change to another");
                //     Alert.error(result.error.data?.error_type);
                // }
                setDisabled(false);
                setDuplicatesSubmitLoading(false);
            }
        } catch (e) {
            setDisabled(false);
            setDuplicatesSubmitLoading(false);
        }
    };

    const onSubmit = async (data, account_manager_id, users, contacts = [], reg_key) => {
        setDisabled(true);

        const checkMessenger = onCheckAccountMessenger(data?.user_messenger, data?.user_telegram_username);

        await checkMessenger.then(async (response) => {
            const contactDublicatesList = response?.contact_list_duplicates || [];

            const createAccountParams = {
                ...data,
                ...(reg_key ? {reg_key} : {}),
                service: transformed_service_reverse_data[service],
                target: {
                    account_manager_id
                }
            };

            const createAccountData = {
                params: createAccountParams,
                users,
                contacts,
                duplicates: response?.contact_list_duplicates || []
            };

            if (contactDublicatesList.length) {
                setShowDuplicatesSubmitModal(true);
                
                if (createAccountData)
                    setDuplicatesSubmitData(createAccountData);

                return;
            }

            await onCreateAccount(createAccountData);
        }).catch(() => {
            Alert.error("Something wrong!")
        });
    };

    return (
        <PanelLayout>
            <PageHeader title="Create Account"/>
            {loadingReferences && <Loader backdrop content="loading..." vertical/>}

            {
                loadingReferences
                    ?
                    <Paragraph rows={5}/>
                    :
                    <CreateForm
                        onSubmit={onSubmit}
                        disabled={loadingReferences || disabled}
                        formDefaultValue={formDefaultValue}
                        onCancel={onCancel}
                        isSeparatedPage={true}
                        registration_key={registeredAccount.reg_key}
                        newAccount
                        {...{
                            service,
                            authInfo,
                            currency_list,
                            payment_terms_list,
                            account_manager_list,
                            client_role_list,
                            admin_role_list,
                            countryList,
                            howFindList,
                            // getAccounts,
                            getAccountManagersDropdownList
                        }}
                    />
            }
            <Modal
                show={showDuplicatesSubmitModal}
                showFooter={true}
                loading={duplicatesSubmitLoading}
                onConfirm={async () => {
                    setDuplicatesSubmitLoading(true);

                    if (duplicatesSubmitData !== null) {
                        await onCreateAccount(duplicatesSubmitData);
                    }
                    setShowDuplicatesSubmitModal(false);
                    setDuplicatesSubmitData({});
                }}
                onClose={() => {
                    setShowDuplicatesSubmitModal(false);
                    setDuplicatesSubmitData({});
                    setDisabled(false);
                }}
                successText="Yes"
                cancelText="No"
                title="Create account warning"
            >
                Your messenger is already in use in following accounts: <br/>
                {duplicatesSubmitData?.duplicates?.map(duplicate => duplicate.account_name).join(", ")} <br/>
                Continue?
            </Modal>
        </PanelLayout>
    );
};

// class NewAccount extends React.Component {
//     formDefaultValue = {
//         account_manager_id: null,
//         cur_key: null,
//         pt_key: null,
//         trunk_notificaton: "all_numbers_trunk"
//     };

//     state = {
//         disabled: false,
//         showDuplicatesSubmitModal: false,
//         duplicatesSubmitLoading: false,
//         duplicatesSubmitData: {}
//     };

//     constructor(props) {
//         super(props);

//         this.setUpFormDefaultValues(props);
//     }

//     setUpFormDefaultValues(props) {
//         const {service, currency_list, payment_terms_list, account_manager_list, registeredAccount} = props;

//         if (payment_terms_list.length)
//             this.formDefaultValue.pt_key = payment_terms_list[0].pt_key;

//         if (account_manager_list.length)
//             this.formDefaultValue.account_manager_id = account_manager_list[0].id;

//         if (Object.keys(registeredAccount)) {
//             if (registeredAccount.cur_key)
//                 this.formDefaultValue.cur_key = registeredAccount.cur_key;
//             this.formDefaultValue.hf_key = registeredAccount.hf_key;
//             this.formDefaultValue.cntr_code = registeredAccount.cntr_code;

//             if (registeredAccount.name && registeredAccount.email) {
//                 const person = registeredAccount.name;
//                 const email = registeredAccount.email;
//                 const messenger = registeredAccount.skype;

//                 this.formDefaultValue.name = person;
//                 this.formDefaultValue.user_name = person;
//                 this.formDefaultValue.user_email = email;
//                 this.formDefaultValue.user_messenger = messenger;
//                 //     this.formDefaultValue.users = [{name: person, email}];
//                 //     this.formDefaultValue.contacts = [{person, email, messenger}];
              
//             }
//         } else {
//             if (currency_list.length)
//                 this.formDefaultValue.cur_key = currency_list[0].cur_key;
//         }
//         // console.log(this.formDefaultValue);
//     }

//     setShowDuplicatesSubmitModal = (flag = false) => {
//         this.setState({showDuplicatesSubmitModal: flag})
//     };

//     setDuplicatesSubmitData = (data) => {
//         if (data) {
//             this.setState({duplicatesSubmitData: data})
//         }
//     };

//     checkData = () => {
//         return !!Object.keys(this.formDefaultValue).filter(
//             x => this.formDefaultValue[x] === null
//         ).length;
//     };

//     shouldComponentUpdate(nextProps, nextState, nextContext) {
//         this.setUpFormDefaultValues(nextProps);

//         return true;
//     }

//     onCancel = () => {
//         this.props.history.goBack();
//     };

//     onCheckAccountMessenger = (messenger) => {
//         return api(ACCOUNT_CONTACT_CHECK_METHOD, {
//             contact_list: [
//                 {messenger}
//             ]
//         });
//     };

//     onCreateAccount = async ({params, users, contacts, ...data}) => {
//         const method = ACCOUNT_CREATE_METHOD;

//         try {
//             const result = await api(method, params, true);
//             this.setState({
//                 disabled: false
//             });

//             if (result && result.account_id) {

//                 if (!checkPermissionsFor("account_user:create") || !checkPermissionsFor("account_contact:create")) {
//                     this.props.history.replace("/reload");
//                     this.props.history.push({
//                         pathname: `/accounts/view/${result.account_id}`,
//                         search: "?tab=general"
//                     });
//                 } else {
//                     await Promise.all([
//                         ...users
//                             .map(
//                                 (user) => api("account_user:create", {
//                                     ...user,
//                                     ...(params?.reg_key ? {reg_key: params.reg_key} : {}),
//                                     target: {
//                                         account_id: result.account_id
//                                     }
//                                 })
//                             ),
//                         ...contacts
//                             .map(
//                                 (contact) =>{
//                                     api("account_contact:create", {
//                                         ...contact,
//                                         target: {account_id: result.account_id}
//                                     }, true)
//                                 }
//                             )
//                         ])
//                         .finally(() => {
//                             this.props.history.replace("/reload");
//                             this.props.history.push({
//                                 pathname: `/accounts/view/${result.account_id}`,
//                                 search: "?tab=general"
//                             });
//                             this.setState({
//                                 duplicatesSubmitLoading: false
//                             });
//                         });
//                 }
//             } else {
//                 if (result.error.code === USER_NAME_ALREADY_USED_CODE) {
//                     // Alert.error("Account name already exists, please change to another");
//                     Alert.error(result.error.data?.error_type);
//                 }
//                 this.setState({
//                     disabled: false,
//                     duplicatesSubmitLoading: false
//                 });
//             }
//         } catch (e) {
//             this.setState({
//                 disabled: false,
//                 duplicatesSubmitLoading: false
//             });
//         }
//     };

//     onSubmit = async (data, account_manager_id, users, contacts = [], reg_key) => {
//         const {service} = this.props;
//         this.setState({
//             disabled: true
//         });

//         const checkMessenger = this.onCheckAccountMessenger(data?.user_messenger);

//         await checkMessenger.then(async (response) => {
//             const contactDublicatesList = response?.contact_list_duplicates || [];

//             const createAccountParams = {
//                 ...data,
//                 ...(reg_key ? {reg_key} : {}),
//                 service: transformed_service_reverse_data[service],
//                 target: {
//                     account_manager_id
//                 }
//             };

//             const createAccountData = {
//                 params: createAccountParams,
//                 users,
//                 contacts,
//                 duplicates: response?.contact_list_duplicates || []
//             };

//             if (contactDublicatesList.length) {
//                 this.setShowDuplicatesSubmitModal(true);
//                 this.setDuplicatesSubmitData(createAccountData);
//                 return;
//             }

//             await this.onCreateAccount(createAccountData);
//         }).catch(() => {
//             Alert.error("Something wrong!")
//         });
//     };

//     render() {
//         const {disabled, showDuplicatesSubmitModal, duplicatesSubmitData, duplicatesSubmitLoading} = this.state;
//         const {
//             service, authInfo, currency_list, payment_terms_list, account_manager_list, loadingReferences, 
//             howFindList, client_role_list, admin_role_list, getAccountManagersDropdownList, registeredAccount,
//             countryList
//         } = this.props;

//         return (
//             <PanelLayout>
//                 <PageHeader title="Create Account"/>
//                 {loadingReferences && <Loader backdrop content="loading..." vertical/>}

//                 {
//                     loadingReferences
//                         ?
//                         <Paragraph rows={5}/>
//                         :
//                         <CreateForm
//                             onSubmit={this.onSubmit}
//                             disabled={loadingReferences || disabled}
//                             formDefaultValue={this.formDefaultValue}
//                             onCancel={this.onCancel}
//                             isSeparatedPage={true}
//                             registration_key={registeredAccount.reg_key}
//                             newAccount
//                             {...{
//                                 service,
//                                 authInfo,
//                                 currency_list,
//                                 payment_terms_list,
//                                 account_manager_list,
//                                 client_role_list,
//                                 admin_role_list,
//                                 countryList,
//                                 howFindList,
//                                 // getAccounts,
//                                 getAccountManagersDropdownList
//                             }}
//                         />
//                 }
//                 <Modal
//                     show={showDuplicatesSubmitModal}
//                     showFooter={true}
//                     loading={duplicatesSubmitLoading}
//                     onConfirm={async () => {
//                         this.setState({
//                             duplicatesSubmitLoading: true
//                         });
//                         if (duplicatesSubmitData !== null) {
//                             await this.onCreateAccount(duplicatesSubmitData);
//                         }
//                         this.setShowDuplicatesSubmitModal(false);
//                         this.setDuplicatesSubmitData({});
//                     }}
//                     onClose={() => {
//                         this.setShowDuplicatesSubmitModal(false);
//                         this.setDuplicatesSubmitData({});
//                         this.setState({disabled: false});
//                     }}
//                     successText="Yes"
//                     cancelText="No"
//                     title="Create account warning"
//                 >
//                     Your messenger is already in use in following accounts: <br/>
//                     {duplicatesSubmitData?.duplicates?.map(duplicate => duplicate.account_name).join(", ")} <br/>
//                     Continue?
//                 </Modal>
//             </PanelLayout>
//         );
//     }
// }

export default withRouter(NewAccount);