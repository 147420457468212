import React, {useMemo, useEffect, useState} from "react";
import PaymentsRequestSection from "./PaymentsRequestSection";
import PaymentsListSection from "./PaymentsListSection";
import PaymentsTrafficReportsSection from "./PaymentsTrafficReportsSection";
import {Spacer} from "../../../components/base/Spacer";
import ModalRequestPayment from "../../Payments/ModalRequestPayment";
import ModalPaymentDetails from "../../Payments/ModalPaymentDetails";
import ModalAddPaymentDetail from "../../PaymentDetails/ModalAddPaymentDetail";
import FormPaymentSettings from "../../Payments/FormPaymentSettings";
import {compareObjects} from '../../../utils'
import {checkPermissionsFor} from "store/storeHelpers";
import {PAYMENT_DETAIL_CRYPTO_LIST_API} from "const/apiMethods";


export default ({
    service,
    accountId,
    permissions,
    paymentItems,
    paymentItemsLoading,

    paymentRequestItems,
    paymentRequestLoading,
    
    paymentTrafficReportsItems,
    paymentTrafficReportsLoading,

    paymentDetailsItems,

    accountJoinList,
    accountInfo,
    accounts,
    cryptoList,

    isSupplier,

    organizationTypeList,
    countryList,
    paymentMethodList,
    currencyList,

    showReasonHandler,

    // API methods
    getAccount,
    getAccounts,
    getPaymentRequestList,
    getPaymentDetailsCryptoList,
    requestPayment,
    cancelPaymentRequest,
    approvePaymentRequest,
    modifyPaymentSettings,
    getPaymentList,
    getTrafficReportsList,
    getPaymentDetailsList,
    createPaymentDetails,
    modifyPaymentDetails,
}) => {
    const [showModalRequestPayment, setShowModalRequestPayment] = useState(false);
    const [requestPaymentFormValue, setRequestPaymentFormValue] = useState({amount: 0, cur_key: accountInfo?.cur_key});
    const [showModalAddPaymentDetail, setShowModalAddPaymentDetail] = useState(false);
    const [showModalPaymentDetail, setShowModalPaymentDetail] = useState(false);
    const [dropdownVisibility, setDropdownVisibility] = useState(true);
    const [amountLimitMin, setAmountLimitMin] = useState(0.01);

    const [settingValue, setSettingValue] = useState(accountInfo &&
        {...accountInfo.options} || {});

    useEffect(() => {
        getAccounts(); // accountId
    }, []);

    useEffect(() => {
        const {options} = accountInfo || {};

        if (!compareObjects(settingValue, options)) {
            setSettingValue({...options});
        }
    }, [accountInfo]);

    useEffect(() => {
        if (checkPermissionsFor(PAYMENT_DETAIL_CRYPTO_LIST_API)) {
            getPaymentDetailsCryptoList();
        }
    }, [permissions]);

    // set payment detail amount min limit
    useEffect( () => {
        if (!requestPaymentFormValue.payment_detail_id || !paymentMethodList.length || !paymentDetailsItems.length) {
            setAmountLimitMin(0.01);
            return;
        }
        const currentPaymentDetail = paymentDetailsItems.find(item => item.id === requestPaymentFormValue.payment_detail_id);
        if (!currentPaymentDetail) {
            setAmountLimitMin(0.01);
            return;
        }

        const currentPaymentMethod = paymentMethodList.find(item => item.pm_key === currentPaymentDetail.pm_key);
        const currentPaymentMethodLimit = currentPaymentMethod && currentPaymentMethod.limit
            ? currentPaymentMethod.limit
            : 0.01;
        setAmountLimitMin(currentPaymentMethodLimit);
    }, [requestPaymentFormValue, paymentMethodList, paymentDetailsItems] );

    // It is not clear what this is for, because it works after every change
    useEffect(() => {
        const {options} = accountInfo || {};
        const currentAccountOptions = {...options, allow_hawala: accountInfo.allow_hawala}

        if (!compareObjects(currentAccountOptions, settingValue)) {
            updateAccount(accountId);
        }
    }, [settingValue])

    
    const updateAccount = (id) => {
        if (id) {
            getAccount(id, true, false);
        }
    };


    // effect: get list on component mount
    useEffect( () => {
        if (!accountId)
            return;

        const data = {target: {account_id: accountId}};
        getPaymentDetailsList(data);
    }, [accountId] );

    const billingCurrencyList = useMemo(() => {
        return accountInfo.billing_currency_list ? accountInfo.billing_currency_list.map(currencyInfo => {
            return currencyInfo ? {
                total: currencyInfo.ready_for_payment,
                available: currencyInfo.available_for_payment, 
                cur_key: currencyInfo.cur_key
            } : {};
        }) : [];
    }, [accountInfo]);

    const getPaymentAmountLimitByCurrency = () => {
        return billingCurrencyList.reduce((result, billingCurrency) => {
            const totalAmount = billingCurrency.available || 0;
            // const itemsAmount = paymentRequestItems.reduce( (sum, currentItem) => (
            //     (currentItem && currentItem.status !== undefined && [0, 2].includes(currentItem.status) ) 
            //         ? sum + currentItem.amount 
            //         : sum
            // ), 0 );
            // const amountDiff = totalAmount - itemsAmount;
            // result[billingCurrency.cur_key] = amountDiff < 0 ? 0 : amountDiff.toFixed(2);
            result[billingCurrency.cur_key] = totalAmount;
            return result;
        }, {});
    };

    const openModalRequestPayment = () => {
        setShowModalRequestPayment(true);
    };

    const closeModalRequestPayment = () => {
        setShowModalRequestPayment(false);
    };

    const openModalPaymentDetail = () => {
        setShowModalPaymentDetail(true);
    }

    const handleShowModalAddPaymentDetail = () => {
        setShowModalAddPaymentDetail(true);
        setDropdownVisibility(false);
    };

    const closeModalAddPaymentDetail = (value) => {
        if (value) {
            setRequestPaymentFormValue({...requestPaymentFormValue, payment_detail_id: value.payment_detail.id});
        }
        setShowModalAddPaymentDetail(false);
        setDropdownVisibility(true);
    };
    const {currency_name: currencyName, cur_key: currency, options} = accountInfo || {};


    return (
        <>
            <Spacer />

            {!isSupplier &&
                <FormPaymentSettings
                    defaultValue={options || {}}
                    {...{
                        accountId,
                        getAccount,
                        modifyPaymentSettings,
                        settingValue,
                        setSettingValue
                    }}
                />
            }

            {!isSupplier && <>
                <PaymentsRequestSection
                    {...{
                        paymentRequestItems,
                        paymentRequestLoading,
                        cancelPaymentRequest,
                        approvePaymentRequest,
                        getPaymentRequestList,
                        openModalRequestPayment,
                        openModalPaymentDetail,
                        accountId,
                        service,
                        currencyName,
                        updateAccount
                    }}
                />
                <Spacer size={30}/>
            </>}
            <PaymentsListSection
                {...{
                    paymentItems,
                    paymentItemsLoading,
                    currencyName,
                    getPaymentList,
                    accountId,
                }}
            />

            <Spacer size={30}/>
            <PaymentsTrafficReportsSection
                {...{
                    paymentTrafficReportsItems,
                    paymentTrafficReportsLoading,
                    openModalRequestPayment,
                    currencyName,
                    getTrafficReportsList,
                    isSupplier,
                    accountId,
                    service
                }}
            />

            <Spacer size={30}/>

            <ModalRequestPayment
                show={showModalRequestPayment}
                onClose={closeModalRequestPayment}
                formValue={requestPaymentFormValue}
                setFormValue={setRequestPaymentFormValue}
                amountLimitByCurrency={getPaymentAmountLimitByCurrency()}
                amountLimitMin={amountLimitMin}
                {...{
                    service,
                    accountId,
                    currency,
                    currencyName,
                    currencyList,
                    // billingCurrency,
                    // accountBalance: billingCurrency.total,
                    requestPayment,
                    paymentDetailsItems,
                    showReasonHandler,
                    getPaymentRequestList,
                    dropdownVisibility,
                    handleShowModalAddPaymentDetail,
                    paymentMethodList,
                    accounts,
                    updateAccount,
                    getPaymentDetailsList
                }}
            />

            <ModalPaymentDetails
                show={showModalPaymentDetail}
                accountId={accountId}
                curKey={accountInfo?.cur_key}
                onClose={() => setShowModalPaymentDetail(false)}
            />
            
            
            <ModalAddPaymentDetail
                show={showModalAddPaymentDetail}
                onClose={closeModalAddPaymentDetail}
                {...{
                    currency,
                    accountId,
                    organizationTypeList,
                    countryList,
                    cryptoList,
                    paymentMethodList,
                    createPaymentDetails,
                    modifyPaymentDetails,
                    getPaymentDetailsList
                }}
            />
           

        </>
    )
};