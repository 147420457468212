import React, {useMemo, useEffect, useState} from 'react';
import TrafficLimitsFilter from './TrafficLimitsFilter';
import TrafficLimitsTable from './TrafficLimitsTable';
import TrafficLimitsSettings from './TrafficLimitsSettings';

import {SMS_TRAFFIC_LIMITS_LIST_API} from '../../../../const/apiMethods';

import { Alert } from 'rsuite';
import { compareObjects } from 'utils';
import { usePrevious } from 'hooks';


export default ({
    data: _data,
    accData,

    isSupplier,

    currentSupplier,

    call_duration_max,
    account,

    getSupplierTrafficLimitsList,
    getAccumulateDataFromRequestedList,
    getSupplierPrefixesDropdown,
    getReferencesForReports,
    getTrafficLimitsList,
    getSupplierAccountTrunk,
    getE212DropdownList,
    getSupplierGroups,
    createTrafficLimit,
    modifyTrafficLimit,
    removeTrafficLimit,
    clearAccumulateData,
    modifySupplierTrafficLimit,
    removeSupplierTrafficLimit,

    service,
    mccList,
    e212List,
    countryList,
    subdestinationList,
    destinationList,
    worldzoneList,
    servicePlanList,
    transitTrunkList,
    suppliersGroupList,
    suppliersPrefixList,

    allocationBanExpiredDate,

    loading
}) => {
    const defaultFilter = service ? {} : isSupplier ? {
        senderid: "",
        str: "",
        default_type_list: ["supplier", "mcc_default"]
    } : {
        senderid: "",
        default_type_list: ["supplier", "mcc_default"]
    };

    const defaultSelectedData = {senderid: "", day: null, hour: null, mcc: null, mnc: null};
 
    
    const [filter, setFilter] = useState(defaultFilter);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [selectedDefaultData, setSelectedDefaultData] = useState(defaultSelectedData);

    const [trafficTypeState, setTrafficTypeState] = useState(isSupplier ? 'mcc' : null);

    const accountId = account?.id ? account.id : null;
    const supplierId = currentSupplier?.supplier_id ? currentSupplier.supplier_id : null;

    const isGanNumbers = trafficTypeState === "gan";
    const isDefault = trafficTypeState === "mcc_default";

    const data = useMemo(() => {
        return isDefault ? accData : _data;
    }, [_data, accData, trafficTypeState]);

    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        onDataUpdate();
        
        if (!service) {
            getSupplierGroups(null, supplierId);
            getSupplierAccountTrunk(supplierId, 'transit')
        }
    }, [accountId, supplierId]);

    useEffect(() => {
        if (!service) {
            clearAccumulateData();
            setFilter(defaultFilter);
        }
    }, [trafficTypeState]);

    useEffect(() => {
        onDataUpdate();
    }, [filter]);

    useEffect(() => {
        if (!isSupplier) {
            const filteredData = data.filter( row => {
                if (!Object.keys(filter).length) {
                    return true;
                }

                return Object.keys(filter).every(key => row[key] === filter[key]);
            });

            setFilteredData(filteredData);
        } else {
            setFilteredData(data);
        }
    }, [data, filter]);


    const onDataUpdate = () => {
        if (!service) {
            const defaultTypeList = filter?.default_type_list;
            const typeMccDefault = defaultTypeList.includes("mcc_default");
            const typeSupplier = defaultTypeList.includes("supplier");
            const typeAll = defaultTypeList.includes("mcc_default") && defaultTypeList.includes("supplier");

            if (isSupplier && !isDefault) {
                getSupplierTrafficLimitsList(supplierId, trafficTypeState, filter);
                return;
            } else if (isDefault) {
                if (defaultTypeList.length) {
                    getAccumulateDataFromRequestedList(
                        [
                            ...((typeMccDefault || typeAll) ? [{
                                method: SMS_TRAFFIC_LIMITS_LIST_API,
                                params: {
                                    target: {
                                        'sms.supplier_id': supplierId
                                    },
                                    type: "mcc_default",
                                    filter
                                },
                                result_list_name: "sms.traffic_limit_list"
                            }] : []),
                            ...((typeSupplier || typeAll) ? [{
                                method: SMS_TRAFFIC_LIMITS_LIST_API,
                                params: {
                                    target: {
                                        'sms.supplier_id': supplierId
                                    },
                                    type: "supplier",
                                    filter
                                },
                                result_list_name: "sms.traffic_limit_list"
                            }] : [])
                        ]
                    );
                } else {
                    clearAccumulateData();
                }

                return;
            }
        }

        getTrafficLimitsList(accountId);
    };


    const handleCreateTrafficLimit = async (params) => {
        if (!isSupplier) {
            const callDurationMax = params?.call_duration_max ? parseInt(params.call_duration_max) : null;

            const r = await createTrafficLimit(accountId, {...params, call_duration_max: callDurationMax});

            if (r?.traffic_limit) {
                Alert.success(`Traffic Limit ${params.number} was added`);
            }

            return r;
        }
        return null;
    };


    const handleModifyTrafficLimit = async (data, defaultData) => {
        if (!isSupplier) {
            return await modifyTrafficLimit(accountId, data, defaultData);
        } else {
            let response;

            const targetParams = {
                supplier_id: supplierId
            };

            if (!('type' in data)) {
                data.type = isDefault ? defaultData?.type : trafficTypeState;
            }

            response = await modifySupplierTrafficLimit(targetParams, data, defaultData);

            if (response['sms.supplier_gan_key_ar']) {
                Alert.success(`Traffic limit "${defaultData["senderid"]}" was modified`);
            }

            return response;
        }
    };


    const handleDeleteTrafficLimit = async (defaultData) => {
        if (!isSupplier) {
            const id = defaultData?.tl_key;
            const result = await removeTrafficLimit(accountId, id);
            Alert.success("Traffic limit was deleted");
            return result;
        } else {
            const targetParams = {
                supplier_id: supplierId
            };
            const data = {
                type: isDefault ? defaultData?.type : trafficTypeState
            };
            const result = await removeSupplierTrafficLimit(targetParams, data, defaultData);
            Alert.success("Traffic limit was deleted");
            return result;
        }
    };

    const currentMccList = mccList ? mccList.map(mccItem => {
        return {
            ...mccItem,
            countryMccName: `${mccItem.country_name} (${mccItem.mcc})`
        }
    }) : [];

    return <>
        <TrafficLimitsSettings
            account={account}
            isSupplier={isSupplier}
            settings={{call_duration_max}}
            trafficTypeState={trafficTypeState}
            setTrafficTypeState={setTrafficTypeState}
        />
        
        <TrafficLimitsFilter
            filter={filter}
            setFilter={setFilter}
            {...{
                service,
                account,
                isSupplier,
                supplierId,
                onDataUpdate,
                allocationBanExpiredDate,
                suppliersPrefixList,
                mccList: currentMccList,
                e212List,
                countryList,
                transitTrunkList,
                subdestinationList,
                destinationList,
                worldzoneList,
                servicePlanList,
                defaultFilter,
                trafficTypeState,
                suppliersGroupList,
                showCreateModal,
                selectedDefaultData,

                setShowCreateModal,
                setTrafficTypeState,
                getE212DropdownList,
                setSelectedDefaultData,
                getReferencesForReports,
                getSupplierPrefixesDropdown,
            }}
        />
        <TrafficLimitsTable
            data={filteredData}
            loading={loading}

            onDataUpdate={onDataUpdate}
            onRowCreate={handleCreateTrafficLimit}
            onRowSubmit={handleModifyTrafficLimit}
            onRowRemove={handleDeleteTrafficLimit}

            removeExtended

            {...{
                mccList: currentMccList,
                e212List,
                suppliersGroupList,
                subdestinationList,
                destinationList,
                worldzoneList,
                servicePlanList,
                trafficTypeState,
                isSupplier,
                service,
                setShowCreateModal,
                setSelectedDefaultData
            }}
        />

    </>;
}