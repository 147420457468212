import React, {useRef, useEffect, useState, useMemo} from "react";
import {
    FlexboxGrid, Form, Input, Icon, Schema, SelectPicker, 
    Alert, Table, Whisper, Popover, Checkbox} from "rsuite";
import Contacts, {contactModel, telegramModel} from "./Contacts";
import Users, {initUser, userModel} from "./Customers";
import CustomField from "./CustomField";
import ControlledCheckbox from "../../components/Form/ControlledCheckbox";
import {generateId, compareObjects, compareObjectsDebug, formValid, getManagedServices, getFormattedDatetime} from "../../utils";
import {AccountCreate} from "./SaveModel";
import styled, {css} from "styled-components";
// import CloseAccount from "./CloseAccount";
// import OpenAccount from "../../pages/NewAccount/OpenAccount";
import "./CreateForm.css";
import { useWindowWidth } from "../../hooks";
import { checkPermissionsFor } from "../../store/storeHelpers";
import {ACCOUNT_ALLOCATION_BAN_CLEAN_API} from "../../const/apiMethods";
import {ButtonContent, ButtonListAdd, ButtonPrimary, ButtonClose, ButtonSecondary} from "../../components/base/BaseButton";
import ModalConfirm from "../../components/Modal/ModalConfirm";
import Button from "rsuite/es/Button"
import ReactDOM from "react-dom";
import {hasErrorObject} from "utils";
import {api} from "../../api/loginRoutes";
import ServiceTable from "./ServiceTable";
import MergeAccountsModal from "./MergeAccountsModal";

const { Column, HeaderCell, Cell } = Table;

const {StringType, NumberType} = Schema.Types;

const accountModelCreate = Schema.Model({
    user_name: StringType().isRequired("Required").maxLength(40, "The maximum is only 40 characters."),
    user_email: StringType().isRequired("Required").maxLength(40, "The maximum is only 40 characters.").isEmail("Email required"),
    user_messenger: StringType().isRequired("Required").maxLength(40, "The maximum is only 40 characters."),
    telegram_username: StringType()
        .pattern(/.*\B@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/, "Telegram username is incorrect. Telegram username must be 5-32 long and contain the '@' symbol at the beginning."),
    name: StringType().isRequired("Required")
        .pattern(/^([a-zA-Z0-9.,()_\-+]+ )*[a-zA-Z0-9.,()_\-+]+ ?$/, "You can type only letters, numbers and '.,()_-+' special characters")
        .maxLength(40, "The maximum is only 40 characters."),
    // account_manager_id: StringType().isRequired("Required"),
    cur_key: NumberType().isRequired("Required"),
    pt_key: NumberType().isRequired("Required"),
    account_manager_id: StringType().isRequired("Required")
});


const callDurationModel = Schema.Model({
    call_duration_max: NumberType().min(1, "Enter a positive number").max(10800, "3 hours is maximum").isRequired("Required")
});

const accountModelModify = Schema.Model({
    name: StringType().isRequired("Required")
        .pattern(/^([a-zA-Z0-9.,()_\-+]+ )*[a-zA-Z0-9.,()_\-+]+ ?$/, "You can type only letters, numbers and '.,()_-+' special characters")
        .maxLength(40, "The maximum is only 40 characters."),
    // account_manager_id: StringType().isRequired("Required"),
    cur_key: NumberType().isRequired("Required"),
    pt_key: NumberType().isRequired("Required"),
});

const supplierModelCreate = Schema.Model({
    // user_name: StringType().isRequired("Required").maxLength(40, "The maximum is only 40 characters."),
    // user_email: StringType().isRequired("Required").maxLength(40, "The maximum is only 40 characters.").isEmail("Email required"),
    name: StringType().isRequired("Required")
        .pattern(/^([a-zA-Z0-9.,()_\-+]+ )*[a-zA-Z0-9.,()_\-+]+ ?$/, "You can type only letters, numbers and '.,()_-+' special characters")
        .maxLength(40, "The maximum is only 40 characters."),
    // account_manager_id: StringType().isRequired("Required"),
    cur_key: NumberType().isRequired("Required"),
    pt_key: NumberType().isRequired("Required"),
    supplier_credit_limit: NumberType().min(1, "Enter a positive number"),
    account_manager_id: StringType().isRequired("Required")
});

const supplierModelModify = Schema.Model({
    name: StringType().isRequired("Required")
        .pattern(/^([a-zA-Z0-9.,()_\-+]+ )*[a-zA-Z0-9.,()_\-+]+ ?$/, "You can type only letters, numbers and '.,()_-+' special characters")
        .maxLength(40, "The maximum is only 40 characters."),
    // account_manager_id: StringType().isRequired("Required"),
    pt_key: NumberType().isRequired("Required"),
    supplier_credit_limit: NumberType().min(1, "Enter a positive number")
});

const serviceTableModel = Schema.Model({
    account_manager_id: StringType().isRequired("Required"),
    cur_key: NumberType().isRequired("Required"),
    pt_key: NumberType().isRequired("Required"),
});

const styleRow = {margin: "10px 0px"};

const serviceListData = [
    {
        name: "Voice",
        id: "voice"
    },
    {
        name: "SMS",
        id: "sms"
    }
];

const UNEDITABLE = 0;
const CREATING_CODE = 1;
const EDITING_CODE = 2;


export default ({
    disabled, disabledCurrency = false, onSubmit: _onSubmit, account_list, currency_list, payment_terms_list,
    account_manager_list, formDefaultValue, account_id, onCancel, active, getAccountManagersDropdownList, getAccounts,
    client_role_list, admin_role_list, isTestAccount, isSeparatedPage, registration_key, checkDuplicates,
    onSetRedirectTab, location, history, duplicateContacts, countryList, howFindList, newAccount, permissions, 
    accountJoinList, joinLoading, getAccountJoin, service, setService, authInfo, newSupplier, isSupplier, currentSupplier, 
    supplierTrunks, setResellerMode, allocationBanExpiredDate = null, ...props
}) => {
    
    let currentModel;

    const managedContactModel = useMemo(() => {
        return isSupplier ? contactModel : Schema.Model.combine(contactModel, telegramModel)
    }, [isSupplier]);

    const initContact = {
        person: "",
        email: "",
        phone: "",
        messenger: "",
        ...(!isSupplier ? {telegram_username: ""} : {}),
        role_list: [],
        key: null
    };

    let formServiceRef = {
        sms:  useRef(null),
        voice: useRef(null)
    };

    const [activeState, setActiveState] = useState(active);
    const [mergeAccountsModalShow, setMergeAccountsModalShow] = useState(false);
    const [mergeAccountsLoading, setMergeAccountsLoading] = useState(false);
    const [accountManagerLoading, setAccountManagerLoading] = useState(!account_manager_list.length);

    const [reportNumberChange, setReportNumberChange] = useState(formDefaultValue.report_number_change);

    const [supplierOptions, setSupplierOptions] = useState(
        {
            block_traffic_credit_excess: formDefaultValue?.block_traffic_credit_excess
        }
    );

    useEffect(() => {
        getAccountManagersDropdownList();
    }, []);

    useEffect(() => {
        if (accountManagerLoading) {
            setTimeout(() => {
                setAccountManagerLoading(false);
            }, 3000)
        }
    }, [accountManagerLoading]);

    useEffect(() => {
        setActiveState(active);
    }, [active]);

    if (newSupplier && newAccount) {
        currentModel = supplierModelCreate;
    } else if (newSupplier && !newAccount) {
        currentModel = accountModelModify;
    } else if (!newAccount && !newSupplier && isSupplier) {
        currentModel = supplierModelModify;
    } else if (newAccount && !newSupplier) {
        if (!service) {
            currentModel = accountModelCreate;
        } else {
            currentModel = Schema.Model.combine(accountModelCreate, callDurationModel);
        }
    } else if (!newAccount && !newSupplier) {
        currentModel = accountModelModify;
    }

    const {users: formDefaultUsers, contacts: formDefaultContacts} = formDefaultValue || {};

    const formDefaultValueInit = JSON.parse(JSON.stringify(formDefaultValue));
    const formDefaultContactsInit = formDefaultContacts && formDefaultContacts.slice(0);
    const formDefaultUsersInit = formDefaultUsers && formDefaultUsers.slice(0);

    const [form, setForm] = useState(null);
    const [formState, setFormState] = useState(formDefaultValue || {});
    const [resellerAllowedValue, setResellerAllowedValue] = useState(formDefaultValue.reseller_allowed);

    const [contacts, updateContacts] = useState(formDefaultContacts && formDefaultContacts.length ? formDefaultContacts : [{
        ...initContact,
        key: generateId()
    }]);

    const makeAccountWithoutGenericFields = (object) => {
        return Object.keys(object).reduce((resultObject, key) => {
            if (["contacts", "users"].includes(key)) {
                const listWithGenericFields = object[key].map((item) => {
                    const resultItem = Object.keys(item).reduce((itemObject, itemKey) => {
                        if (itemKey !== "key")
                            itemObject[itemKey] = item[itemKey];

                        return itemObject;
                    }, {});

                    return resultItem;
                });

                resultObject[key] = listWithGenericFields;
            } else {
                resultObject[key] = object[key];
            }

            return resultObject;
        }, {});
    };

    useEffect(() => {
        if (formDefaultContacts) {
            updateContacts(formDefaultContacts);
        }
    }, [formDefaultContacts]);

    useEffect(() => {
        if (!compareObjects(formDefaultValue, formState)) {
            setFormState(formDefaultValue);
        }
    }, [authInfo, formDefaultValue]);

    const windowWidth = useWindowWidth();
    // const isIssueWidth = windowWidth < 1600;
    const isMobile = windowWidth < 1350;

    const serviceValue = formDefaultValue?.service || null;

    const newAccountService = formDefaultValue?.newAccountService;
    const createOtherService = serviceListData.find(service => service.id !== serviceValue && serviceValue !== null);

    const hasBothService = formDefaultValue?.joined_account_list
        ? !!formDefaultValue?.joined_account_list.find(acc => acc.service === createOtherService.id)
        : false;

    const accountServiceListData = useMemo(() => {
        return serviceListData.map(service => {
            const accountServiceData = accountJoinList && accountJoinList.find(account => account?.service === service.id) || {};
            const accountOtherServiceData = accountJoinList && accountJoinList.find(account => account?.service !== service.id) || {};

            return {
                id: accountServiceData?.id,
                active: accountServiceData?.active,
                hasService: Object.keys(accountServiceData).length,
                service: service.id,
                is_managed: accountServiceData.is_managed,
                service_name: service.name,
                currency_name: accountServiceData?.currency_name,
                payment_terms_name: accountServiceData?.payment_terms_name,
                account_manager_name: accountServiceData?.account_manager_name
            }
        })
    }, [accountJoinList]);

    useEffect(() => {
        if (!disabled && !contacts.length) {
            updateContacts(formDefaultContacts && formDefaultContacts.length ? formDefaultContacts : [{
                ...initContact,
                key: generateId()
            }]);
        }
    }, [formDefaultContacts, disabled]);

    const [users, updateUsers] = useState(formDefaultUsers || []);
    const [existDuplicates, updateExistDuplicates] = useState([]);

    const [showExpandedEmails, setShowExpandedEmails] = useState(false);
    const [errorContactMessages, setErrorContactMessages] = useState([]);

    // Иногда пропадают юзеры, точнее в state users не попадает ничего и он undefined. Разботает раз через 5 раз. Какой-то странный рандом. Данное решение фиксит этот баг
    useEffect(() => {
        if (!disabled && !users.length)
            updateUsers(formDefaultUsers || []);
    }, [formDefaultUsers, disabled]);

    const [showBanMessage, setShowBanMessage] = useState(false);

    useEffect(() => {
        setShowBanMessage(allocationBanExpiredDate)
    }, [allocationBanExpiredDate])

    useEffect(() => {
        if (account_manager_list.length) {
            setAccountManagerLoading(false);
        }
    }, [account_manager_list]);

    const [showConfirm, setShowConfirm] = useState(false);
    const [showCloseAccountConfirm, setShowCloseAccountConfirm] = useState(false);
    const [showResellerAllowConfirm, setShowResellerAllowConfirm] = useState(false);

    const formsContactRef = {};
    const updateContactFormRef = (ref, key) => {
        if (ref) {
            formsContactRef[key] = ref
        }
    };

    const addContacts = () => {
        const unValidContacts = contacts.filter(contact => !formValid(managedContactModel.check(contact)));

        if (unValidContacts.length) {
            const localErrors = [...unValidContacts].reduce((acc, contact) => {
                const errorMessage = errorContactMessages.find(err => err.key === contact.key);

                acc.push({...errorMessage, key: contact.key, error: managedContactModel.check(contact)})
                return acc;
            }, []);

            localErrors.forEach(contact => {
                formsContactRef[contact.key].check();
            });

            setErrorContactMessages(localErrors);
        } else {
            updateContacts([...contacts, {...initContact, key: generateId()}]);
        }
    };

    const onSubmit = async () => {
        const validUsers =
            !Object.keys(formsUsersRef)
                .map(key => formsUsersRef[key].check())
                .filter(check => !check).length;

        let duplicateChecked = false;
        if (!newAccount && checkDuplicates) {
            duplicateChecked = await checkDuplicates(contacts).then(({messenger_duplicate_list: msgDupList, telegram_username_duplicate_list: tgDupList}) => {
                if (msgDupList.length || tgDupList.length) {                   
                    const printDuplicateMessages = (id, name, type, accountName, service, isTg) => {
                        const tablePath = type === "supplier" ? "sms-suppliers" : "accounts";
                        const messenger = isTg ? "Telegram" : "Skype";
                        return <>
                            {messenger} <b>{name}</b> exists in account <b>{accountName}
                                <LinkButton
                                    appearance="link"
                                    onClick={() => {
                                        window.open(`/${tablePath}/view/${id}?service=${service}&tab=general`);
                                    }}
                                >
                                    {service}
                                </LinkButton>
                            </b>
                            <br/>
                        </>
                    };

                    const alertContent = <AlertMessage>
                        {msgDupList.map((duplicate) => {
                            return printDuplicateMessages(
                                duplicate.account_id,
                                duplicate.messenger,
                                duplicate.type,
                                duplicate.account_name,
                                duplicate.service
                            );
                        })}
                        {msgDupList.length && tgDupList.length ? <><br/><br/></> : <></>}
                        {tgDupList.map((duplicate) => {
                            return printDuplicateMessages(
                                duplicate.account_id,
                                duplicate.telegram_username,
                                duplicate.type,
                                duplicate.account_name,
                                duplicate.service,
                                true
                            );
                        })}
                    </AlertMessage>;

                    Alert.error(alertContent, 30000);

                    return true;
                } else {
                    updateExistDuplicates([]);
                    return false;
                }

            })
            .catch((error) => {
                console.error("Error in checkDuplicates", error);
            });
        }
        
        if (!duplicateChecked) {

            form.check();

            const accountModelValues = currentModel.check(form.getFormValue());
            const formCheck = hasErrorObject(accountModelValues);

            const unValidContacts = !newAccount ? contacts.filter(contact => !formValid(contactModel.check(contact))) : [];

            if (!unValidContacts.length && validUsers && !formCheck) {
                const formAccountManagerId = form.getFormValue().account_manager_id;
                const accountManagerId = formAccountManagerId || formDefaultValue?.account_manager_id;
                let newUsers = [];
                let newContacts = [];

                if (!newAccount) {
                    newUsers = users;
                    newContacts = contacts;
                }

                
                let formValues = form.getFormValue();
                
                formValues = {
                    ...formValues,
                    telegram_username: formValues.telegram_username === "" ? null : formValues.telegram_username
                };

                if (reportNumberChange !== formDefaultValue.report_number_change) {
                    formValues = {
                        ...formValues,
                        report_number_change: reportNumberChange
                    };
                }

                if (isSupplier) {
                    formValues = {
                        ...formValues,
                        ...supplierOptions
                    };
                }

                const result = _onSubmit(new AccountCreate(formValues),
                    accountManagerId,
                    newUsers,
                    newContacts,
                    registration_key,
                    isSupplier
                );

                result && result.then(response => {
                    if (response && response.hasOwnProperty("result") && Object.keys(response["result"]).length) {
                        const localDuplicates = existDuplicates.filter(cnt => cnt.id === account_id).map(cnt => cnt.contact_id);

                        if (response["result"].hasOwnProperty("duplicate_list")) {
                            Alert.error("Some new entered messengers have duplicates ");

                            const resultDuplicateKeys = response["result"]["duplicate_list"].map(duplicate => duplicate.duplicate_id);

                            let currentDuplicates = localDuplicates.concat(resultDuplicateKeys);

                            updateExistDuplicates(currentDuplicates);
                            return;
                        } else if (localDuplicates.length >= existDuplicates.length && localDuplicates.length !== 0) {
                            Alert.error("Some new entered messengers have duplicates ");
                            return;
                        }

                        if (!isSupplier && formDefaultValue.reseller_allowed !== resellerAllowedValue) {
                            setResellerMode(account_id, resellerAllowedValue);
                        }

                        Alert.success("Saved");
                    }

                    if (response && response?.code === 3) {
                        let errorMessage;

                        if (response?.data && Array.isArray(response.data)) {
                            errorMessage = response.data.join("\n");
                        } else {
                            errorMessage = response.data;
                        }
                        
                        Alert.error(errorMessage);
                    }
                   
                })
            } else {

                if (unValidContacts.length) {
                    const localErrors = [...unValidContacts].reduce((acc, contact) => {
                        const errorMessage = errorContactMessages.find(err => err.key === contact.key);

                        acc.push({...errorMessage, key: contact.key, error: contactModel.check(contact)})
                        return acc;
                    }, []);

                    localErrors.forEach(contact => {
                        const contactForm = formsContactRef[contact.key];
                        contactForm.check();
                    });
                  
                    setErrorContactMessages(localErrors);
                }
            }
        }
    };

    const formsUsersRef = {};
    const updateUsersFormRef = (ref, key) => formsUsersRef[key] = ref;

    const onAddUser = () => {
        const unValidUsers = users.filter(user => !formValid(userModel.check(user)) && !user.email_disabled);

        if (unValidUsers.length)
            unValidUsers.map(
                user => formsUsersRef[user.key].check()
            );
        else
            updateUsers([{...initUser, key: generateId()}, ...users]);
    };
    
    const fieldIsChanged = (key) => {
        return formDefaultValueInit.hasOwnProperty(key) 
            ? formState && !!formState[key] && formDefaultValueInit[key] !== formState[key]
            : formState && !!formState[key];
    };
    
    const radioIsPicked = (key, radioKey) => {
        return fieldIsChanged(key) && formState[key] === radioKey;
    };

    const addableIsChanged = (id, addableObjectInitial, addableObject) => {
        const addedNew = addableObjectInitial && !addableObjectInitial.map((addable) => addable.id).includes(id);
        const changed = addableObjectInitial && !compareObjects(addableObjectInitial.find(obj => obj.id === id), addableObject);
        return addedNew || changed;
    };

    const onChangeActivity = async (activeState, callback) => {
        const currentSupplierId = currentSupplier.supplier_id;

        const method = activeState ? "sms.supplier:remove" : "sms.supplier:restore";
        const resultMessage = activeState ? "Supplier account was closed" : "Supplier account was opened";
        
        await api(method, {
            target: {"sms.supplier_id": currentSupplierId}
        }).then((result) => {

            if (result["sms.supplier"]) {
                Alert.success(resultMessage);

                updateCurrentPage();
            }
        }).finally(() => {
            setActiveState(!activeState);
            setShowCloseAccountConfirm(false);
        });
    };

    const updateCurrentPage = () => {
        history.replace("/reload");
        setTimeout((valueLocation, valueHistory) => {
            valueHistory.replace({
                ...valueLocation,
                search: "?tab=general"
            });
        },
        100,
        location, history);
    };

    const accountManagerList = account_manager_list.filter(value => value.active);
    const managedServiceList = getManagedServices(authInfo);
    const disabledServices = serviceListData.filter(service => !managedServiceList.includes(service.id)).map(service => service.id);

    const isAnotherServiceExist = !!accountServiceListData.find(accountService => accountService.service !== formDefaultValue?.service)?.id;

    return (<>
        {
            showExpandedEmails &&
            ReactDOM.createPortal(
                <ExpandedEmailsPortal className="portal" onClick={()=>setShowExpandedEmails(false)}/>
                , document.body)
        }
        <Form
            model={currentModel}
            style={{marginBottom: "20px"}}
            layout="horizontal"
            ref={ref => setForm(ref)}
            formValue={formState}
            onChange={setFormState}
            formDefaultValue={formDefaultValue}
        >
            <FlexboxGrid style={styleRow}>
                <StyledRow isMobile={isMobile}>
                    <StyledFlexboxGridItem>
                        {newAccount && 
                        !newSupplier && 
                        <CustomField
                            style={{minWidth: "250px"}}
                            disabled={disabled}
                            accepter={Input}
                            name="user_name"
                            placeholder="User Name"
                            label="User Name"
                            errorPlacement="topStart"
                            className={`tab-general-custom-field ${fieldIsChanged("name") && "has-changed"}`}
                        />}

                        <CustomField
                            style={{minWidth: "250px"}}
                            disabled={disabled}
                            accepter={Input}
                            name="name"
                            placeholder="Account name"
                            label="Account name"
                            errorPlacement="topStart"
                            className={`tab-general-custom-field ${fieldIsChanged("name") && "has-changed"}`}
                        />

                        {newAccount && <CustomField
                            style={{minWidth: "250px"}}
                            disabled={disabled}
                            accepter={SelectPicker}
                            placeholder="Account manager"
                            data={accountManagerList}
                            labelKey="name"
                            valueKey="id"
                            name="account_manager_id"
                            label="Account manager"
                            searchable={false}
                            cleanable={false}
                            className={`tab-general-select-field ${fieldIsChanged("account_manager_id") && "has-changed"}`}
                        />}

                        {/* {newAccount && !newSupplier && <ServiceField
                            style={{minWidth: "250px"}}
                            align="center"
                            label="Service"
                            name="service"
                            placeholder="Service"
                            newAccountService={newAccountService}
                            serviceListData={serviceListData}
                            account_id={account_id}
                            value={serviceValue}
                            disabledItemValues={disabledServices}
                        />} */}

                        {!isSupplier && <CustomField
                            style={{minWidth: "250px"}}
                            disabled={disabled}
                            data={countryList}
                            labelKey="name"
                            valueKey="cntr_code"
                            accepter={SelectPicker}
                            name="cntr_code"
                            placeholder="Country"
                            label="Country"
                            className={`tab-general-custom-field ${fieldIsChanged("cntr_code") && "has-changed"}`}
                        />}

                        {isSupplier && !newAccount && <SelfUpdateDropdownField
                            disabled={disabled}
                            updateOn={accountManagerLoading}
                            data={accountManagerList}
                            labelKey="name"
                            valueKey="id"
                            name="account_manager_id"
                            placeholder="Account manager"
                            label="Account manager"
                            searchable={false}
                            cleanable={false}
                            className={`tab-general-select-field ${fieldIsChanged("account_manager_id") && "has-changed"}`}
                        />}
                        
                        {/* <CustomField
                            style={{minWidth: "250px"}}
                            disabled={disabled || }
                            accepter={SelectPicker}
                            placeholder="Account manager"
                            data={accountManagerList}
                            labelKey="name"
                            valueKey="id"
                            name="account_manager_id"
                            label="Account manager"
                            searchable={false}
                            cleanable={false}
                            errorPlacement="topEnd"
                        /> */}

                        {(newAccount || isSupplier) && <CustomField
                            style={{minWidth: "250px"}}
                            accepter={SelectPicker}
                            data={payment_terms_list}
                            label="Payment Terms"
                            labelKey="name"
                            valueKey="pt_key"
                            name="pt_key"
                            searchable={false}
                            cleanable={false}
                            errorPlacement="topEnd"
                            className={`tab-general-select-field ${fieldIsChanged("pt_key") && "has-changed"}`}
                        />}

                    </StyledFlexboxGridItem>
                    <StyledFlexboxGridItem>
                        {newAccount && 
                        !newSupplier && 
                        <CustomField
                            style={{minWidth: "250px"}}
                            disabled={disabled}
                            accepter={Input}
                            name="user_email"
                            placeholder="User Email"
                            label="User Email"
                            errorPlacement="topStart"
                            className={`tab-general-custom-field ${fieldIsChanged("name") && "has-changed"}`}
                        />}

                        {newAccount && 
                        !newSupplier &&
                        <CustomField
                            style={{minWidth: "250px"}}
                            disabled={disabled}
                            accepter={Input}
                            name="user_messenger"
                            placeholder="Messenger"
                            label="User Messenger"
                            errorPlacement="topStart"
                            className={`tab-general-custom-field ${fieldIsChanged("user_messenger") && "has-changed"}`}
                        />}
                        {newAccount && 
                        !newSupplier &&
                        <CustomField
                            style={{minWidth: "250px"}}
                            disabled={disabled}
                            accepter={Input}
                            name="telegram_username"
                            errorPlacement="topStart"
                            label="User Telegram"
                            placeholder="User Telegram"
                            className={`tab-general-custom-field ${fieldIsChanged("user_telegram") && "has-changed"}`}
                        />}

                        {(newAccount && !isSupplier || !isSupplier) && <CustomField
                            style={{minWidth: "250px"}}
                            disabled={disabled}
                            data={howFindList}
                            labelKey="how_find_name"
                            valueKey="hf_key"
                            accepter={SelectPicker}
                            name="hf_key"
                            placeholder="Choose an option"
                            label="How did you find us?"
                            className={`tab-general-custom-field ${fieldIsChanged("hf_key") && "has-changed"}`}
                        />}

                        {(newAccount || isSupplier) && <CustomField
                            style={{minWidth: "250px"}}
                            disabled={disabled || disabledCurrency}
                            accepter={SelectPicker}
                            name="cur_key"
                            placeholder="Currency"
                            data={currency_list}
                            labelKey="name"
                            valueKey="cur_key"
                            label="Currency"
                            searchable={false}
                            cleanable={false}
                            className={`tab-general-select-field ${fieldIsChanged("cur_key") && "has-changed"}`}
                        />}

                        {service && newAccount && !newSupplier && <CustomField
                            disabled={disabled}
                            accepter={Input}
                            name="call_duration_max"
                            placeholder="2400"
                            label="Call duration"
                            className={`tab-general-custom-field ${fieldIsChanged("call_duration_max") && "has-changed"}`}
                        />}

                        {(newSupplier || isSupplier) && <CustomField
                            align="center"
                            label="Credit limit"
                            name="supplier_credit_limit"
                            placeholder="0"
                            className={`tab-general-custom-field ${fieldIsChanged("supplier_credit_limit") && "has-changed"}`}
                        />}
                        
                    </StyledFlexboxGridItem>
                    {!newAccount && !newSupplier && isSupplier ? <StyledSupplierOptionsContainer>
                        <ControlledCheckbox
                            name="block_traffic_credit_excess"
                            defaultValue={supplierOptions?.block_traffic_credit_excess}
                            onChange={(value) => {
                                const localSupplierOptions = {...supplierOptions};

                                setSupplierOptions({
                                    ...localSupplierOptions,
                                    block_traffic_credit_excess: value
                                });
                            }}
                        >
                            Block traffic if credit limit exceed
                        </ControlledCheckbox>
                    </StyledSupplierOptionsContainer> : <></>}
                    {!newSupplier && !isSupplier ? <StyledFlexboxGridItem>
                        {!newAccount && <ServiceTable
                            formServiceRef={formServiceRef}
                            accountId={account_id}
                            data={accountServiceListData}
                            service={service}
                            history={history}
                            location={location}
                            authInfo={authInfo}
                            isMobile={isMobile}
                            loading={joinLoading}
                            setService={setService}
                            currencyList={currency_list}
                            getAccountJoin={getAccountJoin}
                            paymentTermsList={payment_terms_list}
                            accountManagerList={accountManagerList}
                        />}

                        {showBanMessage && <BanInfoContainer>
                            <BanAccountInfo>Account made to many allocation attempts and was limited till {<DateItem>{getFormattedDatetime(allocationBanExpiredDate)}</DateItem>}</BanAccountInfo>
                            <BanCleanButton
                                appearance="primary"
                                onClick={() => {
                                    setShowConfirm(true);
                                }}
                            >
                                Remove allocation block
                            </BanCleanButton>
                        </BanInfoContainer>}
                    </StyledFlexboxGridItem> : <></>}

                    {!newAccount && !newSupplier && !isSupplier && checkPermissionsFor("account:merge") && <StyledFlexboxGridItem>
                        <>
                            <MergeAccountsHeader>Merge accounts</MergeAccountsHeader>
                            <Whisper
                                placement="auto"
                                trigger="hover" 
                                hide={!isAnotherServiceExist}
                                speaker={<Popover>You have both account services</Popover>}
                            >
                                <MergeAccountsButton
                                    disabled={isAnotherServiceExist}
                                    onClick={() => {
                                        setMergeAccountsModalShow(true);
                                    }}
                                >
                                    Merge accounts
                                </MergeAccountsButton>
                            </Whisper>
                        </>
                    </StyledFlexboxGridItem>}
                </StyledRow>
                    
                {/* <CustomField
                    disabled={disabled}
                    accepter={RadioGroup}
                    name="trunk_notificaton"
                >
                    <Radio
                        style={{width: isMobile ? "auto" : 450}}
                        className={`tab-general-radio-field ${radioIsPicked("trunk_notificaton", "all_numbers_trunk") && "has-changed"}`}
                        value={"all_numbers_trunk"}
                    >Send full list of allocated numbers for trunk after update</Radio>
                    <Radio
                        style={{width: isMobile ? "auto" : 450}}
                        className={`tab-general-radio-field ${radioIsPicked("trunk_notificaton", "all_numbers_all_trunks") && "has-changed"}`}
                        value={"all_numbers_all_trunks"}
                    >Send full list of allocated numbers for all trunk after update</Radio>
                    <Radio
                        style={{width: isMobile ? "auto" : 450}}
                        className={`tab-general-radio-field ${radioIsPicked("trunk_notificaton", "number_trunk") && "has-changed"}`}
                        value={"number_trunk"}
                    >Send only new allocated numbers</Radio>
                    <Radio
                        style={{width: isMobile ? "auto" : 450}}
                        className={`tab-general-radio-field ${radioIsPicked("trunk_notificaton", "nothing") && "has-changed"}`}
                        value={"nothing"}
                    >Don"t send notifications</Radio>

                </CustomField> */}

                
            </FlexboxGrid>
            {!isSupplier && <AccountSettings isMobile={isMobile}>
                <FlexboxGrid.Item>
                    {!newAccount && <ServiceSettings
                        value={resellerAllowedValue}
                        setValue={(value) => {
                            if (value) {
                                setFormState((currentFormValue) => {
                                    return {
                                        ...currentFormValue,
                                        reseller_allowed: true
                                    }
                                })
                
                                setResellerAllowedValue(true);
                            } else {
                                setShowResellerAllowConfirm(true);
                            }
                        }}
                        defaultValue={formState.reseller_allowed}
                    />}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    {!newAccount && <Checkbox
                        name="report_number_change"
                        onChange={(_, checked) => setReportNumberChange(checked)}
                        checked={reportNumberChange}
                        defaultChecked={formDefaultValue.report_number_change}
                    >
                        Send changes in allocated numbers
                    </Checkbox>}
                </FlexboxGrid.Item>
            </AccountSettings>}
            {newAccount || isSeparatedPage && !checkPermissionsFor("account_contact:create") 
                ? null
                : <>
                    <FlexboxGrid style={styleRow}>
                        <HeaderList>Contacts <ButtonListAdd onClick={addContacts}/> </HeaderList>
                    </FlexboxGrid>

                    <FlexboxGrid style={styleRow}>
                        <Contacts
                            {...{
                                contacts, updateContacts, disabled, addableIsChanged,
                                showExpandedEmails, setShowExpandedEmails, errorContactMessages
                            }}
                            conflictContacts={existDuplicates}
                            formsRef={formsContactRef}
                            updateFormRef={updateContactFormRef}
                            initialList={formDefaultContactsInit}
                            isMobile={isMobile}
                            isSupplier={isSupplier}
                        />
                    </FlexboxGrid>
                </>
            }


            {isSupplier || (newAccount || isSeparatedPage && !checkPermissionsFor("account_user:create")) 
                ? null
                : <>
                    <FlexboxGrid style={styleRow}>
                        <HeaderList>Customers <ButtonListAdd onClick={onAddUser}/> </HeaderList>
                    </FlexboxGrid>

                    <FlexboxGrid style={styleRow}>
                        <Users
                            {...{
                                users,
                                updateUsers,
                                disabled,
                                addableIsChanged
                            }}
                            clientRoleList={client_role_list || []}
                            adminRoleList={admin_role_list || []}
                            isTestAccount={isTestAccount}
                            formsRef={formsUsersRef}
                            updateFormRef={updateUsersFormRef}
                            initialList={formDefaultUsersInit}
                            isMobile={isMobile}
                        />
                    </FlexboxGrid>
                </>
            }

            <ButtonFooter>

                <ButtonPrimary
                    disabled={disabled}
                    onClick={onSubmit}
                >
                    Save
                </ButtonPrimary>

                <ButtonContent
                    onClick={() => onCancel()}
                >
                    Cancel
                </ButtonContent>

                {(!newSupplier && !newAccount) && isSupplier && <>
                        {activeState
                            ? <ButtonClose
                                onClick={() => {
                                    setShowCloseAccountConfirm(true);
                                }}
                            >
                                Close account <Icon icon="lock" style={{marginLeft: 5}}/>
                            </ButtonClose>
                            : <ButtonSecondary
                                onClick={() => {
                                    onChangeActivity(activeState);
                                }}
                            >
                                Open account <Icon icon="unlock" style={{marginLeft: 5}}/>
                            </ButtonSecondary>
                        }
                    </>
                }

            </ButtonFooter>
        </Form>
        {mergeAccountsModalShow && <MergeAccountsModal
            show={mergeAccountsModalShow}
            onClose={() => {
                setMergeAccountsModalShow(false);
            }}
            updateCurrentPage={updateCurrentPage}
            accountService={formDefaultValue?.service}
            accountId={account_id}
            loading={mergeAccountsLoading}
            setLoading={setMergeAccountsLoading}
        />}

        <ModalConfirm
            show={showConfirm}
            onClose={() => {
                setShowConfirm(false);
            }}
            onSuccess={() => {
                api(ACCOUNT_ALLOCATION_BAN_CLEAN_API, {target: {account_id}}).then((response) => {
                    if (response?.result === "OK") {
                        Alert.success("Ban has been removed", 3000);
                        setShowBanMessage(false);
                        setShowConfirm(false);
                    }
                });
            }}
            title="Remove allocation block"
        >Are you sure?</ModalConfirm>
        <ModalConfirm
            show={showCloseAccountConfirm}
            onClose={() => {
                setShowCloseAccountConfirm(false);
            }}
            onSuccess={() => {
                onChangeActivity(activeState);
            }}
            title="Close account"
        >
            <p>You are sure?</p>
            <p>All numbers will revoke and all trunks remove</p>
        </ModalConfirm>
        <ModalConfirm
            show={showResellerAllowConfirm}
            onClose={() => {
                setShowResellerAllowConfirm(false);
            }}
            onSuccess={() => {
                setFormState((currentFormValue) => {
                    return {
                        ...currentFormValue,
                        reseller_allowed: false
                    }
                })

                setResellerAllowedValue(false);
                setShowResellerAllowConfirm(false);
            }}
            title="Disallow reseller"
        >
            <p>You are sure?</p>
            <p>All numbers will revoke and all trunks remove</p>
        </ModalConfirm>
    </>);
};


const SelfUpdateDropdownField = ({
    disabled, 
    updateOn, 
    data,
    labelKey,
    valueKey,
    name,
    label,
    placeholder="List...",
    searchable=false,
    cleanable=false,
    className="",
    ...props
}) => {
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        setUpdate(updateOn);
    }, [updateOn]);

    return !update ? <CustomField
        style={{minWidth: "250px"}}
        disabled={disabled || updateOn}
        accepter={SelectPicker}
        placeholder={placeholder}
        data={data}
        labelKey={labelKey}
        valueKey={valueKey}
        name={name}
        label={label}
        searchable={searchable}
        cleanable={cleanable}
        errorPlacement="topEnd"
        className={className}
    /> : <CustomField
        style={{minWidth: "250px"}}
        disabled={updateOn}
        placeholder={placeholder}
        label={label}
        className={className}
    />
};

const ServiceSettings = ({
    value,
    setValue,

    defaultValue=false
}) => {
    return <>
        <Checkbox
            name="reseller_allowed"
            onChange={(_, checked) => setValue(checked)}
            checked={value}
            defaultChecked={defaultValue}
        >
            Allow reseller panel
        </Checkbox>
    </>
};

const StyledFlexboxGridItem = styled(FlexboxGrid.Item)`
    margin-right: 40px;
`;

const BanInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const BanAccountInfo = styled.span`
    color: red;
`;

const BanCleanButton = styled(Button)`
    &&& {
        background-color: var(--color-warning) !important;
        margin-top: 10px;
        width: 200px
    }
`;

const StyledRow = styled.div`
    display: flex;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
    width: 100%;
    padding: 5px 10px 5px 10px !important;
    flex-wrap: wrap;
`;

const DateItem = styled.span`
    && {
        font-weight: 600;
    }
`;

const AlertMessage = styled.p`
  text-align: justify;
`;

const LinkButton = styled(Button)`
  padding: 0 5px 0 5px !important; 
`;

const HeaderList = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: var(--color-main);
`;

const ButtonFooter = styled.div`
    text-align: center;
    margin-top: 30px;
    > .rs-btn {
        margin-right: 50px
    }
`;

const ExpandedEmailsPortal = styled.div`
    width: 100%;
    height: ${() => document.body.offsetHeight - 56}px;
    top: 56px;
    left: 0;
    position: absolute;
    z-index: 5;
`;

const MergeAccountsHeader = styled.label`
    && {
        min-height: 20px;
        // line-height: 1.42857143;
        font-size: 14px;
        padding: 8px 0;
        width: 170px;
        margin-right: 12px;
        margin-bottom: 4px;


        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */
        color: var(--color-main);
    }
`;

const MergeAccountsButton = styled(Button)`
    && {
        display: flex;
    }
`;

const StyledSupplierOptionsContainer = styled.div`
    && {
        margin-top: 40px;
    }
`;

const AccountSettings = styled(FlexboxGrid)`
    && {
        position: relative;

        ${props => props.isMobile ? css`
            flex-direction: column;
        ` : css`
            left: 585px;
            width: fit-content;
        `}
    }

`;