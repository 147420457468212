import React, {createRef} from 'react';
import {
    CheckPicker,
    Form,
    Input,
    Message,
    Alert,
    Schema,
    Icon,
} from 'rsuite';
import CustomField from './ContactCustomFieldRefactored';
import {BorderBtn, ButtonListRemove, BaseButton} from '../../components/base/BaseButton';
import Modal from '../../components/Modal';
import styled, {css} from 'styled-components';
import {copyToClipboard, generatePassword} from '../../utils';
import {api} from "../../api/loginRoutes";
import {ACCOUNT_USER_LOGIN_MASTER_API} from '../../const/apiMethods';
import {Link} from "react-router-dom";


const {StringType, ArrayType} = Schema.Types;
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#?!_@$%^&*-]{8,}$/;


export const userModel = Schema.Model({
    name: StringType().isRequired('Required').maxLength(40, 'The maximum is only 40 characters.'),
    login: StringType().isRequired('Required').maxLength(40, 'The maximum is only 40 characters.'),
    email: StringType().isRequired('Required').isEmail('Email required'),
    role_list: ArrayType()
        .isRequired('This field required')
        .minLength(1, 'Required')
        .addRule((value, data) => {
            if (value.length > 1 && !data.is_test_account) {
                return false;
            }
            return true;
        }, 'You can choose only one option'),
    password: StringType()
        .maxLength(40, 'The maximum is only 40 characters.')
        .addRule(value => passwordRegExp.test(value), 'Please enter legal characters'),
});

export const userModelTest = Schema.Model({
    name: StringType().isRequired('Required').maxLength(40, 'The maximum is only 40 characters.'),
    login: StringType().isRequired('Required').maxLength(40, 'The maximum is only 40 characters.'),
    email: StringType().isRequired('Required').isEmail('Email required'),
    role_list: ArrayType()
        .isRequired('This field required')
        .minLength(1, 'Required'),
    password: StringType()
        .maxLength(40, 'The maximum is only 40 characters.')
        .addRule(value => passwordRegExp.test(value), 'Please enter legal characters'),
});


const userDisabledEmailModel = Schema.Model({
    role_list: ArrayType()
        .isRequired('This field required')
        .minLength(1, 'Required')
        .addRule((value, data) => {
            if (value.length > 1 && !data.is_test_account) {
                return false;
            }
            return true;
        }, 'You can choose only one option'),
    });

const userDisabledEmailModelTest = Schema.Model({
    role_list: ArrayType()
        .isRequired('This field required')
        .minLength(1, 'Required'),
    });


const passwordModel = Schema.Model({
    password: StringType()
        .isRequired('Required')
        .maxLength(40, 'The maximum is only 40 characters.')
        .addRule(value => passwordRegExp.test(value), 'Please enter legal characters'),
    confirm_password: StringType()
        .isRequired('Required')
        .addRule((value, data) => data.password === value, 'Password doesn\'t match'),
});



// export const initUser = {name:'',login:'',email:'', password: null, key: null} 
export const initUser = {name: '', login: '', email: '', role_list: [], key: null};


export default ({
    disabled, 
    users, 
    isMobile,
    updateUsers, 
    addableIsChanged, 
    initialList, 
    clientRoleList, 
    adminRoleList, 
    updateFormRef,
    isTestAccount
}) => {

    let passwordRef = createRef();

    const [verificationData, setVerificationData] = React.useState({});
    const [resetPasswordData, setResetPassword] = React.useState({});

    const [passwordModal, setShowPasswordModal] = React.useState(false);
    const [errorsPasswordForm, setErrorsPasswordForm] = React.useState({
        password: null,
        confirm_password: null
    });
    const [passwordState, setPasswordState] = React.useState(null);
    const [passwordUserObject, setPasswordUserObject] = React.useState({key: null, email: null, login: null});

    const updateFormValues = (formValues, key) => {
        users && updateUsers([
            ...users.map(user =>
                user.key === key
                    ?
                    {...user, ...formValues} : user
            )
        ]);
    };
    const onRemove = (key) => {
        updateUsers(users.filter(user => user.key !== key));
    };

    const onShowPasswordModal = (user) => {
        setShowPasswordModal(true);
        setPasswordUserObject({...user});
        setErrorsPasswordForm({
            password: null,
            confirm_password: null
        });
    };

    const onCloseModal = () => {
        setPasswordState(null);
        setShowPasswordModal(false);
    };
    const setPassword = () => {
        if (!errorsPasswordForm.password && !errorsPasswordForm.confirm_password) {
            const formValue = passwordRef.getFormValue();
            
            if (!formValue)
                return;

            const password = passwordRef.getFormValue().password;
            updateUsers([
                ...users.map(user =>
                    user.key === passwordUserObject.key
                        ?
                        {...user, password} : user
                )
            ]);
            setPasswordState(null);
            onCloseModal();
        }
    };

    const onChangePasswordForm = (formValue) => {
        setPasswordState(formValue);
        setTimeout((passwordRef) => {
            const status = passwordModel.check(passwordRef.getFormValue());
            const password = status.password.hasError ? status.password.errorMessage : null;
            const confirm_password = status.confirm_password.hasError ? status.confirm_password.errorMessage : null;
            setErrorsPasswordForm({
                password,
                confirm_password
            });
        }, 100, passwordRef);
    };

    const generateNewPassword = () => {
        const password = generatePassword();
        setPasswordState({...passwordState, password, confirm_password: password});
        const textToCopy = `login: ${passwordUserObject.login} password: ${password}`;
        copyToClipboard(textToCopy);
        Alert.success('New generated password has been copied!', 10000)
    };

    const sendVerification = (id) => {
        setVerificationData({...verificationData, [id]: {loading: true, status: false}});
        api("account_user:verify_email_create", {
            target: {account_user_id: id},
            clean_password: true,
        })
            .then(r => {
                setVerificationData({...verificationData, [id]: {loading: false, status: true}});
                Alert.success("Confirmation email was sent");
            })
            .catch(error => {
                setVerificationData({...verificationData, [id]: {loading: false, status: false}});
            });
    };

    const resetCustomerPassword = ({id, email}) => {
        setResetPassword({...resetPasswordData, [id]: {loading: true, status: false}});
        api("account_user__reset_password:request_by_account_manager", {
            email: email,
        })
            .then(r => {
                setResetPassword({...resetPasswordData, [id]: {loading: false, status: true}});
                Alert.success(`Reset password link for "${email}" was successfully sent`);
            })
            .catch(error => {
                setResetPassword({...resetPasswordData, [id]: {loading: false, status: false}});
            });
    };


    const handleClickLogin = (id) => {
        window.open(`/redirect?customer-id=${id}`);
    };


    return (
        <>
            {users.map( (user, idx) => {
                const roles = (user.is_account_manager || isTestAccount
                    ? [...adminRoleList]
                    : clientRoleList
                ).map(x => ({value: x, label: x}));
                const rolesOnlyNames = roles.map(role => role.value);

                const clearedInitialList = initialList.map(initialValues => {
                    const {key, ...remainingInitialValues} = initialValues;
                    return remainingInitialValues;
                });
                const {key, ...clearedUserValues} = user;

                const changedClass = addableIsChanged(user.id, clearedInitialList, clearedUserValues) ? ' has-changed' : '';

                const allRolesChangable = user.role_list.every(role => rolesOnlyNames.includes(role));

                return <>
                    {isMobile && idx !== 0 ? <hr style={{width: '100%', height: '2px'}}/> : <></>}
                    <StyledForm
                        layout="inline"
                        key={user.key}
                        ref={ref => updateFormRef(ref, user.key)}
                        onChange={(formValues) => updateFormValues(formValues, user.key)}
                        formDefaultValue={user}
                        model={user.email_disabled
                            ? isTestAccount ? userDisabledEmailModelTest : userDisabledEmailModel
                            : isTestAccount ? userModelTest : userModel}
                        className={`tab-general-addable${changedClass}`}
                        mobile={isMobile}
                    >

                        <CustomField
                            disabled={disabled}
                            accepter={Input}
                            name="name"
                            placeholder="Name"
                        />

                        <CustomField
                            disabled={disabled || user.login_disabled}
                            accepter={Input}
                            name="login"
                            placeholder="Login"
                        />

                        <CustomField
                            disabled={disabled || user.email_disabled}
                            accepter={Input}
                            name="email"
                            placeholder="Email"
                        />

                        <StyledCheckPicker>
                            <CustomField
                                className="custom-field-role-list"
                                disabled={disabled}
                                accepter ={CheckPicker}
                                name="role_list"
                                data={roles}
                                placeholder="Roles"
                                searchable = {false}
                                cleanable = {allRolesChangable}
                                classPrefix="minWidth"
                                renderValue={(value, items, selectedElement) => {
                                    const selectedRolesString = value.join(", ");
                                    return selectedRolesString;
                                }}
                            />
                        </StyledCheckPicker>

                        {(!isTestAccount && user.id) &&
                            <span>

                                <VerificationColumn>
                                    {user.email_verified
                                        ? <StyledActionButtonVerified
                                            tooltipText='Identity confirmed'
                                            tooltipPlacement='leftStart'
                                            confirmed 
                                            buttonStyle="default"
                                        >
                                            <Icon icon="envelope-open-o"/>
                                            <Icon className="button-badge" icon="check-circle"/>
                                        </StyledActionButtonVerified>
                                        : <>
                                            {user.email_verified === false || (verificationData[user.id] && verificationData[user.id].status)

                                                ? <StyledActionButtonSenVerification
                                                    tooltipText='A confirmation email has been sent'
                                                    tooltipPlacement='leftStart'
                                                    buttonStyle="secondary"
                                                    onClick={() => sendVerification(user.id)}
                                                    loading={verificationData[user.id] && verificationData[user.id].loading}
                                                >
                                                    <Icon icon="envelope-o"/>
                                                    <Icon className="button-badge" icon="hourglass"/>
                                                </StyledActionButtonSenVerification>

                                                : <StyledActionButtonSenVerification
                                                    tooltipText='Send verification link'
                                                    tooltipPlacement='leftStart'
                                                    buttonStyle="secondary"
                                                    onClick={() => sendVerification(user.id)}
                                                    loading={verificationData[user.id] && verificationData[user.id].loading}
                                                >
                                                    <Icon icon="envelope-o"/>
                                                </StyledActionButtonSenVerification>
                                            }
                                        </>
                                    }
                                </VerificationColumn>

                                <div style={{display: 'inline-block', marginRight: 10}}>
                                    <StyledActionButtonResetPassword
                                        tooltipText='Send reset password link'
                                        tooltipPlacement='leftStart'
                                        buttonStyle="default"
                                        onClick={() => resetCustomerPassword(user)}
                                        loading={resetPasswordData[user.id] && resetPasswordData[user.id].loading}
                                    >
                                        <Icon icon="key"/>
                                        <Icon className="button-badge" icon="refresh2"/>
                                    </StyledActionButtonResetPassword>
                                </div>
                            </span>
                        }


                        {(isTestAccount || user.id) &&
                            <div style={{display: 'inline-block'}}>
                                <BorderBtn
                                    withShadow
                                    buttonStyle="primary"
                                    style={{marginRight: 10, width: 170}}
                                    onClick={() => onShowPasswordModal(user)}
                                    size="sm"
                                >
                                    Set password
                                </BorderBtn>
                            </div>
                        }

                        {(user.id) &&
                            <div style={{display: 'inline-block'}}>
                                <StyledActionButton
                                    buttonStyle="primary"
                                    style={{marginLeft: '10px', marginRight: '20px'}}
                                    tooltipText={<>Login as <b>{user.login}</b></>}
                                    tooltipPlacement='leftStart'
                                    componentClass={Link}
                                    target={'_blank'}
                                    to={`/redirect?customer-id=${user.id}`}
                                >
                                    <Icon style={{fontSize: 16}} icon="sign-in"/>
                                </StyledActionButton>
                            </div>
                        }

                        <div 
                            style={{marginTop: 5, display: 'inline-block'}}
                        >
                            <ButtonListRemove
                                className="remove-button" 
                                onClick={() => onRemove(user.key)}
                            />
                        </div>

                    </StyledForm>
                </>
            })}

            <Modal width={700} show={passwordModal} onClose={onCloseModal}>

                <Message
                    type="info"
                    description={
                        <p>The minimal length of password is 8 characters (just latin, digits special symbols
                            #?!_@$%^&amp;* are allowed). Password must contain at least per one uppercase, lowercase and
                            digit.</p>
                    }
                    style={{marginBottom: 10}}
                />

                <Form
                    layout="inline"
                    ref={ref => passwordRef = ref}
                    // model = {passwordModel}
                    formValue={passwordState}
                    onChange={(formValue) => onChangePasswordForm(formValue)}
                >
                    <CustomField
                        disabled={disabled}
                        accepter={Input}
                        name="password"
                        type="password"
                        placeholder="Password"
                        errorMessage={errorsPasswordForm.password}
                    />
                    <CustomField
                        disabled={disabled}
                        accepter={Input}
                        name="confirm_password"
                        type="password"
                        placeholder="Confirm password"
                        errorMessage={errorsPasswordForm.confirm_password}
                    />
                    <FormButton onClick={generateNewPassword}>Generate password</FormButton>
                    <FormButton onClick={setPassword}>Set password</FormButton>
                </Form>

            </Modal>
        </>

    );
}


const FormButton = styled(BaseButton).attrs(() => ({
    buttonStyle: "primary",
    size: "sm"
}))`
    &&& {
        height: 36px;
        margin-right: 5px;
    }
`;

const StyledForm = styled(Form)`
  &&& {
        ${props => props.mobile ? css`
            display: block;

            .rs-input {
                margin-bottom: 15px;
            }
            .rs-form-group .rs-form-control-wrapper {
                float: inherit;
            }
        ` : css`
            display: flex;
        `};
    }
`;

const StyledCheckPicker = styled.div`
  display: inline-block;
  margin-right: 0 !important;
  .rs-form-group {
      margin-right: 0 !important;
  }
`;


const VerificationColumn = styled.div`
    display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
`;

const StyledActionButton = styled(BaseButton)`
    position: relative;
    
    .button-badge {
        position: absolute;
        top: 3px;
        right: 3px;
        font-size: 10px;
        line-height: 1;
        font-size: 10px;
    }
`;

const StyledActionButtonSenVerification = styled(StyledActionButton)`
    &&& {
        
        .button-badge {
            font-size: 8px;
        }
    }
`;

const StyledActionButtonVerified = styled(StyledActionButton)`
    &&& {
        cursor: not-allowed;
        
        .button-badge {
            color: var(--color-secondary);
        }
    }
`;

const StyledActionButtonResetPassword = styled(StyledActionButton)`
    &&& {
        
        .button-badge {
            color: var(--info);
        }
    }
`;