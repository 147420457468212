import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useWindowWidth} from "../../../hooks";
import m from "../../../definedMessages";
import {CustomModal} from "../../../components/base";

export default ({
    show,
    onClose,
    maxWidth = 600,
    selectedRequest,
    cancelPaymentRequest,
    getPaymentRequestList,
    onSuccess,
    updateAccount
}) => {

    const {formatMessage} = useIntl();

    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);

    const handleSuccess = async () => {
        setLoading(true);

        const data = {
            target: {
                // account_id: accountId,
                payment_request_id: (selectedRequest || {})['id']
            },
        }
        
        await cancelPaymentRequest(data).then((response) => {
            if (response?.payment_request) {
                getPaymentRequestList();
                onSuccess(selectedRequest.amount);      
                updateAccount();
            }

            setLoading(false);
        });

   
        onClose();
    };

    return (
        <CustomModal
            show={show}
            onClose={() => onClose(false)}
            title={formatMessage(m.cancelPaymentRequest)}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showFooter={true}
            loading={loading}
            confirmButtonText={formatMessage(m.yes)}
            focusTarget="close"
            onConfirm={handleSuccess}
        >
            {formatMessage(m.areYouSure)}
        </CustomModal>
    )
}