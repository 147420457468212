import {connect} from 'react-redux';
import {getAccount, getAccountJoin, getDropdownAccounts, getAccountAllocatedNumbers, getAccountTrunk, getAccountUsers, setCurrentTrunkId,
    setAllocationRestrictions, setModifiedLimitId, setCurrentRangeNumber, onSetRedirectTab, setEmptyAccountTrunk} from '../../actions/accounts';
import {getTrunkNumberCsvById} from '../../actions/numbers';
import {getRates, getTransitRates} from '../../actions/rates';
import AccountRefactored from './AccountRefactored';
import {getAccessListFilter} from 'actions';
import {getKeyList} from 'actions/apiPage';
import {getAccountManagersDropdownList} from "../../actions/account_managers";
import {getAccountServicePlanList} from "../../actions/self_allocation_restrictions";
import {getAccounts, unsetTrunksInited} from '../../actions'
import {withRouter} from 'react-router';
import {setService} from '../../actions/auth';
import {getReferencesForReports} from '../../actions/references';
// import history from '../../config/history';
import {
    getE212DropdownList
} from '../../actions/e212';
import {
    setCurrentSupplier,
    getSupplierPlanList
} from '../../actions/suppliers';
import {
    setResellerMode
} from "../../actions/resellers";

const mapState = ({accounts, suppliers, references, roles, account_managers, auth, e212, self_allocation_restrictions}) => {
    // const isSupplier = history.location.pathname.includes('sms-suppliers');
    return {
        // isSupplier: isSupplier,

        service: auth.service,  

        authInfo: auth.authInfo,
        account_list: accounts.items,
        account: accounts.accountView,
        accountLoading: accounts.loading,
        accountJoinList: accounts.accountJoinList,
        billingServiceList: accounts.billingServiceList,
        joinLoading: accounts.joinLoading,

        hasData: !!accounts.items.length,
        trunks: accounts.accountTrunk,
        trunksLoading: accounts.accountTrunkLoading,
        e212List: e212.list,
        currency_list: references.currency_list,
        payment_terms_list: references.payment_terms_list,
        payment_terms_supplier_list: references.payment_terms_supplier_list,
        loadingReferences: references.loading,
        allocatedNumbers: accounts.accountAllocated,
        allocatedNumbersCount: accounts.accountAllocatedCount,
        allocatedNumbersTotalCount: accounts.accountAllocatedNumbersTotalCount,
        allocatedNumbersPage: accounts.allocatedNumbersPage,
        allocatedNumbersPerPage: accounts.allocatedNumbersPerPage,
        allocatedNumbersLoading: accounts.accountAllocatedLoading,
        modifiedLimit: accounts.modifiedLimit,
        worldzone_list: references.worldzone_list,
        subdestination_list: references.subdestination_list,
        destination_list: references.destination_list,
        account_manager_list: account_managers.dropdown_list,

        accountUsers: accounts.accountUsers,
        accountUsersLoading: accounts.accountUsersLoading,

        newCreatedAccountId: suppliers.newCreatedAccountId,

        rates: accounts.rates,
        ratesLoading: accounts.ratesLoading,
        transitRates: accounts.transitRates,
        transitRatesLoading: accounts.transitRatesLoading,
        account_user_modify_method: roles.account_user_modify_method,
        account_user_remove_method: roles.account_user_remove_method,
        role_list: roles.role_list,

        accessList: accounts.accessList,
        accessListLoading: accounts.accessListLoading,
        currentTrunkId: accounts.currentTrunkId,
        currentRangeNumber: accounts.currentRangeNumber,
        client_role_list: references.client_role_list,
        admin_role_list: references.admin_role_list,

        redirectTab: accounts.redirectTab,
        countryList: references.country_list,
        howFindList: references.how_find_list,
        accountServicePlanList: self_allocation_restrictions.list,

        raterc_list: references.raterc_list,
        permissions: auth.permissions,
        suppliersPlanList: suppliers.suppliersPlanList,
        currentSupplier: suppliers.currentSupplier,
        
        supplierTrunks: accounts.accountSupplierTrunks,

        ganTrunks: accounts.accountSupplierGanTrunks
    }
};

export default withRouter(connect(mapState,
    {
        unsetTrunksInited,
        setAllocationRestrictions,
        setCurrentTrunkId,
        setCurrentRangeNumber,
        getAccount,
        getAccountJoin,
        getAccounts,
        getDropdownAccounts,
        getAccountTrunk,
        getAccessListFilter,
        getAccountAllocatedNumbers,
        getReferencesForReports,
        getTrunkNumberCsvById,
        setModifiedLimitId,
        getAccountUsers,
        getRates,
        getTransitRates,
        getKeyList,
        setService,
        getSupplierPlanList,
        setCurrentSupplier,
        setEmptyAccountTrunk,
        getE212DropdownList,
        getAccountManagersDropdownList,
        onSetRedirectTab,
        getAccountServicePlanList,
        setResellerMode
    })(AccountRefactored));
  
