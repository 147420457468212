import React, {useState} from "react";
import {Table} from 'rsuite';
import {Form, Schema, SelectPicker, FormControl, Input, InputNumber, Button} from "rsuite";
import NumberField from "components/Form/NumberField";
import Icon from "rsuite/es/Icon";
import Whisper from 'rsuite/es/Whisper';
import Tooltip from 'rsuite/es/Tooltip';
import styled from "styled-components";

const {Column, HeaderCell, Cell} = Table;

export default ({service, defaultFilter, addItem, update, ...props}) => {
    const form = React.createRef();

    const formControlNames = service 
        ? ["a_wz_key", "a_de_key", "a_sde_key", "a_prefix", "b_wz_key", "b_de_key", "b_sde_key", "bil_acc_orig", "bil_acc_term"]
        : ["acc_supp_id", "acc_dial_id", "senderid", "sender_name", "sender_wz_key", "sender_de_key", "sender_sde_key", "phone_wz_key", "phone_de_key", "phone_sde_key"];

    const [fields, setFields] = useState({});
    const [hasRequiredException, setRequiredException] = useState(false);

    const {StringType, NumberType} = Schema.Types;
    const formModel = Schema.Model({
        a_sde_key: NumberType(),
        a_prefix: StringType()
            .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
            .minLength(6, 'The minimum is 6 characters.')
            .maxLength(15, "The minimum is 15 characters"),
        b_sde_key: NumberType(),
        bil_acc_orig: NumberType(),
        bil_acc_term: NumberType()
    });

    const formControlItems = service ?
     [
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="bil_acc_orig" accepter={SelectPicker} data={props.clientList} placeholder="Originator" valueKey="acc_key" labelKey="name" type="text" />,
        <FormControl errorPlacement="topEnd" className="tableFilters_field" name="a_prefix" accepter={Input} placeholder="Origin Prefix" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="a_wz_key" accepter={SelectPicker} data={props.worldzoneList} placeholder="Origin zone" valueKey="wz_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="a_de_key" accepter={SelectPicker} data={props.destinationList} placeholder="Origin country" valueKey="de_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="a_sde_key" accepter={SelectPicker} data={props.subdestinationList} placeholder="Origin operator" valueKey="sde_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="b_wz_key" accepter={SelectPicker} data={props.worldzoneList} placeholder="Desctination zone" valueKey="wz_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="b_de_key" accepter={SelectPicker} data={props.destinationList} placeholder="Destination country" valueKey="de_key" labelKey="name" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="b_sde_key" accepter={SelectPicker} data={props.subdestinationList} placeholder="Destination operator" valueKey="sde_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="bil_acc_term" accepter={SelectPicker} data={props.clientList} placeholder="Terminator" valueKey="acc_key" labelKey="name" type="text" />,
    ] :
    [
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="acc_supp_id" accepter={SelectPicker} data={props.supplierList} placeholder="Supplier" valueKey="id" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="acc_dial_id" accepter={SelectPicker} data={props.dialerList} placeholder="Dialer" valueKey="id" labelKey="name" type="text" />,
        <FormControl errorPlacement="topEnd" className="tableFilters_field" name="senderid" accepter={Input} placeholder="Sender ID" type="text" />,
        <FormControl errorPlacement="topEnd" className="tableFilters_field" name="sender_name" accepter={Input} placeholder="Sender Name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="sender_wz_key" accepter={SelectPicker} data={props.worldzoneList} placeholder="Sender worldzone" valueKey="wz_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="sender_de_key" accepter={SelectPicker} data={props.destinationList} placeholder="Sender destination" valueKey="de_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="sender_sde_key" accepter={SelectPicker} data={props.subdestinationList} placeholder="Sender subdest" valueKey="sde_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="phone_wz_key" accepter={SelectPicker} data={props.worldzoneList} placeholder="Phone worldzone" valueKey="wz_key" labelKey="name" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="phone_de_key" accepter={SelectPicker} data={props.destinationList} placeholder="Phone destination" valueKey="de_key" labelKey="name" type="text" />,
        <FormControl placement="autoVerticalStart" errorPlacement="topEnd" className="tableFilters_field" name="phone_sde_key" accepter={SelectPicker} data={props.subdestinationList} placeholder="Phone subdest" valueKey="sde_key" labelKey="name" type="text" />,
    ];

    const handleChange = (formValues) => {
        setFields(formValues);

        checkAtLeastOneException(formValues);
    };
    const checkAtLeastOneException = (values) => {
        setRequiredException(!!formControlNames.map(key => values[key]).filter(value => value).length);
    };
    const handleSubmit = async (_, event) => {
        event.preventDefault();

        let validStatus = form.current.check();

        if (!validStatus)
            return;

        let formValues = form.current.getFormValue();
        let data = {
            ...formValues,
            a_prefix: formValues.a_prefix 
                ? formValues.a_prefix 
                : null
        };

        addItem(service, data).then(() => {
            update(service);
        });

    };



    const columns = formControlNames.map( key => {
        return {dataKey: key, label: ''}
    } );

    const tableColumnsElements = {};
    for (let i = 0; i < formControlNames.length; i++) {
        tableColumnsElements[formControlNames[i]] = formControlItems[i];
    }

    const columnsElements = formControlNames.map( (item) => {
        return (
            <Column flexGrow>
                <HeaderCell></HeaderCell>
                <Cell dataKey={item}/>
            </Column>
        )
    } );

    return(
        <>
            <StyledForm
                onSubmit={handleSubmit}
                ref={form}
                model={formModel}
                onChange={handleChange}
                formDefaultValue={fields}
                style={{margin: '0px', width: '100%'}}
                className={"tableFilters tableFilters__form"}
            >

                <StyledFieldsWrapper>
                    {formControlItems}
                </StyledFieldsWrapper>

                <StyledSubmitWrapper>
                    <Button
                        disabled={!hasRequiredException}
                        className="tableFilters_submit"
                        color="green"
                        type="submit"
                    >
                        <Icon icon="plus"/>
                    </Button>
                    {!hasRequiredException &&
                        <Whisper
                            trigger="hover"
                            placement="topEnd"
                            speaker={
                                <Tooltip>
                                    At least one exception field must be not empty
                                </Tooltip>
                            }
                        >
                            <Icon className="access_list_exception_add__waring" icon="remind"/>
                        </Whisper>
                    }
                </StyledSubmitWrapper>

            </StyledForm>
        </>
    )
};


const StyledFieldsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-top: 5px;
`;

const StyledSubmitWrapper = styled.div`
    padding: 5px 10px 5px 20px;
    width: 185px;
    flex-shrink: 0;
    
    .access_list_exception_add__waring {
        padding-left: 20px
    }
`;

const StyledForm = styled(Form)`
    display: flex;
    padding-bottom: 30px;
    
    .rs-form-control-wrapper {
        flex-shrink: 0;
        min-width: 175px;
        padding-bottom: 5px;
        padding-right: 5px;
        width: 20%;
    }
`;