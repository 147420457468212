import React from 'react';
import { Form, Input, SelectPicker, Schema} from 'rsuite';
import CustomField from '../../../components/Form/CustomField';
import FormHOC from '../../../hoc/FilltersForm';
import ServicePlans from "../../../components/Filters/ServicePlans";
import {connect} from "react-redux";
import {SP_KEY__INCORPORATED} from "../../../const";
const { StringType } = Schema.Types;

const filtersModel = Schema.Model({});


const filters = ({
    onChange,
    isSupplier,
    countryList,
    worldzone_list, 
    subdestination_list, 
    destination_list, 
    filteredServicePlanList,
    defaultSPKey,
    pickSupplierPlan,
    pickedSupplierPlan,
    transitSupplierPlanList
}) => {

    const compareDefaultRateData = [
        {
            label: "Show only lower than default rate",
            value: 1
        }, 
        {
            label: "Show only higher than default rate",
            value: 2
        }
    ];

    return (
            <>
                <Form layout="inline" 
                    onChange={onChange}
                    formDefaultValue={{sp_key: defaultSPKey}}
                    model={filtersModel}
                    >

                    {isSupplier ? <SelectPicker
                        data={transitSupplierPlanList}
                        cleanable={false}
                        searchable={false}
                        placeholder={'Select supplier plan'}
                        valueKey="id"
                        labelKey="name"
                        value={pickedSupplierPlan?.id}
                        onChange={(id) => {
                            const supplierValue = transitSupplierPlanList.find(supplier => supplier?.id === id);

                            pickSupplierPlan(supplierValue)
                        }}
                        style={{
                            width: 230
                        }}
                    />
                    : <ServicePlans 
                        classPrefix="allocated"
                        extraList={filteredServicePlanList}
                        excluded={[SP_KEY__INCORPORATED]}
                        disabled={isSupplier ? !pickedSupplierPlan?.id : false}
                    />}
                    {!isSupplier ? <CustomField
                        accepter={SelectPicker}
                        labelKey="name"
                        valueKey="wz_key"
                        data={worldzone_list}
                        placeholder="Worldzone"
                        name="wz_key"
                        classPrefix="allocated"
                        disabled={isSupplier && !pickedSupplierPlan?.id}
                    /> : <></>}
                    {isSupplier ? <CustomField
                        accepter={SelectPicker}
                        data={countryList}
                        labelKey="name"
                        valueKey="name"
                        placeholder="Country"
                        name="country"
                        style={{width: 200}}
                    />
                   : <CustomField
                        accepter={SelectPicker}
                        data={destination_list}
                        labelKey="name"
                        valueKey="de_key"
                        placeholder="Destination"
                        name="de_key"
                        classPrefix="allocated"
                        disabled={isSupplier && !pickedSupplierPlan?.id}
                    />}
                    {isSupplier ? <CustomField
                        accepter={Input}
                        name="operator"
                        placeholder="Operator"
                        style={{width: 200}}
                    />
                   : <CustomField
                        accepter={SelectPicker}
                        data={subdestination_list}
                        labelKey="name"
                        valueKey="sde_key"
                        placeholder="Subdestination"
                        name="sde_key"
                        classPrefix="allocated"
                        // disabled={isSupplier && !pickedSupplierPlan?.id}
                    />}
                    {!isSupplier ? <CustomField
                        labelKey="label"
                        valueKey="value"
                        cleanable={true}
                        searchable={false}
                        placeholder="Default rate options"
                        accepter={SelectPicker}
                        disabled={isSupplier && !pickedSupplierPlan?.id}
                        name="default_rate"
                        data={compareDefaultRateData}
                        
                    /> : <></>}
                    {isSupplier ? <CustomField
                        accepter={Input}
                        name="mcc"
                        placeholder="MCC"
                        style={{width: 80}}
                    /> : <></>}
                    {isSupplier ? <CustomField
                        accepter={Input}
                        name="mnc"
                        placeholder="MNC"
                        style={{width: 80}}
                    /> : <></>}
                </Form>
            </>
        )
};

const mapState = ({references})=> ({
    defaultSPKey: references.defaultSPKey
});

export default connect(mapState, null)(FormHOC(filters, filtersModel,300));