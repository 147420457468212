import React, {useEffect, useRef, useState} from 'react'
import _ from "lodash";
import styled from "styled-components";
import {connect} from 'react-redux';
import Recaptcha from 'react-google-invisible-recaptcha';
import {Link, withRouter} from 'react-router-dom';
import {
    Badge, Button, Container, Content, ControlLabel, Form, FormControl,
    FormGroup, Header, Icon, Message, Nav, Navbar, Panel, Schema, Alert
} from 'rsuite';

import {loginUser, loginBySessionKey, changeLanguage} from '../../actions/auth';
import {clearLocalStorage, isError} from '../../utils';
import {LOCAL_STORAGE_USER_INFO} from "../../const/localStorageKeys";
import {APP_TYPE_ADMIN, APP_TYPE_DEFAULT} from "../../const";
import {RtlContext} from "../../App";

import LangSwitcher from "../../components/LangSwitcher";
import {Spacer} from "../../components/base/Spacer";
import SkypeWidget from "../../components/SkypeWidget";
import VersionLabel from '../../components/VersionLabel/VersionLabel';
import {BaseButton, ButtonToolbar} from "../../components/base/BaseButton";

import RegistrationModal from './RegistrationModal';
import AccountSwitchingInfo from "./AccountSwitchingInfo";
import ManualWidget from 'components/ManualWidget';


const {StringType} = Schema.Types;



export const loginModel = Schema.Model({
    login: StringType().isRequired('Required'),
    password: StringType().isRequired('Required'),
});


export const secondAuthModel = Schema.Model({
    second_password: StringType()
        .isRequired('Required')
        .maxLength(8, 'The maximum of this field is 8 characters')
});


const Login = (
    {
        loginUser,
        loginBySessionKey,
        changeLanguage,

        loading,
        secondAuthType,
        secondAuthLink,
        secondAuthEmail,
        location,
        history,
        lang
        
    }
) => {

    let form;

    const formData = useRef(null);

    const recaptchaRef = useRef(null);
    const [recapchaIsVisible] = useState(() => {
        return process?.env?.REACT_APP_IS_PRODUCTION 
            ? !!(+process?.env?.REACT_APP_IS_PRODUCTION)
            : false
    });
    const [showPassword, onChangeShowPassword] = useState(true);
    const [showRegistration, onShowRegistrationModal] = useState( new URLSearchParams(location.search).has('sign-up') );
    const [emailPending, setEmailPending] = useState(null);
    const [sessionKey, setSesionKey] = useState(() => {
        return new URLSearchParams(location.search).get('session-key') || null;
    });
    const [language, setLanguage] = useState(lang);


    useEffect(() => {
        if (!sessionKey) {
            return;
        }

        history.replace('/reload');

        loginBySessionKey(sessionKey);
    }, [sessionKey]);



    useEffect(() => {
        document.body.classList.remove("rtl");
        // localStorage.removeItem('SMS_SERVICE');
    }, []);


    useEffect(() => {
        return () => resetRecaptcha();
    });


    const submit = () => {
        if (!form.check()) {
            resetRecaptcha();
            return;
        }

        formData.current = form.getFormValue();

        executeRecaptcha();
    };


    const executeRecaptcha = () => {
        if (!recapchaIsVisible) {
            handleResolveCaptcha();
            return;
        }

        if (!recaptchaRef?.current?.execute) {
            return;    
        }

        recaptchaRef.current.execute();
    }
    

    const resetRecaptcha = () => {
        if (!recapchaIsVisible) {
            return;
        }

        if (!recaptchaRef?.current?.reset) {
            return;
        }

        recaptchaRef.current.reset();
    }


    const handleResolveCaptcha = (token) => {

        if (!token && recapchaIsVisible) {
            Alert.error("Bot verification failed")
            resetRecaptcha();
            return;
        }

        loginUser({
            ...formData.current, lang: language,
            captcha_token: token, loginLocation: location, loginHistory: history
        })
            .then((r) => {
                if ( isError(r) ) {
                    const reasonCode = r.response?.data.error?.reason_code || null;
                    
                    if ("not_confirmed" === reasonCode) {
                        setEmailPending(r.response?.data?.error?.data?.email || null);
                    }
                }

                clearLocalStorage()
            })
            .finally(() => {
                resetRecaptcha();
            });
    }


    let {account_name, name, login} = localStorage.getItem(LOCAL_STORAGE_USER_INFO)
        ? JSON.parse( localStorage.getItem(LOCAL_STORAGE_USER_INFO) )
        : {};

    const defaultValue = APP_TYPE_DEFAULT === APP_TYPE_ADMIN
        ? ({login})
        : ({login, lang});


    if (sessionKey) {
        return (
            <AccountSwitchingInfo />
        );
    }


    return (
        <RtlContext.Provider value={false}>
            <StyledContainer>

                <Header>
                    <Navbar appearance="inverse">
                        <Navbar.Header>
                            {account_name && name &&
                                <StyledMenuUserInfo>
                                    <StyledMenuCompanyInfo>
                                        <StyledBadge />
                                        {account_name}
                                    </StyledMenuCompanyInfo>
                                    {name}
                                </StyledMenuUserInfo>
                            }
                        </Navbar.Header>

                        <Navbar.Body>
                            <Nav pullRight>
                                <StyledNavItem
                                    icon={<Icon icon="sign-in"/>}
                                >
                                    Log in
                                </StyledNavItem>
                            </Nav>
                        </Navbar.Body>

                    </Navbar>
                </Header>

                <Content>
                    <StyledPanel
                        header={<h3>Login</h3>}
                        style={{marginTop: '15vh'}}
                        bordered
                    >

                        {!secondAuthType &&
                            <Form
                                fluid
                                ref={ref => (form = ref)}
                                formDefaultValue={defaultValue}
                                onChange={(value) => {
                                    setLanguage(value?.lang)
                                }}
                                model={loginModel}
                            >
                                <FormGroup>
                                    <ControlLabel>Email address or login</ControlLabel>
                                    <FormControl autoFocus={!login} name="login"/>
                                </FormGroup>

                                <FormGroup>
                                    <ControlLabel>Password</ControlLabel>
                                    <FormControl
                                        autoFocus={!!login}
                                        name="password"
                                        type={showPassword ? 'password' : 'text'}
                                    />
                                    <Button
                                        onClick={() => onChangeShowPassword(!showPassword)}
                                        style={{
                                            position: 'absolute',
                                            marginLeft: '-38px',
                                            background: 'transparent'
                                        }}>
                                        <Icon icon={showPassword ? 'eye' : 'eye-slash'}/>
                                    </Button>
                                </FormGroup>

                                {APP_TYPE_DEFAULT !== APP_TYPE_ADMIN &&
                                    <FormGroup>
                                        <ControlLabel>Language</ControlLabel>
                                        <FormControl
                                            accepter={LangSwitcher}
                                            onChange={(lang) => {
                                                changeLanguage(lang);
                                            }}
                                            name="lang"
                                            menuClassName="no-rtl"
                                        />
                                    </FormGroup>
                                }

                                <FormGroup>
                                    <ButtonToolbar>
                                        <StyledButton
                                            onClick={submit}
                                            type="submit"
                                            disabled={loading}
                                        >
                                            Sign in
                                        </StyledButton>


                                        <Link to="/reset-password">Forgot password?</Link>
                                    </ButtonToolbar>
                                    <ButtonToolbar>
                                        {APP_TYPE_DEFAULT !== APP_TYPE_ADMIN &&
                                        <StyledSignUpButton
                                            appearance="link"
                                            onClick={() => {
                                                onShowRegistrationModal(true)
                                            }}
                                            disabled={loading}
                                        >
                                            Looking to sign up? Contact us!
                                        </StyledSignUpButton>
                                        }
                                    </ButtonToolbar>
                                </FormGroup>
                            </Form>
                        }

                        {secondAuthType &&
                            <Form
                                ref={ref => (form = ref)}
                                formDefaultValue={{email: secondAuthEmail}}
                                model={secondAuthModel}
                                style={{display: 'block'}}
                            >
                                <div role="alert" className="alert alert-warning">
                                    Your account is under two factor authentication, please enter code which we sent to
                                    your {secondAuthType} <b>{secondAuthLink}</b>
                                </div>

                                <FormControl
                                    name="email"
                                    hidden={true}
                                />

                                <FormControl
                                    name="second_password"
                                    placeholder="Enter your code here"
                                    autoFocus={true}
                                    style={{
                                        display: 'block',
                                        width: '100%',
                                        marginBottom: '20px'
                                    }}
                                />

                                <FormGroup>
                                    <ButtonToolbar>
                                        <StyledButton
                                            onClick={submit}
                                            type="submit"
                                            disabled={loading}
                                        >
                                            Confirm
                                        </StyledButton>
                                    </ButtonToolbar>
                                </FormGroup>
                            </Form>
                        }

                        <Spacer/>

                        {emailPending &&
                        <>
                            <Message
                                showIcon
                                closable
                                type="warning"
                                onClose={() => setEmailPending(null)}
                                description={<>Email is not verified. Please check your address <b>{emailPending}</b> for details</>}
                            />
                        </>
                        }

                    </StyledPanel>
                </Content>

                <ManualWidget/>
                <SkypeWidget/>
                <VersionLabel/>

                <RegistrationModal
                    maxWidth={700}
                    show={showRegistration}
                    onClose={onShowRegistrationModal }
                />

                
                {recapchaIsVisible &&
                    <Recaptcha
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onResolved={handleResolveCaptcha}
                    />
                }

            </StyledContainer>
        </RtlContext.Provider>
    );
};

const mapState = ({auth}) => ({
    loading: auth.loading,
    secondAuthType: auth.secondAuthType,
    secondAuthLink: auth.secondAuthLink,
    secondAuthEmail: auth.secondAuthEmail,
    lang: auth.lang,
});


export default connect(mapState, {
    loginUser,
    loginBySessionKey,

    changeLanguage
})( withRouter(Login) );

const StyledSignUpButton = styled(Button)`
    font-size: 13px !important;
    margin-top: 18px;
    padding: 0 !important;
`;

const StyledContainer = styled(Container)`
    min-height: 100vh;
`;

const StyledPanel = styled(Panel)`
    margin-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    min-width: 340px;
    width: 100%;
`;

const StyledMenuCompanyInfo = styled.div`
    position: absolute;
    left: 4px;
    top: 4px;
    font-size: 8px;
`;

const StyledMenuUserInfo = styled.li`
    display: inline-block;
    padding: 19px 16px;
    height: 56px;
    font-size: 13px;
    color: #fff;
`;

const StyledBadge = styled(Badge)`
    &&& {
        margin-right: 2px;
        width: 4px;
        height: 4px;
        position: relative;
        top: -4px;
    }
`;

const StyledButton = styled(BaseButton).attrs(() => ({
    buttonStyle: APP_TYPE_DEFAULT === APP_TYPE_ADMIN ? "secondary" : "primary"
}))`
    && {
        min-width: 0;
    }
`;

const StyledNavItem = styled(Nav.Item)`
    .rs-nav-item-content {
    
        &:hover,
        &:active,
        &:focus {
            background-color: #5f2bb3 !important;
            
            .app-client & {
                background-color: #20b188 !important;
            }
        }
    }
`;