import React, {useRef, useMemo, useState, useEffect} from "react";
import styled from "styled-components";
import Form from "rsuite/es/Form";
import {Alert, Button, FlexboxGrid, SelectPicker, Input, Schema, CheckPicker} from "rsuite";
import {FormGroup, RadioGroup, Radio} from "rsuite/es";
// import {ButtonPrimary} from "../../../../components/base/BaseButton";
import CustomField from "../../../../components/Form/CustomField";
import ServicePlans from "../../../../components/Filters/ServicePlans";
import ModalConfirm from "../../../../components/Modal/ModalConfirm";
import TrafficLimitsCreateModal from "./TrafficLimitsCreateModal";
import { api } from "api/loginRoutes";
import { debounce } from "lodash";
import { getAuthNumberType } from "actions/auth_numbers";
import { compareObjects } from "utils";

const {StringType, NumberType} = Schema.Types;

export default ({
    // account, 
    filter,
    service,
    setFilter,
    supplierId,
    isSupplier,
    onDataUpdate,
    mccList,
    e212List,
    countryList,
    worldzoneList, 
    transitTrunkList,
    subdestinationList,
    suppliersPrefixList,
    destinationList,
    defaultFilter,
    trafficTypeState,
    suppliersGroupList,
    showCreateModal,
    selectedDefaultData,
    setShowCreateModal,
    setTrafficTypeState,
    getE212DropdownList,
    setSelectedDefaultData,
    getReferencesForReports,
    getSupplierPrefixesDropdown
}) => {

    const filterInputsRefs = {
        senderid: useRef(null),
        str: useRef(null),
        mcc: useRef(null)
    };

    const isGanGroup = trafficTypeState === "gan_group";
    const isGanNumber = trafficTypeState === "gan";
    const isPrefix = trafficTypeState === "prefix";
    const isMcc = trafficTypeState === "mcc";
    const isDefault = trafficTypeState === "mcc_default";

    useEffect(() => {
        Object.keys(defaultFilter).forEach(key => {
            if (filterInputsRefs && filterInputsRefs[key] && filterInputsRefs[key].current) {
                filterInputsRefs[key].current.value = defaultFilter[key];
            }
        });
        setDefaultLimitState(defaultFilter["default_type_list"]);
    }, [trafficTypeState]);

    const filterModel = isSupplier ? {
        senderid: StringType()
            .maxLength(40, "The maximum is only 40 characters."),
        str: StringType()
            .maxLength(40, "The maximum is only 40 characters."),
        mcc: NumberType()
    } : {
        sp_key: NumberType(),
        wz_key: NumberType(),
        de_key: NumberType(),
        mcc: NumberType()
    };

    const formModel = Schema.Model(filterModel);
    const [defaultLimitState, setDefaultLimitState] = useState(filter?.default_type_list);

    const strFilterPlaceholder = isMcc ? "MCC+MNC, Operator" : "Prefix, GAN Number, GAN Group name";
    const defaultLimitTypeList = [
        {
            value: "supplier",
            name: "Whole account limit"
        },
        {
            value: "mcc_default",
            name: "Default MCC limit"
        }
    ];

    return (
        <FlexboxGrid>
            <FullWidthFlexboxGridItem>
                <StyledForm
                    layout="inline"
                    onChange={debounce((value) => {
                        const filterData = Object.keys(value).reduce((currentFilter, key) => {
                            if (value[key] !== null) {
                                currentFilter[key] = value[key];
                            } else {
                                delete currentFilter[key];
                            }
                            return currentFilter;
                        }, {...filter});

                        setFilter(filterData);
                    }, 1000)}
                    formDefaultValue={defaultFilter}
                    model={formModel}
                >
                    <TrafficTypeContainer>
                        {isSupplier && <FormGroup controlId="trafficType">
                            <RadioGroup
                                inline
                                name="traffic_type" 
                                defaultValue="gan_group"
                                onChange={setTrafficTypeState}
                                value={trafficTypeState}
                            >
                                <Radio value="gan_group">GAN Groups</Radio>
                                <Radio value="gan">GAN Numbers</Radio>
                                {/* <Radio value="prefix">Prefixes</Radio> */}
                                <Radio value="mcc">Operators</Radio>
                                <Radio value="mcc_default">Default limits</Radio>
                            </RadioGroup>
                        </FormGroup>}
                    </TrafficTypeContainer>
                    <TrafficTypeFiltersContainer>
                        {isSupplier 
                        ? <div>
                            {isDefault && <CustomField
                                // ref={ref => filterInputsRef["default_type_list"] = ref}
                                accepter={CheckPicker}
                                data={defaultLimitTypeList}
                                value={defaultLimitState}
                                onChange={setDefaultLimitState}
                                valueKey="value"
                                labelKey="name"
                                name="default_type_list"
                                placeholder="Limit type"
                                width={200}
                                searchable={false}
                            />}
                            {!isMcc && <CustomField
                                inputRef={filterInputsRefs["senderid"]}
                                accepter={Input}
                                // value={filter?.senderid}
                                placeholder="Sender ID"
                                name="senderid"
                                width={200}
                            />}
                            {!isDefault && <CustomField
                                inputRef={filterInputsRefs["str"]}
                                accepter={Input}
                                // value={filter?.str}
                                placeholder={strFilterPlaceholder}
                                name="str"
                                width={360}
                            />}
                            {(isPrefix) && <CustomField
                                ref={filterInputsRefs["mcc"]}
                                accepter={SelectPicker}
                                // value={filter?.mcc}
                                data={mccList}
                                labelKey="countryMccName"
                                valueKey="mcc"
                                placeholder="Country"
                                name="mcc"
                                width={200}
                            />}
                        </div>
                        :<div>
                            <ServicePlans
                                cleanable={true}
                            />
                            <CustomField
                                accepter={SelectPicker}
                                labelKey="name"
                                valueKey="wz_key"
                                data={worldzoneList}
                                placeholder="Worldzone"
                                name="wz_key"
                                width={200}
                            />
                            <CustomField
                                accepter={SelectPicker}
                                data={destinationList}
                                labelKey="name"
                                valueKey="de_key"
                                placeholder="Destination"
                                name="de_key"
                                width={200}
                            />
                            {service ? 
                                <CustomField
                                accepter={SelectPicker}
                                data={subdestinationList}
                                labelKey="name"
                                valueKey="sde_key"
                                placeholder="Subdestination"
                                name="sde_key"
                                width={200}
                            /> : <CustomField
                                accepter={SelectPicker}
                                data={mccList}
                                labelKey="countryMccName"
                                valueKey="mcc"
                                placeholder="Country"
                                name="mcc"
                                width={200}
                            />}
                        </div>}
                        {isSupplier && !isGanNumber && <Button
                            appearance="primary"
                            onClick={() => {
                                setShowCreateModal(true);
                            }}
                        >
                            Create new traffic limit
                        </Button>}
                </TrafficTypeFiltersContainer>

                </StyledForm>
            </FullWidthFlexboxGridItem>
            <TrafficLimitsCreateModal
                show={showCreateModal}
                supplierId={supplierId}
                onClose={() => {
                    setShowCreateModal(false);
                }}
                onSuccess={() => {
                    onDataUpdate();
                    setShowCreateModal(false);
                    Alert.success("Traffic limit was created");
                }}
                service={service}
                e212List={e212List}
                countryList={countryList}
                title="Traffic limit create modal"
                trafficTypeState={trafficTypeState}
                transitTrunkList={transitTrunkList}
                suppliersGroupList={suppliersGroupList}
                // subdestinationList={subdestinationList}
                suppliersPrefixList={suppliersPrefixList}
                selectedDefaultData={selectedDefaultData}
                getE212DropdownList={getE212DropdownList}
                setSelectedDefaultData={setSelectedDefaultData}
                getReferencesForReports={getReferencesForReports}
                getSupplierPrefixesDropdown={getSupplierPrefixesDropdown}
            />
         
        </FlexboxGrid>
    );
};

const TrafficTypeContainer = styled.div`
    && {
        width: 100%;
        margin: 0px;
    }
`;

const TrafficTypeFiltersContainer = styled.div`
    && {
        width: 100%;
        display: flex;
        margin: 0px;
        justify-content: space-between;
        align-items: start;
    }

    && div {
        display: flex;
    }
`;


const FullWidthFlexboxGridItem = styled(FlexboxGrid.Item)`
    && {
        width: 100%;
    }
`

const StyledForm = styled(Form)`
  .rs-form-group {
    margin-bottom: 20px;
    margin-right: 10px;
  }
`;