import React, {useRef, useMemo, useState} from "react";
import {Form, Schema, InputNumber, SelectPicker, FlexboxGrid, DatePicker} from "rsuite";
import CustomField from "pages/NewAccount/ContactCustomFieldRefactored";
import { AMOUNT_MIN, AMOUNT_MAX } from "const";
import Modal from "components/Modal";
import styled, {css} from "styled-components";

import m from "definedMessages";
import { toStartDayTime } from "utils";


const {NumberType, StringType, DateType} = Schema.Types;

const AddNewPaymentModal = ({
    show,
    disabled,

    voiceCurrencyKey,

    currencyList,

    addNewFunc,
    onCloseModal,

    formatMessage
}) => {

    const paymentRef = useRef(null);
    const paymentModel = Schema.Model({
        date: DateType()
            .isRequired(formatMessage(m.required)),
        amount: NumberType()
            .isRequired(formatMessage(m.required))
            .min(0.01, "The minimum number is 0.1"),
        comment: StringType()
            .isRequired(formatMessage(m.required))
            .maxLength(255, "The maximum is 255 characters"),
        cur_key: NumberType()
            .isRequired(formatMessage(m.required))
    });

    const defaultFormValue = useMemo(() => {
        return {
            cur_key: voiceCurrencyKey || null,
            date: new Date()
        }
    }, [show, voiceCurrencyKey]);

    const [formState, setFormState] = useState(defaultFormValue);



    const onAddNewPayment = () => {
        if (paymentRef.current.check()) {
            const formValues = paymentRef.current.getFormValue();
            const requestData = {
                ...formValues,
                amount: parseFloat(formValues.amount),
            };

            addNewFunc(requestData, () => {
                setFormState(defaultFormValue);
            });
        }
    };

    return <Modal 
        width={520}
        show={show}
        title={formatMessage(m.addNewPayment)}
        disabled={disabled}
        onSuccess={() => {
            onAddNewPayment();
        }}
        onClose={onCloseModal}
        successText={formatMessage(m.ok)}
        cancelText={formatMessage(m.cancel)}
        footer={true}
    >
        <StyledForm
            ref={paymentRef}
            model={paymentModel}
            formValue={formState}
            defaultFormValue={defaultFormValue}
            onChange={(formValue) => setFormState(formValue)}
        >
            <StyledWidthControlledWrapper>
                <CustomField
                    disabled={disabled}
                    accepter={DatePicker}
                    format="YYYY-MM-DD HH:mm"
                    ranges={[
                        {
                            label: "Today",
                            value: new Date()
                        },
                        {
                            label: "Tomorrow",
                            value: new Date(new Date().setDate(new Date().getDate() + 1))
                        }
                    ]}
                    name="date"
                    placeholder={`${formatMessage(m.date)}...`}
                />
            </StyledWidthControlledWrapper>
            <FlexboxGrid>
                <FlexboxGrid.Item>
                    <CustomField
                        disabled={disabled}
                        accepter={InputNumber}
                        name="amount"
                        placeholder={`${formatMessage(m.amount)}...`}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <CustomField
                        disabled={disabled}
                        accepter={SelectPicker}
                        name="cur_key"
                        data={currencyList}
                        labelKey="name"
                        valueKey="cur_key"
                        placeholder={`${formatMessage(m.currency)}...`}
                        searchable={false}
                        cleanable={false}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
          
            <StyledWidthControlledWrapper width={380}>
                <CustomField
                    className="textarea-input"
                    componentClass="textarea"
                    disabled={disabled}
                    name="comment"
                    placeholder={`${formatMessage(m.comment)}...`}
                />
            </StyledWidthControlledWrapper>
        </StyledForm>
    </Modal>
};

export default AddNewPaymentModal;



const StyledForm = styled(Form)`
    &&.rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
        margin-bottom: 0px;
    }

    && > .rs-form-group {
        margin-bottom: 0px;
    }

    && .rs-flex-box-grid > .rs-flex-box-grid-item:not(:last-child) {
        margin-right: 20px;
    }

`;


const StyledWidthControlledWrapper = styled.div`
    && {
        ${props => props.width && css`
            .rs-form-group {
                width: ${props.width}px;
            }
            .rs-form-control-wrapper {
                width: ${props.width}px;
            }
            .rs-input {
                width: ${props.width}px;
            }
            .rs-picker-date {
                width: ${props.width}px;
            }
        `}
    }
`;