import React from "react";
import {Link, withRouter} from "react-router-dom";
import {Form, Icon, Input, Schema, FormControl} from "rsuite";
import {generateId, formValid} from "utils";
import styled, {css} from "styled-components";
import {BorderBtn, BaseButton, ButtonListAdd, ButtonListRemove} from "components/base/BaseButton";
import CustomField from "pages/NewAccount/ContactCustomFieldRefactored";

import m from "definedMessages";

const {StringType} = Schema.Types;

const initCustomer = {user_name: "", user_email: "", key: null};


const Customers = ({
    history,
    location,
    isMobile,
    disabled,
    refsData,
    customers,
    addableIsChanged,
    initialCustomers,
    onShowPasswordModal,

    updateCustomers,

    formatMessage
}) => {

    const updateCustomersRef = (ref, key) => refsData[key] = ref;


    const customerModel = Schema.Model({
        user_name: StringType()
            .isRequired(formatMessage(m.required))
            .maxLength(40, "The maximum is only 40 characters."),
        user_email: StringType()
            .isRequired(formatMessage(m.required))
            .pattern(/^[a-zA-Z0-9_\-\.]*(?:@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,})?$/, "Email or login are only available")
            .maxLength(40, "The maximum is only 40 characters."),
    });

    const customerPasswordModel = Schema.Model({
        password: StringType()
            .isRequired(formatMessage(m.required))        
    });


    // const sendVerification = (id) => {
    //     setVerificationData({...verificationData, [id]: {loading: true, status: false}});
    //     api("account_user:verify_email_create", {
    //         target: {account_user_id: id},
    //         clean_password: true,
    //     })
    //         .then(r => {
    //             setVerificationData({...verificationData, [id]: {loading: false, status: true}});
    //             Alert.success("Confirmation email was sent");
    //         })
    //         .catch(error => {
    //             setVerificationData({...verificationData, [id]: {loading: false, status: false}});
    //         });
    // };

    // const resetCustomerPassword = ({id, email}) => {
    //     setResetPassword({...resetPasswordData, [id]: {loading: true, status: false}});
    //     api("account_user__reset_password:request_by_account_manager", {
    //         email: email,
    //     })
    //         .then(r => {
    //             setResetPassword({...resetPasswordData, [id]: {loading: false, status: true}});
    //             Alert.success(`Reset password link for "${email}" was successfully sent`);
    //         })
    //         .catch(error => {
    //             setResetPassword({...resetPasswordData, [id]: {loading: false, status: false}});
    //         });
    // };

    const onRemove = (key) => {
        updateCustomers(customers.filter(customer => customer.key !== key));
    };


    const onAdd = () => {
        const invalidCustomers = customers.filter(customer => !formValid(customerModel.check(customer)) && !customer.email_disabled);

        if (invalidCustomers.length)
            invalidCustomers.map(
                customer => refsData[customer.key].check()
            );
        else
            updateCustomers([...customers, {...initCustomer, key: generateId()}]);
    };
    
    const customersList = [
        ...customers,
        {
            key: null, user_id: null, user_name: "", user_email: "", password: null
        }
    ];
    
    return <>
        <CustomersHeader>{formatMessage(m.customers)}</CustomersHeader>
        {customersList.map( (customer, idx) => {
            const clearedInitialList = initialCustomers.map(initialValues => {
                const {key, ...remainingInitialValues} = initialValues;
                return remainingInitialValues;
            });
            const {key, ...clearedCustomerValues} = customer;

            const changedClass = addableIsChanged(customer.user_id, clearedInitialList, clearedCustomerValues) ? " has-changed" : "";

            const updateFormValues = (formValues, key) => {
                customers && updateCustomers([
                    ...customers.map(customer =>
                        customer.key === key ? {...customer, ...formValues} : customer
                    )
                ]);
            };
        
            const clearCustomerForm = customer.key === null;

            const model = key !== customer.user_id 
                ? Schema.Model.combine(customerModel, customerPasswordModel)
                : customerModel;

            return <>
                {isMobile && idx !== 0 ? <hr style={{width: "100%", height: "2px"}}/> : <></>}
                <StyledForm
                    layout="inline"
                    unchangeable={clearCustomerForm}
                    key={customer.key}
                    ref={ref => updateCustomersRef(ref, customer.key)}
                    onChange={(formValues) => updateFormValues(formValues, customer.key)}
                    formValue={customer}
                    formDefaultValue={customer}
                    model={model}
                    className={`tab-general-addable${changedClass}`}
                    mobile={isMobile}
                >

                    <CustomField
                        width={250}
                        disabled={disabled || clearCustomerForm}
                        accepter={Input}
                        name="user_name"
                        placeholder={formatMessage(m.name)}
                    />

                    <CustomField
                        width={300}
                        disabled={disabled || clearCustomerForm || customer.user_id}
                        accepter={Input}
                        name="user_email"
                        placeholder={formatMessage(m.loginOrEmail)}
                    />
                
                    <PasswordField>
                        <HiddenField
                            name="password"
                            errorPlacement="topStart"
                            value={customer.password}
                        />
                        <BorderBtn
                            disabled={clearCustomerForm}
                            withShadow
                            buttonStyle="primary"
                            style={{marginRight: 10, width: 170}}
                            onClick={() => onShowPasswordModal(customer)}
                            size="sm"
                        >
                            {formatMessage(m.newSubaccountSetPassword)}
                        </BorderBtn>
                    </PasswordField>
                    

                    {clearCustomerForm && <ButtonListAdd
                        className="add-button"
                        onClick={() => {
                            onAdd();
                        }}
                    />}

                    {(customer.user_id) &&
                        <div style={{display: "inline-block"}}>
                            <StyledActionButton
                                buttonStyle="primary"
                                style={{marginLeft: "10px", marginRight: "20px"}}
                                tooltipText={<>{formatMessage(m.loginAs)}:<b>{customer.email}</b></>}
                                tooltipPlacement="leftStart"
                                componentClass={Link}
                                target={"_blank"}
                                to={`/redirect?sub-customer-id=${customer.user_id}`}
                            >
                                <Icon style={{fontSize: 16}} icon="sign-in"/>
                            </StyledActionButton>
                        </div>
                    }

                    {!clearCustomerForm && <ButtonListRemove
                        onClick={() => {
                            onRemove(customer.key);
                        }}
                    />}

                    
                </StyledForm>
            </>
        })}
    </>
};

export default withRouter(Customers);


const StyledForm = styled(Form)`
    &&& {
        ${props => props.mobile ? css`
            display: block;

            .rs-input {
                margin-bottom: 15px;
            }
            .rs-form-group .rs-form-control-wrapper {
                float: inherit;
            }
        ` : css`
            display: flex;
            width: fit-content;
        `};

        .add-button {
            margin-top: 4px !important;
        }
    }

    &&&.tab-general-addable.has-changed {
        ${props => props.unchangeable && css`
            box-shadow: none;
        `};
    }
`;

const CustomersHeader = styled.div`
    && {
        margin-top: 15px;
        margin-bottom: 5px;
        margin-left: 10px;

        color: var(--color-brand1);
        font-size: 16px;
        font-weight: 600;
    }
`;


const PasswordField = styled.div`
    && {
        display: inline-block;
    }
`;

const HiddenField = styled(FormControl)`
    && {
        display: none;
    }
`;

const StyledActionButton = styled(BaseButton)`
    position: relative;
    
    .button-badge {
        position: absolute;
        top: 3px;
        right: 3px;
        font-size: 10px;
        line-height: 1;
        font-size: 10px;
    }
`;
