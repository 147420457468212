import React, {useRef, useMemo, useState, useEffect} from 'react';
import {connect} from "react-redux";
import {BrowserRouter, Router, Route, Switch, Redirect} from "react-router-dom";
import {useLocation, withRouter} from 'react-router';
// import history from './config/history';
import {getReferences, getDialerTrunk, setInitedReferences} from "./actions/references";
import {getPermission, setService} from "actions/auth";
import {setDialerService} from "./actions/auth_dialer";
import Maintenance from 'pages/Maintenance/Maintenance';
import CacheBooster from 'components/CacheBooster';
import AdminRoutes from "./routes/RoutesAdmin";
import ClientRoutes from "./routes/RoutesClient";
import {
    APP_TYPE_ADMIN,
    APP_TYPE_CLIENT,
    APP_TYPE_DEFAULT,
    HAS_BOTH_SERVICE_VALUE,
    DEFAULT_AUTH_PATH_ADMIN_RATEMOD,
    DEFAULT_AUTH_PATH_ADMIN,
    DEFAULT_NOT_AUTH_PATH,
    transformed_service_data,
    transformed_service_reverse_data,
    DEFAULT_AUTH_PATH_CLIENT
} from "./const";
import store from './store/index';
import {storeDataOnRecaptchaComplete} from './actions/settings';

import usePrevious from './hooks/usePrevious';
import locales from "./locales";
import localesRsuite from "./locales-rsuite";
import {Button, Alert, Form, IntlProvider as RSIntlProvider} from "rsuite";
import {IntlProvider} from "react-intl";
import {api} from './api/loginRoutes';
import {CustomModal} from 'components/base';
import {getAccountDialerInfo} from "./actions/auth_dialer";
import {servicePick, setBodyClassNames, getManagedServices, compareTwoArrays} from "./utils";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import {ThemeProvider} from "styled-components";
import themeContext from "./context/themeContext";
import Recaptcha from 'react-google-invisible-recaptcha';
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import PaymentDetailConfirm from "./pagesClient/PaymentDetails/PaymentDetailConfirm";
import {getAccountManagersInformators} from "./actions/account_managers";
import { ACCOUNT_MANAGERS_GET_INFORMATORS_API } from 'const/apiMethods';
import {LOCAL_STORAGE_DELAYED_SERVICE_CHANGE} from "const/localStorageKeys";
import ModalResponseHandlerGlobalHOC from 'components/Modal/ModalResponseHandlerGlobalHOC';

import styled from 'styled-components';
import { compareArray } from 'rsuite/es/utils/treeUtils';


export const AppTypeContext = React.createContext(APP_TYPE_DEFAULT);
export const RtlContext = React.createContext(false);
export const PermissionContext = React.createContext(null);


function App(
    {
        auth,
        authInfo,
        lang,
        rtl,
        service,
        setService,
        permissions,
        role_list,
        appType,
        history,

        referenceInited,
        smsNotDeployed,
        voiceNotDeployed,

        dialerInfo,
        accountJoinList,
        methodRecaptcha,
        paramsRecaptcha,
        visibleRecaptcha,

        getReferences,
        getPermission,
        maintenanceMode,
        serviceDialer,
        getDialerTrunk,
        setDialerService,
        getAccountDialerInfo,
        getAccountManagersInformators
    }
) {
    const location = useLocation();
    const prevAuth = usePrevious(auth);
    const prevLocation = usePrevious(location);
    const prevRoleList = usePrevious(role_list);
    // const firstHistoryReplaceInit = use 

    const recaptchaRef = useRef(null);
    const hiddenFormRef = useRef(null);
    const [recaptchaIsVisible, setRecaptchaIsVisible] = useState(visibleRecaptcha);
    const [showRecaptchaModal, setShowRecaptchaModal] = useState(false);

    const serviceDelayed = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DELAYED_SERVICE_CHANGE));

    // useEffect(() => {
    //     if (service !== null && serviceDelayed === null) {
    //         localStorage.setItem(LOCAL_STORAGE_DELAYED_SERVICE_CHANGE, service) 
    //     }

    //     if (serviceDelayed !== null) {
    //         localStorage.setItem("service", transformed_service_reverse_data[serviceDelayed])
    //     }
    // }, [service, serviceDelayed])

    if (!auth && !prevAuth) {
        const notSavablePathnameList = ["/login", "/reset-password", "/accounts", "/hot-accesses", "/hot-accesses-oice", "/hot-accesses-sms"];

        const lastPagePathName = localStorage.getItem("LAST_PAGE_BEFORE_SESSION_EXPIRED");
        
        if (!lastPagePathName && !notSavablePathnameList.includes(window.location.pathname)) {
            localStorage.setItem("LAST_PAGE_BEFORE_SESSION_EXPIRED", window.location.pathname);

            const urlSearch = window.location.search;
            const params = new URLSearchParams(urlSearch);
            const urlService = params.get("service");

            if (urlService) {
                localStorage.setItem("LAST_SERVICE_BEFORE_SESSION_EXPIRED", urlService);
            }
            
        }

    }

    useEffect(() => {
        if (appType === APP_TYPE_ADMIN) {
            if (service !== serviceDelayed && serviceDelayed !== null) {
                const lastService = localStorage.getItem("LAST_SERVICE_BEFORE_SESSION_EXPIRED");

                const newService = lastService ? transformed_service_data[lastService] : serviceDelayed;

                setService(newService);
            }

            if (service !== null && service !== undefined) {
                localStorage.setItem("service", service);
            }
        }
    }, [service]);

    const currentServiceValue = useMemo(() => {
        return appType === APP_TYPE_ADMIN ? service : serviceDialer;
    }, [appType, service, serviceDialer]);

    const prevServiceValue = usePrevious(currentServiceValue);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const prevSearchParams = new URLSearchParams(prevLocation.search)
        const searchServiceParam = searchParams.get('service');
        const prevSearchServiceParam = prevSearchParams.get('service');

        if (searchServiceParam === null 
            || prevSearchServiceParam && searchServiceParam !== prevSearchServiceParam
            || !compareTwoArrays(role_list, prevRoleList)
        ) {
            const serviceTransformed = transformed_service_reverse_data[service];

            let currentService = prevSearchServiceParam;

            if (!searchServiceParam || serviceTransformed !== prevSearchServiceParam) {
                currentService = serviceTransformed;
            }

            const lastService = localStorage.getItem("LAST_SERVICE_BEFORE_SESSION_EXPIRED");

            const ratemodRole = (role_list.includes('Ratemod') || role_list.includes('Ratemod (sms)'));
            const isRatemod = role_list && role_list.length ? ratemodRole: null;
            const supplierPath = servicePick(service, "/suppliers", "/sms-suppliers");

            if (auth && isRatemod && appType === APP_TYPE_ADMIN && ![DEFAULT_AUTH_PATH_ADMIN_RATEMOD, supplierPath].includes(location.pathname)) {
                searchParams.set('service', lastService ? lastService : currentService);
                history.replace({
                    ...location,
                    pathname: DEFAULT_AUTH_PATH_ADMIN_RATEMOD,
                    search: searchParams.toString()
                });
                
            // } else if ((auth === true && prevAuth === false || !history.location.key ) && appType === APP_TYPE_ADMIN) {
            } else if ((auth && (
                    history.location.pathname === "/login" ||
                    (prevRoleList.includes('Ratemod') || prevRoleList.includes('Ratemod (sms)')) && !ratemodRole )
                ) && appType === APP_TYPE_ADMIN) {
                const serviceDelayed = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DELAYED_SERVICE_CHANGE));
                // ATX-3242
                // try to find out how do less updates but update last time after redirection to client
                if (serviceDelayed === null) {
                    const lastPageBeforeSessionExpired = localStorage.getItem("LAST_PAGE_BEFORE_SESSION_EXPIRED");
                    const lastService = localStorage.getItem("LAST_SERVICE_BEFORE_SESSION_EXPIRED");

                    if (lastPageBeforeSessionExpired) {
                        searchParams.set('service', lastService ? lastService : currentService);

                        history.replace({
                            ...location,
                            pathname: lastPageBeforeSessionExpired,
                            search: searchParams.toString()
                        });
                    } else {
                        searchParams.set('service', lastService ? lastService : currentService);

                        history.replace({
                            ...location,
                            pathname: DEFAULT_AUTH_PATH_ADMIN,
                            search: searchParams.toString()
                        });
                    }
                }
            } else if (auth === true && prevAuth === false && appType === APP_TYPE_CLIENT) {
                const lastPageBeforeSessionExpired = localStorage.getItem("LAST_PAGE_BEFORE_SESSION_EXPIRED");
                if (lastPageBeforeSessionExpired) {
                    history.push({
                        ...location,
                        pathname: lastPageBeforeSessionExpired
                    });
                } else {
                    history.push({
                        ...location,
                        pathname: `${DEFAULT_AUTH_PATH_CLIENT}-${transformed_service_reverse_data[service]}`
                    });
                }
            } else if (auth && appType === APP_TYPE_ADMIN) {
                setTimeout((locationParam, historyParam) => {
                    searchParams.set('service', lastService ? lastService : currentService);

                    historyParam.replace({
                        ...locationParam,
                        search: searchParams.toString()
                    });
                }, 100,
                location, history);
            }
            
        }

        if (service === serviceDelayed && serviceDelayed !== null) {
            localStorage.removeItem(LOCAL_STORAGE_DELAYED_SERVICE_CHANGE);
        }
    }, [auth, service, history.location.key, location.search, role_list]);

    useEffect(() => {
        setBodyClassNames(authInfo || {});

        const managedServiceList = getManagedServices(authInfo);
        
        if (auth && !maintenanceMode) {
            if (managedServiceList.length === 1) {
                const currentService = transformed_service_data[managedServiceList[0]];
                const lastService = localStorage.getItem("LAST_SERVICE_BEFORE_SESSION_EXPIRED");

                const newService = lastService ? transformed_service_data[lastService] : currentService; 
                setService(newService);
            }
        }
    }, [auth, maintenanceMode, authInfo]);

    // useEffect(() => {
    //     if (!recaptchaIsVisible) {
    //         if (recaptchaIsVisible !== visibleRecaptcha) {
    //             setRecaptchaIsVisible(visibleRecaptcha);
    //             setShowRecaptchaModal(true);
    //         }
    //     }

    //     if (visibleRecaptcha) {
    //         setShowRecaptchaModal(true);
    //     }
    // }, [visibleRecaptcha]);

    // preload common info
    useEffect(() => {
        if (auth && !maintenanceMode && (!referenceInited || prevServiceValue !== currentServiceValue)) {
            if (accountJoinList.length > 1 || appType === APP_TYPE_ADMIN) { 
                // if (currentServiceValue !== null) {
                    getReferences(appType, currentServiceValue, accountJoinList);
                // }
            } else if (accountJoinList.length === 1 && currentServiceValue !== null || appType === APP_TYPE_ADMIN) {
                if (serviceDialer !== null) {
                    getReferences(appType, currentServiceValue, accountJoinList);
                }
            }
        }
    }, [auth, referenceInited, maintenanceMode, accountJoinList.length, currentServiceValue, history.location.pathname]);

    // useEffect(() => {
    //     if (auth && !maintenanceMode) {
    //         if (currentServiceValue === null) {
    //             getReferences(appType, true, accountJoinList);
    //         }
    //     }
    // }, [auth, maintenanceMode,  currentServiceValue]);

    useEffect(() => {
        if (auth && !maintenanceMode) {
            getPermission();
        }

        if (!auth) {
            setInitedReferences(false);
        }
    }, [auth, maintenanceMode]);

    useEffect(() => {
        if (auth && !maintenanceMode) {
            if (appType === APP_TYPE_CLIENT) {
                getAccountDialerInfo();
            }
        }
    }, [auth, maintenanceMode, appType]);

    // useEffect(() => {
    //     // serviceDialer and accountJoinList changes, that trigger 3 updates
    //     if (auth && !maintenanceMode) {
    //         const currentService = appType === APP_TYPE_ADMIN ? service : serviceDialer;

    //         // currentService !== null && - need test without this condition
    //         if (accountJoinList.length || appType === APP_TYPE_ADMIN) { 
    //             getReferences(appType, currentService);
    //         }
    //         getPermission();

    //         if (appType === APP_TYPE_CLIENT) {
    //             getAccountDialerInfo();
    //         }
    //     }
    // }, [service, serviceDialer, accountJoinList.length, appType, auth, maintenanceMode]);


    useEffect(() => {
        if (permissions && permissions.includes && permissions.includes(ACCOUNT_MANAGERS_GET_INFORMATORS_API)) {
            getAccountManagersInformators();
        }
    }, [service, permissions]);

    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty('--color-main', service ? 'var(--color-violet)' : 'var(--color-info)');
        root.style.setProperty('--color-main-hover', service ? 'var(--color-violet-dark)' : 'var(--activate-bg)');
    }, [service])

    useEffect(() => {
        if (accountJoinList.length === 1) {
            const accountService = accountJoinList[0];
            const currentService = accountService?.service;
    
            setDialerService(transformed_service_data[currentService]);
            localStorage.setItem(HAS_BOTH_SERVICE_VALUE, false);
        } else if (accountJoinList.length === 2) {
            localStorage.setItem(HAS_BOTH_SERVICE_VALUE, true);
            // setDialerService(true);
        }
        
    }, [authInfo, accountJoinList])

    const locale = lang || "en";
    const messages = locales[locale];
    const rsMessages = localesRsuite[locale];

    const executeRecaptcha = () => {

        if (!recaptchaIsVisible) {
            handleResolveCaptcha();
            return;
        }

        if (!recaptchaRef?.current?.execute) {
            return;    
        }

        recaptchaRef.current.execute();
    }
    
    const resetRecaptcha = () => {

        if (!recaptchaIsVisible) {
            return;
        }

        if (!recaptchaRef?.current?.reset) {
            return;
        }

        recaptchaRef.current.reset();
    }

    const handleResolveCaptcha = (token) => {

        if (!token && recaptchaIsVisible) {
            Alert.error("Bot verification failed")
            resetRecaptcha();
            return;
        }

        if (methodRecaptcha) {
            api(methodRecaptcha, {
                ...paramsRecaptcha,
                captcha_token: token,
            })
                .then((response) => {
                    store.dispatch(storeDataOnRecaptchaComplete(methodRecaptcha, response));
                })
                .finally(() => {
                    resetRecaptcha();
                });
        }
        setShowRecaptchaModal(false);
    }

    return (
        <IntlProvider locale={locale} messages={messages}>
            <RSIntlProvider locale={rsMessages}>
                <ThemeProvider theme={themeContext[appType]}>
                    <AppTypeContext.Provider value={appType}>
                        <RtlContext.Provider value={rtl}>
                            <PermissionContext.Provider value={permissions}>


                                {recaptchaIsVisible && <Recaptcha
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    onResolved={handleResolveCaptcha}
                                />}

                                 {/*проверить redirect и history.push (multiple switches between numbers and login)*/}
                                <Router history={history}>

                                    <Switch>

                                        {maintenanceMode &&
                                            <Route path="/" component={Maintenance} />
                                        }

                                        <Route path="/login" component={Login}/>
                                        <Route path="/reset-password/:token?" component={ResetPassword}/>
                                        <Route path="/verify-mail/:token?" component={EmailVerification}/>
                                        {!auth && appType !== APP_TYPE_ADMIN &&
                                            <Route exact path="/payment-detail/:token" component={PaymentDetailConfirm}/>
                                        }
                                        {!auth &&
                                            <Redirect to={DEFAULT_NOT_AUTH_PATH}/>
                                        }

                                        {appType === APP_TYPE_ADMIN
                                            ? <AdminRoutes
                                                auth={auth}
                                                service={service} 
                                                authInfo={authInfo}
                                            />
                                            : <ClientRoutes 
                                                auth={auth}
                                                authInfo={authInfo}
                                                dialerInfo={dialerInfo}
                                                accountJoinList={accountJoinList}
                                                executeRecaptcha={executeRecaptcha}
                                                resetRecaptcha={resetRecaptcha}
                                            />
                                        }

                                    </Switch>

                                </Router>

                                <ModalResponseHandlerGlobalHOC />
                                <CacheBooster />
                
                                {showRecaptchaModal && <RecaptchaModal 
                                    show={showRecaptchaModal}
                                    onConfirm={() => {
                                       
                                    }}
                                    customFooter={() => (
                                        <Button 
                                            onClick={() => {
                                                executeRecaptcha();
                                                setShowRecaptchaModal(false);
                                            }} 
                                            appearance="primary"
                                        >
                                            Ok
                                        </Button>
                                    )}
                                    title={"Warning"}
                                    showFooter
                                >
                                    Too many operations!
                                </RecaptchaModal>}

               
                            </PermissionContext.Provider>
                        </RtlContext.Provider>
                    </AppTypeContext.Provider>
                </ThemeProvider>
            </RSIntlProvider>
        </IntlProvider>
    );
}

const mapState = ({auth, roles, settings, auth_dialer, references}) => ({
    appType: auth.appType,
    authInfo: auth.authInfo,
    dialerInfo: auth_dialer.dialerInfo,
    accountJoinList: auth_dialer.accountJoinList,
    auth: auth.auth,
    lang: auth.lang,
    rtl: auth.rtl,
    role_list: roles.role_list,
    service: auth.service,
    referenceInited: references.inited,
    smsNotDeployed: references.sms_not_deployed,
    voiceNotDeployed: references.voice_not_deployed,
    serviceDialer: auth_dialer.serviceDialer,
    visibleRecaptcha: settings.visibleRecaptcha,
    methodRecaptcha: settings.methodRecaptcha,
    paramsRecaptcha: settings.paramsRecaptcha,
    permissions: auth.permissions,
    maintenanceMode: auth.maintenanceMode,
});
 //
// ATX-2922
//
export default withRouter(connect(mapState, {
    setService,
    getDialerTrunk,
    getReferences,
    getPermission,
    setDialerService,
    setInitedReferences,
    getAccountDialerInfo,
    getAccountManagersInformators,
})(App));


const RecaptchaModal = styled(CustomModal)`
    .rs-modal-header-close {
        display: none;
    }
`;

const HidenForm = styled(Form)`

`;