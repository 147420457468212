import React from "react";
import {Checkbox, CheckboxGroup, CheckPicker, Form, FormControl} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../../components/base/FlexGrid";
import {debounce} from "../../../utils";

export default ({defaultFilter, onChangeFilters, accounts, authInfo}) => {

    const accountSession = authInfo?.session;
    const accountManangedServiceList = accountSession?.account_user?.managed_service_list;

    const filteredAccounts = accounts.filter( item => {
        return item.is_managed && accountManangedServiceList.includes(item.service);
    } );

    const formattedAccounts = filteredAccounts.map((account) => {
        return {
            ...account,
            name: `${account.name} / ${account.service}`
        }
    });
    
    return (
        <Form
            formDefaultValue={defaultFilter}
            onChange={debounce((value) => {
                onChangeFilters(value);
            }, 500)}
        >
            <FlexGrid align="middle">
                <FlexGridItem>
                    <FormControl
                        accepter={CheckPicker}
                        sticky
                        name="account_id_list"
                        data={formattedAccounts}
                        valueKey="id"
                        labelKey="name"
                        style={{ width: 290 }}
                    />
                </FlexGridItem>
                <FlexGridItem>
                    <FormControl
                        inline={true}
                        name="status"
                        accepter={CheckboxGroup}
                    >
                        <Checkbox value={0}>Processing</Checkbox>
                        <Checkbox value={2}>Passed to financial</Checkbox>
                        <Checkbox value={4}>Show completed</Checkbox>
                        <Checkbox value={1}>Show cancelled</Checkbox>
                        <Checkbox value={3}>Show declined</Checkbox>
                    </FormControl>
                </FlexGridItem>
            </FlexGrid>
        </Form>
    )
}