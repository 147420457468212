import React from 'react';
import {FlexboxGrid, Whisper, Tooltip} from 'rsuite';
import PanelLayout from '../../components/base/PanelLayout';
import TableServerSort from './../../components/Table/TableServerSort';
import Filters from './RangesFilters';
import Numbers from './Numbers';
import AddNewRange from '../Prices/Ranges/AddNewRange';
import DeleteRanges from './DeleteRanges';
import Loader from '../../components/Loader/Loader';
import {connect} from 'react-redux';
import {setCurrentTrunkId} from '../../actions/accounts';
import {getRanges} from '../../actions/ranges';
import {getNumbers} from '../../actions/numbers';
import queryString from 'query-string';
import {ASC_SORT} from 'const';
import {setCurrentRangeNumber} from 'actions';
import {showReasonHandler} from "../../actions/reason_handler";
import axios from "axios";
import { getAccountInfoFromSession } from 'utils';
import NumbersRefactored from './Numbers/NumbersRefactored';

class Ranges extends React.Component {
    constructor(props) {
        super(props);

        this.cancelToken = axios.CancelToken.source();
        const urlParams = queryString.parse(props.history.location.search);

        this.filters = {
            sde_key: +urlParams.sde_key,
            de_key: +urlParams.de_key,
            wz_key: +urlParams.wz_key,
            only_with_unallocated_numbers: true
        };
    }

    /** @type Numbers **/
    refNumbers = null;
    /** @type TableServerSort **/
    refRangesTable = null;

    state = {
        pr_key: null,
        selected: {all: false, list: []},
        sort: {column: 'name', type: ASC_SORT},
        sortNumbers: {},
        incorporatedOnly: false,

        number_filters: {
            show_allocated_numbers: true,
            show_unallocated_numbers: true,
            show_test_numbers: true,
            show_block_allocation_numbers: true,
            show_only_block_allocation_numbers: false
        }
    };

    getSelectedList = () => ({...this.state.selected, countAll: this.props.rangesCount});

    async componentDidMount() {
        const {sort, pr_key} = this.state;
        const {rangesPerPage, ranges} = this.props;
        this.getRangesCommon(this.filters, 1, rangesPerPage, sort);
        // await this.props.getRanges(this.filters, 1, rangesPerPage, sort);
        // The code below was the reason of the unnecessary API fetch (price_range_number:get_list)
        // If something went wrong - uncomment it and check FLUX one more time
        // (c) Applesinkin

        /*
        const {ranges} = this.props;
        if (ranges && ranges.length) {
            this.setState({pr_key: ranges[0].pr_key}, () => {
                this.getNumbers(this.refNumbers.getFilters(), 1, this.props.numbersPerPage);
            });
        }
        */
    }

    shouldComponentUpdate({ranges: _ranges, numbersPerPage, service: _service}) {
        const {number_filters, pr_key, sortNumbers} = this.state;
        const {ranges, service, getNumbers} = this.props;

        if (ranges !== _ranges && _ranges.length) {

            const params = {...number_filters};
            if (this.filters.only_with_incorporated_numbers) {
                params.show_only_incorporated_numbers = true;
            }

            if (!pr_key) {
                this.setState({pr_key: _ranges[0].pr_key});
           
                getNumbers(_ranges[0].pr_key, params, service, 1, numbersPerPage, sortNumbers);
            } else {
                getNumbers(pr_key, params, service, 1, numbersPerPage, sortNumbers);
            }
        }
        if (ranges !== _ranges && !_ranges.length) {
            this.setState({pr_key: null});
            getNumbers(null, number_filters, service, 1, numbersPerPage, sortNumbers);
        }

        return true;
    }

    // componentWillMount() {
    //     const {pr_key} = this.state;
    //     const {ranges} = this.props;
    //     if (ranges && ranges.length && !pr_key) {
    //         this.setState({pr_key: ranges[0].pr_key});
    //     }
    // }

    clearNumberFilters = () => {
        this.setState({
            number_filters: {
                show_allocated_numbers: true,
                show_unallocated_numbers: true,
                show_test_numbers: true,
                show_block_allocation_numbers: true,
                show_only_block_allocation_numbers: false
            }
        });
    };
    onChangeNumberFilters = (value) => {
        this.setState({number_filters: value});
    };

    clearSelected() {
        this.refRangesTable.clear();
        this.refNumbers.clearSelected();
    }

    onClickRange = (pr_key) => {
        const {number_filters, sortNumbers} = this.state;

        this.setState({pr_key}, () => {
            this.getNumbers(number_filters, 1, this.props.numbersPerPage, sortNumbers);
            this.refNumbers.clearSelected();
        });
    };

    onChangeFilters = (filters) => {
        this.filters = filters;
        this.setState({
            incorporatedOnly: !!filters.only_with_incorporated_numbers || false
        });
        this.getRangesCommon(this.filters, 1, this.props.rangesPerPage, this.state.sort);
        this.clearSelected();
    };

    getNumbers = (filters, page, per_page, sort) => {
        if (this.props.rangesLoading) return;

        const params = {...filters};
        if (this.filters.only_with_incorporated_numbers) {
            params.show_only_incorporated_numbers = true;
        }
        const {service} = this.props;
        const {pr_key} = this.state;
        
        this.props.getNumbers(pr_key, params, service, page, per_page, sort);
    };

    getRangesCommon = (filters, page, per_page, sort) => {
        const {service, getRanges} = this.props;

        if (typeof this.cancelToken !== typeof undefined) {
            this.cancelToken.cancel("Operation canceled due to new request.");
        }

        this.cancelToken = axios.CancelToken.source();

        getRanges(filters, service, page, per_page, sort);
    };

    getRanges = (page, per_page, sort) => {
        this.getRangesCommon(this.filters, page, per_page, sort);
    };

    setSortNumber = (sort) => {
        this.setState({sortNumbers: sort})
    };

    render() {
        const {
            worldzone_list, subdestination_list, destination_list, service,
            ranges, rangesLoading, rangesPage, rangesCount, rangesPerPage,
            numbersLoading, numbersPage, numbersCount, numbersPerPage, permissions,
            accountSession, setCurrentTrunkId, allocatedNumbersCount, setCurrentRangeNumber,
            showReasonHandler
        } = this.props;
        const {selected, number_filters, incorporatedOnly, sortNumbers} = this.state;


        //Clear numbers when no one range filtered
        let numbers = ranges && ranges.length ? this.props.numbers : [];
        const pickedRange = ranges && ranges.length ? ranges.find(range => range.pr_key === this.state.pr_key) : {};
        const sdeKey = pickedRange ? pickedRange.sde_key : null;
        const accountInfo = getAccountInfoFromSession(accountSession, service);

        return (
            <PanelLayout>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={12} style={{paddingRight: 20, borderRight: ' 3px solid #C4C4C4'}}>
                        <div style={{marginBottom: 25}}>
                            <div style={{display: 'inline-block', marginRight: 15}}>
                                <AddNewRange
                                    service={service}
                                    {...{
                                        subdestination_list
                                    }}
                                />
                            </div>
                            <DeleteRanges
                                permissionMethods={permissions}
                                disabled={!selected.list.length}
                                getList={this.getSelectedList}
                                service={service}
                                update={() => {
                                    this.setState({pr_key: null});
                                    this.getRangesCommon(this.filters, 1, rangesPerPage, this.state.sort);
                                    this.clearNumberFilters();
                                    this.clearSelected();
                                }}
                            />
                        </div>
                        <Filters
                            onChange={this.onChangeFilters}
                            defaultValues={this.filters}
                            {...{
                                worldzone_list,
                                subdestination_list,
                                destination_list,
                            }}/>

                        <TableServerSort
                            ref={ref => this.refRangesTable = ref}
                            data={ranges}
                            onRowClick={this.onClickRange}
                            loading={rangesLoading}
                            columns={columns}
                            originalColumns={columns}
                            count={rangesCount}
                            per_page={rangesPerPage}
                            page={rangesPage}
                            getItems={this.getRanges}
                            active_id={this.state.pr_key}
                            row_key="pr_key"
                            setSelected={selected => this.setState({selected})}
                            ispagination
                            isselected
                            isSetObject
                            style={{width: '100%'}}
                            onSort={(column, type) => this.setState({sort: {column, type}})}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={12}>
                        <Loader show={rangesLoading} isLocal/>
                        {/* <Numbers
                            ref={ref => this.refNumbers = ref}
                            getNumbers={this.getNumbers}
                            allocatedNumbersCount={allocatedNumbersCount}
                            updateRanges={() => this.getRangesCommon(this.filters, 1, this.props.rangesPerPage, this.state.sort)}
                            filters={number_filters}
                            rangesFilter={this.filters}
                            onChangeNumberFilters={this.onChangeNumberFilters}
                            {...{
                                service,
                                numbers,
                                numbersLoading,
                                numbersPage,
                                numbersCount,
                                numbersPerPage,
                                account_id: accountInfo?.id,
                                setCurrentTrunkId,
                                setCurrentRangeNumber,
                                incorporatedOnly,
                                pr_key: this.state.pr_key,
                                sde_key: sdeKey,
                                showReasonHandler,
                                onSort: this.setSortNumber,
                                sort: sortNumbers,
                                selectedRange: pickedRange
                            }}
                        /> */}

                        <Numbers
                            ref={ref => this.refNumbers = ref}
                            getNumbers={this.getNumbers}
                            allocatedNumbersCount={allocatedNumbersCount}
                            updateRanges={() => this.getRangesCommon(this.filters, 1, this.props.rangesPerPage, this.state.sort)}
                            filters={number_filters}
                            rangesFilter={this.filters}
                            onChangeNumberFilters={this.onChangeNumberFilters}
                            {...{
                                service,
                                numbers,
                                numbersLoading,
                                numbersPage,
                                numbersCount,
                                numbersPerPage,
                                account_id: accountInfo?.id,
                                setCurrentTrunkId,
                                setCurrentRangeNumber,
                                incorporatedOnly,
                                pr_key: this.state.pr_key,
                                sde_key: sdeKey,
                                showReasonHandler,
                                onSort: this.setSortNumber,
                                sort: sortNumbers,
                                selectedRange: pickedRange
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </PanelLayout>
        );

    }
}

const columns = [
    {
        label: 'Range', 
        dataKey: 'name', 
        title: true, 
        sortable: true, 
        width: 300
    },
    {
        label: 'Test numbers', 
        // dataKey: 'test_numbers', 
        value: (({test_trunk_number_list}) => {
            if (!test_trunk_number_list)
                return;

            const list = test_trunk_number_list.slice(0, 2);

            return (
                list.length
                    ? <>
                        <Whisper
                            placement="auto"
                            speaker={
                                <Tooltip>{test_trunk_number_list.join(", ")}</Tooltip>
                            }
                        >
                             <span>{list.join(", ")}{list.length && '...'}</span>
                        </Whisper>
                    </>
                    :
                    <span></span>
            );
        })
    },
    {
        label: 'Numbers',
        dataKey: 'allocated_numbers',
        // formatData: (value, key, row) => (
        //     (!row.blocked && row.allocated_numbers || '0') + '/' + (row.all_numbers || '0')
        // ),
        value: ({blocked, allocated_numbers, all_numbers, inc_all_numbers, inc_allocated_numbers}) => {
            return inc_all_numbers
                ? `${(!blocked && allocated_numbers || '0')}(${inc_allocated_numbers || 0})/${(all_numbers || '0')}(${inc_all_numbers || 0})`
                : `${(!blocked && allocated_numbers || '0')}/${(all_numbers || '0')}`
        },
        sortable: true
    }
];

const mapState = ({actions, references, ranges, numbers, auth}) => ({
    worldzone_list: references.worldzone_list,
    subdestination_list: references.subdestination_list,
    destination_list: references.destination_list,
    defaultSPKey: references.defaultSPKey,

    ranges: ranges.ranges,
    rangesLoading: ranges.loading,
    rangesPage: ranges.page,
    rangesCount: ranges.count,
    rangesPerPage: ranges.per_page,

    service: auth.service,
    numbers: numbers.numbers,
    numbersLoading: numbers.loading,
    numbersPage: numbers.page,
    numbersCount: numbers.count,
    allocatedNumbersCount: numbers.allocatedNumbersCount,
    numbersPerPage: numbers.per_page,
    accountSession: auth.authInfo.session,
    permissions: auth.permissions
});

export default connect(mapState, {
    getRanges,
    getNumbers,
    setCurrentTrunkId,
    setCurrentRangeNumber,
    showReasonHandler
})(Ranges);