import styled, { css } from "styled-components";
import {Table as Table_, FormControl, ControlLabel, FormGroup as FormGroup_, Button} from "rsuite";
import Modal from "../../components/Modal";
import BaseTable from "../../components/base/BaseTable";

export const Table = styled(BaseTable).attrs(props => ({
    rowHeight: 30,
    headerHeight: 40
}))``;

export const FlexBlock = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
`;

export const SectionTitle = styled.h2`

    color: var(--color-main);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    padding: 15px 0px;
    
    flex-grow: 1;
    margin: 0;
    width: auto;
    
    @media (min-width: 768px) {
        max-width: 200px;
    }
`;

export const ActionButtonWrapper = styled.div`
    
    @media (min-width: 768px) {
        text-align: right;
    }
`;

export const FormModal = styled(Modal)`
    .rs-modal-content {
        display: flex;
        flex-direction: column;
    }

    .rs-modal-footer {
        margin-top: auto;
        padding-bottom: 20px;
        padding-top: 20px;
    }
`;

export const Label = styled(ControlLabel)`
    flex-shrink: 0;
    font-size: 18px;
    padding: 0;   
    text-align: left;
    
    @media (min-width: 768px) {
        font-size: 14px;
    }

    && {
        margin: 0 10px 0 0;
    }

    .rtl & {
        text-align: right;
        margin: 0 0 0 10px;
    }
    
    ${props =>
        props.disabled &&
        css`
            color: var(--color-disabled)
        `
    }
`;

export const Field = styled(FormControl)`

    &&& {
        min-width: 0;
        width: 100%;
        max-width: 350px;

        ${props => 
            props.componentClass === 'textarea' &&
            css`
                min-height: 120px;
                background: #fff;
                // overflow-x: scroll;
            `
        }
    }

    ${props =>
        props.disabled &&
        css`
            opacity: var(--value-disabled-opacity)
        `
    }
`;

export const FieldAmountWrapper = styled.div`
    && {
        margin-left: ${props => props.ml ? props.ml + 'px' : '0px'}
        margin-right: ${props => props.mr ? props.mr  + 'px' : '0px'}
        margin-top: ${props => props.mt ? props.mt  + 'px' : '0px'}
        margin-bottom: ${props => props.mb ? props.mb + 'px' : '0px'}
    }
    width: 80px;
`;

export const FormRow = styled.div`
    margin-bottom: -20px;
    
    @media (min-width: 600px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const FormItem = styled.div`
    padding-bottom: 20px;
    padding-right: 20px;

    .rtl & {
        padding-right: 0;
        padding-left: 20px;
    }

    &:last-child {
        padding-right: 0;
    }

    .rs-form-control-wrapper {
        width: 100%;
        vertical-align: top;
    }

    ${props =>
        props.grow && 
        css`
            flex-grow: 1;
        `
    }
`;

export const FormGroup = styled(FormGroup_)`
    display: block;
    align-items: center;
    display: flex;
`;

export const Currency = styled.span`
    display: block;
    margin-left: 10px;

    .rtl & {
        margin-left: 0;
        margin-right: 10px;
    }
`;


export const StyledTableActions = styled.div`
    margin-top: -3px;

    .rtl & {
        direction: rtl;
    }

    ${props => 
        props.alignEnd && css`
            text-align: right;
        
            .rtl & {
                text-align: left;
            }
        `
    }
`;


export const StyledTableButton = styled(Button).attrs(props => ({
    appearance: "primary",
    size: "sm"
}))`
    && {
        line-height: 25px;
        height: 25px;
        margin-right: 10px;
        padding: 0 7px;

        &:last-child {
            margin-right: 0;
        }

        .rtl & {
            margin-right: 0;
            margin-left: 10px;

            &:last-child {
                margin-left: 0;
            }
        }
    }

    ${props => props.color && props.color === 'green' && css`
        &&& {
            background-color: #20BA88 !important;
        }
    `}

    ${props => props.color && props.color === 'red' && css`
        &&& {
            background-color: #F8432F !important;
        }
    `}
`;


export const ToggleButton = styled(Button).attrs(props => ({
    size: "sm"
}))`
    && {
        background-color: var(--color-main);
        color: #ffffff;

        &:focus,
        &:active,
        &:active:focus,
        &:hover {
            background-color: var(--color-main);
            color: #ffffff;
        }

        .rs-icon {
            color: #ffffff;
        }
    }
`;