import {
    PRICE_DIALER as PREFIX,
    SET_TRUNK_NUMBER_DIALER,
    SET_LOADING_PRICE_ALLOCATED_NUMBERS_DIALER,
    SET_UPLOAD_LOADING_DIALER, SP_KEY__INCORPORATED, DESC_SORT
} from '../../const'

import {
    RATECARD_LIST_OTP_DEFAULT_DIALER,
    RATECARD_LIST_DEFAULT_DIALER,
    RATECARD_LIST_OTP_ACCOUNT_DIALER,
    RATECARD_LIST_ACCOUNT_DIALER,
    ALLOCATION_PRICE_RANGE_DIALER_API,
    SMS_ALLOCATION_TEMPLATE_DIALER_API,
    RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER,
    RATECARD_LIST_INCORPORATED_DEFAULT_DIALER,
    SMS_RATECARD_LIST_ACCOUNT_DIALER,
    SMS_RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER,
    SMS_RATECARD_LIST_DEFAULT_DIALER,
    SMS_RATECARD_LIST_INCORPORATED_DEFAULT_DIALER,
    ALLOCATION_TEMPLATE_DIALER_API,
    TRUNK_LIST_API,
    TRUNK_LIST_SMS_API,
    SMS_ALLOCATION_GAN_API,
    ALLOCATION_INCORPORATED_DIALER_API
} from '../../const/apiMethods'

import {api, getFileResponse} from '../../api/loginRoutes';
import {_setValue} from './defaults';
import {servicePick, downloadDataAsFile} from '../../utils/helpers';

const setPriceList = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);
const setUploadLoading = _setValue(SET_UPLOAD_LOADING_DIALER);
const setTrunkList = _setValue(SET_TRUNK_NUMBER_DIALER);

export const setLoadingAllocatedNumbersDialer = _setValue(SET_LOADING_PRICE_ALLOCATED_NUMBERS_DIALER);

export const setUploadingPriceLoadingDialer = (state) => (dispatch) => {
    dispatch(setUploadLoading(state));
};

export const getPriceListDialer = (service, per_page = 12, filter = {}, page = 1, servicePlan, sort) => (dispatch) => {
    dispatch(setLoading(true));

    let sort1, sort1_desc;


    const isTest = localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo')).session.is_test 
        : false;

    if (sort.column) {
        sort1 = sort.column;
        sort1_desc = sort.type && sort.type === DESC_SORT;
    } else {
        sort1 = !servicePlan.gan
            ? 'subdestination_name' : 'incorporated_group_name';
    }

    const request = servicePlan.auth 
        ? !isTest 
            ? RATECARD_LIST_OTP_ACCOUNT_DIALER 
            : RATECARD_LIST_OTP_DEFAULT_DIALER
        : !isTest 
            ? !servicePlan.gan
                ? servicePick(service, RATECARD_LIST_ACCOUNT_DIALER, SMS_RATECARD_LIST_ACCOUNT_DIALER) : servicePick(service, RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER, SMS_RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER)
            : !servicePlan.gan
                ? servicePick(service, RATECARD_LIST_DEFAULT_DIALER, SMS_RATECARD_LIST_DEFAULT_DIALER) :  servicePick(service, RATECARD_LIST_INCORPORATED_DEFAULT_DIALER, SMS_RATECARD_LIST_INCORPORATED_DEFAULT_DIALER);

    api(request, {
        filter, 
        page, 
        per_page, 
        add: {},
        sort1,
        sort1_desc
    }).then((response) => {
        if (response === undefined) {
            return;
        }

        const resultList = !servicePlan.gan
            ? response.ratecard_standart_list
            : response.ratecard_incorporated_list;

        const resultCount = !servicePlan.gan
            ? response.ratecard_standart_count
            : response.ratecard_incorporated_count;

        dispatch(setPriceList({
            ...(servicePlan.auth
                ? {
                    ratecard_list: response.ratecard_otp_list || [],
                    ratecard_count: response.ratecard_otp_count || 0
                }
                : {
                    ratecard_list: resultList || [],
                    ratecard_count: resultCount || 0
                }
            ),
            page,
            per_page
        }));

    });
};

export const setLoadingDataDialer = (load) => (dispatch) => {
    dispatch(setLoading(load));
};

export const exportPriceDialer = (service, filter = {}, servicePlan = {}, sort = {}) => async (dispatch) => {
    const isAuth = servicePlan?.auth || false;

    const isTest = localStorage.getItem('userInfo') 
        ? JSON.parse(localStorage.getItem('userInfo')).session.is_test 
        : false;

    let sort1, sort1_desc;

    if (sort.column) {
        sort1 = sort.column;
        sort1_desc = sort.type && sort.type === DESC_SORT;
    } else {
        sort1 = !servicePlan.gan ? 'subdestination_name' : 'incorporated_group_name';
    }

    const request = isAuth
        ? !isTest 
            ? RATECARD_LIST_OTP_ACCOUNT_DIALER 
            : RATECARD_LIST_OTP_DEFAULT_DIALER
        : !isTest 
            ? !servicePlan.gan
                ? servicePick(service, RATECARD_LIST_ACCOUNT_DIALER, SMS_RATECARD_LIST_ACCOUNT_DIALER) : servicePick(service, RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER, SMS_RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER)
            : !servicePlan.gan
                ? servicePick(service, RATECARD_LIST_DEFAULT_DIALER, SMS_RATECARD_LIST_DEFAULT_DIALER) :  servicePick(service, RATECARD_LIST_INCORPORATED_DEFAULT_DIALER, SMS_RATECARD_LIST_INCORPORATED_DEFAULT_DIALER);

    
    dispatch(setUploadLoading(true));
    await getFileResponse(request, {filter, sort1, sort1_desc})
        .then((response) => downloadDataAsFile(response, 'prices.csv'))
        .catch( e => e );
    dispatch(setUploadLoading(false));
};

export const getTrunkListDialer = (service) => (dispatch) => {
    const method = service ? TRUNK_LIST_API : TRUNK_LIST_SMS_API;

    api(method, {}).then((response) => {
        if (response !== undefined)
            dispatch(setTrunkList({
                trunk_list: response.trunk_list || []
            }));
    });
};

export const allocateByTemplateDialer = (service=true, trunk_id, numbers, template, random_number, ing_key = null) => async (dispatch) => {
    try {
        dispatch(setLoadingAllocatedNumbersDialer(true));
        const params = {
            target: service ? {trunk_id} : {
                'sms.trunk_id': trunk_id,
                ...(ing_key ? {"sms.supplier_gan_group_id": ing_key} : {})
            },
            numbers: +numbers,
            random_number: random_number,
            template: template
        };

        if (service) {
            if (ing_key) {
                params.ing_key = ing_key;
            }
        }

        const incorpMethod = service ? ALLOCATION_INCORPORATED_DIALER_API : SMS_ALLOCATION_GAN_API;
        const templateMethod = service ? ALLOCATION_TEMPLATE_DIALER_API : SMS_ALLOCATION_TEMPLATE_DIALER_API;
        const method = ing_key ? incorpMethod : templateMethod;

        const response = await api(method, params);
        dispatch(setLoadingAllocatedNumbersDialer(false));

        if (response.response && response.response.data.error) {
            return response.response.data.error;
        }
        return response;
    } catch {
        dispatch(setLoadingAllocatedNumbersDialer(false));
    }

}

export const allocateByPriceRangeDialer = (trunk_id, pr_key, numbers, random_number, ing_key = null) => async (dispatch) => {

    try {
        dispatch(setLoadingAllocatedNumbersDialer(true));
        const params = {
            target: {trunk_id}, 
            numbers: +numbers,
            random_number: random_number
        };

        if (ing_key) {
            params.ing_key = ing_key;
        } else {
            params.pr_key = pr_key;
        }

        const method = ing_key ? ALLOCATION_INCORPORATED_DIALER_API : ALLOCATION_PRICE_RANGE_DIALER_API;

        const response = await api(method, params);
        dispatch(setLoadingAllocatedNumbersDialer(false));
        if (response.response && response.response.data.error) {
            return response.response.data.error;
        }
        return response;
    } catch {
        dispatch(setLoadingAllocatedNumbersDialer(false));
    }
};