import React from 'react';
import Filters from './NumbersFilters';
import TableServerSort from '../../../components/Table/TableServerSort';
import Revoke from './Revoke';
import Allocate from './Allocate';
import SetOrUnsetTest from './SetOrUnsetTest';
import Block from 'pages/RangesAndNumbers/Numbers/Block';
import Unblock from 'pages/RangesAndNumbers/Numbers/Unblock';
import FlexboxGrid from 'rsuite/es/FlexboxGrid';
import {getRangeByNumber} from 'utils';
import {Link} from 'react-router-dom';
import './Numbers.css';
import {Icon, Whisper, Tooltip} from 'rsuite';
import styled from 'styled-components';


export default class Numbers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: {all: false, list: []},
            selectedNumbersInfo: [],

            columns: [
                {
                    label: 'Number',
                    dataKey: 'number',
                    value: (({number, numbers_count}) => getRangeByNumber(number, numbers_count)),
                    sortable: true,
                    width: 200
                },
                {
                    label: 'Allocated to and trunk',
                    dataKey: 'trunk_name',
                    value: (({account_name, account_manager_name, trunk_name, number, account_id, trunk_id}) => {
                        return account_name ?
                            <>
                                <Link className="ranges_numbers_table_link" to={`/accounts/view/${account_id}`} onClick={() => {
                                    this.props.setCurrentTrunkId(trunk_id);
                                    this.props.setCurrentRangeNumber(number)
                                }}>
                                    {account_name} / {account_manager_name || 'no manager'}&nbsp;
                                </Link>
                                {trunk_name.slice(account_name.length,-1)}
                            </>
                            : trunk_name
                    }),
                    sortable: true
                },
            ]
        }
    }



    /** @type MyTable */
    tableRef = null;

    getFilters = () => {
        return this.props.filters
    };
    getSelectedList = () => ({
        ...this.state.selected,
        countAll: this.props.allocatedNumbersCount
    });

    getNumbers = (page, per_page, sort) => {
        const {filters} = this.props;
        this.props.getNumbers(filters, page, per_page, sort);
    };
    // componentDidMount() {
    //     this.props.getNumbers(this.props.filters, 1, this.props.numbersPerPage, this.state.sort);
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.numbers !== this.props.numbers) {

            const isRange = this.props.numbers.some(item => item.numbers_count > 1);

            this.setState({
                columns: [
                    {
                        label: 'Number',
                        dataKey: 'number',
                        value: (({number, numbers_count, incorporated_group}) => {

                            if (!incorporated_group) {
                                return getRangeByNumber(number, numbers_count);
                            }

                            const payoutGroups = incorporated_group.rate_list
                                .reduce((summ, item) => {
                                    return {
                                        ...summ,
                                        [item.cur_name]: summ[item.cur_name]
                                            ? summ[item.cur_name] + ` ${item.rate}/${item.payment_terms_name}`
                                            : `${item.rate}/${item.payment_terms_name}`
                                    }
                                }, {});

                            return (
                                <>
                                    {getRangeByNumber(number, numbers_count)}
                                    {incorporated_group &&
                                        <Whisper
                                            enterable
                                            placement="right"
                                            speaker={
                                                <Tooltip>
                                                    <StyledTooltipInner>
                                                        <p>Supplier account name: <br/>{incorporated_group.supplier_name}</p>
                                                        <p>Group name: <br/>{incorporated_group.name}</p>
                                                        <p>Rate: <br/>{incorporated_group.rate}{incorporated_group.cur_name}</p>
                                                        <p>Default payouts: <br/>
                                                            {Object.keys(payoutGroups).map((item) => (
                                                                <><b>{item}</b>: {payoutGroups[item]} <br /></>
                                                            ))}
                                                        </p>
                                                    </StyledTooltipInner>
                                                </Tooltip>
                                            }
                                        >
                                            <StyledTooltipIcon icon='info'/>
                                        </Whisper>
                                    }
                                </>
                            )
                        }),
                        sortable: true,
                        width: isRange ? 260 : 200
                    },
                    {
                        label: 'Allocated to and trunk',
                        dataKey: 'trunk_name',
                        value: (({account_name, account_manager_name, trunk_name, number, account_id, trunk_id, test_number}) => {
                            return account_name ?
                                <>
                                    <Link className="ranges_numbers_table_link" to={`/accounts/view/${account_id}`} onClick={() => {
                                        this.props.setCurrentTrunkId(trunk_id);
                                        this.props.setCurrentRangeNumber(number)
                                    }}>
                                        {account_name} {!test_number && `/ ${account_manager_name || 'no manager'}`}&nbsp;
                                    </Link>
                                    {trunk_name.slice(account_name.length,-1)}
                                </>
                                : trunk_name
                        }),
                        sortable: true
                    },
                ]
            })
        }

        if (!this.props.numbers.length && prevProps.numbers !== this.props.numbers) {
            this.clearSelected();
        }
    }

    onChangeFilters = (filters) => {
        this.props.onChangeNumberFilters(filters);
        this.props.getNumbers(this.props.filters, 1, this.props.numbersPerPage, this.props.sort);
        this.clearSelected();
    };


    clearSelected() {
        this.setState({
            selected: {all: false, list: []},
            selectedNumbersInfo: [],
        });
        this.tableRef.clear();
    }

    render() {
        const {
            numbers, numbersLoading, numbersPage, numbersCount, numbersPerPage, service,
            account_id, sde_key, pr_key, filters, rangesFilter, incorporatedOnly, showReasonHandler, selectedRange
        } = this.props;
        const {
            selected,
            columns,
            selectedNumbersInfo
        } = this.state;

        const includedNumber = selected.list.length && numbers.filter(number => selected.list.includes(number.prn_key));
        const disabledBlockedNumbersButtonSign = includedNumber && includedNumber.some(number => number.trunk_name === 'blocked');
        const hasNoZeroPrefixNumbers = includedNumber && includedNumber.some(number => number.numbers_count !== 1);

        const hasSelected = (selected.list && selected.list.length) || selected.all;

        return (
            <div>
                <FlexboxGrid justify="space-around" style={{marginBottom: 25, textAlign: 'right'}}>
                    <FlexboxGrid.Item>
                        <Revoke
                            incorporatedOnly={incorporatedOnly}
                            selectedNumbersInfo={selectedNumbersInfo}
                            disabled={!hasSelected}
                            pr_key={pr_key}
                            service={service}
                            filters={filters}
                            getList={this.getSelectedList}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Allocate
                            showReasonHandler={showReasonHandler}
                            incorporatedOnly={incorporatedOnly}
                            selectedNumbersInfo={selectedNumbersInfo}
                            service={service}
                            filters={filters}
                            disabled={!hasSelected || disabledBlockedNumbersButtonSign}
                            rangesFilter={rangesFilter}
                            getList={this.getSelectedList}
                            pr_key={pr_key}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                            account_id={account_id}
                            sde_key={sde_key}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <SetOrUnsetTest
                            incorporatedOnly={incorporatedOnly}
                            selectedNumbersInfo={selectedNumbersInfo}
                            disabled={!hasSelected || disabledBlockedNumbersButtonSign || hasNoZeroPrefixNumbers || rangesFilter?.only_with_incorporated_numbers || selected.all}
                            getList={this.getSelectedList}
                            pr_key={pr_key}
                            service={service}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                            isSetTest={true}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <SetOrUnsetTest
                            incorporatedOnly={incorporatedOnly}
                            selectedNumbersInfo={selectedNumbersInfo}
                            disabled={!hasSelected || disabledBlockedNumbersButtonSign || rangesFilter?.only_with_incorporated_numbers || selected.all}
                            getList={this.getSelectedList}
                            pr_key={pr_key}
                            service={service}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                            isSetTest={false}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Block
                            incorporatedOnly={incorporatedOnly}
                            selectedNumbersInfo={selectedNumbersInfo}
                            disabled={!hasSelected || disabledBlockedNumbersButtonSign || selected.all}
                            getList={this.getSelectedList}
                            pr_key={pr_key}
                            service={service}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Unblock
                            incorporatedOnly={incorporatedOnly}
                            selectedNumbersInfo={selectedNumbersInfo}
                            disabled={!hasSelected || selected.list.length && !disabledBlockedNumbersButtonSign || selected.all}
                            getList={this.getSelectedList}
                            pr_key={pr_key}

                            service={service}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <div style={{marginLeft: 20}}>
                    <Filters
                        value={this.props.filters}
                        onChange={this.onChangeFilters}
                    />
                    <TableServerSort
                        ref={ref => this.tableRef = ref}
                        data={numbers}
                        loading={numbersLoading}
                        columns={columns}
                        originalColumns={columns}
                        count={numbersCount}
                        per_page={numbersPerPage}
                        page={numbersPage}
                        getItems={this.getNumbers}
                        row_key="prn_key"
                        isSelectedAll={!incorporatedOnly}
                        setSelected={selected => {
                            const filteredNumbers = selectedNumbersInfo.filter(item => selected.list.includes(item.prn_key));
                            const currentPageNumbers = numbers.filter(item => selected.list.includes(item.prn_key));
                            const numbersUpdated = currentPageNumbers.reduce((summ, item) => {
                                if (summ.find(_item => _item.prn_key === item.prn_key)) {
                                    return summ;
                                }
                                return [...summ, item];
                            }, [...filteredNumbers]);

                            this.setState({
                                selected,
                                selectedNumbersInfo: numbersUpdated
                            });
                        }}
                        ispagination
                        isselected
                        onSort={(column, type) => {
                            const {onSort} = this.props;
                            onSort({column, type})
                        }}
                    />
                </div>
            </div>
        );

    }
}


const StyledTooltipIcon = styled(Icon)`
    margin-left: 5px;

    .rtl & {
        margin-left: 0;
        margin-right: 5px;
    }
`;

const StyledTooltipInner = styled.div`
    text-align: left;
`;