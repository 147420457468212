import React, { useRef } from "react";
import TableServerSort from "components/Table/TableServerSort";
import { Table } from "rsuite";
import { ButtonRowEdit, ButtonRowDelete } from "components/base/BaseButton";
import styled from "styled-components";
import { DEFAULT_PER_PAGE_MAX } from "const";
import m from "definedMessages";

const {Column, HeaderCell, Cell} = Table;

const AccessListFiltersTable = ({
    data,
    count,
    page,
    perPage,
    loading,

    setSort,
    onEdit,
    onDelete,
    getItems,
    
    formatMessage
}) => {
    const isMobile = false;

    const tableRef = useRef(null);

    const columns = [
        {
            label: "Service Name",
            dataKey: "antf_name", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 130,
            minWidthMobile: 300
        },
        {
            label: formatMessage(m.originZone),
            dataKey: "a_worldzone_name", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 130,
            minWidthMobile: 300
        },
        {
            label: formatMessage(m.originCountry),
            dataKey: "a_destination_name", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 130,
            minWidthMobile: 300
        },
        {
            label: formatMessage(m.originOperator),
            dataKey: "a_subdestination_name", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 130,
            minWidthMobile: 300
        },
        {
            label: formatMessage(m.originPrefix),
            dataKey: "a_prefix", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 130,
            minWidthMobile: 300
        },
        {
            label: formatMessage(m.destinationZone),
            dataKey: "b_worldzone_name", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 130,
            minWidthMobile: 300
        },
        {
            label: formatMessage(m.destinationCountry),
            dataKey: "b_destination_name", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 130,
            minWidthMobile: 300
        },
        {
            label: formatMessage(m.destinationOperator),
            dataKey: "b_subdestination_name", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 130,
            minWidthMobile: 300
        },
        {
            label: formatMessage(m.minimalPayout),
            dataKey: "rate_min", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 130,
            minWidthMobile: 300
        },
    ];

    const actionCells = ActionCell(
        onEdit,
        onDelete
    );

    return <StyledTable
        shouldUpdateScroll={false}
        ref={tableRef}
        data={data}
        loading={loading}
        columns={columns}
        originalColumns={columns}
        // count={count}
        // per_page={perPage}
        // perPageMaximum={DEFAULT_PER_PAGE_MAX}
        // page={page}

        // extraHeight={isMobile ? 208 : 180}
        rowHeight={isMobile ? 42 : 30}

        row_key="alf_key"

        // showedCount={count}
        // ispagination

        onSort={(column, type) => setSort({column, type})}
        getItems={getItems}

        ActionCell={actionCells}
    />
};

export default AccessListFiltersTable;


const ActionCell = (onEdit, onDelete) => {
    return  <Column width={86} fixed="right">
        <HeaderCell>Actions</HeaderCell>
        <Cell>
            {rowData => (
                <StyledActionCell>
                    
                    {onEdit && <ButtonRowEdit 
                        onClick={() => onEdit(rowData)}
                    />}
                                       
                    {onDelete && <ButtonRowDelete 
                        onClick={() => onDelete(rowData.alf_key)}
                    />}
                </StyledActionCell>
            )}
        </Cell>
    </Column>
}


const StyledTable = styled(TableServerSort)`
    && {
        margin-bottom: 20px;
    }
`;


const StyledActionCell = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
`;
