import {
    PAYMENT_DETAIL as PREFIX,
} from '../const';
import {
    PAYMENT_DETAIL_CONFIRM_API,
    PAYMENT_DETAIL_CONFIRM_STATUS_API
} from '../const/apiMethods';

const initialState = {
    items: [],
    crypto_items: [],
    loading: true,
    confirm_payment: {},
    confirm_status: null
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {items}}) => ({
        ...state,
        items: items,
        loading: false
    }),
    ['SET_ITEMS_CRYPTO_' + PREFIX]: (state, {payload: {items}}) => ({...state, crypto_items: items}),
    [PAYMENT_DETAIL_CONFIRM_API]: (state, {payload: {items}}) => ({...state, confirm_payment: items, loading: false}),
    [PAYMENT_DETAIL_CONFIRM_STATUS_API]: (state, {payload: {status}}) => ({...state, confirm_status: status}),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}