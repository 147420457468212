import {
    ACCOUNTS as PREFIX,
    VIEW_ACCOUNT,
    SET_VIEW_ACCOUNT,
    SET_VIEW_ACCOUNT_JOIN,
    SET_TRUNK_ACCOUNT,
    SET_ALLOCATED_ACCOUNT,
    SET_TRUNKS_SUPPLIER,
    SET_TRUNKS_SUPPLIER_LOADING,
    SET_TRUNKS_SUPPLIER_DROPDOWN,
    ADD_TRUNK_ACCOUNT,
    SET_TRUNK_ACCOUNT_LOADING,
    SET_ALLOCATED_ACCOUNT_LOADING,
    SET_VIEW_ACCOUNT_AFTER_DELETE,
    SET_USERS_ACCOUNT,
    SET_USERS_ACCOUNT_LOADING,
    SPECIAL_RATES,
    SET_TRANSIT_RATES,
    DEFAULT_PER_PAGE_SMALL,
    SET_ACCESS_LIST_FILTERS_LOADING,
    SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST,
    SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST_LOADING,
    SET_ACCESS_LIST_NOTIFICATION_SETTINGS,
    UNSET_ACCESS_LIST_NOTIFICATION_SETTINGS,
    SET_LIMIT_MODIFIED,
    SET_DEFAULT_FORM_CREATE_ACCOUNT,
    SET_REDIRECT_TAB, TRUNKS_LIST_UNSET_INITED
} from '../const/'
import store from '../store/index';
import {getAccount} from '../actions';
import {
    SET_ACCESS_LIST_FILTERS,
    SET_EDIT_ERROR,
    SET_EDIT_ERROR_MESSAGE,
    SET_CURRENT_TRUNK_ID, SET_CURRENT_RANGE_NUMBER
} from 'const';
import {
    SET_REALTIME_PAYMENT_STATUS
} from '../const/apiMethods'

const initialState = {
    loading: false,
    joinLoading: false,

    accessList: [],
    accessListLoading: false,
    accountView: {},
    accountTrunk: [],

    accountSupplierTransitTrunks: [],
    accountSupplierGanTrunks: [],

    accountTrunkLoading:false,
    accountJoinList: [],
    billingCurrencyList: [],
    billingServiceList: [],

    trunksInited: false,
    accountAllocated:[],
    accountAllocatedCount: 0,
    accountAllocatedNumbersTotalCount: 0,
    allocatedNumbersPage: 1,
    allocatedNumbersPerPage: +localStorage.getItem('allocatedNumbersPerPage') || DEFAULT_PER_PAGE_SMALL,
    accountAllocatedLoading: false,
    items: [],
    accountUsers: [],
    accountUsersLoading: false,
    editError: false,
    editErrorMessage: null,
    ranges: [],
    rangesLoading: false,

    // Access List Filters data (NOT Access lists data! Be carefully)
    accessListFiltersList: [],
    accessListFiltersListLoading: true,
    accessListNotificationSettings: null,

    currentRangeNumber: null,
    currentTrunkId: null,
    modifiedLimit: null,

    registeredAccount: {},

    redirectTab: null,
    realtimePaymentStatus: 0,

    transitRates: [],
    transitRatesLoading: false
};

const viewAccount = (state, id) => {
    const accountView = state.items.find( acc => acc.id === id);
    if(!accountView)
        setTimeout(() => {
            store.dispatch(getAccount(id, true, true))
        }, 0);
    return {...state, accountView: accountView || {}};
};


const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload}) => ({
        ...state,
        items: payload.items,
        loading: false
    }),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    ['SET_JOIN_LOADING' + PREFIX]: (state, {payload}) => ({...state, joinLoading: payload}),
    [VIEW_ACCOUNT]: (state, {payload:id}) => viewAccount(state, id),
    [SET_VIEW_ACCOUNT]: (state, {payload: accountView}) => ({
        ...state, 
        accountView,
        loading: false
    }),
    [SET_VIEW_ACCOUNT_JOIN]: (state, {payload: {accountJoinList, billingCurrencyList, billingServiceList, ...accountJoin}}) => ({
        ...state, 
        accountJoinList,
        billingCurrencyList,
        billingServiceList,
        accountView: accountJoin,
        loading: false
    }),
    [SET_TRUNKS_SUPPLIER]: (state, {payload}) => ({
        ...state,
        ...payload,
        trunksInited: true,
        loading: false
    }),
    [SET_TRUNKS_SUPPLIER_LOADING]: (state, {payload}) => ({
        ...state,
        loading: payload || false
    }),
    [SET_TRUNKS_SUPPLIER_DROPDOWN]: (state, {payload}) => ({
        ...state,
        ...payload
    }),
    [SET_TRUNK_ACCOUNT]: (state, {payload: accountTrunk}) => {
        const filteredAccountTrunks = accountTrunk.filter(trunk => !trunk.closed && trunk.active);
        const trunkId = filteredAccountTrunks.find(item => item.id === state.currentTrunkId)
            ? state.currentTrunkId
            : filteredAccountTrunks?.[0]?.id || null;

        return {
            ...state,
            accountTrunk,
            currentTrunkId: trunkId,
            accountTrunkLoading: false,
            trunksInited: true,
        }
    },

    [SET_CURRENT_TRUNK_ID]: (state, {payload: id}) => ({
        ...state,
        currentTrunkId: id
    }),

    [TRUNKS_LIST_UNSET_INITED]: (state) => ({
        ...state,
        trunksInited: false
    }),

    [SET_CURRENT_RANGE_NUMBER]: (state, {payload: number}) => ({
       ...state, currentRangeNumber: number
    }),
    [SET_TRUNK_ACCOUNT_LOADING]: (state,{payload: accountTrunkLoading}) => ({...state, accountTrunkLoading}),
    [SET_ALLOCATED_ACCOUNT]: (state, {payload: {items, count, trunk_number_count, page, per_page}}) => ({
        ...state,
        accountAllocated: items,
        accountAllocatedCount: count,
        accountAllocatedNumbersTotalCount: trunk_number_count,
        allocatedNumbersPage: page,
        allocatedNumbersPerPage: per_page || +localStorage.getItem('allocatedNumbersPerPage') || DEFAULT_PER_PAGE_SMALL,
        accountAllocatedLoading:false
    }),
    [SET_ALLOCATED_ACCOUNT_LOADING]: (state, {payload: accountAllocatedLoading}) => ({...state, accountAllocatedLoading}),
    [ADD_TRUNK_ACCOUNT]: (state,{payload: trunk}) => ({
        ...state,
        accountTrunk: [
            ...state.accountTrunk.filter( x => x.id !== trunk.id ),
            trunk
        ]
    }),
    [SET_VIEW_ACCOUNT_AFTER_DELETE]: (state, {payload: id}) => ({
        ...state,
        accountTrunk: state.accountTrunk.filter(trunkObj=>trunkObj.id !== id),
        accountTrunkLoading:false
    }),
    [SET_USERS_ACCOUNT]: (state, {payload: accountUsers}) => ({
        ...state,
        accountUsers,
        accountUsersLoading:false
    }),
    [SET_USERS_ACCOUNT_LOADING]: (state, {payload: accountUsersLoading}) => ({...state, accountUsersLoading}),
    [SPECIAL_RATES]: (state,{payload}) => ({...state,rates:payload,ratesLoading: false}),
    [SET_EDIT_ERROR]: (state, {payload}) => ({...state, editError: payload, loading: false}),
    [SET_EDIT_ERROR_MESSAGE]: (state, {payload}) => ({...state, editErrorMessage: payload, editError: true, loading: false}),
    [SET_ACCESS_LIST_FILTERS]: (state, {payload}) => ({...state, accessList: payload, accessListLoading: false}),
    [SET_ACCESS_LIST_FILTERS_LOADING]: (state, {payload}) => ({...state, accessListLoading: payload}),
    ['SET_LOADING_' + SPECIAL_RATES]: (state,{payload}) => ({...state, ratesLoading: payload}),
    [SET_TRANSIT_RATES]: (state, {payload}) => ({...state, transitRates: payload, transitRatesLoading: false}),
    ['SET_LOADING_' + SET_TRANSIT_RATES]: (state, {payload}) => ({...state, transitRatesLoading: payload}),
    [SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST]: (state, {payload}) => ({
        ...state,
        accessListFiltersList: payload,
        accessListFiltersListLoading: false
    }),
    [SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST_LOADING]: (state, {payload}) => ({...state, accessListFiltersListLoading: true}),
    [SET_ACCESS_LIST_NOTIFICATION_SETTINGS]: (state, {payload}) => {
        return(
            {
                ...state,
                accessListNotificationSettings: {...payload}
            }
        )
    },
    [UNSET_ACCESS_LIST_NOTIFICATION_SETTINGS]: (state) => {
        return ({
            ...state,
            accessListNotificationSettings: null
        })
    },
    [SET_LIMIT_MODIFIED]: (state, {payload}) => ({...state, modifiedLimit: payload}),
    [SET_DEFAULT_FORM_CREATE_ACCOUNT]: (state, {payload}) => ({...state, registeredAccount: payload}),
    [SET_REDIRECT_TAB]: (state, {payload}) => ({...state, redirectTab: payload}),
    [SET_REALTIME_PAYMENT_STATUS]: (state, {payload}) => ({...state, realtimePaymentStatus: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};
