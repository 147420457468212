import React, { useRef, useState, useEffect } from "react";
import {Form, Input, Schema, InputNumber, SelectPicker, FlexboxGrid} from "rsuite";
import CustomField from "pages/NewAccount/ContactCustomFieldRefactored";
import {BaseButton} from "components/base/BaseButton";
import Modal from "components/Modal";
import styled, {css} from "styled-components";
import { hasErrorObject } from "utils";
import m from "definedMessages";
import FlexboxGridItem from "rsuite/lib/FlexboxGrid/FlexboxGridItem";

const {
    NumberType,
    StringType
} = Schema.Types;

const AccessListFiltersCreateModal = ({
    show,
    data,

    servicePlanList,
    currencyList,
    worldzoneList,
    destinationList,
    subdestinationList,
    numberTypeFamilyList,
    
    onSubmit,
    onCloseModal,
    formatMessage
}) => {

    const defaultFilterValue = {
        antf_key: null,
        a_wz_key: null,
        a_de_key: null,
        a_sde_key: null,
        a_prefix: null,
        b_wz_key: null,
        b_de_key: null,
        b_sde_key: null,
        rate_min: null
    };

    const formModel = Schema.Model({
        antf_key: NumberType(),
        a_wz_key: NumberType(),
        a_de_key: NumberType(),
        a_sde_key: NumberType(),
        a_prefix: StringType()
            .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
            .minLength(6, "Min length is 6 characters")
            .maxLength(15, "Max length is 15 characters"),
        b_wz_key: NumberType(),
        b_de_key: NumberType(),
        b_sde_key: NumberType(),
        rate_min: NumberType()
    });

    const formRef = useRef(null);
    const [formState, setFormState] = useState(defaultFilterValue);

    const isEditFlag = !!data && Object.keys(data).includes("alf_key");

    useEffect(() => {
        if (!!data && Object.keys(data).includes("alf_key")) {
            const editData = {
                alf_key: data?.alf_key,
                antf_key: data?.antf_key || null,
                a_wz_key: data?.a_wz_key || null,
                a_de_key: data?.a_de_key || null,
                a_sde_key: data?.a_sde_key || null,
                a_prefix: data?.a_prefix || null,
                b_wz_key: data?.b_wz_key || null,
                b_de_key: data?.b_de_key || null,
                b_sde_key: data?.b_sde_key || null,
                rate_min: data?.rate_min || null
            }
            setFormState(editData);
        }
    }, [data]);

    const onCreate = () => {
        if (formRef.current.check()) {
            if (isEditFlag) {
                onSubmit(formState.alf_key, formState);
            } else {
                onSubmit(formState);
            }
            setFormState(defaultFilterValue);
        }
    };

    const formHasError = hasErrorObject(formModel.check(formState));

    const modalTitle = isEditFlag ? "Modify access list filter" : "Create new access list filter";


    return <Modal 
        width={680}
        show={show}
        title={modalTitle}
        onClose={() => {
            onCloseModal();
            setFormState(defaultFilterValue);
        }}
        onSuccess={() => {
            onCreate();
            onCloseModal();
        }}
        disabled={formHasError}
        successText={formatMessage(m.ok)}
        cancelText={formatMessage(m.cancel)}
        footer
    >
        <StyledForm
            layout="inline"
            ref={formRef}
            model={formModel}
            formValue={formState}
            onChange={(formValue) => setFormState(formValue)}
        >
            <StyledFlexboxGrid direction="column">
                <FlexboxGrid.Item>
                    <CustomField
                        name="antf_key"
                        accepter={SelectPicker}
                        data={numberTypeFamilyList}
                        valueKey="antf_key"
                        labelKey="family_name" 
                        placeholder="Service Name"
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <CustomField
                        name="a_prefix"
                        accepter={Input}
                        placeholder="Origin Prefix"
                    />
                    <CustomField
                        name="rate_min"
                        accepter={Input}
                        placeholder="Minimal payout"
                        width={130}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <StyledFlexboxGrid direction="row">
                        <FlexboxGrid.Item>
                            <span className="header">Origin</span>
                            <CustomField
                                name="a_wz_key"
                                accepter={SelectPicker}
                                data={worldzoneList}
                                valueKey="wz_key"
                                labelKey="name"
                                placeholder="Origin zone"
                                width={280}
                            />
                            <CustomField
                                name="a_de_key"
                                accepter={SelectPicker}
                                data={destinationList}
                                valueKey="de_key"
                                labelKey="name"
                                placeholder="Origin country"
                                width={280}
                            />
                            <CustomField
                                name="a_sde_key"
                                accepter={SelectPicker}
                                data={subdestinationList}
                                valueKey="sde_key"
                                labelKey="name"
                                placeholder="Origin operator"
                                width={280}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <span className="header">Destination</span>
                            <CustomField
                                name="b_wz_key"
                                accepter={SelectPicker}
                                data={worldzoneList}
                                valueKey="wz_key"
                                labelKey="name"
                                placeholder="Destination zone"
                                width={280}
                            />
                            <CustomField
                                name="b_de_key"
                                accepter={SelectPicker}
                                data={destinationList}
                                valueKey="de_key"
                                labelKey="name"
                                placeholder="Destination country"
                                width={280}
                            />
                            <CustomField
                                name="b_sde_key"
                                accepter={SelectPicker}
                                data={subdestinationList}
                                valueKey="sde_key"
                                labelKey="name"
                                placeholder="Destination operator"
                                width={280}
                            />
                        </FlexboxGrid.Item>
                    </StyledFlexboxGrid>
                </FlexboxGrid.Item>
            </StyledFlexboxGrid>
        </StyledForm>
    </Modal>
};

export default AccessListFiltersCreateModal;

const StyledForm = styled(Form)`
`;

const StyledFlexboxGrid = styled(FlexboxGrid)`
    && {
        display: flex;
        flex-direction: ${props => props.direction};
    }

    && .rs-flex-box-grid-item .rs-form-group {
        margin-right: 10px;
    }

    && .rs-flex-box-grid-item {
        span.header {
            margin-bottom: 8px;
        }

        ${props => props.direction === "row" && css`
            display: flex;
            flex-direction: column;

            :first-child {
                margin-right: 20px;
            }
        `}
    }

    /* && .rs-flex-box-grid-item */
`;