import {connect} from "react-redux";
import {
    getNotification,
    modifyNotification,
    getAccessListFiltersList,
    createAccessListFilter,
    modifyAccessListFilter,
    removeAccessListFilter,
    setLoadingData
} from "actions/client/access_list_filters";

import {servicePlanNoTrunkFilter} from "utils/filters";

import AccessListFiltersPage from "./AccessListFiltersPage";
import {showReasonHandler} from "actions/reason_handler";

import {
    getAuthNumberType
} from "actions/auth_numbers";

import {transformed_service_reverse_data} from "const";

const mapState = ({client_access_list_filters, auth, auth_dialer, auth_numbers, references}) => {
    return {
        data: client_access_list_filters.items,
        settings: client_access_list_filters.settings,
        settingsIsFetched:  client_access_list_filters.settingsIsFetched,
        loading: client_access_list_filters.loading,


        service: auth_dialer.serviceDialer,
        account: auth.authInfo,
        permissions: auth.permissions,
        accountDialerState: auth_dialer.dialerInfo,
        accountJoinList: auth_dialer.accountJoinList,

        dialerInfo: auth.dialerInfo,
        accountId: auth.dialerInfo 
            ? auth.dialerInfo.account_list.find(account => account.service === transformed_service_reverse_data[auth_dialer.serviceDialer])?.id 
            : null,
        servicePlanList: references.service_plan_list.filter(servicePlanNoTrunkFilter),
        currencyList: references.currency_list,
        worldzoneList: references.worldzone_list,
        destinationList: references.destination_list,
        subdestinationList: references.subdestination_list,
        numberTypeFamilyList: auth_numbers.auth_number_type_family_list,
    }
};


export default connect(mapState, {
    getNotification,
    modifyNotification,
    getAuthNumberType,
    getList: getAccessListFiltersList,
    create: createAccessListFilter,
    modify: modifyAccessListFilter,
    remove: removeAccessListFilter,
    setLoading: setLoadingData,
    showReasonHandler,
})(AccessListFiltersPage);