import React, {useMemo, useState, useEffect} from "react";
import {Form, FormControl, Icon, SelectPicker, Uploader, Schema, Divider} from "rsuite";
import * as S from "./styled";
import {usePrevious, useWindowWidth} from "../../../hooks";
import { parse } from 'object-flaser';
import { Spacer } from "../../../components/base/Spacer";
import {PAYMENT_METHOD_CRYPTO, PAYMENT_METHOD_HAWALA, PAYMENT_METHOD_MISC} from "../../../const";
import fields, {
    
    // organization field groups
    customerFields,
    companyFields,

    // payment method field groups
    bankTransferFields, 
    
    paypalFields,
    cryptoFields,
    cryptoCustomerFields,
    cryptoCompanyFields,
    hawalaFields,
    miscFields,
    otherTransferSystems
} from "./fields";
import { toBase64 } from "../../../utils/helpers";


import {
    FieldInput, 
    FieldSelect, 
    FieldFile
} from "./Field";

const MAX_CHARACTERS_MID = 40;
const MAX_CHARACTERS = 255;
const MAX_DESCRIPTION_CHARACTERS = 1023;

const methodFields = [
    bankTransferFields,
    paypalFields,
    otherTransferSystems,
    otherTransferSystems,
    hawalaFields,
    cryptoFields,
    miscFields
];

const {StringType, NumberType, ArrayType} = Schema.Types;

const maxFileSize = 5; // MB
const maxFiles = 5;

const defaultValue = {ot_key: 1, pm_key: 1};

export default ({
    show,
    curKey,
    onClose,
    maxWidth = 1000,
    modify = false,
    accountId,
    currency,
    availableCurrency,

    cryptoList,
    organizationTypeList,
    countryList,
    paymentMethodList,
    createPaymentDetails,
    modifyPaymentDetails,
    getPaymentDetailsList,

    defaultFormValue = null,
}) => {

    const formModel = Schema.Model({
        // type fields
        ot_key: NumberType().isRequired( "This field is required" ),
        pm_key: NumberType()
            .isRequired( "This field is required" )
            .addRule((value, data) => {
                if (data.ot_key === 2 && value === 5)
                    return false;
            }, 'This method is not allowed with the "company" option'),

        // customer fields
        customer_name: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        customer_last_name: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        tax_vat_number: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        customer_cntr_code: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'customer_address.city': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'customer_address.region': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`),
        'customer_address.address': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'customer_address.postal_code': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'customer_phone': StringType().maxLength(MAX_CHARACTERS_MID, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        ext_files: ArrayType()
            .addRule( (value, data) => {
                return (data.ot_key === 2 || (value && value.length > 0)) ? true : false;
            }, "This field is required", true )
            .addRule( (value) => value.length > maxFiles ? false : true, `Maximum number of files is ${maxFiles}` )
            .addRule( (value) => {
                const names = [];
                for (const file of value) {
                    if (file.blobFile && file.blobFile.size > (1024 * 1024 * maxFileSize))
                        names.push(file.name);
                }
                return !names.length
            }, `File size should not exceed ${maxFileSize} MB`),

        // beneficiary fields
        beneficiary_name: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        beneficiary_last_name: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`)
            .addRule( (value, data) => {
                if ( !value ) {
                    if (data.ot_key === 2 && data.pm_key === 1)
                        return true;
    
                    return false
                }
            }, "This field is required", true ),
        beneficiary_cntr_code: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'beneficiary_address.region': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`),
        'beneficiary_address.city': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'beneficiary_address.address': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'beneficiary_address.postal_code': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),

        // bank transfer fields
        bank_name: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        bank_cntr_code: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'bank_address.region': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`),
        'bank_address.city': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'bank_address.address': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`),
        'bank_address.postal_code': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`),
        account_iban_number: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        swift_number: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        intermediate_bank_details: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),

        // paypal fields
        paypal_id: StringType().maxLength(MAX_CHARACTERS_MID, `The maximum of this field is ${MAX_CHARACTERS_MID}`).isRequired( "This field is required" ),
        
        // crypto fields
        crypto_wallet_number: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        
        crypto_currency_key: NumberType().isRequired( "This field is required" ),
        crypto_network_key: NumberType().isRequired( "This field is required" ),

        // hawala fields
        hawala_description: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),

        // msic fields
        misc_description: StringType().maxLength(MAX_DESCRIPTION_CHARACTERS, `The maximum of this field is ${MAX_DESCRIPTION_CHARACTERS}`).isRequired( "This field is required" )
    });


    let formRef = null;

    const refactoredDefaultFormValue = defaultFormValue !== null ? {
        ...defaultFormValue,
        customer_cntr_code: defaultFormValue.customer_cntr_code && defaultFormValue.customer_cntr_code.trim(),
        beneficiary_cntr_code: defaultFormValue.beneficiary_cntr_code && defaultFormValue.beneficiary_cntr_code.trim()
    } : null;

    const [formValue, setFormValue] = useState(defaultFormValue || defaultValue);

    const prevFormValue = usePrevious(formValue);

    const [activeFields, setActiveFields] = useState([]);
    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);
    const [localPaymentMethodKey, setLocalPaymentMethodKey] = useState(null);

    const isCrypto = useMemo(() => PAYMENT_METHOD_CRYPTO === localPaymentMethodKey, [localPaymentMethodKey]);

    const [currentCryptoCurrency, setCurrentCryptoCurrency] = useState(null);
    
    const networkList = useMemo(() => {
        if (currentCryptoCurrency) {
            const currentCryptoValue = cryptoList.find((currency) => currency.crypto_currency_key === currentCryptoCurrency);
            const currentNetworkList = currentCryptoValue ? currentCryptoValue.network_list : [];

            const resultNetworkList = currentNetworkList.map(({crypto_network_key, crypto_network_name}) => {
                return {key: crypto_network_key, name: crypto_network_name};
            });

            return resultNetworkList;

        }
        return [];
    }, [currentCryptoCurrency]);


    const cryptoCurrencyList = useMemo(() => {
        return cryptoList && cryptoList.length ? cryptoList.map(({crypto_currency_key, crypto_currency_name}) => {
            return {key: crypto_currency_key, name: crypto_currency_name};
        }) : []
    }, [formValue.pm_key, cryptoList]);

    // effect: change form data by defaults
    useEffect(() => {
        if (defaultFormValue) {
            setCurrentCryptoCurrency(null);
            setFormValue({...refactoredDefaultFormValue});
        } else {
            setFormValue({...defaultValue});
        }
    }, [defaultFormValue]);


    useEffect(() => {
        if (!currentCryptoCurrency && formValue.crypto_currency_key) {
            setCurrentCryptoCurrency(formValue.crypto_currency_key);
        }
    }, [formValue.crypto_currency_key])

    useEffect(() => {
        if (formValue.pm_key && !prevFormValue.pm_key && prevFormValue.pm_key !== formValue.pm_key) {
            setLocalPaymentMethodKey(formValue.pm_key);
            setFormValue({...formValue, crypto_currency_key: null, crypto_network_key: null});
        }
    }, [formValue.pm_key]);

    useEffect(() => {
        if (formValue.crypto_currency_key && formValue.crypto_currency_key !== currentCryptoCurrency) {
            setFormValue({
                ...formValue,
                ...(currentCryptoCurrency !== null ? {crypto_network_key: null} : {})
            });
        }
    }, [formValue.crypto_currency_key]);


    // effect: set active fields
    useEffect( () => {
        const [personal, beneficiary] = setFormFields(formValue.ot_key, formValue.pm_key);
        setActiveFields([...personal, ...beneficiary]);
    }, [isCrypto, formValue.ot_key, formValue.pm_key] );


    // effect: prevent "hawala" && "company" option
    useEffect( () => {
        if (formValue.ot_key === 2 && [PAYMENT_METHOD_HAWALA, PAYMENT_METHOD_MISC].includes(formValue.pm_key)) {
            setFormValue({...formValue, pm_key: 1});
        }
    }, [formValue.ot_key, formValue.pm_key] );


    // submit data
    const handleFormSubmit = async () => {

        const fieldsValidity = [];
        for (const fieldName of activeFields) {
            fieldsValidity.push( formRef.checkForField(fieldName) );
        }
        

        if (fieldsValidity.includes(false))
            return;

        setLoading(true);
        const formValue = formRef.getFormValue();

        const data = {
            target: {
                account_id: accountId
            },
            ot_key: formValue.ot_key,
            pm_key: formValue.pm_key,
            cur_key: curKey || currency
        };

        if (modify && formValue.id) {
            delete data.ot_key;
            delete data.pm_key;
            data.target = {
                payment_detail_id: formValue.id
            }
        }

        for (const field of activeFields) {
            
            if (formValue[field] === undefined || formValue[field] === null)
                continue;
           
            if (field === "ext_files") {
                const files = [];
                for (const file of formValue[field]) {
                    const fileBase64 = file.blobFile 
                        ? await toBase64(file.blobFile)
                        : file.url;
                    const [type, content] = fileBase64.split(',');
    
                    files.push( {
                        file: content,
                        type: type.split(';')[0].split(':')[1],
                        fileKey: file.fileKey,
                        name: file.name,
                        status: file.status
                    } );
                }
                data[field] = files;
                continue;
            }

            data[field] = formValue[field];
        }

        const response = !modify
            ? await createPaymentDetails( parse(data) ) 
            : await modifyPaymentDetails( parse(data) );

        if (response) {
            getPaymentDetailsList({target: {account_id: accountId}});
            onClose(response);
        }
        
        setLoading(false);
    };


    const setFormFields = (organizationType, paymentMethod) => {

        // company + hawala
        if (organizationType === 2 && paymentMethod === PAYMENT_METHOD_HAWALA) {
            return [[], []];
        }
        // private person + hawala
        if (organizationType === 1 && paymentMethod === PAYMENT_METHOD_HAWALA) {
            return [[], ['hawala_description']];
        }
        // company + misc
        if (organizationType === 2 && paymentMethod === PAYMENT_METHOD_MISC) {
            return [[], []];
        }
        // private person + misc
        if (organizationType === 1 && paymentMethod == PAYMENT_METHOD_MISC) {
            return [[], ['misc_description']];
        }
            
        // default
        const organizationFields = organizationType === 1 
            ? isCrypto ? cryptoCustomerFields : customerFields 
            : isCrypto ? cryptoCompanyFields : companyFields;

        return [organizationFields, methodFields[paymentMethod - 1]]
    };


    const renderFields = (fieldNames = [], formValue) => (

        fieldNames.map((fieldName) => {
            const {type, name, label: defaultLabel, labelId, hideOn, ...props} = fields[fieldName];

            // hide on option
            if (hideOn && Array.isArray(hideOn)) {
                for (const conditionItem of hideOn) {
                    const keys = Object.keys(conditionItem || {});
                    const status = keys.map(key => {
                        return formValue[key] && formValue[key] === conditionItem[key];
                    });
                    
                    if (!status.includes(false))
                        return null;
                }
            }

            // get label
            const label = defaultLabel;

            switch (type) {
                case 'select_country':
                    return <FieldSelect
                        key={name}
                        name={name}
                        label={label}
                        data={countryList}
                        valueKey="cntr_code"
                        labelKey="name"
                        {...props}
                    />;

                case 'file':
                    const label_ = formValue.ot_key === 1 
                        ? "Passport scans"
                        : "Certificate of Incorporation";
                    return <FieldFile key={name} name={name} defaultFileList={formValue[name]} label={label_} {...props} />;

                case 'select_crypto_currency':
                    return <FieldSelect
                        key={name}
                        name={name}
                        label={label}
                        data={cryptoCurrencyList}
                        onChange={(value) => {
                            setCurrentCryptoCurrency(value);
                        }}
                        labelKey="name"
                        valueKey="key"
                        {...props}
                    />;
            
                case 'select_crypto_network':
                    return <FieldSelect
                        key={name}
                        name={name}
                        label={label}
                        data={networkList}
                        disabled={!currentCryptoCurrency}
                        labelKey="name"
                        valueKey="key"
                        {...props}
                    />;
                

                default:
                    return <FieldInput key={name} name={name} label={label} {...props} />
            }
        })
    );


    const clearForm = () => {
        setFormValue(defaultValue);
    };


    const cleanErrors = () => {
        formRef.cleanErrors();
    };


    return (
        <S.FormModal
            {...{show, onClose}}
            title={modify 
                ? "Modify payment details"
                : "Add new payment details"
            }
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={true}
            onSuccessClose={false}
            successButton="Save changes"
            loading={loading}
            keyboard={false}
            onSuccess={handleFormSubmit}
            onClose={() => {
                onClose(false);
            }}
            onExited={() => {
                clearForm();
            }}
        >

                <Form
                    ref={ref => formRef = ref}
                    model={formModel}
                    formValue={formValue}
                    onChange={setFormValue}
                >
                    <S.FlexBlock>
                        <S.FlexChild>
                            <S.Group>
                                <S.Label>Choose organization type</S.Label>
                                <FormControl
                                    name="ot_key"
                                    accepter={SelectPicker}
                                    data={organizationTypeList}
                                    labelKey="name"
                                    valueKey="ot_key"
                                    cleanable={false}
                                    searchable={false}
                                    disabled={modify}
                                    onChange={cleanErrors}
                                />
                            </S.Group>
                        </S.FlexChild>

                        <S.FlexChild>
                            <S.Group>
                                <S.Label>Payment method</S.Label>
                                <FormControl
                                    name="pm_key"
                                    accepter={SelectPicker}
                                    data={paymentMethodList}
                                    labelKey="name"
                                    valueKey="pm_key"
                                    cleanable={false}
                                    searchable={false}
                                    disabled={modify}
                                    disabledItemValues={formValue.ot_key === 2 ? [PAYMENT_METHOD_HAWALA, PAYMENT_METHOD_MISC] : []}
                                    onChange={(value) => {
                                        setLocalPaymentMethodKey(value);
                                        cleanErrors();
                                    }}
                                />
                            </S.Group>
                        </S.FlexChild>

                    </S.FlexBlock>

                    <Spacer size={30}/>

                    <S.FormColumns>
                        {setFormFields(formValue.ot_key, formValue.pm_key)
                            .map((fieldsGroup, index, arr) => {
                                if (!fieldsGroup.length)
                                    return null;
                                
                                return (
                                    <>
                                        {index > 0 && arr[0].length > 0 && 
                                            <Divider style={{width: 0}} vertical />
                                        }
                                        <S.FormColumn colWidth="50%">
                                            {renderFields(fieldsGroup, formValue)}
                                        </S.FormColumn>
                                    </>
                                )
                            })
                        }
                    </S.FormColumns>
                </Form>
        </S.FormModal>
    )
};

