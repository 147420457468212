import React, {useMemo, useState, useEffect} from "react";
import TrunksRefactored from "./TrunksRefactored2";
import {Badge, Icon, Tooltip, Whisper, Checkbox} from "rsuite";
import AllocatedNumbersRefactored from "./AllocatedNumbers/AllocatedNumbersRefactored2";
import {Header} from "./styled";
import {DEFAULT_PER_PAGE_SMALL, transformed_service_data, transformed_service_reverse_data} from "../../../const";
import {formatDateApi, getManagedServices} from "utils";
import { usePrevious } from "hooks";
import ColorBadge from "../../../components/base/ColorBadge";
import styled from "styled-components";


export default (
    {
        trunks = [],
        service,
        account,
        isMobile,
        accountId,
        activeTab,
        trunksInited,
        trunksLoading,
        currentTrunkId,
        currentSupplier,
        suppliersPlanList,
        suppliersPrefixList,
        suppliersPrefixListСount,
        suppliersPrefixListPage,
        suppliersPrefixListPerPage,
        suppliersPrefixLoading,
        suppliersPrefixDropdownList,
        
        raterc_list,
        paymentTermsList,
        ipOptionList,
        globalSettings,
        allocatedNumbers,
        allocatedNumbersPage,
        allocatedNumbersCount,
        allocatedNumbersPerPage,
        allocatedNumbersLoading,
        allocatedNumbersTotalCount,
        accountServicePlanList,

        trunkDownloadNumbers,

        createSupplierPlan,
        modifySupplierPlan,
        removeSupplierPlan,

        setCurrentTrunkId,
        unsetTrunksInited,
        getAccountTrunk,

        createTrafficLimit,
        modifyTrafficLimit,
        removeTrafficLimit,
   
        setEmptyPrefixes,

        setCurrentRangeNumber,
        getSupplierPrefixes,
        getSupplierPlanList,
        getSupplierPrefixesDropdown,

        rerouteStatusList
    }
) => {

    const [showAllocatedNumbersModal, setShowAllocatedNumbersModal] = useState(false);
    const [showClosedTrunks, setShowClosedTrunks] = useState(false);
    const [numbersTableReadonly, setNumbersTableReadonly] = useState(false);
    const [savedParams, setSavedParams] = useState({});

    const transitDefaultLimit = useMemo(() => globalSettings ? globalSettings.sms_supplier_transit_trunk_limit_day : null, [globalSettings]);

    useEffect(() => {
        if (!currentTrunkId || !trunks.length) {
            setEmptyPrefixes();
        }
    }, [trunks, currentTrunkId])

    useEffect(() => {
        // if (!trunks.length ) {
        //     getTrunks(true);
        // } else 
        const filteredTrunks = trunks.filter((trunk) => !trunk.closed);
        if (filteredTrunks.length && !currentTrunkId) {
            setCurrentTrunkId(filteredTrunks[0]?.id)
        } else {
            // setCurrentTrunkId(null);
            // setEmptyPrefixes();
        }
    }, [trunks]);

    useEffect(() => {
        // getTrunks();
        if (currentSupplier?.supplier_id) {
            getSupplierPlanList(currentSupplier.supplier_id);

            // if (!trunks.length) {
                getTrunks();
            // }
        }

        return () => {
            setCurrentTrunkId(null);
            unsetTrunksInited();
        }
    }, [currentSupplier]);

    const getTrunks = (withNumbers=false) => {
        getAccountTrunk(currentSupplier?.supplier_id, "transit");

        // if (Object.keys(numbersParams).length && withNumbers && currentTrunkId) {
        //     const {group, filter, page, per_page, sort} = numbersParams;

        //     getAccountAllocatedNumbers(accountId, currentTrunkId, service, group, filter, page, per_page, sort)
        // }
    };
    

    const getNumbers = (group, filter, page, per_page, sort, first = false) => {
        if (currentTrunkId) {
            // setNumbersParams(params);
            const curPerPage = per_page || DEFAULT_PER_PAGE_SMALL;
            const params = {group, filter, page, per_page: curPerPage, sort};
            setSavedParams(params);
            // getAccountAllocatedNumbers(accountId, currentTrunkId, service, group, filter, page, curPerPage, sort);
            // sms.supplier_prefix:get_list

            // const savedSupplier = localStorage.getItem("CURRENT_SUPPLIER");

            let callback;
            
            if (!trunks.length) {// && savedSupplier === JSON.stringify(currentSupplier)) {
                callback = setEmptyPrefixes;
            }

            getSupplierPrefixes(currentTrunkId, filter, page, curPerPage, sort, true, callback);
        }
    };

    const setTrunk = (trunk_id) => {
        if (allocatedNumbersLoading) return;

        const activeTrunk = trunks.find((trunk) => trunk.id === trunk_id);
        const numbersTableReadonly = activeTrunk ? activeTrunk.closed: true;

        setCurrentTrunkId(trunk_id);
        // setNumbersTableReadonly(numbersTableReadonly);
    };

    const currentTrunk = trunks.find(trunk => trunk.id === currentTrunkId) || {};

    const columns = [
        {
            id: "trunk_type_name",
            label: "Trunk type",
            align: "center",
            value: ({plan}) => {
                const commentAdmin = plan?.comment_admin;
                const commentDialer = plan?.comment_dialer;

                return <ColumnDataWithIcon>
                    <span classname="name">{plan?.name}</span>
                    <span className="icon">
                        <Whisper
                            trigger="hover"
                            speaker={<Tooltip><div>Admin's comment: {commentAdmin}<br/><br/>Dialer's comment: {commentDialer}</div></Tooltip>}
                        >
                            <Icon icon="info"/>
                        </Whisper>
                    </span>
                </ColumnDataWithIcon>
            },
            minWidth: 180,
            flexGrow: 2
        },
        {
            id: "mdr_billing_status",
            label: "Status",
            align: "center",
            value: ({message_protocol, ip_list}) => {
                const statusList = ip_list.length ? ip_list.map(ip => ip.bound) : [];
              
                return statusList.map(status => {
                    const badgeColor = status ? "var(--color-success)" : "var(--color-error2)";

                    return message_protocol === "smpp" 
                        ? <ColorBadge badgeColor={badgeColor}></ColorBadge>
                        : <></>
                })
            },
            minWidth: 80,
            flexGrow: 1
        },
        {
            id: "login_list",
            label: "IPs",
            align: "center",
            value: ({ip_list}) => {
                const dataValues = ip_list && ip_list.slice(0, 3).map(value => {
                    return `${value?.login}@${value?.ip}`;
                }).join(", ");
                const tooltipData = ip_list ? ip_list.map(value => {
                    const badgeStyle = {marginTop: "2px", marginRight: "4px", width: "8px", height: "8px"};
                    const badgeColor = value?.bound ? "var(--color-success)" : "var(--color-error2)";
                    return <p><ColorBadge style={badgeStyle} badgeColor={badgeColor}/> {`${value?.login}@${value?.ip}`}</p>
                }) : "";

                if (ip_list && ip_list.length) {
                    return (
                        <Whisper
                            enterable
                            placement="auto"
                            speaker={
                                <Tooltip>{tooltipData}</Tooltip>
                            }
                        >
                            <span>{dataValues}{ip_list.length > 3 ? "..." : ""}</span>
                        </Whisper>
                    )
                }
                
                return "—";
            },
            minWidth: 180,
            flexGrow: 2
        },
        {
            id: "start_date",
            label: "Message protocol",
            dataKey: "message_protocol",
            align: "center",
            minWidth: 100,
            flexGrow: 1
        },
        {
            id: "start_date",
            label: "Date",
            // dataKey: "start_date",
            value: ({start_date, end_date}) => `${formatDateApi(start_date)}${end_date ? ` - ${formatDateApi(end_date)}`: ""}`,
            align: "center",
            minWidth: 100,
            flexGrow: 1
        },
        {
            id: "tariffication_all",
            dataKey: "tariffication_all",
            // showLabelOnCreate: true,
            label: "Bill all incoming SMS",

            value: ({plan}) => {
  
                return (
                    <StyledCheckbox 
                        disabled={true}
                        defaultChecked={plan.tariffication_all}
                        checked={plan.tariffication_all}
                    />
                )
            },
            width: 140,
            align: "center"
        },
        {
            id: "custom_ranges",
            dataKey: "custom_ranges",
            // showLabelOnCreate: true,
            label: "Custom ranges trunk",

            value: ({custom_ranges}) => {
                return (
                    <StyledCheckbox 
                        disabled={true}
                        defaultChecked={false}
                        checked={custom_ranges}
                    />
                )
            },
            width: 140,
            align: "center"
        }
    ];

    const mobileColumns = [
        {
            id: "supplier_plan_name",
            label: "Supplier Plan Name",
            value: (({supplier_plan_name, type}) => (
                    <div className={"table-two-staged__cell"}>
                        <div className="table-two-staged__cell-first">{`${type}`}</div>
                        <div className="table-two-staged__cell-second">{supplier_plan_name}</div>
                    </div>
                )
            ),
            minWidth: 150,
            flexGrow: 1
        },
    ];

    const customRangesTrunk = currentTrunk?.custom_ranges;

    return (
        <>

            <TrunksRefactored
                {...{
                    trunks,
                    columns,
                    service,
                    account,
                    isMobile,
                    activeTab,
                    mobileColumns,
                    trunksLoading,
                    currentTrunkId,
                    currentSupplier,
                    ipOptionList,
                    accountServicePlanList,
                    transitDefaultLimit,
                    allocatedNumbersLoading,
                    trunkDownloadNumbers,
                    createTrafficLimit,
                    modifyTrafficLimit,
                    removeTrafficLimit,

                    rerouteStatusList
                }}
                isTransitTrunks

                acc_key={accountId}
                trunk_id={currentTrunkId}
                account_name={account?.name}
                paymentTermsList={paymentTermsList}
                closedTrunksShow={showClosedTrunks}
                
                savedParams={savedParams}

                setTrunk={setTrunk}
                getTrunks={getTrunks}
                getNumbers={getNumbers}
                suppliersPlanList={suppliersPlanList}
                createSupplierPlan={createSupplierPlan}
                modifySupplierPlan={modifySupplierPlan}
                removeSupplierPlan={removeSupplierPlan}
                getSupplierPlanList={getSupplierPlanList}
                onAllocateNumbers={() => setShowAllocatedNumbersModal(true)}
                setCurrentTrunkId={setCurrentTrunkId}
                setClosedTrunksShow={() => setShowClosedTrunks(!showClosedTrunks)}
                setCurrentRangeNumber={setCurrentRangeNumber}
            />

            <Header>Prefixes</Header>

            <AllocatedNumbersRefactored
                {...{
                    activeTab,
                    account,
                    service,    
                    // currentRangeNumber,
                    allocatedNumbers,
                    allocatedNumbersLoading,
                    allocatedNumbersCount,
                    allocatedNumbersTotalCount,
                    allocatedNumbersPage,
                    allocatedNumbersPerPage,
                    showAllocatedNumbersModal,
                    customRangesTrunk,
                    // getTrunkNumberCsvById,

                    currentSupplier,
                    suppliersPrefixList,
                    suppliersPrefixListСount,
                    suppliersPrefixListPage,
                    suppliersPrefixListPerPage,
                    suppliersPrefixLoading,
                    suppliersPrefixDropdownList,

                    trunkDownloadNumbers,

                    trunksLoading,
                    trunks,
                    trunksInited,
                    raterc_list,

                    createTrafficLimit,
                    modifyTrafficLimit,
                    removeTrafficLimit
                }}

                isTransitTrunks

                trunk_id={currentTrunkId}
                closeAllocatedNumbersModal = {() => setShowAllocatedNumbersModal(false)}
                getSupplierPrefixes={getSupplierPrefixes}
                getSupplierPrefixesDropdown={getSupplierPrefixesDropdown}
                sp_key={currentTrunk.sp_key}
                sp_auth={currentTrunk.sp_auth}
                spOtpType={currentTrunk.sp_otp_type}
                acc_key={accountId}
                account_name={account.name}
                getNumbers={getNumbers}
                readonly={numbersTableReadonly}
                getTrunks={() => getTrunks(true)}
            />

        </>
    )
}


const StyledCheckbox = styled(Checkbox)`
    && {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    && .rs-checkbox-wrapper {
        top: 2px !important;
    }
`;

const ColumnDataWithIcon = styled.div`
    && .icon {
        margin-left: 10px;
    }
`;