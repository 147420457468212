import {checkVersionAxios, requestAxios, requestAxiosWithHeaders, requestFormDataAxios} from '.';
import {getManagedServices, responseErrorToString} from "utils";
import {Alert} from 'rsuite';
import axios from "axios";
import {pageIdLogObject} from "../utils/pageIdLogger";
import {APP_VERSION} from "../index";
import store from '../store';
import {APP_TYPE_CLIENT, transformed_service_reverse_data} from "../const";

import React from "react";


const setPageId = () => {
    const currentPage = pageIdLogObject.pageMatch.path;
    const tab = pageIdLogObject.lastTab;

    return !pageIdLogObject.withTab
        ? currentPage
        : `${currentPage}${tab ? '/' + tab : ''}`
}


export const api = async (method = '', params = {}, processErrorManual, cancelToken, withHeaders = false) => {
    const state = store.getState();
    const servicePath = 'auth' in state && state.auth.appType !== APP_TYPE_CLIENT
        ? (state.auth.service ? '/voice' : '/sms')
        : '';
    
    const transformedService = state.auth.service in transformed_service_reverse_data 
        && transformed_service_reverse_data[state.auth.service];

    // Need to think a little more
    // if (!managedServiceList.includes(transformedService)) {
    //     return await Promise.reject();
    // }
    
    const serviceDialer = 'auth_dialer' in state && state.auth_dialer.serviceDialer;
    const serviceAdmin =  'auth' in state && state.auth.service;
    const service = 'auth' in state && state.auth.appType !== APP_TYPE_CLIENT ? serviceAdmin : serviceDialer;
    const methodAllocationPrefixList = ["allocation:", "sms.allocation:", ":allocate"];

    const paramsCloned = {...params};
    const config = {
        headers: {"X-App-Version": 'atx_admin/' + APP_VERSION}
    };

    // cancelToken as f argument is deprecated. use params property "cancelToken" instead
    if (cancelToken) {
        config.cancelToken = cancelToken.token;
    }

    if (paramsCloned.cancelToken) {
        config.cancelToken = paramsCloned.cancelToken.token;
        delete paramsCloned.cancelToken;
    }

    const requestApi = withHeaders ? requestAxiosWithHeaders : requestAxios;

    return requestApi.post(
        generateApiUrl({method, path: servicePath}),
        {
            jsonrpc: "2.0",
            method,
            params: paramsCloned,
            page_id: setPageId(),
            id: null
        },
        config
    )
    .then(response => {
        if (axios.isCancel(response))
            return {...response, cancelled: true};

        // const result = _.get(response, 'data.result', {});
        const result = response?.data?.result || {};

        if (methodAllocationPrefixList.find(prefix => method.startsWith(prefix) || method.endsWith(prefix))) {
            result.hash = response.data.hash;
        }

        return (response && response.data && response.data.result) || {}
    })
        .catch((error) => {
            if (error && !error.response) {
                return error;
            }

            if (processErrorManual) {
                return error.response.data;
            } else {
                if (error.response && error.response.data && error.response.data.error && error.response.data.error.reason_code !== null) {
                    Alert.error(responseErrorToString(error.response.data.error, error.response.status));
                } else {
                    if (error.response && error.response.data && error.response.data.error && error.response.data.error && 
                        error.response.data.error.data && Array.isArray(error.response.data.error.data) && error.response.data.error.data.length) {
                        Alert.error(error.response.data.error.data.join("\n"));
                    } else {
                        Alert.error(error.response.data.error.message);
                    }
                }

                if ( error.response && error.response.data.error.reason_code &&
                    methodAllocationPrefixList.find(prefix => method.startsWith(prefix) || method.endsWith(prefix))
                 ) {
                    const data = error.response.data.error;
                    return {
                        ...data,
                        hash: error.response.data.hash
                    };
                }

                if (error.response.status === 403) {
                    Alert.error("Need captcha");

                    if (error.response)
                        return error?.response?.data;
                }

                if (!error.response || error.response.status !== 503) {
      
                    if (error.response)
                        return error?.response?.data;
                }
            }
        })
};




export const apiFormData = (method = '', params = {}, processErrorManual) => {
    const state = store.getState();

    const servicePath = 'auth' in state && state.auth.appType !== APP_TYPE_CLIENT
        ? (state.auth.service ? '/voice' : '/sms')
        : '';

    const serviceDialer = 'auth_dialer' in state && state.auth_dialer.serviceDialer;
    const serviceAdmin =  'auth' in state && state.auth.service;
    const service = 'auth' in state ? state.auth.appType !== APP_TYPE_CLIENT ? serviceAdmin : serviceDialer : null;

    const methodAllocationPrefixList = ["allocation:", "sms.allocation:", ":allocate"];

    const paramsCloned = {...params};

    const reasonsCodesWithData = [
        "csv_validation"
    ];

    return requestFormDataAxios.post(
        generateApiUrl({method, path: servicePath}),
        {
            jsonrpc: "2.0",
            method,
            params: paramsCloned,
            page_id: setPageId(),
            id: null
        }
    )
    // .then(response => (response && response.data && response.data.result) || {})
    .then(response => {
        // const result = _.get(response, 'data.result', {});
        const result = response?.data?.result || {};

        if ( result.reason_code &&
            methodAllocationPrefixList.find(prefix => method.startsWith(prefix) || method.endsWith(prefix)) 
        ) {
            result.hash = response.data.hash;
        }

        return result
    })
    .catch((error) => {

        if (error && !error.response) {
            return error;
        }
        if (processErrorManual) {
            return error.response.data;
        } else {
            if (error.response && !error.response.status !== 503) {
                if (error.response.data && error.response.data.error 
                    && reasonsCodesWithData.includes(error.response.data.error.reason_code)
                    && !!Object.keys(error.response.data.error?.data).length
                ) {
                    const reasonCodeData = error.response.data.error.data;
                    const errorMessage = (
                        <div>
                            {reasonCodeData.validation_error || error} at line {reasonCodeData.line_number}.
                            <br />
                            Wrong line: {reasonCodeData.line_str}
                        </div>
                    );
                    Alert.error(errorMessage);
                } else {
                    Alert.error(error.response.data.error.message);
                }
            } else {
                Alert.error(responseErrorToString(error.response.data.error, error.response.status));
            }
        }
    })
};


export const generateApiGetUrl = ({method, path = '', params = {}}) => {
    const strParams = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    return `${process.env.REACT_APP_API_URL}${path}?method=${method}${strParams && '&'}${strParams}`
}


export const generateApiUrl = ({method, path = ''}) => {
    return `${process.env.REACT_APP_API_URL}${path}?method=${method}`
};



export const getCsvResponse = (method, params) => {
    const state = store.getState();
    const servicePath = 'auth' in state &&  state.auth.appType !== APP_TYPE_CLIENT
        ? (state.auth.service ? '/voice' : '/sms')
        : '';

    return requestAxios.post(
        generateApiUrl({method, path: `${servicePath}${'/csv'}`}),
        // generateApiUrl({method, path: '/csv'}),
        {
            jsonrpc: '2.0',
            method,
            params,
            page_id: setPageId(),
            id: null,
        },
        {
            responseType: 'text/csv',
            headers: {
                "X-App-Version": 'atx_admin/' + APP_VERSION,
            }
        }

    )
    .then( response => (response && response.data) )
    .catch( e => null )
};




export const getFileResponse = (method, params, type = 'csv') => {
    const state = store.getState();
    const servicePath = 'auth' in state && state.auth.appType !== APP_TYPE_CLIENT
        ? (state.auth.service ? '/voice' : '/sms')
        : '';

    return requestAxios.post(
        generateApiUrl({method, path: `${servicePath}${'/' + type}`}),
        // generateApiUrl({method, path: '/' + type}),
        {
            jsonrpc: '2.0',
            method,
            params,
            page_id: setPageId(),
            id: null,
        },
        {
            responseType: 'blob',
            headers: {
                "X-App-Version": 'atx_admin/' + APP_VERSION,
            }
        }

    )
    .then( response => (response && response.data) )
    .catch( e => null )
};




export const apiWithHeaders = (method, params) => {
    const state = store.getState();
    const servicePath = 'auth' in state && state.auth.appType !== APP_TYPE_CLIENT
        ? (state.auth.service ? '/voice' : '/sms')
        : '';

    return requestAxiosWithHeaders.post(
        generateApiUrl({method, path: `${servicePath}/headers`}),
        {
            jsonrpc: '2.0',
            params,
            method,
            page_id: setPageId(),
            id: null
        },
        {headers: {
                "X-App-Version": 'atx_client/' + APP_VERSION,
                httpAgent: 123
            }})
        .then(response => (response && response.data && response.data.result) || {})
        .catch(e => e)
};




export const getAppVersion = () => {
    return checkVersionAxios.get(`/meta.json?timestamp=${Date.now()}`)
        .then( (response) => response && response.data && response.data.version
            ? response.data
            : null
        )
        .catch( e => null )
};