import React, {useEffect, useRef, useState, useCallback} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import styled from 'styled-components';
import {Icon} from 'rsuite';

import LiveCallsTable from './LiveCallsTable';
import LiveCallsFilters from './LiveCallsFilters';
import LiveCallsApiFilters from './LiveCallsApiFilters';
import {
    TWO_STAGE_DEFAULT_PER_PAGE_DIALER,
    LOCAL_STORAGE_LIVE_CALLS_COLUMNS_DIALER,
    LOCAL_STORAGE_LIVE_CALLS_FILTERS_DIALER,
    LOCAL_STORAGE_LIVE_CALLS_PER_PAGE_DIALER,
    MIN_PER_PAGE_DIALER
} from '../../const';
import PageHeader from '../../components/base/PageHeader';
import {FlexGridItem, FlexGrid} from '../../components/base/FlexGrid';
import { Spacer } from '../../components/base/Spacer';
import {ButtonPrimary} from '../../components/base/BaseButton';
import m from "../../definedMessages";
import {LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE} from "../../const/localStorageKeys";
import LiveCallsAutoUpdateForm from "../../pages/LiveCalls/LiveCallsAutoUpdateForm";
import Page from "../../components/base/Page";



class LiveCalls extends React.Component {
    constructor(props) {
        super(props);
        this.filter = {number_destination: ''};
        this.filterApi = {test_calls: false};

        this.curPerPage = null;
        this.curPage = null;
        this.isTest = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).session.is_test : false;
        this.formatMessage = props.intl.formatMessage.bind(props.intl);
        const savedAutoUpdate = localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE);
        const timerValue = savedAutoUpdate && !isNaN(savedAutoUpdate) && parseFloat(savedAutoUpdate) <= 100000
            ? savedAutoUpdate
            : null;
        this.state = {
            show: false,
            searchLoading: false,
            savedTableFilter: JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_FILTERS_DIALER)),
            savedPerPage: JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_PER_PAGE_DIALER)),
            savedTableColumns: JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_COLUMNS_DIALER)),
            resizedWidth: window.innerWidth,
            paginatedData: [],
            numbersCount: 0,

            autoUpdateFormValue: {
                timer: timerValue || null
            },
        };

        this.updateDataTimer = null;

        this.columns = [
            {
                id: 'a_number', 
                label: this.formatMessage(m.aNumber),
                dataKey: 'a_number', 
                flexGrow: 2,
                minWidth: 120,
                align: 'center',
                value: ({a_number, a_subdestination_name}) => {
                    return (
                        <div className="table-two-staged__cell">
                            <div className="table-two-staged__cell-first">{a_number}</div>
                            <div className="table-two-staged__cell-second">{a_subdestination_name}</div>
                        </div>
                    )
                }
            },
            {
                id: 'b_number', 
                label: this.formatMessage(m.bNumber),
                dataKey: 'b_number', 
                flexGrow: 2,
                minWidth: 120,
                align: 'center',
                value: ({b_number, b_subdestination_name}) => {
                    return (
                        <div className="table-two-staged__cell">
                            <div className="table-two-staged__cell-first">{b_number}</div>
                            <div className="table-two-staged__cell-second">{b_subdestination_name}</div>
                        </div>
                    )
                }
            },
            {
                id: 'range',
                label: this.formatMessage(m.range),
                dataKey: 'range',
                flexGrow: 4,
                minWidth: 200,
                align: 'center',
                value: ({range, rate_term}) => {
                    return (
                        <div className="table-two-staged__cell">
                            <div className="table-two-staged__cell-first">{range}</div>
                            <div className="table-two-staged__cell-second">Rate: {rate_term}</div>
                        </div>
                    )
                }
            },
            {
                id: 'term',
                label: `${this.state.resizedWidth > 1366 ? this.formatMessage(m.terminationPoint) : this.formatMessage(m.termPoint)}`,
                flexGrow: 2,
                minWidth: 120,
                align: 'center',
                value: ({ip_term, port_term})=> {
                    return (
                        <div className="table-two-staged__cell">
                            <div className="table-two-staged__cell-single">{`${ip_term}:${port_term}`}</div>
                        </div>
                    )
                },
            },
            {
                id: 'duration',
                label: this.formatMessage(m.duration),
                dataKey: 'duration',
                flexGrow: 1,
                minWidth: 80,
                align: 'center',
                value: ({duration})=> {
                    return (
                        <div className="table-two-staged__cell">
                            <div className="table-two-staged__cell-single">{duration}</div>
                        </div>
                    )
                },
            },
        ]
    }

    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };

    componentDidMount() {
        const {savedPerPage} = this.state;
        const {getLiveCalls} = this.props;

        this.curPerPage = savedPerPage;
        getLiveCalls();
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps) {
        const {live_calls_list} = this.props;
        if (prevProps.live_calls_list !== live_calls_list) {
            this.setState({paginatedData: this.getPaginatedData(live_calls_list)});
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        if (this.updateDataTimer) {
            clearTimeout(this.updateDataTimer);
            this.updateDataTimer = null;
        }
    }

    onChangeApiFilters = (filtersObj) => {
        const {getLiveCalls, live_calls_list} = this.props;
        this.curPage = 1;
        this.filterApi = filtersObj;
        getLiveCalls(!!filtersObj.test_calls);
        this.setState({
            paginatedData: this.getPaginatedData(live_calls_list)
        });
    }

    onChangeFilters = (filtersObj) => {
        const {searchLoading} = this.state;
        const {live_calls_list} = this.props;

        if (!searchLoading) this.setState({searchLoading: true});

        const intervalTimer = () => {
            setTimeout(() => {
                this.setState({searchLoading: false});
            }, 500);
        };

        this.filter = filtersObj;

        this.setState({paginatedData: this.getPaginatedData(live_calls_list)});
        localStorage.setItem(LOCAL_STORAGE_LIVE_CALLS_FILTERS_DIALER, JSON.stringify(this.filter));

        intervalTimer();
    };

    onChangePerPage = (perPage) => {
        const {searchLoading} = this.state;
        const {live_calls_list} = this.props;

        if (perPage)
            this.setState({savedPerPage: null});
        else
            this.setState({savedPerPage: this.curPerPage});

        if (!searchLoading) this.setState({searchLoading: true});

        const intervalTimer = () => {
            setTimeout(() => {
                this.setState({searchLoading: false});
            }, 500);
        };
        const per_page = Number(perPage) || this.curPerPage;

        per_page <= 0 ? this.curPerPage = MIN_PER_PAGE_DIALER : per_page > 100 ? this.curPerPage = 100 : this.curPerPage = per_page;

        this.setState({paginatedData: this.getPaginatedData(live_calls_list)});
        localStorage.setItem(LOCAL_STORAGE_LIVE_CALLS_PER_PAGE_DIALER, JSON.stringify(this.curPerPage));

        intervalTimer();
    };

    onChangePage = (pageNumber) => {
        const {searchLoading} = this.state;
        const {live_calls_list} = this.props;

        if (!searchLoading) this.setState({searchLoading: true});

        const intervalTimer = () => {
            setTimeout(() => {
                this.setState({searchLoading: false});
            }, 1000);
        };
        intervalTimer();
        this.curPage = pageNumber;


        this.setState({paginatedData: this.getPaginatedData(live_calls_list)});

    };

    getPaginatedData = (data) => {
        const {savedPerPage, savedTableFilter} = this.state;

        const page = this.curPage || 1;
        const per_page = this.curPerPage || savedPerPage || TWO_STAGE_DEFAULT_PER_PAGE_DIALER;
        const filterValue = savedTableFilter && savedTableFilter.number_destination
            ? savedTableFilter.number_destination
            : this.filter && this.filter.number_destination
                ? this.filter.number_destination
                : '';
        const dataValue = data.filter((value) => {
            return value && (
                (value.a_number && value.a_number.includes(filterValue))
                || (value.b_number && value.b_number.includes(filterValue))
                || (value.a_subdestination_name && value.a_subdestination_name.includes(filterValue))
                || (value.b_subdestination_name && value.b_subdestination_name.includes(filterValue))
                || (value.termination_point && value.termination_point.includes(filterValue))
            )

        });

        this.setState({numbersCount: dataValue.length});

        return dataValue ? dataValue.slice(page * per_page - per_page, page * per_page) : [];
    };

    onSaveColumnsTable = (idListToSave) => {
        localStorage.setItem(LOCAL_STORAGE_LIVE_CALLS_COLUMNS_DIALER, JSON.stringify(idListToSave));
    };

    onUpdateData = () => {
        const {getLiveCalls} = this.props;
        getLiveCalls(this.filterApi.test_calls || false);
    };

    onTableDataUpdate = () => {
        const {autoUpdateFormValue} = this.state;
        if (!autoUpdateFormValue.timer && this.updateDataTimer) {
            clearTimeout(this.updateDataTimer);
            return;
        }

        if (this.updateDataTimer) {
            clearTimeout(this.updateDataTimer);
        }

        this.updateDataTimer = setTimeout(() => {
            this.onUpdateData()
        }, autoUpdateFormValue.timer * 1000);
    };

    onChangeAutoUpdateValue = (value) => {
        const timer = isNaN(value.timer) || !parseFloat(value.timer) ? "" : value.timer;
        localStorage.setItem(LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE, timer);
        this.setState(() => ({autoUpdateFormValue: {timer}}), this.onTableDataUpdate);
    };

    render() {
        const {searchLoading, savedTableFilter, savedPerPage, paginatedData, savedTableColumns, numbersCount, autoUpdateFormValue} = this.state;
        const {loading, service} = this.props;

        const onUpdateData =  this.onUpdateData;
        const onChangePage = this.onChangePage;
        const onChangePerPage = this.onChangePerPage;
        const onSaveColumnsTable = this.onSaveColumnsTable;
        const onChangeAutoUpdateValue = this.onChangeAutoUpdateValue;
        const onTableDataUpdate = this.onTableDataUpdate;

        return (
            <Page>
                <PageHeader showService={false} title={<FormattedMessage id="liveCalls.liveCalls.header" defaultMessage="LIVE CALLS" />}/>

                <FlexGrid justify="space-between">
                    <FlexGridItem>
                        <FlexGrid>
                            <FlexGridItem>
                                <LiveCallsFilters
                                    onChangeFilters={this.onChangeFilters}
                                    loading={loading || searchLoading}
                                    {...{savedTableFilter}}
                                />
                            </FlexGridItem>
                            {!this.isTest &&
                                <FlexGridItem>
                                    <LiveCallsApiFilters
                                        onChangeFilters={this.onChangeApiFilters}
                                    />
                                </FlexGridItem>
                            }
                        </FlexGrid>
                    </FlexGridItem>
                    <FlexGridItem>
                        <StyledUpdateBlock>
                            <LiveCallsAutoUpdateForm
                                formDefaultValue={autoUpdateFormValue}
                                onChange={onChangeAutoUpdateValue}
                            />
                            <ButtonPrimary
                                onClick={onUpdateData}
                                loading={loading}
                            >
                                <StyledActionButtonIcon icon='refresh'/>
                                {this.formatMessage(m.updateData)}
                            </ButtonPrimary>
                        </StyledUpdateBlock>
                    </FlexGridItem>
                </FlexGrid>
                
                <Spacer/>

                <LiveCallsTable
                    count={numbersCount || 0}
                    data={paginatedData.length ? paginatedData : []}
                    page={this.curPage || 1}
                    per_page={this.curPerPage || savedPerPage || TWO_STAGE_DEFAULT_PER_PAGE_DIALER}
                    loading={loading || searchLoading}
                    pickedColumns={this.columns}
                    rowHeight={46}
                    twoStaged={true}
                    onDataUpdated={onTableDataUpdate}
                    {...{
                        onChangePage,
                        onChangePerPage,
                        onSaveColumnsTable,
                        savedTableColumns,
                        savedPerPage,
                    }}
                />
            </Page>
        );
    }
}

export default injectIntl(LiveCalls)

const StyledActionButtonIcon = styled(Icon)`
    margin-right: 5px;
    
    .rtl & {
        margin-left: 5px;
        margin-right: 0;
    }
`;

const StyledUpdateBlock = styled.div`
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: -10px;
    
    & > * {
        margin-bottom: 10px;
    }
    
    .rs-form {
        margin-right: 20px;
    }
    
    @media (min-width: 768px) {
        text-align: right;
    }
`;