import React, {useMemo, useEffect, useState} from "react";
import PaymentsRequestSection from "./PaymentsRequestSection";
import PaymentsListSection from "./PaymentsListSection";
import PaymentsTrafficReportsSection from "./PaymentsTrafficReportsSection";
import {Spacer} from "../../components/base/Spacer";
import {LOCAL_STORAGE_USER_INFO_DIALER, transformed_service_reverse_data} from "../../const";
import ModalRequestPayment from "./ModalRequestPayment";
import ModalAddPayment from "../PaymentDetails/ModalAddPaymentDetail";
import Page from "../../components/base/Page";
import {checkPermissionsFor} from "store/storeHelpers";
import {PAYMENT_DETAIL_CRYPTO_LIST_API} from "const/apiMethods";


export default ({
    service,
    permissions,

    paymentItems,
    paymentItemsLoading,

    paymentRequestItems,
    paymentRequestLoading,
    
    paymentTrafficReportsItems,
    paymentTrafficReportsLoading,

    paymentDetailsItems,
    dialerInfo,
    accountInfo,
    accountJoinList,

    organizationTypeList,
    cryptoList,
    countryList,
    currencyList,
    paymentMethodList,
    globalSettings,

    // API methods
    getAccount,
    getPaymentRequestList,
    requestPayment,
    cancelPaymentRequest,
    getPaymentList,
    getTrafficReportsList,
    getPaymentDetailsList,
    getPaymentDetailsCryptoList,
    createPaymentDetails,
    modifyPaymentDetails,
    setReadyForPayment,
}) => {
    // why do we store an account_id information only in the LocalStorage??
    let storagedAccountInfo = localStorage.getItem(LOCAL_STORAGE_USER_INFO_DIALER);
    storagedAccountInfo = storagedAccountInfo !== null ? JSON.parse(storagedAccountInfo) : {session: false};

    // const accountSession = storagedAccountInfo && storagedAccountInfo.session ? storagedAccountInfo.session : {};

    const [showModalRequestPayment, setShowModalRequestPayment] = useState(false);
    const [requestPaymentFormValue, setRequestPaymentFormValue] = useState({amount: 0});
    const [showModalAddPaymentDetail, setShowModalAddPaymentDetail] = useState(false);
    const [dropdownVisibility, setDropdownVisibility] = useState(true);
    const [amountLimitMin, setAmountLimitMin] = useState(0.01);
    const [pickedCurrencyKey, setPickedCurrencyKey] = useState(null);

    const currentAccountJoinList = useMemo(() => {
        return accountJoinList;
    }, [accountJoinList]);

    const billingCurrencyList = useMemo(() => {
        return accountInfo?.billing_currency_list;
    }, [accountInfo]);

    useEffect(() => {
        if (checkPermissionsFor(PAYMENT_DETAIL_CRYPTO_LIST_API)) {
            getPaymentDetailsCryptoList();
        }
    }, [permissions]);


    useEffect(() => {
        let pickedCurKey = null;
        const curKeyAllEqual = accountJoinList.every(item => item?.cur_key === accountJoinList[0]?.cur_key);

        if (accountJoinList.length === 2 && curKeyAllEqual) {
            pickedCurKey = accountJoinList[0]?.cur_key;
        } else if (accountJoinList.length === 1) {
            pickedCurKey = accountJoinList[0]?.cur_key;
        }

        setPickedCurrencyKey(pickedCurKey);
    }, [accountJoinList]);

    // effect: get list on component mount

    // set payment detail amount min limit
    useEffect( () => {
        if (!requestPaymentFormValue.payment_detail_id || !paymentMethodList.length || !paymentDetailsItems.length) {
            setAmountLimitMin(0.01);
            return;
        }
        const currentPaymentDetail = paymentDetailsItems.find(item => item.id === requestPaymentFormValue.payment_detail_id);
        if (!currentPaymentDetail) {
            setAmountLimitMin(0.01);
            return;
        }
        const currentPaymentMethod = paymentMethodList.find(item => item.pm_key === currentPaymentDetail.pm_key);
        const currentPaymentMethodLimit = currentPaymentMethod && currentPaymentMethod.limit
            ? currentPaymentMethod.limit
            : 0.01;

        setAmountLimitMin(currentPaymentMethodLimit);
    }, [requestPaymentFormValue, paymentMethodList, paymentDetailsItems] );

    const openModalRequestPayment = () => {
        setShowModalRequestPayment(true);
    };

    const closeModalRequestPayment = () => {
        setShowModalRequestPayment(false);

        const curKeyAllEqual = accountJoinList.every(item => item?.cur_key === accountJoinList[0]?.cur_key);
        
        if (accountJoinList.length === 2 && !curKeyAllEqual) {
            setPickedCurrencyKey(null);
        }
    };

    const handleShowModalAddPaymentDetail = () => {
        setShowModalAddPaymentDetail(true);
        setDropdownVisibility(false);
    };

    const closeModalAddPaymentDetail = (value) => {
        if (value) {
            setRequestPaymentFormValue({
                ...requestPaymentFormValue,
                payment_detail_id: value.payment_detail.id
            });
        }
        setShowModalAddPaymentDetail(false);
        setDropdownVisibility(true);
    };

    const changeReadyForPayment = (readyForPayment, amount) => {
        setReadyForPayment(+readyForPayment - amount);
    };

    const {currency_name: currencyName} = accountInfo || {};
    return (
        <Page>
            <PaymentsRequestSection
                {...{
                    permissions,
                    paymentRequestItems, 
                    paymentRequestLoading, 
                    cancelPaymentRequest,
                    getPaymentRequestList,
                    openModalRequestPayment,
                    currencyName,
                    setReadyForPayment,
                    readyForPayment: 0,
                    updateAccount: getAccount
                }}
            />
            
            <Spacer size={30}/>
            <PaymentsListSection
                {...{
                    permissions,
                    paymentItems,
                    paymentItemsLoading,
                    currencyName,
                    getPaymentList
                }}
            />

            <Spacer size={30}/>
            <PaymentsTrafficReportsSection
                {...{
                    service,
                    permissions,
                    accountJoinList,
                    paymentTrafficReportsItems, 
                    paymentTrafficReportsLoading,
                    openModalRequestPayment,
                    currencyName,
                    globalSettings,
                    getTrafficReportsList,
                }}
            />


            <ModalRequestPayment
                show={showModalRequestPayment}
                onClose={closeModalRequestPayment}
                formValue={requestPaymentFormValue}
                setFormValue={setRequestPaymentFormValue}
                amountLimitMin={amountLimitMin}
                onSuccess={(readyForPayment, amount) => {
                    changeReadyForPayment(readyForPayment, amount);
                    getAccount();
                }}
                {...{
                    service,
                    currencyList,
                    currencyName,
                    requestPayment,
                    accountJoinList,
                    pickedCurrencyKey,
                    setPickedCurrencyKey,
                    billingCurrencyList,
                    paymentDetailsItems, 
                    getPaymentRequestList,
                    getPaymentDetailsList,
                    dropdownVisibility,
                    handleShowModalAddPaymentDetail
                }}
            />

            {showModalAddPaymentDetail && <ModalAddPayment
                show={showModalAddPaymentDetail}
                onClose={closeModalAddPaymentDetail}
                accountJoinList={currentAccountJoinList}
                {...{
                    organizationTypeList,
                    cryptoList,
                    countryList,
                    currencyList,
                    paymentMethodList,
                    pickedCurrencyKey,
                    createPaymentDetails,
                    modifyPaymentDetails,
                    getPaymentDetailsList
                }}
                defaultFormValue={{pm_key: 1, ot_key: 1, cur_key: pickedCurrencyKey}}
            />}

        </Page>
    )
};