import React from 'react';
import {Form, Schema} from 'rsuite';
import CustomField from '../../../components/Form/CustomField';
import InputSearch from '../../../components/Form/InputSearch';
import FormHOC from '../../../hoc/FilltersForm';
import Checkbox from '../../../hoc/Checkbox';
import styled from 'styled-components';

const {StringType} = Schema.Types;

const filtersModel = Schema.Model({
    str: StringType().maxLength(40, 'The maximum is only 40 characters.'),
});

const Filters = ({onChange, value}) => {
    return (
        <>
            <Form layout="inline"
                  style={{marginBottom: '-25px'}}
                  onChange={onChange}
                  formDefaultValue={{
                      ...value,
                      show_unallocated_numbers: true,
                      show_allocated_numbers: true,
                      show_test_numbers: true,
                      show_block_allocation_numbers: true,
                      show_only_block_allocation_numbers: false
                  }}
                  model={filtersModel}
            >
                <span style={{position: 'relative', top: 8, margin: 0}}>Show numbers:</span>
                <Box><Checkbox name="show_unallocated_numbers" checked={value.show_unallocated_numbers} defaultChecked={true}>Unallocated</Checkbox></Box>
                <Box><Checkbox name="show_allocated_numbers" checked={value.show_allocated_numbers}  defaultChecked={true}>Allocated</Checkbox></Box>
                <Box><Checkbox name="show_test_numbers" checked={value.show_test_numbers}  defaultChecked={true}>Test</Checkbox></Box>
                <Box><Checkbox name="show_block_allocation_numbers"  checked={value.show_block_allocation_numbers} defaultChecked={true}>Blocked</Checkbox></Box>
                <Box><Checkbox name="show_only_block_allocation_numbers" checked={value.show_only_block_allocation_numbers}  defaultChecked={false}>Only blocked</Checkbox></Box>
                <br/>
                <CustomField
                    name="str"
                    accepter={InputSearch}
                    errorPlacement="topRight"
                    placeholder="Number search"
                    style={{width: 220}}
                />
            </Form>
        </>
    );
};

export default FormHOC(Filters, filtersModel, 300);
const Box = styled.div`
    display: inline-block
    // div{
    //     display: inline-block
    // }
`;