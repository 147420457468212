import React from 'react';
import AccessListFiltersContainer from './AccessListFilters/AccessListFiltersContainerRefactored';
import {Header} from './styled';

const TabAccessListFilters = ({account_id, accessListLoading}) => {
    return (
        <>
            <Header>Access list mailing</Header>
            <AccessListFiltersContainer/>
        </>
    );
};

export default TabAccessListFilters;