import React, {useState, useEffect} from "react";
import TrunksRefactored from "./TrunksRefactored2";
import {Badge, Icon, Tooltip, Whisper, Checkbox} from "rsuite";
import AllocatedNumbersRefactored from "./AllocatedNumbers/AllocatedNumbersRefactored2";
import {DEFAULT_PER_PAGE_SMALL, transformed_service_data, transformed_service_reverse_data} from "../../../const";
import {formatDateApi, getManagedServices} from "utils";
import { usePrevious } from "hooks";
import ColorBadge from "../../../components/base/ColorBadge";
import styled from "styled-components";


export default (
    {
        trunks = [],
        service,
        account,
        isMobile,
        accountId,
        activeTab,
        permissions,
        trunksInited,
        trunksLoading,
        currentTrunkId,
        currentSupplier,
        suppliersPlanList,
        suppliersPrefixList,
        suppliersPrefixListСount,
        suppliersPrefixListPage,
        suppliersPrefixListPerPage,
        suppliersPrefixLoading,
        suppliersGroupList,
        
        supplierList,
        currencyList,
        paymentTermsList,
        ipOptionList,

        raterc_list,
        allocatedNumbers,
        allocatedNumbersPage,
        allocatedNumbersCount,
        allocatedNumbersPerPage,
        allocatedNumbersLoading,
        allocatedNumbersTotalCount,
        accountServicePlanList,

        trunkDownloadNumbers,
        groupDownloadNumbers,

        createSupplierPlan,
        modifySupplierPlan,
        removeSupplierPlan,

        setCurrentTrunkId,
        unsetTrunksInited,
        getAccountTrunk,

        createTrafficLimit,
        modifyTrafficLimit,
        removeTrafficLimit,
   
        setEmptyPrefixes,

        getSuppliers,
        showReasonHandler,
        setCurrentRangeNumber,
        getSupplierPrefixes,
        getSupplierGroups,
        getSupplierPlanList,

        rerouteStatusList
    }
) => {

    const [showAllocatedNumbersModal, setShowAllocatedNumbersModal] = useState(false);
    const [showClosedTrunks, setShowClosedTrunks] = useState(false);
    const [numbersTableReadonly, setNumbersTableReadonly] = useState(false);
    const [savedParams, setSavedParams] = useState({});

    useEffect(() => {
        if (!currentTrunkId || !trunks.length) {
            setEmptyPrefixes();
        }
    }, [trunks, currentTrunkId])

    useEffect(() => {
        // TODO: maybe this is not a solution
        if (currentTrunkId === null && trunks.length) {
            const firstTrunk = trunks[0];

            if (Object.keys(savedParams).length && firstTrunk?.id) {
                const {group, filter, page, per_page, sort} = savedParams;
                
                if (group === "groups") {
                    getSupplierGroups(firstTrunk.id, null, filter, sort);
                }
                //  else {
                //     getSupplierPrefixes(firstTrunk.id, filter, page, per_page, sort);
                // }
            }
            const filteredTrunks = trunks.filter((trunk) => !trunk.closed);
            setCurrentTrunkId(filteredTrunks[0]?.id);
        }
    }, [trunks]);

    useEffect(() => {
        getSuppliers();
    }, []);

    useEffect(() => {
        if (currentSupplier?.supplier_id) {
            getSupplierPlanList(currentSupplier.supplier_id);
            getTrunks();
        }

        return () => {
            setCurrentTrunkId(null);
            unsetTrunksInited();
        }
    }, [currentSupplier]);

    const getTrunks = (withNumbers=false) => {
        getAccountTrunk(currentSupplier?.supplier_id, "gan");

        if (Object.keys(savedParams).length && withNumbers && currentTrunkId) {
            const {group, filter, page, per_page, sort} = savedParams;
            
            if (group !== "groups") {
                getSupplierPrefixes(currentTrunkId, filter, page, per_page, sort);
            }
        }
    };
    

    const getNumbers = (group, filter, page, per_page, sort, first = false) => {
        if (currentTrunkId) {
            const params = {group, filter, page, per_page, sort};

            setSavedParams(params);
            const curPerPage = per_page || DEFAULT_PER_PAGE_SMALL;
            // getAccountAllocatedNumbers(accountId, currentTrunkId, service, group, filter, page, curPerPage, sort);
            //sms.supplier_prefix:get_list

            let callback;
            
            if (!trunks.length) {
                callback = setEmptyPrefixes;
            }

            if (group === "groups") {
                getSupplierGroups(currentTrunkId, null, filter, sort, callback);
                return;
            }

            getSupplierPrefixes(currentTrunkId, filter, page, curPerPage, sort, true, callback);
        }
    };

    const setTrunk = (trunk_id) => {
        if (allocatedNumbersLoading) return;

        const activeTrunk = trunks.find((trunk) => trunk.id === trunk_id);
        const numbersTableReadonly = activeTrunk ? activeTrunk.closed: true;

        setCurrentTrunkId(trunk_id);
        // setNumbersTableReadonly(numbersTableReadonly);
    };

    const currentTrunk = trunks.find(trunk => trunk.id === currentTrunkId) || {};

    const columns = [
        {
            id: "trunk_type_name",
            label: "Trunk type",
            align: "center",
            value: ({plan}) => {
                const commentAdmin = plan?.comment_admin;
                const commentDialer = plan?.comment_dialer;

                return <ColumnDataWithIcon>
                    <span classname="name">{plan?.name}</span>
                    {(commentAdmin || commentDialer) ? <span className="icon">
                        <Whisper
                            trigger="hover"
                            speaker={<Tooltip><div>Admin's comment: {commentAdmin}<br/><br/>Dialer's comment: {commentDialer}</div></Tooltip>}
                        >
                            <Icon icon="info"/>
                        </Whisper>
                    </span> : <></>}
                </ColumnDataWithIcon>
            },
            minWidth: 180,
            flexGrow: 2
        },
        {
            id: "mdr_billing_status",
            label: "Status",
            align: "center",
            value: ({message_protocol, ip_list}) => {
                const statusList = ip_list.length ? ip_list.map(ip => ip.bound) : [];
              
                return statusList.map(status => {
                    const badgeColor = status ? "var(--color-success)" : "var(--color-error2)";

                    return message_protocol === "smpp" 
                        ? <ColorBadge badgeColor={badgeColor}></ColorBadge>
                        : <></>
                })
            },
            minWidth: 80,
            flexGrow: 1
        },
        {
            id: "login_list",
            label: "IPs",
            align: "center",
            value: ({ip_list, ip_white_list, message_protocol}) => {
                let ipList = ip_white_list;

                if (message_protocol === "smpp") {
                    ipList = ip_list;
              
                    const dataValues = ipList && ipList.slice(0, 3).map(value => {
                        return `${value?.login}@${value?.ip}`;
                    }).join(", ");
                    const tooltipData = ipList ? ipList.map(value => {
                        const badgeStyle = {marginTop: "2px", marginRight: "4px", width: "8px", height: "8px"};
                        const badgeColor = value?.bound ? "var(--color-success)" : "var(--color-error2)";
                        return <p><ColorBadge style={badgeStyle} badgeColor={badgeColor}/> {`${value?.login}@${value?.ip}`}</p>
                    }) : "";

                    if (ipList && ipList.length) {
                        return (
                            <Whisper
                                enterable
                                placement="auto"
                                speaker={
                                    <Tooltip>{tooltipData}</Tooltip>
                                }
                            >
                                <span>{dataValues}{ipList.length > 3 ? "..." : ""}</span>
                            </Whisper>
                        )
                    }
                } else if (message_protocol === "http/https") {
                    const dataValues = ipList && ipList.slice(0, 3).join(", ");
                    const tooltipData = ipList ? ipList : "";

                    if (ipList && ipList.length) {
                        return (
                            <Whisper
                                enterable
                                placement="auto"
                                speaker={
                                    <Tooltip>{tooltipData}</Tooltip>
                                }
                            >
                                <span>{dataValues}{ipList.length > 3 ? "..." : ""}</span>
                            </Whisper>
                        )
                    }

                }
                
                return "—";
            },
            minWidth: 180,
            flexGrow: 2
        },
        {
            id: "start_date",
            label: "Message protocol",
            dataKey: "message_protocol",
            align: "center",
            minWidth: 100,
            flexGrow: 1
        },
        {
            id: "start_date",
            label: "Date",
            // dataKey: "start_date",
            value: ({start_date, end_date}) => `${formatDateApi(start_date)}${end_date ? ` - ${formatDateApi(end_date)}`: ""}`,
            align: "center",
            minWidth: 100,
            flexGrow: 1
        },
        {
            id: "tariffication_all",
            dataKey: "tariffication_all",
            // showLabelOnCreate: true,
            label: "Bill all incoming SMS",

            value: ({plan}) => {
  
                return (
                    <StyledCheckbox 
                        disabled={true}
                        defaultChecked={plan.tariffication_all}
                        checked={plan.tariffication_all}
                    />
                )
            },
            width: 140,
            align: "center"
        }
    ];

    const mobileColumns = [
        {
            id: "supplier_plan_name",
            label: "Supplier Plan Name",
            value: (({supplier_plan_name, type}) => (
                    <div className={"table-two-staged__cell"}>
                        <div className="table-two-staged__cell-first">{`${type}`}</div>
                        <div className="table-two-staged__cell-second">{supplier_plan_name}</div>
                    </div>
                )
            ),
            minWidth: 150,
            flexGrow: 1
        },
    ];

    return (
        <>

            <TrunksRefactored
                {...{
                    trunks,
                    columns,
                    service,
                    account,
                    isMobile,
                    activeTab,
                    mobileColumns,
                    trunksLoading,
                    currentTrunkId,
                    currentSupplier,
                    ipOptionList,
                    showReasonHandler,
                    accountServicePlanList,
                    allocatedNumbersLoading,
                    trunkDownloadNumbers,
                    createTrafficLimit,
                    modifyTrafficLimit,
                    removeTrafficLimit,

                    rerouteStatusList
                }}
                isGanTrunks

                acc_key={accountId}
                trunk_id={currentTrunkId}
                account_name={account?.name}
                closedTrunksShow={showClosedTrunks}
                
                savedParams={savedParams}

                getNumbers={getNumbers}
                setTrunk={setTrunk}
                getTrunks={getTrunks}
                supplierList={supplierList}
                getSuppliers={getSuppliers}
                paymentTermsList={paymentTermsList}
                suppliersPlanList={suppliersPlanList}
                createSupplierPlan={createSupplierPlan}
                modifySupplierPlan={modifySupplierPlan}
                removeSupplierPlan={removeSupplierPlan}
                getSupplierPlanList={getSupplierPlanList}
                onAllocateNumbers={() => setShowAllocatedNumbersModal(true)}
                setCurrentTrunkId={setCurrentTrunkId}
                setClosedTrunksShow={() => setShowClosedTrunks(!showClosedTrunks)}
                setCurrentRangeNumber={setCurrentRangeNumber}
            />

            <AllocatedNumbersRefactored
                {...{
                    activeTab,
                    account,
                    service,    
                    permissions,
                    // currentRangeNumber,
                    allocatedNumbers,
                    allocatedNumbersLoading,
                    allocatedNumbersCount,
                    allocatedNumbersTotalCount,
                    allocatedNumbersPage,
                    allocatedNumbersPerPage,
                    showAllocatedNumbersModal,
                    // getTrunkNumberCsvById,

                    currentSupplier,
                    suppliersPrefixList,
                    suppliersPrefixListСount,
                    suppliersPrefixListPage,
                    suppliersPrefixListPerPage,
                    suppliersPrefixLoading,
                    suppliersGroupList,

                    trunkDownloadNumbers,
                    groupDownloadNumbers,

                    trunksLoading,
                    trunks,
                    trunksInited,
                    raterc_list,

                    createTrafficLimit,
                    modifyTrafficLimit,
                    removeTrafficLimit,

                    showReasonHandler
                }}

                isGanTrunks

                trunk_id={currentTrunkId}
                closeAllocatedNumbersModal = {() => setShowAllocatedNumbersModal(false)}
                getSupplierPrefixes={getSupplierPrefixes}
                paymentTermsList={paymentTermsList}
                currencyList={currencyList}
                sp_key={currentTrunk.sp_key}
                sp_auth={currentTrunk.sp_auth}
                spOtpType={currentTrunk.sp_otp_type}
                acc_key={accountId}
                account_name={account.name}
                getNumbers={getNumbers}
                readonly={numbersTableReadonly}
                getTrunks={() => getTrunks(true)}
            />

        </>
    )
}

const StyledCheckbox = styled(Checkbox)`
    && {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    && .rs-checkbox-wrapper {
        top: 2px !important;
    }
`;


const ColumnDataWithIcon = styled.div`
    && .icon {
        margin-left: 10px;
    }
`;