import React from 'react';
import JsSIP from 'jssip';
import * as audioPlayer from './audioPlayer';
import Session from './Session';
import styled, {css} from "styled-components";
import {ButtonSecondary} from "../base/BaseButton";
import {Icon, Whisper} from "rsuite";
import {api} from "../../api/loginRoutes";
import {IVR_LISTEN_API} from "../../const/apiMethods";
import {Alert} from "rsuite";
import Tooltip from "rsuite/es/Tooltip";
import axios from "axios";


const DEFAULT_SETTINGS = {
    display_name: "778",
    authorization_user: "778",
    uri: "sip:778@ivr.premiumy.net",
    password: "7001sip1007",

    registrar_server: "ivr.premiumy.net:5060",
    contact_uri: null,
    instance_id: null,
    session_timers: true,
    use_preloaded_route: false,
    socket: {
        uri: "wss://ivr.premiumy.net:8089/ws",
        via_transport: "wss"
    },
    pcConfig: {
        rtcpMuxPolicy: "negotiate",
        iceServers: [
            // {urls: ["stun:stun.l.google.com:19302"]}
        ]
    },
    callstats: {
        enabled: false,
        AppID: null,
        AppSecret: null
    }
};

export default class Phone extends React.Component {
    constructor(props) {
        super(props);

        this.cancelToken = null;
        this._ua = null;
        this.state = {
            loading: false,
            isInCall: false,
            session: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {ivrId} = this.props;
        const {session} = this.state;

        if (typeof cancelToken !== typeof undefined) {
            this.cancelToken.cancel("Operation canceled due to new request.");
        }

        if (session && ivrId !== prevProps.ivrId) {
            this.handleStopSession();
        }
    }

    fetchIvrRegisterData () {
        const {ivrId} = this.props;

        if (!ivrId) {
            return;
        }

        this.setState({loading: true});

        if (typeof cancelToken !== typeof undefined) {
            this.cancelToken.cancel("Operation canceled due to new request.");
        }
        this.cancelToken = axios.CancelToken.source();

        api(IVR_LISTEN_API, {target: {ivr_id: ivrId}}, null, this.cancelToken)
            .then(r => {

                if (!r || !r.listen) {
                    Alert.error("Something went wrong", 10000);
                    this.setState({loading: false});
                    return;
                }

                if ('too_much_attempts' === r.listen.reason) {
                    Alert.error("Too much attempts. Please, wait a few minutes and try again", 10000);
                    this.setState({loading: false});
                    return;
                }

                if (!r || r.listen.status !== 'ok') {
                    if (r.listen.reason === "not_listening_ready") {
                        Alert.error("IVR listening is not ready now. Please try again in a few minutes", 10000);
                    }
                    this.setState({loading: false});
                    return;
                }

                const settings = {
                    ...DEFAULT_SETTINGS,
                    display_name: r.listen.login,
                    authorization_user: r.listen.login,
                    uri: `sip:${r.listen.login}@${r.listen.host}`,
                    password: r.listen.password,
                    registrar_server: `${r.listen.host}${r.listen.port ? `:${r.listen.port}` : ''}`,
                    socket: {
                        uri: `wss://${r.listen.host}:8089/ws`,
                        via_transport: "wss"
                    },
                };

                this.handleRegister(settings, r.listen.number);
            })
    };

    handleRegister (settings, callUri) {

        const socket = new JsSIP.WebSocketInterface(settings.socket.uri);
        socket['via_transport'] = "wss";

        const options = {
            uri: settings.uri,
            password: settings.password,
            'display_name': settings.display_name,
            sockets: [socket],
            'registrar_server': settings.registrar_server,
            'contact_uri': settings.contact_uri,
            'authorization_user': settings.authorization_user,
            'instance_id': settings.instance_id,
            'session_timers': settings.session_timers,
            'use_preloaded_route': settings.use_preloaded_route
        };

        try {
            this._ua = new JsSIP.UA(options);
        }
        catch (error) {
            return;
        }

        // event handlers

        // connecting
        this._ua.on('connecting', () => {
            // console.log("connecting params", settings);
        });

        // connected
        this._ua.on('connected', () => {
            // console.log("connected params", settings);
        });

        // disconnected
        this._ua.on('disconnected', () => {
            // console.log("disconnected params", settings);
        });

        // registered
        this._ua.on('registered', () => {
            // console.log("registered params", settings);
            this.handleOutgoingCall(settings, callUri);
        });

        // unregistered
        this._ua.on('unregistered', () => {
            this.setState({isInCall: false});
            // console.log("unregistered params", settings);
            // Alert.error("unregistered", 10000);
        });

        // registrationFailed
        this._ua.on('registrationFailed', (e) => {
            this.setState({
                isInCall: false,
                loading: false
            });
            Alert.error("registrationFailed", 10000);
            console.log("registrationFailed params", settings);
            console.log("registrationFailed e", e);
        });

        // newSession
        this._ua.on('newSession', () => {
            // console.log("newSession", settings);
        });

        this._ua.start();
    }

    // startCallingSound = () => {
    //     this.ringingTimer
    // };


    handleOutgoingCall(settings, callUri = '888444') {

        const session = this._ua.call(callUri, {
            pcConfig: settings.pcConfig || {iceServers: []},
            mediaConstraints: {
                audio: true,
                video: false
            },
            rtcOfferConstraints: {
                offerToReceiveAudio: 1,
                offerToReceiveVideo: 0
            }
        });

        console.log("start session", session);

        session.on('connecting', () => {
            console.log("connecting", session);
        });

        session.on('connected', () => {
            console.log("connected");
        });
        session.on('accepted', () => {
            console.log("_accepted");
        });
        session.on('confirmed', () => {
            console.log("_confirmed");
        });

        session.on('progress', () => {
            console.log("progress");
            audioPlayer.play('ringback');
        });

        session.on('started', () => {
            console.log('started');
        });

        session.on('failed', (data) => {
            console.log("failed", data);
            audioPlayer.stop('ringback');
            this.setState({
                session: null,
                loading: false
            });
            Alert.error(`Session failed: ${data.cause}`, 10000);
        });

        session.on('ended', () => {
            audioPlayer.stop('ringback');
            this.setState({
                session: null,
                isInCall: false,
            });
        });

        session.on('accepted', () => {
            audioPlayer.stop('ringback');
            this.setState({
                isInCall: true,
                loading: false
            });
        });

        this.setState({session});
    }


    handleStopSession() {
        const {session} = this.state;
        session.terminate();
        this._ua.stop();
    }

    stop() {
        this.handleStopSession()
    }

    render() {
        const {session, isInCall, loading} = this.state;
        const {disabled, onClick} = this.props;

        return (
            <>
                <Whisper
                    placement="left"
                    speaker={<Tooltip>Listen IVR</Tooltip>}
                >
                    <ButtonPlay
                        isInCall={isInCall}
                        disabled={disabled}
                        loading={loading}
                        onClick={() => {
                            onClick && onClick();
                            if (session) {
                                this.handleStopSession();
                                return;
                            }
                            this.fetchIvrRegisterData();
                        }}
                    >
                        {isInCall
                            ? <Icon icon="pause"/>
                            : <Icon icon="play"/>
                        }
                    </ButtonPlay>
                </Whisper>

                {session &&
                    <Session session={session}/>
                }
            </>
        );
    }
}


const ButtonPlay = styled(ButtonSecondary)`
    && {
        padding: 0 0 0 3px;
        border-radius: 44px;
        height: 44px;
        width: 44px;
        margin-right: 10px;
        
        ${props => props.isInCall && css`
            padding: 0;
        `}
        
        .rs-icon {
            font-size: 18px;
            line-height: 44px;
        }
    }
`;