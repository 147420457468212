import React, {useEffect, useState, useMemo, useRef} from "react";
import PageHeader from "../../components/base/PageHeader";
import AccessesFilters from "./AccessesFilters";
import AccessesTable from "./AccessesTable";
import ButtonDownload from "../../components/client/ButtonDownload";
import {
    ACCESSES_LIST_CLIENT_PATHNAME,
    LOCAL_STORAGE_ACCESSES_FILTERS_DIALER,
    LOCAL_STORAGE_ACCESSES_PER_PAGE_DIALER,
    LIMITED_PER_PAGE_DIALER,
    MIN_PER_PAGE_DIALER,
    SCREEN_MEDIA,
    SP_KEY__GLOBAL_ACCESS,
    SP_KEY__INCORPORATED,
    USD_DEFAULT_CURRENCY_KEY,
    transformed_service_data,
    transformed_service_reverse_data
} from "../../const";
import {compareObjects, servicePick, getBlobContent, getServiceByLocation} from "../../utils/helpers";
import {injectIntl} from "react-intl";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import {Spacer} from "../../components/base/Spacer";
import styled, {createGlobalStyle} from "styled-components";
import {api, getFileResponse} from "../../api/loginRoutes";
import ModalAllocationCommon from "../../components/client/Modal/ModalAllocationCommon";
import Page from "../../components/base/Page";

import ModalAllocationResult from "../../components/client/Modal/ModalAllocationResult";
import axios from "axios";
import m from "../../definedMessages";
import {ButtonPrimary} from "../../components/base/BaseButton";
import {Icon, Schema} from "rsuite";
import ModalSettings from "./ModalSettings";
import {checkPermissionsFor} from "../../store/storeHelpers";
import {withRouter} from "react-router";
import GlobalAccessNumbersFilters from "./GlobalAccessNumbersFilters";
import {usePrevious, useWindowWidth} from "../../hooks";
import { FieldTextAreaAutoResize } from "pages/Settings/styled";

import { FilterSectionFlexboxGrid, StyledItemFitWidth, StyledItemAutoMargin } from "components/base/FitContainers";


const {md} = SCREEN_MEDIA;
const widthDesktopMin = md.min;

const {StringType} = Schema.Types;

const MIN_LENGTH = 3;
const MAX_LENGTH = 40;


const Accesses = ({
    intl,

    accessesList,
    service,
    trunkList,
    currency_list,
    service_plan_list,
    trunk_number_transaction,

    settings,
    settingsIsFetched,

    account,
    accountId,

    getTrunkList,
    getAccessList,
    setEmptyAccessList,
    getAccessListFiltersDialer,
    getNotification,
    modifyNotification,

    page,
    count,
    per_page,

    allocateByAccess,
    allocateByTemplate,
    downloadNumbers,
    downloadAccesses,

    uploadLoading,
    loadingAllocation,
    accessesLoading,
    uploadLoadingSetting,

    accountDialerState,

    showReasonHandler,

    accessFilters,
    accessFiltersLoading,

    worldzoneList,
    destinationList,
    subdestinationList,

    setUploadingLoading,
    setTrunkNumberTransaction,
    setAccessLoadingAllocatedNumbers,

    resetRecaptcha,

    numberTypeFamilyList,
    getAuthNumberType,
    accountJoinList=[],

    ...props
}) => {
    const isSms = !service;

    const defaultTrunkId = null;

    const formatMessage = intl.formatMessage.bind(intl);
    let cancelTokenAccessesList = axios.CancelToken.source();
    let currentPerPage = null;

    let savedTableFilter = {};
    
    const accountInfo = useMemo(() => {
        return accountJoinList.find(accountService => 
            transformed_service_data[accountService?.service] === service
        ) || {};
    }, [service, accountJoinList]);

    const accountIsSigle = useMemo(() => {
        return accountJoinList.length && accountJoinList.length === 1;
    }, [accountJoinList]);

    const currencyKey = ( (savedTableFilter && savedTableFilter.cur_key) || {} ).cur_key || accountInfo.cur_key;
    const isTest = "is_test" in accountInfo ? accountInfo.is_test : false;
    const currentPathName = props.location.pathname;
    const accessPageOption = currentPathName.split("-").find((path) => path === ACCESSES_LIST_CLIENT_PATHNAME);

    const filtersModel = Schema.Model({
        str: StringType()
            .minLength(MIN_LENGTH, formatMessage(m.minLength, {count: MIN_LENGTH}))
            .maxLength(MAX_LENGTH, formatMessage(m.maxLength, {count: MAX_LENGTH})),
        str2: StringType()
            .minLength(MIN_LENGTH, formatMessage(m.minLength, {count: MIN_LENGTH}))
            .maxLength(MAX_LENGTH, formatMessage(m.maxLength, {count: MAX_LENGTH})),
        origin: StringType()
            .minLength(MIN_LENGTH, formatMessage(m.minLength, {count: MIN_LENGTH}))
            .maxLength(MAX_LENGTH, formatMessage(m.maxLength, {count: MAX_LENGTH})),
        destination: StringType()
            .minLength(MIN_LENGTH, formatMessage(m.minLength, {count: MIN_LENGTH}))
            .maxLength(MAX_LENGTH, formatMessage(m.maxLength, {count: MAX_LENGTH})),
    });


    const hotAccessFilter = JSON.parse(localStorage.getItem("PICKED_HOT_ACCESS")) || {};

    const filterDefault = useMemo(() => {
        return service ? {
            str: hotAccessFilter?.a_description || "",
            str2: hotAccessFilter?.b_description || "",
            sp_key_list: hotAccessFilter?.sp_key ? [hotAccessFilter?.sp_key] : null,
            cur_key: USD_DEFAULT_CURRENCY_KEY,
            ...(isTest && currencyKey ? {cur_key: currencyKey} : {cur_key: USD_DEFAULT_CURRENCY_KEY})
        }: {
            origin: hotAccessFilter?.a_description || "",
            destination: hotAccessFilter?.b_description || "",
            message: "",
            sp_key_list: hotAccessFilter?.sp_key ? [hotAccessFilter?.sp_key] : null,
            cur_key: USD_DEFAULT_CURRENCY_KEY,
            ...(isTest && currencyKey ? {cur_key: currencyKey} : {cur_key: USD_DEFAULT_CURRENCY_KEY})
        };
    }, [service]);

    let filter = {
        ...filterDefault,
        ...(savedTableFilter || {}),
    };

    const GAFilterDefault = useMemo(() => {
        return service ? {
            str: "",
            str2: "",
            sp_key_list: [service ? SP_KEY__INCORPORATED : SP_KEY__GLOBAL_ACCESS],
            cur_key: USD_DEFAULT_CURRENCY_KEY,
            ...(isTest && currencyKey ? {cur_key: currencyKey} : {cur_key: USD_DEFAULT_CURRENCY_KEY})
        }: {
            origin: "",
            destination: "",
            message: "",
            sp_key_list: [service ? SP_KEY__INCORPORATED : SP_KEY__GLOBAL_ACCESS],
            cur_key: USD_DEFAULT_CURRENCY_KEY,
            ...(isTest && currencyKey ? {cur_key: currencyKey} : {cur_key: USD_DEFAULT_CURRENCY_KEY})
        };
    }, [service]);

    const GAFilter = {
        ...GAFilterDefault,
        ...(savedTableFilter || {})
    };

    const defaultFilter = useMemo(() => {
        return accessPageOption ? filterDefault : GAFilterDefault;
    }, [service, accessPageOption]);
   
    const savedFilter = useMemo(() => {
        return accessPageOption ? filter : GAFilter;
    }, [service, accessPageOption]);

    const [isSupport, setIsSupport] = useState(null);
    const [filterState, setFilter] = useState({...defaultFilter});
    const [selectedData, setSelectedData] = useState({});

    const prevService = usePrevious(service);
    const prevFilter = usePrevious(filterState);

    const [savedPerPage, setSavedPerPage] = useState(JSON.parse( localStorage.getItem(LOCAL_STORAGE_ACCESSES_PER_PAGE_DIALER) ));
    const [trunkId, setTrunkId] = useState(defaultTrunkId);
    const [csvContent, setCsvContent] = useState(null);
    const [notAllocatedNumbers, setNotAllocatedNumbers] = useState(null);
    const [allocatedNumbersCount, setAllocatedNumbersCount] = useState(0);

    const [showGetNumbersModal, setShowGetNumbersModal] = useState(false);
    const [showNewNumbersModal, setShowNewNumbersModal] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [modalSettingsAvailable, setModalSettingsAvailable] = useState(false);

    const [resultLoading, setResultLoading] = useState(false);

    const resizedWidth = useWindowWidth();

    const hasMounted = useRef(false);
    const [filterChanged, setFilterChanged] = useState(false);

    useEffect(() => {
        if (hasMounted.current && service !== null) {
            // if (Object.keys(hotAccessFilter).length && accountIsSigle) {
            setFilter(defaultFilter);
            // }
            localStorage.removeItem("PICKED_HOT_ACCESS");
        }
    }, [service, hasMounted.current, accountIsSigle]);

    useEffect(() => {
        if (settingsIsFetched) {
            setModalSettingsAvailable(true);
        }
    }, [settingsIsFetched])

    useEffect(() => {
        let currentService = service;
        
        // uncomment  
        // const checker = filtersModel.check(filterState);
        // const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        //if (!formHasError &&
        if (accountInfo?.id) {
            if (service === null) {
                currentService = getServiceByLocation(props.location);
            }
    
            getTrunkList(currentService);
            if (!isTest) {
                getNotification(accountId);
            }
            getAccessListCommon(savedPerPage || per_page, defaultFilter, 1);
        }

        if (account && Object.keys(account).length) {
            if (account?.session) {
                const accountUser = account.session.account_user;
                if (Object.keys(accountUser).length && accountUser.role_list && accountUser.role_list.length) {
                    const isSupportFlag = accountUser.role_list.includes("Account Support");
                    setIsSupport(isSupportFlag)
                }
            }
        }

        // resize listener
        // window.addEventListener("resize", this.handleResize);
        return () => {
            if (typeof cancelTokenAccessesList !== typeof undefined) {
                cancelTokenAccessesList.cancel("Operation canceled due to new request.");
            }
        }
    }, [service, accountInfo?.id]); // check it

    useEffect(() => {
        if (service !== null) {
            if (!compareObjects(filterState, defaultFilter)) {

                // setFilter(defaultFilter);
                localStorage.setItem(LOCAL_STORAGE_ACCESSES_FILTERS_DIALER, JSON.stringify(defaultFilter));
                // getTrunkList(service);
            }
        }
    }, [service]);

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            return;
        }

        let currentFilter = filterState;
        if (prevService !== service) {
            currentFilter = defaultFilter;
        }
        
        const checker = filtersModel.check(filterState);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError && filterChanged) { // && !compareObjects(prevFilter, filterState)
            if (!isTest) {
                getNotification(accountId);
            }
            // catch error => execute
            // or if methodRecaptcha is not null => execute
            getAccessListCommon(savedPerPage || currentPerPage || per_page, filterState, 1, false); 
        } else {
            setEmptyAccessList();
        }
    }, [filterState, filterChanged]);


    const filteredTrunkList = useMemo(() => {
        const dataSpKeyList = accessesList.map((value) => value.sp_key);

        const result = trunkList.filter(value => {
            return (!value || !value?.allocation_limit?.allocate)
                ? false
                :   !value.closed
                    && value.active
                    && (filterState?.sp_key_list 
                        ? filterState.sp_key_list.includes(value.sp_key)
                        : dataSpKeyList.includes(value.sp_key)
                    )
                    && (!selectedData.sp_key || !!showGetNumbersModal && selectedData?.sp_key && value.sp_key === selectedData.sp_key)
        });
        return result;
    }, [trunkList, filterState, accessesList, selectedData, showGetNumbersModal]);

    const getAccessListCommon = (per_page, filter, page, isAuth) => {
        if (typeof cancelTokenAccessesList !== typeof undefined) {
            cancelTokenAccessesList.cancel("Operation canceled due to new request.");
        }
        cancelTokenAccessesList = axios.CancelToken.source();

        let currentService = service;
        
        if (service === null) {
            currentService = getServiceByLocation(props.location);
        }

        getAccessList(currentService, per_page, filter, page, isAuth, cancelTokenAccessesList);
    };

    const onChangeFilters = (filters, replace=false) => {
        const newFilter = replace ? filters : {...filterState, ...filters};

        setFilter(newFilter);

        localStorage.setItem(LOCAL_STORAGE_ACCESSES_FILTERS_DIALER, JSON.stringify(newFilter));
    };

    const onChangePerPage = (perPage) => {
        setSavedPerPage(perPage ? null : currentPerPage);
        const per_page = Number(perPage) || currentPerPage;
        currentPerPage = per_page <= 0
            ? MIN_PER_PAGE_DIALER
            : per_page > LIMITED_PER_PAGE_DIALER ? LIMITED_PER_PAGE_DIALER : per_page;
            
        localStorage.setItem(LOCAL_STORAGE_ACCESSES_PER_PAGE_DIALER, JSON.stringify(currentPerPage));

        const checker = filtersModel.check(filterState);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError) { //&& (!accessPageOption || (filterState?.str || filterState?.str2))) {
            getAccessListCommon(currentPerPage, filterState, 1);
        }
    };

    const onChangePage = (pageNumber) => {
        const checker = filtersModel.check(filterState);
        const formHasError = Object.keys(checker).some(key => checker[key].hasError);

        if (!formHasError) { //&& (!accessPageOption || (filterState?.str || filterState?.str2))) {
            getAccessListCommon(savedPerPage || currentPerPage || per_page, filterState, pageNumber);
        }
    };

    const onDownloadNumbers = (trunk_id) => downloadNumbers(trunk_id, service);

    const onDownloadAccesses = () => {
        downloadAccesses(service, accountInfo?.id, filterState);
    };

    const getNumbers = async (params) => {
        const template = selectedData.b_number;

        if (params?.gan || !template) {
            return await allocateByAccess(service, params.trunk_id, selectedData.sde_key, params.numbers, params.random_number, selectedData.ing_key);
        }
        
        // if (!service) {
        return await allocateByTemplate(service, params.trunk_id, params.numbers, template, params.random_number, selectedData.ing_key);
        // } 
    };

    const onAllocationGetResult = (response) => {
        const notAllocatedNumbers = response.not_allocated_numbers;
        notAllocatedNumbers && setNotAllocatedNumbers(notAllocatedNumbers);

        const trunkNumberTransaction = response.trunk_number_transaction;
        setTrunkNumberTransaction(trunkNumberTransaction || {});

        if (trunkNumberTransaction) {
            const transactionId = trunkNumberTransaction.id;
            const allocatedNumbersCount = trunkNumberTransaction.numbers;

            setAllocatedNumbersCount(allocatedNumbersCount);
            setResultLoading(true);

            const method = service ? "trunk_number:get_list" : "sms.trunk_number:get_list" ;

            getFileResponse(method, {
                target: {
                    [service ? "trunk_number_transaction_id" : "sms.trunk_number_transaction_id"]: transactionId
                }
            })
            .then((response) => {
                setAccessLoadingAllocatedNumbers(false);
                if (response) {
                    const csvText = getBlobContent(response);
                    csvText.then((text) => {
                        setCsvContent(text);
                        setResultLoading(false);
                        setShowGetNumbersModal(false);
                        setShowNewNumbersModal(true);
                    });
                }
            });
        }
    };

    const downloadNumberList = (csvNumberList, trunkId, setUploadingLoading, ext="csv") => {
        const encodedUri = encodeURI(csvNumberList);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `allocated_numbers.${ext}`);
        document.body.appendChild(link);
        link.click();
        setUploadingLoading(trunkId, false);
    };

    const onShowGetNumbersModal = (state, data = {}) => {
        setShowGetNumbersModal(state);
        if (state) {
            setSelectedData(data);
        }
    };

    const clearAllocatedData = () => {
        setCsvContent(null);
        setAllocatedNumbersCount(0);
    };

    const headerTitle = accessPageOption ? formatMessage(m.accessList) : formatMessage(m.globalAccessList);

    const modalGetNumbersTitle = selectedData?.range_name 
        ? `${formatMessage(m.getNumbersForRange)} ${selectedData.range_name}`
        : selectedData?.a_description 
            ? formatMessage(m.getNumbersForDestination, {name: selectedData.b_number ? `${selectedData.b_description} (${selectedData.b_number})` : `${selectedData.b_description}`})
            : formatMessage(m.getNumbers);

    return (
        <Page>
            <GlobalStyle/>
            <PageHeader title={ headerTitle }/>

            {resizedWidth < widthDesktopMin &&
            <FlexGrid justify="end">
                <FlexGridItem>
                    {modalSettingsAvailable &&
                    <>
                        <ButtonPrimary
                            onClick={() => {
                                setShowSettingsModal(true);
                            }}
                        >
                            <StyledIcon icon="gears2"/>{formatMessage(m.notificationSettings)}
                        </ButtonPrimary>
                        <Spacer />
                    </>
                    }
                </FlexGridItem>
            </FlexGrid>
            }

            <FilterSectionFlexboxGrid>
                <StyledItemFitWidth>
                    {accessPageOption ? <AccessesFilters
                        onChangeFilter={onChangeFilters}
                        loading={accessesLoading || false}
                        prices={service_plan_list}
                        filterState={filterState}
                        defaultFilter={defaultFilter}
                        savedFilter={savedFilter}
                        filtersModel={filtersModel}
                        {...{
                            service,
                            accountInfo,
                            filterState,
                            currency_list,
                            setFilterChanged,
                            accountDialerState
                        }}
                    /> : <GlobalAccessNumbersFilters
                        onChangeFilter={onChangeFilters}
                        loading={accessesLoading || false}
                        defaultFilter={defaultFilter}
                        savedFilter={savedFilter}
                        filtersModel={filtersModel}
                        {...{
                            service,
                            accountInfo,
                            filterState,
                            currency_list,
                            setFilterChanged,
                            accountDialerState
                        }}
                    />}
    
                </StyledItemFitWidth>
                {resizedWidth >= widthDesktopMin &&
                <StyledItemAutoMargin>
                    {modalSettingsAvailable && !isTest &&
                    <StyledButtonSettings
                        onClick={() => {
                            setShowSettingsModal(true);
                            if (service) {
                                getAuthNumberType();
                            }
                        }}
                    >
                        <StyledIcon icon="gears2"/>{formatMessage(m.notificationSettings)}
                    </StyledButtonSettings>
                    }
                    <ButtonDownload
                        disabled={!service_plan_list || (service_plan_list && !service_plan_list.length)}
                        onDownload={onDownloadAccesses}
                        limitWidth={widthDesktopMin}
                        buttonText={formatMessage(m.download)}
                        loading={uploadLoading}
                    />
                </StyledItemAutoMargin>
                }
            </FilterSectionFlexboxGrid>

            <Spacer/>

            <AccessesTable
                data={accessesList}
                loading={accessesLoading || false}
                filter={filterState}
                trunks={filteredTrunkList}
                servicePlanList={service_plan_list}
                {...{
                    service,
                    accountInfo,
                    isTest,
                    isSupport,
                    onShowGetNumbersModal,
                    setShowNewNumbersModal,
                    onChangePage,
                    onChangePerPage,
                    page,
                    count,
                    per_page,
                    savedPerPage,
                    accountDialerState,
                    isGlobalAccess: !accessPageOption
                }}
            />

            {resizedWidth < widthDesktopMin &&
            <StyledActionButtonWrapper>
                <ButtonDownload
                    disabled={!service_plan_list || (service_plan_list && !service_plan_list.length)}
                    onDownload={onDownloadAccesses}
                    limitWidth={widthDesktopMin}
                    buttonText={formatMessage(m.download)}
                    loading={uploadLoading}
                />
            </StyledActionButtonWrapper>
            }

            <ModalAllocationCommon
                show={showGetNumbersModal}
                onSend={getNumbers}
                onClose={() => {
                    onShowGetNumbersModal(false);
                    setSelectedData({});
                }}
                loading={loadingAllocation}
                trunks={filteredTrunkList}
                trunksNotFilterable
                trunkId={trunkId}
                service={service}
                selectedData={selectedData}
                accountDialerState={accountDialerState}
                showReasonHandler={showReasonHandler}
                title={modalGetNumbersTitle}
                onSuccess={(r, formData) => {
                    onAllocationGetResult(r);
                    getAccessListCommon(savedPerPage || currentPerPage || per_page, filterState, 1);

                    const currentTrunkList = trunkList.filter(value => (!value || !value?.allocation_limit?.allocate)
                        ? false
                        : value.sp_key === selectedData?.sp_key && !value.closed && value.active
                    );
                    let currentTrunkId = null;
                    if (formData.trunk_id) {
                        currentTrunkId = formData.trunk_id;
                    } else {
                        if (currentTrunkList.length) {
                            currentTrunkId = currentTrunkList[0].id;
                        }
                    }
                    setTrunkId(currentTrunkId);
                }}
                hideRandom={isSms}
            />

            <ModalAllocationResult
                show={showNewNumbersModal}
                maxWidth={850}
                account={account}
                trunkId={trunkId}
                trunks={trunkList}
                defaultTrunkId={defaultTrunkId}
                service={service}
                csvContent={csvContent}
                methodName={"AccessList"}
                loading={loadingAllocation}
                downloadNumbers={downloadNumbers}
                downloadNumbersTxt={({csvNumberList, trunkId, setUploadingLoading}) => {
                    downloadNumberList(csvNumberList, trunkId, setUploadingLoading, "txt")
                }}
                uploadLoading={uploadLoadingSetting}
                onDownloadNumbers={onDownloadNumbers}
                allocatedNumbersCount={allocatedNumbersCount}
                notAllocatedNumbers={notAllocatedNumbers}
                trunkNumberTransaction={trunk_number_transaction}
                setUploadingLoading={setUploadingLoading}
                onClose={() => {
                    setSelectedData({});
                    clearAllocatedData();
                    setNotAllocatedNumbers(null);
                    setShowNewNumbersModal(false);
                }}
            />

            {modalSettingsAvailable &&
                <ModalSettings
                    show={showSettingsModal}
                    data={accessFilters}
                    service={service}
                    loading={accessFiltersLoading}
                    accountInfo={accountInfo}
                    accountId={accountId}

                    settings={settings}
                    settingsIsFetched={settingsIsFetched}

                    getAccessListFiltersDialer={getAccessListFiltersDialer}
                    modifyNotification={modifyNotification}
                    onClose={() => setShowSettingsModal(false)}
                    {...{
                        worldzoneList,
                        destinationList,
                        subdestinationList,
                        numberTypeFamilyList
                    }}
                />
            }
        </Page>
    )
};


export default injectIntl(withRouter(Accesses));


const StyledActionButtonWrapper = styled.div`
    padding-top: 15px;
    text-align: center;
    
    @media (min-width: ${widthDesktopMin}px) {
        text-align: right;
    }
`;

const StyledIcon = styled(Icon)`
    margin-right: 5px;
    
    .app-client.rtl & {
        margin-left: 5px;
        margin-right: 0;
    }
`;

const StyledButtonSettings = styled(ButtonPrimary)`
    margin-right: 20px;

    .app-client.rtl & {
        margin-left: 20px;
        margin-right: 0;
    }
`;

const GlobalStyle = createGlobalStyle`
    .rs-picker-check-menu-items {
        overflow-y: hidden !important;
        max-height: none !important;
    }
`;