import React, {useState, useEffect} from "react";
import PaymentsRequestTable from "./PaymentsRequestTable";
import {Spacer} from "../../../components/base/Spacer";
import PaymentsRequestFilter from "./PaymentsRequestFilter";
import ModalChangePaymentRequestStatus from "../ModalChangePaymentRequestStatus";


export default ({
    service,
    authInfo,
    paymentRequestItems, 
    paymentRequestLoading, 
    accounts = [],
    setShowModalPaymentDetail,
    setActiveAccountId,

    updateAccount,
    getPaymentRequestList,
    cancelPaymentRequest,
    approvePaymentRequest,
}) => {

    const [selectedRequest, setSelectedRequest] = useState(null);
    const [filter, setFilter] = useState({status: [0, 2]});
    const [showModalCancelPaymentRequest, setShowModalCancelPaymentRequest] = useState(false);
    const [showModalApprovePaymentRequest, setShowModalApprovePaymentRequest] = useState(false);


    useEffect( () => {
        const data = {
            target: {account_id_list: null},
            filter: {
                status: [0, 2],
                service: service ? 'voice' : 'sms'
            }
        };
        getPaymentRequestList(data, true);
    }, [] );


    const handleManagePaymentDetails = (value) => {
        setActiveAccountId(value.account_id);
        setShowModalPaymentDetail(true);
    };


    const onChangeFilters = (value) => {
        const filterValue = {...value};
        setFilter({...value});

        const accountIdList = value.account_id_list !== undefined ? value.account_id_list : [];
        delete filterValue.account_id_list;

        getPaymentRequestList({
            target: {
                account_id_list: accountIdList.length ? accountIdList : null
            },
            filter: filterValue
        }, true);
    };


    const handleApprovePaymentRequest = (value) => {
        setSelectedRequest(value);
        setShowModalApprovePaymentRequest(true);
    };


    const handleApprovePayment = async () => {
        const data = {
            target: {
                payment_request_id: (selectedRequest || {})['id']
            },
        };

        await approvePaymentRequest(data);

        const filterValue = {...filter};
        const accountIdList = filterValue.account_id_list !== undefined ? filterValue.account_id_list : [];
        delete filterValue.account_id_list;

        getPaymentRequestList({
            target: {
                account_id_list: accountIdList.length ? accountIdList : null
            },
            filter: filterValue
        }, true);
    };


    const handleCancelPaymentRequest = (value) => {
        setSelectedRequest(value);
        setShowModalCancelPaymentRequest(true);
    };


    const handleCancelPayment = async () => {
        const data = {
            target: {
                payment_request_id: (selectedRequest || {})['id']
            },
        };

        await cancelPaymentRequest(data)
            .then((response) => {
                const filterValue = {...filter};
                const accountIdList = filterValue.account_id_list !== undefined ? filterValue.account_id_list : [];
                delete filterValue.account_id_list;
        
                getPaymentRequestList({
                    target: {
                        account_id_list: accountIdList.length ? accountIdList : null
                    },
                    filter: filterValue
                }, true);
                updateAccount();
            });
    };

    return (
        <>
            <PaymentsRequestFilter
                defaultFilter={filter}
                accounts={accounts}
                authInfo={authInfo}
                onChangeFilters={onChangeFilters}
            />

            <Spacer/>

            <PaymentsRequestTable
                withFilters
                data={paymentRequestItems}
                loading={paymentRequestLoading}
                {...{
                    handleManagePaymentDetails,
                    handleCancelPaymentRequest,
                    handleApprovePaymentRequest,
                }}
            />

            <ModalChangePaymentRequestStatus
                title={`Approve payment request${selectedRequest && " (account: " + selectedRequest.account_name + ")"}`}
                show={showModalApprovePaymentRequest}
                onClose={() => {
                    setShowModalApprovePaymentRequest(false)
                }}
                onSuccess={handleApprovePayment}
            />

            <ModalChangePaymentRequestStatus
                title={`Cancel payment request${selectedRequest && " (account: " + selectedRequest.account_name + ")"}`}
                show={showModalCancelPaymentRequest}
                onClose={() => {
                    setShowModalCancelPaymentRequest(false)
                }}
                onSuccess={handleCancelPayment}
            />
        </>
    )
};