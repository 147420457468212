import React, { useRef, useMemo, useState, useEffect } from 'react';
import { DateRangePicker, Form, FormGroup, SelectPicker, CheckPicker, Input, Checkbox} from "rsuite";
import CustomField from "../../components/Form/CustomField";
import { connect } from "react-redux";
import { getReferencesForReports } from "../../actions/references";
import Text from "../../components/Form/Text";
import FormHOC from "../../hoc/FilltersForm";
import { servicePick, removeNilValues } from "../../utils";
import { ButtonPrimary } from "../../components/base/BaseButton";
import {toEndDayUTCTime, toStartDayUTCTime, toUTCDateTime} from "utils";
// import DateTimeRangePicker from "../../components/DateRangePicker/DateTimerRangePickerRefactored";
import DateTimeRangePicker from "../../components/DateRangePicker/DateTimerRangePicker";
import styled from "styled-components";
import {useIntl} from "react-intl";
import m from "definedMessages";

const { allowedRange } = DateRangePicker;


const CDRFilters = ({
    onApplyFilters,
    preFilters,
    setPreFilters,
    defaultFilters,
    loading,
    globalSettings = {},
    isFailedMdr,
    service,
    ...props
}) => {
    const {formatMessage} = useIntl();
    
    const isFailedMdrDefaultValues = {
        ...defaultFilters,
        operator: null,
        cntr_key: null,
        supplier_id: null,
        senderid: null,
        mcc_mnc: null,
        sender_sde_key: null,
        phone_sde_key: null,
        phone: null,
        reason_list: []
    };

    const trafficMdrDefaultValues = {
        ...defaultFilters,
        ...(!service ? {operator: null, cntr_key: null} : {}),
        [servicePick(service, "originator_key", "originator_name")]: null,
        [servicePick(service, "supplier_key", "supplier_id")]: null,
        dialer_id: null,
        [servicePick(service, "a_sde_key", "sender_sde_key")]: null,
        [servicePick(service, "b_sde_key", "phone_sde_key")]: null,
        [servicePick(service, "a_number", "senderid")]: null,
        [servicePick(service, "b_number", "phone")]: null,
        ...(service ? {} : {mcc_mnc: null})
    };

    const [filters, setFilters] = useState(defaultFilters);
    const [secondUpdateFixer, setSecondUpdateFixer] = useState(null);
    const [onlyMyAccountCheck, setOnlyMyAccountCheck] = useState(true);

    const [currentFilters, setCurrentFilters] = useState(<></>);

    const supplierPlanList = useMemo(() => {
        if (!filters?.supplier_id) 
            return [];

        const supplier = props.supplierList.find(supplier => supplier.id === filters.supplier_id);
        return supplier.supplier_plan_list || [];
    }, [filters?.supplier_id]);

    useEffect(() => {
        const deafultFilterValues = isFailedMdr ? isFailedMdrDefaultValues : trafficMdrDefaultValues;

        setFilters(deafultFilterValues);

        const preDeafultFilterValues = {...deafultFilterValues};

        if (preDeafultFilterValues.range && preDeafultFilterValues.range.length) {
            // filterValues['start_date'] = toStartDayUTCTime(filterValues.range[0]);
            // filterValues['end_date'] = toEndDayUTCTime(filterValues.range[1]);
            preDeafultFilterValues['start_date'] = toUTCDateTime(preDeafultFilterValues.range[0]);
            preDeafultFilterValues['end_date'] = toUTCDateTime(preDeafultFilterValues.range[1]);

            delete preDeafultFilterValues.range;
        }
        setPreFilters(removeNilValues(preDeafultFilterValues))

        setOnlyMyAccountCheck(true);

        setSecondUpdateFixer(null);
    }, [isFailedMdr])

    const allowedRanges = useMemo(() => {
        const date = new Date();
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const intervalMonth = globalSettings.cdr_full__interval_month || 0;
        const dateRange = [
            new Date(startDate.setMonth(startDate.getMonth() - intervalMonth)),
            new Date(endDate.setMonth(endDate.getMonth() + 12))
        ];

        return [
            `${dateRange[0].getFullYear()}-${dateRange[0].getMonth() + 1}-${dateRange[0].getDate()}`,
            `${dateRange[1].getFullYear()}-${dateRange[1].getMonth() + 1}-${dateRange[1].getDate()}`,
        ]
    }, [globalSettings]);

    const reasonList = props.reasonList ? props.reasonList.map((reason, idx) => { return { name: reason, key: reason } }) : [];

    const handleChangeDateTime = (value) => {
        const filter = {...value};
        const currentDatetimeFilter = {range: [filter.range[0], filter.range[1]]};

        const filtersToUpdate = {
            ...filters,
            ...filter,
            ...currentDatetimeFilter
        };

        setFilters(filtersToUpdate);

        const filterValues = {...filtersToUpdate};

        if (filterValues.range && filterValues.range.length) {
            // filterValues['start_date'] = toStartDayUTCTime(filterValues.range[0]);
            // filterValues['end_date'] = toEndDayUTCTime(filterValues.range[1]);
            filterValues['start_date'] = toUTCDateTime(filterValues.range[0]);
            filterValues['end_date'] = toUTCDateTime(filterValues.range[1]);

            delete filterValues.range;
        }

        setPreFilters(removeNilValues(filterValues))
    };

    const handleChange = (formValues) => {
        if (!service && (formValues["supplier_id"] === null || formValues["supplier_id"] !== filters["supplier_id"])) {
            if ("supplier_plan_id" in formValues) {
                delete formValues.supplier_plan_id;
            }
        }

        setFilters(formValues);

        const filterValues = {...formValues};

        if (filterValues.range && filterValues.range.length) {
            // filterValues['start_date'] = toStartDayUTCTime(filterValues.range[0]);
            // filterValues['end_date'] = toEndDayUTCTime(filterValues.range[1]);
            filterValues['start_date'] = toUTCDateTime(filterValues.range[0]);
            filterValues['end_date'] = toUTCDateTime(filterValues.range[1]);

            delete filterValues.range;
        }

        setPreFilters(removeNilValues(filterValues))
    };

    const handleApply = () => {

        onApplyFilters(removeNilValues(preFilters));
    };

    const checkOnlyAccount = (checked) => {
        setFilters((value) => {
            return {
                ...value,
                only_my_account: checked
            }
        });
        setPreFilters((preValue) => {
            return {
                ...preValue,
                only_my_account: checked
            }
        });
        setOnlyMyAccountCheck(checked);
    };

    useEffect(() => {
        props.getReferencesForReports();
    }, []);

    useEffect(() => {
        const cdrMdrFilters = <>
            {/* <CustomField
                accepter={DateRangePicker}
                disabledDate={allowedRange(...allowedRanges)}
                cleanable={false}
                placeholder="Select Date Range"
                name="range"
                width={300}
            /> */}
            <DateTimeRangePicker
                onChange={(value) => {
                    handleChangeDateTime(value);
                }}
                defaultStartDate={defaultFilters?.range[0]}
                defaultEndDate={defaultFilters?.range[1]}
                startDate={filters.range[0]}
                endDate={filters.range[1]}
                // input={false}
            />
            {service ? <CustomField
                accepter={SelectPicker}
                labelKey="name"
                valueKey="acc_key"
                data={props.originatorList}
                placeholder="Originator"
                name="originator_key"
                width={200}
            /> :
                <Text name="originator_name" placeholder="Originator" width={200} />}

            {!service ?<Text 
                name="operator"
                placeholder="Operator"
                width={150}
                value={filters.operator}
            /> : <></>}
            {!service ? <CustomField
                accepter={SelectPicker}
                labelKey="name"
                valueKey="cntr_key"
                data={props.countryList}
                placeholder="Country"
                name="cntr_key"
                width={200}
            /> : <></>}
            {!service ? <CustomField
                accepter={SelectPicker}
                data={props.supplierList}
                labelKey="name"
                valueKey="id"
                placeholder="Supplier"
                name={servicePick(service, "supplier_key", "supplier_id")}
                width={200}
            /> : <></>}
            {!service ? <CustomField
                accepter={SelectPicker}
                data={supplierPlanList}
                labelKey="name"
                valueKey="id"
                placeholder="Supplier trunk"
                name="supplier_plan_id"
                width={200}
                disabled={!filters?.supplier_id}
            /> : <></>}
            <CustomField
                accepter={SelectPicker}
                data={props.dialerList}
                labelKey="name"
                valueKey="id"
                placeholder="Dialer"
                name="dialer_id"
                width={200}
            />
            <br />
            <CustomField
                accepter={SelectPicker}
                width={300}
                data={props.subdestinationList}
                labelKey="name"
                valueKey="sde_key"
                placeholder="Origin"
                name={servicePick(service, "a_sde_key", "sender_sde_key")}
            />
            <CustomField
                accepter={SelectPicker}
                width={300}
                data={props.subdestinationList}
                labelKey="name"
                valueKey="sde_key"
                placeholder="Destination"
                name={servicePick(service, "b_sde_key", "phone_sde_key")}
            />
            {service ? <Text 
                name="a_number"
                placeholder="A-Number"
                width={150}
                value={filters.a_number}
            /> : <CustomField
                accepter={Input}
                name="senderid"
                placeholder="Sender ID"
                width={150}
                value={filters.senderid}
            />}
            {service ? <Text
                name="b_number"
                placeholder="B-Number"
                width={150}
                value={filters.b_number}
            /> : <Text
                name="phone"
                placeholder="Phone"
                width={150}
                value={filters.phone}
            />}
            {!service ? <>
                <Text
                    name="mcc_mnc"
                    placeholder="MCC/MNC"
                    width={130}
                    value={filters?.mcc_mnc}
                />
            </> : <></>}
           
            {!service ?
                <Checkbox
                    name="only_my_account"
                    onChange={(value, checked) => {
                        checkOnlyAccount(checked);
                    }}
                    defaultChecked={onlyMyAccountCheck}
                    style={{width: 220, display: "inline-block"}}
                >
                    Show only my account traffic
                </Checkbox>
            : <></>}
        </>;

        const mdrFailedFilters = <>
            {/* <CustomField
                accepter={DateRangePicker}
                disabledDate={allowedRange(...allowedRanges)}
                cleanable={false}
                placeholder="Select Date Range"
                name="range"
                width={300}
            /> */}
            <DateTimeRangePicker
                onChange={(value) => {
                    handleChangeDateTime(value);
                }}
                defaultStartDate={defaultFilters?.range[0]}
                defaultEndDate={defaultFilters?.range[1]}
                startDate={filters.range[0]}
                endDate={filters.range[1]}
            />

            <Text 
                name="operator"
                placeholder="Operator"
                width={150}
                value={filters.operator}
            />
            <CustomField
                accepter={SelectPicker}
                labelKey="name"
                valueKey="cntr_key"
                data={props.countryList}
                placeholder="Country"
                name="cntr_key"
                width={200}
            />
            <CustomField
                accepter={SelectPicker}
                data={props.supplierList}
                labelKey="name"
                valueKey="id"
                placeholder="Supplier"
                name="supplier_id"
                width={200}
            />
            {!service ?<CustomField
                accepter={SelectPicker}
                data={supplierPlanList}
                labelKey="name"
                valueKey="id"
                placeholder="Supplier trunk"
                name="supplier_plan_id"
                width={200}
                disabled={!filters?.supplier_id}
            /> : <></>}
            <CustomField
                accepter={Input}
                name="senderid"
                placeholder="Sender ID"
                width={150}
                value={filters.senderid}
            />
            <Text
                name="mcc_mnc"
                placeholder="MCC/MNC"
                width={130}
                value={filters.mcc_mnc}
            />
            <CustomField
                accepter={SelectPicker}
                width={300}
                data={props.subdestinationList}
                labelKey="name"
                valueKey="sde_key"
                placeholder="Origin"
                name="sender_sde_key"
            />
            <CustomField
                accepter={SelectPicker}
                width={300}
                data={props.subdestinationList}
                labelKey="name"
                valueKey="sde_key"
                placeholder="Destination"
                name="phone_sde_key"
            />
            <Text
                name="phone"
                placeholder="Phone"
                width={200}
                value={filters.phone}
            />
            <CustomField
                accepter={CheckPicker}
                width={300}
                data={reasonList}
                labelKey="name"
                valueKey="key"
                placeholder="Reason"
                name="reason_list"
            />

            {!service ?
                <Checkbox
                    name="only_my_account"
                    onChange={(value, checked) => {
                        checkOnlyAccount(checked);
                    }}
                    defaultChecked={onlyMyAccountCheck}
                    style={{width: 220, display: "inline-block"}}
                >
                    Show only my account traffic
                </Checkbox>
            : <></>}
        </>;

        const currentFilters = isFailedMdr ? mdrFailedFilters : cdrMdrFilters;
        setCurrentFilters(currentFilters);
    }, [filters, secondUpdateFixer, props.supplierList, props.dialerList, props.subdestinationList, props.reasonList]);

    

    return (
        <>
            <Form
                layout="inline"
                onChange={handleChange}
                formDefaultValue={defaultFilters}
                formValue={filters}
            >
                {currentFilters}
                <FormGroupRight>
                    <ButtonPrimary
                        style={{ padding: '8px 34px', marginLeft: 12 }}
                        onClick={handleApply}
                        disabled={loading}
                    >
                        {formatMessage(m.applyFilter)}
                    </ButtonPrimary>
                </FormGroupRight>
            </Form>
        </>
    );
};

const mapStateToProps = ({ references, cdr, auth }) => ({
    loading: cdr.loading,
    service: auth.service,
    originatorList: references.client_list,
    reasonList: references['sms.reason_list'],
    countryList: references['country_list'],
    dialerList: auth.service ? references.dialer_list : references['sms.dialer_list'],
    supplierList: auth.service ? references.supplier_list : references['sms.supplier_list'],
    subdestinationList: references.subdestination_list,
});

const mapDispatchToProps = {
    getReferencesForReports
};

export default connect(mapStateToProps, mapDispatchToProps)(FormHOC(CDRFilters));

const FormGroupRight = styled(FormGroup)`
    && {
        float: right;
        right: 0;
        margin-right: 0;
    }
`;