import React, {useRef, useMemo, useEffect, useState} from "react";
import {Form, Schema, Whisper, Tooltip, SelectPicker, InputNumber} from "rsuite";
import CustomField from "pages/NewAccount/ContactCustomFieldRefactored";
import {BaseButton} from "components/base/BaseButton";
import Modal from "components/Modal";
import styled from "styled-components";
import Checkbox from "hocClient/Checkbox";

import m from "definedMessages";


const {NumberType, StringType} = Schema.Types;

const ChangeRateModal = ({
    show,
    disabled,

    subAccountList,

    onSuccess,
    onCloseModal,
    formatMessage
}) => {
    const allocateRef = useRef(null);

    const defaultFormValue = {
        default_rate: true,
        rate: null
    };

    const [formState, setFormState] = useState(defaultFormValue);

    const allocateModel = useMemo(() => {
        return Schema.Model({
            subacc_id: StringType()
                .isRequired(formatMessage(m.required)),
            ...(!formState?.default_rate ? {
                rate: NumberType()
                .isRequired(formatMessage(m.required))
                .min(0.0001, "Minimum is 0.0001")
            } : {})
        });
    }, [formState]);

    useEffect(() => {
        if (allocateRef.current) {
            allocateRef.current.check();
        }
    }, [formState.default_rate]);


    const onAllocate = () => {
        const rate = formState.default_rate ? null : formState.rate;
        const subAccId = formState.subacc_id;

        if (allocateRef.current.check()) {
            onSuccess(rate, subAccId);
        }
    };

    return <Modal 
        width={400}
        show={show}
        title={formatMessage(m.allocateNumbers)}
        onClose={onCloseModal}
        onSuccess={() => {
            onAllocate();
        }}
        successText={formatMessage(m.ok)}
        cancelText={formatMessage(m.cancel)}
        footer
    >
        <StyledForm
            layout="inline"
            ref={allocateRef}
            model={allocateModel}
            formValue={formState}
            onChange={(formValue) => setFormState(formValue)}
        >
            <InlineContainer>
                <span className="label">{formatMessage(m.allocateInto)}</span>
                <StyledCustomField
                    disabled={disabled}
                    placeholder={formatMessage(m.subaccounts)}
                    accepter={SelectPicker}
                    data={subAccountList}
                    valueKey="id"
                    labelKey="name"
                    name="subacc_id"
                    errorPlacement="topRight"
                />
            </InlineContainer>
            <InlineContainer>
                <Checkbox
                    disabled={disabled}
                    name="default_rate"
                    defaultChecked={formState.default_rate}
                >
                    {formatMessage(m.defaultRate)}
                </Checkbox>
                <Whisper
                    trigger="hover"
                    speaker={<Tooltip>{formatMessage(m.uncheckTheDefaultRateCheckboxToEnterACustomRate)}</Tooltip>}
                    placement="auto"
                >
                    <CustomField
                        disabled={disabled || formState?.default_rate}
                        name="rate"
                        placeholder={`${formatMessage(m.customRate)}...`}
                    />
                </Whisper>
            </InlineContainer>
        </StyledForm>
    </Modal>
};

export default ChangeRateModal;



const StyledForm = styled(Form)`
    && > .rs-form-group {
        margin-bottom: 0px;
        margin-right: 15px;
    }
`;

const InlineContainer = styled.div`
    && {
        display: flex;
        justify-content: space-between;
        margin: 0;
    }

    && .label {
        padding-top: 5px;
    }
    
    && .rs-form-control-wrapper {
        width: auto;
    }
`;

const StyledCustomField = styled(CustomField)`
    && {
        width: 100%;
    }
`;

const FormButton = styled(BaseButton).attrs(() => ({
    buttonStyle: "primary",
    size: "sm"
}))`
    &&& {
        height: 36px;
        margin-right: 5px;
        margin-bottom: 0px;
    }
`;