import {connect} from "react-redux";
import Payments from "./Payments";
import {
    getPaymentList,
    getPaymentRequestList,
    getTrafficReportsList,
    requestPayment,
    cancelPaymentRequest
} from "../../actions/payments";
import {
    getPaymentDetailsList,
    getPaymentDetailsCryptoList,
    createPaymentDetails, 
    modifyPaymentDetails
} from "../../actions/paymentDetails"
import {setReadyForPayment, getAccountDialerInfo} from "../../actions/auth_dialer";

const mapStateToProps = ({payments, paymentDetails, auth, auth_dialer, references}) => ({
    service: auth.service,
    permissions: auth.permissions,
    
    paymentItems: payments.paymentItems,
    paymentItemsLoading: payments.paymentItemsLoading,

    paymentRequestItems: payments.paymentRequestItems,
    paymentRequestLoading: payments.paymentRequestLoading,
    
    paymentTrafficReportsItems: payments.paymentTrafficReportsItems,
    paymentTrafficReportsLoading: payments.paymentTrafficReportsLoading,
    
    paymentDetailsItems: paymentDetails.items,
    
    accountInfo: auth_dialer.dialerInfo,
    accountJoinList: auth_dialer.accountJoinList,
    
    organizationTypeList: references.organization_type_list,
    cryptoList: paymentDetails.crypto_items,
    countryList: references.country_list,
    currencyList: references.currency_list,
    paymentMethodList: references.payment_method_list,

    globalSettings: references.global_setting,
});

export default connect(mapStateToProps, {
    getPaymentList,
    getPaymentRequestList,
    getTrafficReportsList,
    getPaymentDetailsList,
    getPaymentDetailsCryptoList,
    requestPayment,
    cancelPaymentRequest,

    createPaymentDetails,
    modifyPaymentDetails,
    setReadyForPayment,
    getAccount: getAccountDialerInfo
})(Payments);