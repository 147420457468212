import React, {forwardRef, useEffect, useMemo, useState} from "react";
import Table from "../../../components/Table";
import {Icon, IconButton, Popover, Whisper, Tooltip} from "rsuite";
import {ButtonRow, ButtonToolbar} from "../../../components/base/BaseButton";
import {sortData, servicePick, copyArrayWithoutReference} from "../../../utils";
import {ASC_SORT} from "../../../const";


export default forwardRef((
    {
        data,
        rowKey,
        service,
        loading,
        loadableGroups,
        paymentTermsList,
        uploadAllowed,

        onEditClick,
        onRemoveClick,
        onUploadClick,
        onDownloadClick,
        onSelectItems,
        ...props
    }, ref
) => {
    const columns = useMemo(() => {

        const paymentsColumnWidth = 55 + (110 * (paymentTermsList.length || 2 ));

        return [
            {
                id: "supplier_name",
                dataKey: "supplier_name",
                value: ({supplier_name, supplier_comment, comment}) => <>
                    <span title={supplier_name}>{supplier_name}</span>
                    {supplier_comment && !comment && <IconComment comment={supplier_comment} />}
                </>,
                label: "Supplier",
                flexGrow: 1,
                minWidth: 285,
                sortable: true
            },
            {
                id: "name",
                dataKey: "name",
                value: ({name, comment}) => <>
                    <span title={name}>{name}</span>
                    {comment && <IconComment group comment={comment} />} 
                </>,
                label: "Group name",
                flexGrow: 1,
                minWidth: 285,
                sortable: true
            },
            {
                id: "test_number_list",
                dataKey: "test_number_list",
                label: "Test numbers",
                value: ({test_number_list}) => {
                    const text = test_number_list && test_number_list.join(", ");
                    return <span title={text}>{text}</span>
                },
                width: 140,
                sortable: true
            },
            {
                id: "numbers",
                dataKey: "all_numbers",
                label: "Numbers",
                value: ({all_numbers, allocated_numbers}) => `${allocated_numbers}/${all_numbers}`,
                width: 100,
                sortable: true
            },
            {
                id: "rate",
                dataKey: "rate",
                label: "Inc. payout",
                value: ({rate, cur_name, supplier_cur_name}) => `${rate} ${servicePick(service, cur_name, supplier_cur_name)}`,
                width: 100,
                sortable: true
            },
            {
                id: "rate_list",
                dataKey: "rate_list",
                label: servicePick(service, "Billing increment & Dialer's payouts", "Dialer's payouts"),
                value: ({rate_list, rrc_name}) => {
                    const payoutGroups = rate_list.reduce((summ, item) => {
                        return {
                            ...summ,
                            [item.cur_name]: summ[item.cur_name]
                                ? summ[item.cur_name] + `, ${item.payment_terms_name}: ${item.rate} ${item.cur_name}`
                                : `${item.payment_terms_name}: ${item.rate} ${item.cur_name}`
                        }
                    }, {});

                    return (
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            {service && <div style={{width: 70, paddingRight: 0, textAlign: "left", flexShrink: 0}}>{rrc_name}</div>}
                            <div style={{fontSize: 12, lineHeight: "1.2em", flexShrink: 0, flexGrow: 1}}>
                                {Object.keys(payoutGroups).map((item) => <span>{payoutGroups[item]}<br/></span>, "")}
                            </div>
                        </div>
                    )
                },
                width: paymentsColumnWidth,
            },
            ...(!service ? [{
                id: "trafic_limit_list",
                label: "Traffic limits",
                dataKey: "trafic_limit_list",
                // sortable: true,
                value: ({trafic_limit_list}) => {
                    const sortedList = copyArrayWithoutReference(trafic_limit_list).sort((a, b) => {
                        if (a.senderid < b.senderid) {
                            return -1;
                        } else if (a.senderid >= b.senderid) {
                            return 1;
                        }
                        return 0;
                    });
    
                    const firstElement = sortedList.length ? sortedList[0] : {};
                    const remainingElementsList = sortedList.splice(1, sortedList.length);
    
                    const stringElementsList = remainingElementsList.map(value => {
                        const hourDayValue = !value?.hour && !value?.day ? null : `${value.hour} hour/${value.day} day`;
                        
                        if (hourDayValue) {
                            return `${value?.senderid || "<empty>"} — ${hourDayValue}`;
                        }
    
                         return "No limits";
                    });
    
                    const newLinedString = stringElementsList.length ? stringElementsList.map(element => <p>{element}</p>) : "No more limits";
    
                    const hour = firstElement.hour;
                    const day = firstElement.day;
                    const hourDayValue = !hour && !day ? null : `${hour} hour/${day} day`;
                    const finalValue = hourDayValue ? `${firstElement.senderid || "Any senderId"} — ${hourDayValue}` : "No limits";
                    
    
                    return (
                        <Whisper
                            enterable
                            placement="auto"
                            speaker={
                                // Back later
                                <Tooltip>{newLinedString}</Tooltip>
                            }
                        >
                            <span>{finalValue}</span>
                        </Whisper>
                    )
                },
                minWidth: 120,
                minWidthMobile: 140
            }] : []),
            {
                id: "actions",
                dataKey: "actions",
                width: 150,
                value: (rowData) => {
                    const isManagedDisabled = !service ? !rowData?.is_managed : false;
                    return (
                        <ButtonToolbar style={{marginTop: 0}}>
                            <ButtonRow
                                as={IconButton}
                                icon={<Icon icon="edit"/>}
                                disabled={isManagedDisabled}
                                onClick={() => {
                                    const groupData = service ? {
                                        rate_list: rowData.rate_list,
                                        name: rowData.name,
                                        rate: rowData.rate,
                                        ing_key: rowData.ing_key,
                                        rrc_key: rowData.rrc_key,
                                        comment: rowData.comment
                                    } : {
                                        rate_list: rowData.rate_list,
                                        comment: rowData.comment,
                                        name: rowData.name,
                                        rate: rowData.rate,
                                        id: rowData.id
                                    };

                                    onEditClick(groupData);
                                }}
                            />
                            {uploadAllowed && 
                                <ButtonRow
                                    as={IconButton}
                                    icon={<Icon icon="plus"/>}
                                    disabled={isManagedDisabled}
                                    onClick={() => {
                                        const groupData = service ? {
                                            supplier_key: rowData.supplier_key,
                                            rate_list: rowData.rate_list,
                                            name: rowData.name,
                                            rate: rowData.rate,
                                            ing_key: rowData.ing_key
                                        } : {
                                            supplier_id: rowData.supplier_id,
                                            comment: rowData.comment,
                                            name: rowData.name,
                                            rate: rowData.rate,
                                            id: rowData.id
                                        };

                                        onUploadClick(groupData);
                                    }}
                                />
                            }
                            <ButtonRow
                                as={IconButton}
                                icon={<Icon icon="cloud-download"/>}
                                loading={loadableGroups.includes(rowData[rowKey])}
                                onClick={() => onDownloadClick(rowData[rowKey])}
                            />
                            <ButtonRow
                                as={IconButton}
                                icon={<Icon icon="trash"/>}
                                disabled={isManagedDisabled}
                                onClick={onRemoveClick}
                            />
                        </ButtonToolbar>
                    )
                }
            }
        ]
    }, [loadableGroups, paymentTermsList, uploadAllowed]);

    const [sortColumn, setSortColumn] = useState(ASC_SORT);
    const [sortType, setSortType] = useState("supplier_name");
    const [dataState, setData] = useState(data !== null ? data : data);

    useEffect(() => {
        if (data !== null) {
            setData(data);
        }
    }, [data]);

    const handleSortColumn = (column, type) => {
         setSortColumn(column);
         setSortType(type);
         setData(sortData(dataState, column, type));
    };

    return (
        <Table
            ref={ref}
            virtualized
            checkboxPositionStart={true}
            shouldUpdateScroll={false}
            data={dataState}
            extraHeight={(dataState.length && dataState.length < 7) ? (dataState.length * 40) + 40 : 280}
            columns={columns}
            loading={loading}
            rowHeight={40}
            setSelected={onSelectItems}
            onSortColumn={handleSortColumn}
            sortType={sortType}
            sortColumn={sortColumn}
            {...props}
        />
    )
});


const IconComment = ({
    comment,
    group
}) => {

    return (
        <span style={{marginLeft: 10, display: "inline-block"}}>
            <Whisper
                enterable={true}
                placement="rightStart"
                speaker={<Popover style={{ width: 300 }}>{comment}</Popover>}
            >
                <span
                    className={"rs-icon rs-icon-commenting"}
                    style={{color: "var(--color-main)"}}
                />
            </Whisper>
        </span>
    )
};