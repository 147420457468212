import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import {Checkbox, FlexboxGrid, Table as _Table, Alert} from 'rsuite';

import {canIMoveNumbersToAnyTrunk} from '../../../utils'
import {
    ButtonPrimary as Add, ButtonRowAllocate, ButtonRowDelete, ButtonRowUpload,
    ButtonRowDownload, ButtonRowEdit, ButtonRowRevoke
} from '../../../components/base/BaseButton';
import Table from '../../../components/Table/Table';
import {Spacer} from "../../../components/base/Spacer";

import UploadSupplierPrefixModal from './UploadSupplierPrefixModal/UploadSupplierPrefixModal';
import UploadGanNumbersModal from './UploadGanNumbersModal/UploadGanNumbersModal';
import ModalUploadResult from './UploadGanNumbersModal/ModalUploadResult';
import ManageSupplierPlanModal from './ManageSupplierPlanModal/ManageSupplierPlanModal';

import TrunkModal from './TrunkModal/TrunkModal';
import RevokeTrunkModal from './RevokeTrunkModal';
import RemoveTrunkModal from './RemoveTrunkModal';
import {Header, HeaderTrunkActions} from './styled';

const {Column, HeaderCell, Cell} = _Table;


const TrunkRefactored = ({
    trunks,
    account,
    acc_key,
    service,
    columns,
    isMobile,
    ivr_list,
    trunk_id,
    activeTab,
    savedParams,
    isGanTrunks,
    ipOptionList,
    supplierList,
    account_name,
    trunksLoading,
    mobileColumns,
    currentTrunkId,
    isTransitTrunks,
    currentSupplier,
    closedTrunksShow,
    paymentTermsList,
    rerouteStatusList,
    suppliersPlanList,
    transitDefaultLimit,
    accountServicePlanList,
    allocatedNumbersLoading,

    setTrunk,
    getTrunks,
    getNumbers,
    getSuppliers,
    showReasonHandler,
    onAllocateNumbers,
    setCurrentTrunkId,
    createSupplierPlan,
    modifySupplierPlan,
    removeSupplierPlan,
    createTrafficLimit,
    modifyTrafficLimit,
    removeTrafficLimit,
    getSupplierPlanList,
    setClosedTrunksShow,
    trunkDownloadNumbers,
    setCurrentRangeNumber,
}) => {

    const [currentTop, setCurrentTop] = useState(0);
    const [data, setData] = useState([]);
    const [currentTrunkData, setCurrentTrunkData] = useState({});
    const [modalResultData, setModalResultData] = useState(null);
    const [showEditTrunkModal, setShowEditTrunkModal] = useState(false);
    const [showRemoveTrunkModal, setShowRemoveTrunkModal] = useState(false);
    const [showRevokeTrunkModal, setShowRevokeTrunkModal] = useState(false);
    const [showManageSupplierPlanModal, setShowManageSupplierPlanModal] = useState(false);
    const [showUploadPrefixTrunkModal, setShowUploadPrefixTrunkModal] = useState(false);
    const [showUploadGanNumbersModal, setShowUploadGanNumbersModal] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);

    useEffect(() => {
        const columnHeight = 30;

        const trunksList = trunks.filter((trunk) => {
            return closedTrunksShow ? true : !trunk.closed
        });

        let top;

        if (!currentTrunkId)
            top = Math.abs(currentTop);
        else {
            top =  Math.abs((trunksList.map(trunk => trunk.id).indexOf(currentTrunkId) || 0) * columnHeight);
        }

        setCurrentTop(top);
        setData(trunksList);
    }, [trunks, closedTrunksShow]);

    const handleClickTrunk = (id) => {
        setTrunk(id);
        setCurrentRangeNumber(null)
    };
        
    const showTrunk = () => {
        setShowEditTrunkModal(true);
        setCurrentTrunkData({});
    };

    const showManageSupplierPlan = () => {
        setShowManageSupplierPlanModal(true);
    };

    const onDownloadNumbers = async (id) => {

        setData(data.map(
            trunk => ({
                ...trunk,
                downloadLoading: trunk.id === id 
                    ? true 
                    : trunk.downloadLoading
            })
        ));

        const plan_type = isTransitTrunks ? 'transit' : isGanTrunks ? 'gan' : undefined; 

        trunkDownloadNumbers(id, service, plan_type, currentSupplier?.supplier_id);

        setData(() => {
            return data.map(
                trunk => ({
                    ...trunk,
                    downloadLoading: trunk.id === id ? false : trunk.downloadLoading
                })
            )
        });
    };

    const onEditTrunk = (rowData) => {
        const endDate = rowData.end_date 
            ? new Date(rowData.end_date) 
            : null;

        setShowEditTrunkModal(true);
        setCurrentTrunkData({
            ...rowData,
            end_date: endDate 
                ? new Date( 
                    endDate.getFullYear(), 
                    endDate.getMonth(), 
                    endDate.getDate(), 
                    endDate.getHours(), 
                    endDate.getMinutes(), 
                    endDate.getSeconds() - 1 
                )
                : null
        });
    };


    const onRevokeTrunk = () => {
        setShowRevokeTrunkModal(true);
    };


    const onRemoveTrunk = (rowData) => {
        setShowRemoveTrunkModal(true);
        setCurrentTrunkData({...rowData});
    };

    const onUploadPrefixes = (rowData) => {
        if (isTransitTrunks) {
            setShowUploadPrefixTrunkModal(true);
            setCurrentTrunkData({...rowData});
        } else if (isGanTrunks) {
            setShowUploadGanNumbersModal(true);
            setCurrentTrunkData({...rowData});
        }
        
    };

    const onDataUpdate = () => {
        getSupplierPlanList(currentSupplier?.supplier_id);
    };

    const handleCreateSupplierPlan = async (data, defaultData) => {
        const targetParams = {
            supplier_id: currentSupplier?.supplier_id
        };

        const beginData = {
            comment: ''
        };

        const currentTrunkType = isTransitTrunks ? 'transit' : isGanTrunks ? 'gan' : null;
        const discountList = paymentTermsList.map(paymentTerm => {
            const paymentTermKey = `discount_${paymentTerm.name}`;

            if (Object.keys(data).includes(paymentTermKey)) {
                const discount = data[paymentTermKey];

                return {pt_key: paymentTerm.pt_key, discount: parseFloat(discount / 100)};
            }
        });

        data.type = currentTrunkType;
        
        if (isGanTrunks) {
            data.discount_list = discountList;
        }

        const compositeData = {
            ...beginData,
            ...data
        };

        const res = await createSupplierPlan(targetParams, compositeData, defaultData);

        if (res["sms.supplier_plan_id"]) {
            getTrunks(false);
        }

        return res;
    };

    const handleModifySupplierPlan = async (data, defaultData) => {
        const targetParams = {
            supplier_plan_id: data?.id
        };

        const beginData = {
            comment: ''
        };

        const currentTrunkType = isTransitTrunks ? 'transit' : isGanTrunks ? 'gan' : null;

        if (isGanTrunks) {
            const discountList = paymentTermsList.map(paymentTerm => {
                const paymentTermKey = `discount_${paymentTerm.name}`;
    
                const defaultDiscount = defaultData.discount_list.find(({payment_terms_name}) => {
                    return payment_terms_name === paymentTerm.name;
                });
    
                if (Object.keys(data).includes(paymentTermKey)) {
                    const discount = data[paymentTermKey] || defaultDiscount.discount;
                    const isDefaultDiscount = !data[paymentTermKey];
                    const discountValue = isDefaultDiscount ? discount :  parseFloat(discount / 100);
                    
                    return {pt_key: paymentTerm.pt_key, discount: discountValue}
                }
            });

            data.discount_list = discountList;
        }

        const compositeData = {
            ...beginData,
            ...data
        };
        
        const res = await modifySupplierPlan(targetParams, compositeData, defaultData);

        if (res["sms.supplier_plan_id"]) {
            getTrunks(false);
        }

        return res;
    };

    const handleRemoveSupplierPlan = async (id) => {
        const targetParams = {
            supplier_plan_id: id
        };

        const res = await removeSupplierPlan(targetParams);

        if (res["sms.supplier_plan_id"]) {
            getTrunks(false);
        }
        
        return res;
    };


    const transformedData = data.map(item => {
        return {
            ...item,
            start_date: item.start_date ? item.start_date.replace(" ", "T") : "",
            end_date: item.end_date ? item.end_date.replace(" ", "T") : "",
        }
    });

    const currentTrunk = trunks.find((trunk) => trunk.id === trunk_id);

    const headers = {
        'trunks': 'Trunks',
        'gan_trunks': 'GAN Trunks',
        'transit_trunks': 'Transit Trunks',
        'default': 'Trunks'
    };

    const defaultActionCells = ActionCell(
        onEditTrunk,
        onDownloadNumbers,
        onAllocateNumbers,
        onRevokeTrunk,
        onRemoveTrunk,
    );

    const supplierActionCells = ActionCell(
        onEditTrunk,
        onDownloadNumbers,
        null,
        null,
        onRemoveTrunk,
        onUploadPrefixes
    );

    const actionCells = (isGanTrunks || isTransitTrunks) ? supplierActionCells : defaultActionCells;

    const filteredSupplierPlanList = suppliersPlanList && suppliersPlanList.length 
        ? suppliersPlanList.filter(supplier => {
            return isTransitTrunks ? supplier.type === "transit" : 
                isGanTrunks ? supplier.type === "gan" : true
        }) : [];

    return (<div>
        <Spacer size={10}/>
        <FlexboxGrid align={"middle"}>
                
                
        {!isMobile &&
            <FlexboxGrid.Item>
                <Header>{headers[activeTab] || headers['default']}</Header>
            </FlexboxGrid.Item>
        }


        <FlexboxGrid.Item  style={{marginLeft: 'auto'}}>
            <FlexboxGrid align={"middle"}>

                {!isMobile &&
                    <FlexboxGrid.Item>
                        <StyledFilter>
                            <Checkbox 
                                onClick={setClosedTrunksShow}
                            />
                            <span>Show closed trunks</span>
                        </StyledFilter>
                    </FlexboxGrid.Item>
                }

                <FlexboxGrid.Item>
                    <HeaderTrunkActions>
                        <Add onClick={showTrunk}>+Add New Trunk</Add>
                    </HeaderTrunkActions>
                </FlexboxGrid.Item>

                {(isGanTrunks || isTransitTrunks) && <FlexboxGrid.Item style={{marginLeft: 10}}>
                    <HeaderTrunkActions>
                        <Add onClick={showManageSupplierPlan}>Manage Trunk Type</Add>
                    </HeaderTrunkActions>
                </FlexboxGrid.Item>}


            </FlexboxGrid>
        </FlexboxGrid.Item>
    </FlexboxGrid>


    {showEditTrunkModal &&
        <TrunkModal
            isGanTrunks={isGanTrunks}
            isTransitTrunks={isTransitTrunks}
            accountServicePlanList={accountServicePlanList}
            initialValues={currentTrunkData}
            ipOptionList={ipOptionList}
            ivr_list={ivr_list}
            account_id={acc_key}
            account={account}
            account_name={account_name}
            update={(extraUpdate=false) => {
                const isSuppliersTrunks = (isGanTrunks || isTransitTrunks);
                const updateNumbers = !isSuppliersTrunks;
                getTrunks(updateNumbers || extraUpdate);
            }}
            trunksList={trunks}
            service={service}
            transitDefaultLimit={transitDefaultLimit}
            currentSupplier={currentSupplier}
            suppliersPlanList={filteredSupplierPlanList}
            rerouteStatusList={rerouteStatusList}
            onClose={() => setShowEditTrunkModal(false)}
            createTrafficLimit={createTrafficLimit}
            modifyTrafficLimit={modifyTrafficLimit}
            removeTrafficLimit={removeTrafficLimit}
        />
    }

    {showUploadPrefixTrunkModal && 
        <UploadSupplierPrefixModal
            show={showUploadPrefixTrunkModal}
            currentTrunkData={currentTrunkData}
            currentSupplier={currentSupplier}
            onSuccess={(response) => {
                const inserted = response['supplier_prefixes']?.inserted || 0;
                const updated = response['supplier_prefixes']?.updated || 0;
                const count = inserted + updated;

                Alert.success(`Trunk ${count} prefixes has been uploaded`);
                
                setShowUploadPrefixTrunkModal(false);
                getNumbers(savedParams?.group, savedParams?.filter, savedParams?.page, savedParams?.per_page, savedParams?.sort);
            }}
            onClose={() => {
                setShowUploadPrefixTrunkModal(false);
            }}
        />
    }

    {showUploadGanNumbersModal && 
        <UploadGanNumbersModal
            show={showUploadGanNumbersModal}
            trunks={trunks}
            service={service}
            supplierList={supplierList}
            getSuppliers={getSuppliers}
            currentTrunkId={currentTrunkId}
            currentSupplier={currentSupplier}
            showReasonHandler={showReasonHandler}
            onSuccess={(response) => {
                // Alert.success(`Trunk ${response['uploaded_numbers']} prefixes has been uploaded`);    
                setShowUploadGanNumbersModal(false);

                if (!('error' in response)) {
                    setModalResultData({
                        ...response
                    });
                    setShowResultModal(true);  
                }

                getNumbers(savedParams?.group, savedParams?.filter, savedParams?.page, savedParams?.per_page, savedParams?.sort);
            }}
            onClose={() => {
                setShowUploadGanNumbersModal(false);
            }}
        />
    }

    {showResultModal &&
        <ModalUploadResult
            show={showResultModal}
            data={modalResultData}
            onClose={() => setShowResultModal(false)}
            onExited={() => setModalResultData(null)}
        />
    }

    {showManageSupplierPlanModal &&
        <ManageSupplierPlanModal
            isGanTrunks={isGanTrunks}
            isTransitTrunks={isTransitTrunks}
            paymentTermsList={paymentTermsList}
            suppliersPlanList={suppliersPlanList}
            // createSupplierPlan={createSupplierPlan}
            // modifySupplierPlan={modifySupplierPlan}
            // removeSupplierPlan={removeSupplierPlan}

            onDataUpdate={onDataUpdate}
            onRowCreate={handleCreateSupplierPlan}
            onRowSubmit={handleModifySupplierPlan}
            onRowRemove={handleRemoveSupplierPlan}

            currentSupplier={currentSupplier}
            getSupplierPlanList={getSupplierPlanList}
            onClose={() => setShowManageSupplierPlanModal(false)}
        />
    }


    <Table
        active_id={trunk_id}
        data={transformedData}
        columns={!isMobile ? columns : mobileColumns}
        loading={trunksLoading || allocatedNumbersLoading}
        rowHeight={!isMobile ? 30 : 50}
        shouldUpdateScroll={false}
        rowClassName={(rowData) => rowData && rowData.closed
            ? 'closed-row'
            : (rowData && !rowData.active) ? 'row-disabled' : ''
        }
        
        ActionCell={actionCells}
        onRowClick={handleClickTrunk}
    />


    {showRevokeTrunkModal &&
        <RevokeTrunkModal
            service={service}
            account_id={acc_key}
            trunk_id={trunk_id}
            onClose={() => setShowRevokeTrunkModal(false)}
            update={() => getTrunks(true)}
        />
    }


    {showRemoveTrunkModal &&
        <RemoveTrunkModal
            service={service}
            account_id={acc_key}
            trunk_id={trunk_id}
            data={currentTrunkData}
            isSupplierTrunks={isGanTrunks || isTransitTrunks}
            trunkList={data.filter(trunk => canIMoveNumbersToAnyTrunk(currentTrunk, trunk))}
            onClose={() => setShowRemoveTrunkModal(false)}
            update={() => {
                // const isSuppliersTrunks = (isGanTrunks || isTransitTrunks);
                const updateNumbers = false;

                getTrunks(updateNumbers);
                setCurrentTrunkId(null);
            }}
        />
    }


    </div>);
};


const ActionCell = (edit, download, allocate, revoke, remove, uploadPrefix) => (
    <Column width={218}>
        <HeaderCell>Actions</HeaderCell>
        <Cell>
            {rowData => (
                <StyledActionCell>
                    
                    {edit && <ButtonRowEdit 
                        onClick={() => edit(rowData)}
                    />}
                    
                    {download && <ButtonRowDownload 
                        loading={rowData.downloadLoading} 
                        onClick={() => download(rowData.id)}
                    />}
                    
                    {allocate ? rowData.incorporated
                        ? <ButtonRowAllocate
                            tooltipText='You cannot allocate global access numbers from here'
                            tooltipPlacement='left'
                            disabled={true}
                        />
                        : <ButtonRowAllocate 
                            disabled={rowData.closed || !rowData.active} 
                            onClick={() => allocate(rowData.id)}
                        />
                    : <></>}
                    
                    {revoke && <ButtonRowRevoke 
                        onClick={() => revoke(rowData.id)}
                    />}
 
                    {(rowData.custom_ranges || rowData.type === "gan") && uploadPrefix && <ButtonRowUpload
                        onClick={() => uploadPrefix(rowData)}
                    />}

                    {remove && <ButtonRowDelete 
                        onClick={() => remove(rowData)}
                    />}

                </StyledActionCell>
            )}
        </Cell>
    </Column>
);

export default TrunkRefactored;

const StyledFilter = styled.div.attrs(props => ({
    className: "",
}))`
    align-items: center !important;
    display: flex !important;
    padding-right: 20px;
`;

const StyledActionCell = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
`;