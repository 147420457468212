import React, {useRef, useState, useEffect} from "react";
import { Alert, Form, FormControl, FormGroup, Checkbox, CheckboxGroup, ControlLabel, SelectPicker, CheckPicker, FlexboxGrid } from "rsuite";
import ServicePlans from "components/Filters/ServicePlans";
import {ButtonPrimary} from "components/base/BaseButton";
import { FilterSectionFlexboxGrid, StyledItemFitWidth } from "components/base/FitContainers";


import styled from "styled-components";
import m from "definedMessages";


const AcessListFiltersSettings = ({
    service,
    loading,
    accountId,
    settingsValue,
    settingsIsFetched,
    setSettingsValue,

    handleSubmit,

    formatMessage
}) => {

    const formRef = useRef(null);

    const [isDaily, setIsDaily] = useState(false);
    const [isAppearence, setIsAppearence] = useState(false);


    const [formValueState, setFormValueState] = useState(settingsValue);

    useEffect(() => {
        if (settingsIsFetched) {
            const defaultValue = getDefaultSettings();

            setFormValueState(defaultValue);

            setIsDaily(defaultValue.daily);
            setIsAppearence(defaultValue.appearance);
        }
    }, [settingsValue, settingsIsFetched]);

    const getDefaultSettings = () => {
        const start_time = settingsValue.start_time;
        const during = `${start_time}-23:59:59`;

        return (
            {
                ...settingsValue,
                daily: settingsValue.daily,
                appearance: settingsValue.appearance,
                during: during,
                prices: settingsValue.prices
            }
        )
    };


    const getDuringOptions = () => {
        const duringOptions = [];

        for (let i = 0; i < 24; i++) {
            const start_time = new Date(0,0,0, i);
            const end_time = new Date(0,0,0, i + 1);
    
            duringOptions.push({
                dr_key: `${start_time.toLocaleTimeString("it-IT")}-23:59:59`,
                dr_name: `${start_time.toLocaleTimeString(["it-IT"], { hour: "2-digit", minute: "2-digit" })} - ${end_time.toLocaleTimeString(["it-IT"], { hour: "2-digit", minute: "2-digit" })} GMT`
            });
        }

        return duringOptions;
    };

    const handleApply = () => {
        if ( !formRef.current.check() )
            return;

        const formValues = formRef.current.getFormValue();

        //get time values
        const during = formValues["during"];
        let start_time = "00:00:00";
        let end_time = "23:59:59";

        if (during !== null) {
            [start_time, end_time] = during.split("-");
        }

        const newData = {
            daily: isDaily || false,
            appearance: isAppearence || false,
            sp_key_list: formValues.sp_key_list ? formValues.sp_key_list : [],
            start_time: start_time,
            end_time: end_time,
            type: formValues.type ? formValues.type : 2
        };

        handleSubmit(accountId, newData).then(() => {
            Alert.success(formatMessage(m.saved));
        });
    };

    return (<StyledForm
        ref={formRef}
        formValue={formValueState}
        formDefaultValue={settingsValue}
        onChange={(value) => {
            let start_time = "00:00:00";
            let end_time = "23:59:59";
    
            if (value.during !== null) {
                [start_time, end_time] = value.during.split("-");
            }

            const newValue = {
                ...value,
                start_time,
                end_time
            };

            setSettingsValue(newValue);
            setFormValueState(newValue);
        }}
    >
        <SettingsContainer>
            <Checkbox checked={isAppearence}  onChange={(_, checked) => {
                setIsAppearence(checked);
            }}>{formatMessage(m.perAppearanceForFilteredDestinations)}</Checkbox>

            <Checkbox checked={isDaily} onChange={(_, checked) => {
                setIsDaily(checked);
            }}>{formatMessage(m.daily)}</Checkbox>
        </SettingsContainer>
        <FilterSectionFlexboxGrid>
            <StyledItemFitWidth>
                <SettingsContainer>
                    <ServicePlans
                        accepter={CheckPicker}
                        name="sp_key_list"
                        disabled={!isDaily}
                        filtered
                        style={{marginLeft: "12px"}}
                    />
                    <FormControl
                        name="during"
                        accepter={SelectPicker}
                        data={getDuringOptions()}
                        labelKey="dr_name"
                        valueKey="dr_key"
                        placeholder="During time"
                        style={{width: "180px"}}
                        searchable={false}
                        disabled={!isDaily}
                        cleanable={false}
                    />
                    <FormControl
                        name="type"
                        accepter={SelectPicker}
                        data={[
                            {tp_key: 1, tp_name: formatMessage(m.typeFullPrice)},
                            {tp_key: 2, tp_name: formatMessage(m.typeJustForAllocated)},
                            {tp_key: 4, tp_name: formatMessage(m.typeOnlyFilteredDestinations)}
                        ]}
                        labelKey="tp_name"
                        valueKey="tp_key"
                        placeholder={formatMessage(m.type)}
                        style={{width: "180px"}}
                        searchable={false}
                        disabled={!isDaily}
                    />
                    <ButtonPrimary
                        style={{padding: "8px 34px", marginLeft: 12}}
                        onClick={handleApply}
                        disabled={loading}
                    >
                        {formatMessage(m.save)}
                    </ButtonPrimary>
                </SettingsContainer>
            </StyledItemFitWidth>
            {/* <StyledItemAutoMargin></StyledItemAutoMargin> */}
        </FilterSectionFlexboxGrid>
    </StyledForm>)
};


export default AcessListFiltersSettings;


const StyledForm = styled(Form)`
    && {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .rs-form-control-wrapper {
        width: auto;
        margin-right: 20px;
    }

    && .allocated-wrapper .rs-picker-toggle-wrapper {
        width: auto ;
        margin-right: 20px;
        margin-left: 0px !important;
    }
    && .rs-form-group {
        margin-bottom: 0px !important;
    }
`;


const SettingsContainer = styled.div`
    && {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
`;